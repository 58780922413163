import { Button, Dialog, useToasts, Text, DateRangePicker } from "@puzzle/ui";
import { endOfMonth, parseDate, today } from "@puzzle/utils";
import { downloadFileFromPath } from "components/common/files/utils";
import { useActiveCompany } from "components/companies";
import { MonthlyExpensesExceededBannerInline } from "components/featureGate/MonthlyExpensesExceededBanner";
import { useExpensesExceededFeatureGate } from "components/featureGate/useExpensesExceededFeatureGate";
import Analytics from "lib/analytics";
import React, { useCallback } from "react";
import { StyledToolbar } from "../common";
import { ReportsPageAccountFragment, useTransactionsReportLazyQuery } from "../graphql.generated";
import { useReportFilter } from "../useReportFilter";
import { Box, S } from "ve";
import { inputBlock, inputLabel } from "../Reports.css";

interface Props {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  account?: ReportsPageAccountFragment;
}

export const TransactionReportExportModal = ({
  open,
  onConfirm,
  onCancel,
  title,
  account,
}: Props) => {
  const { toast } = useToasts();
  const { company, timeZone } = useActiveCompany<true>();
  const { filters, setFilters } = useReportFilter();
  const { anyDatesExpenseGated } = useExpensesExceededFeatureGate();
  const isExpensesExceeded = anyDatesExpenseGated([filters.start, filters.end]);
  const [getTransactionsReport, { loading }] = useTransactionsReportLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.transactionsReport.file) {
        downloadFileFromPath(data.transactionsReport.file.downloadInfo.signedUrl);
        Analytics.reportDownloaded({
          reportId: data.transactionsReport.file.id,
          reportType: "TransactionReport",
        });
      }
    },
    onError: () => {
      toast({
        status: "error",
        message:
          "Your report failed to generate. Please try again. If the issue persists, please narrow your query or contact support.",
      });
    },
  });

  const getCsv = useCallback(() => {
    getTransactionsReport({
      variables: {
        input: {
          companyId: company.id,
          accountId: account?.id,
          range: {
            start: filters.start.toString(),
            end: endOfMonth(parseDate(filters.end.toString())).toString(),
            timePeriodKey: "month",
          },
        },
      },
    });
  }, [getTransactionsReport, filters, company, account]);
  return (
    <Dialog open={open} size="small" onOpenChange={onCancel}>
      <Dialog.Title>Export {title} report</Dialog.Title>
      <Dialog.Body css={{ padding: 0 }}>
        <div className={inputBlock}>
          <div className={inputLabel}>Export format</div>
          <Text variant="bodyS" color="$gray100">
            CSV
          </Text>
        </div>
        <div className={inputBlock}>
          <div className={inputLabel}>Set period range</div>
          <StyledToolbar>
            <DateRangePicker
              value={[filters.start, filters.end]}
              onChange={([s, e]) => {
                setFilters({ start: s, end: e });
              }}
              maxDate={today(timeZone)}
              view={"month"}
              groupBy={"month"}
              showLabel={false}
              dark={false}
            />
          </StyledToolbar>
        </div>
        {isExpensesExceeded && (
          <Box css={{ paddingLeft: S["3"], paddingRight: S["3"], paddingTop: S["3"] }}>
            <MonthlyExpensesExceededBannerInline subtext="download reports for the selected period" />
          </Box>
        )}
      </Dialog.Body>
      <Dialog.Footer align="right">
        <Dialog.Actions>
          <Dialog.Close asChild>
            <Button variant="secondary" onClick={onCancel} autoFocus>
              Cancel
            </Button>
          </Dialog.Close>
          <Button
            variant="primary"
            onClick={() => {
              toast({
                status: null,
                message:
                  "We are generating your report. Large reports may take some time but will export once complete.",
              });
              Analytics.reportModalExportClicked({
                reportType: "TransactionReport",
                startDate: filters.start.toString(),
                endDate: filters.end.toString(),
              });
              getCsv();
              onConfirm();
            }}
            disabled={isExpensesExceeded}
          >
            Export
          </Button>
        </Dialog.Actions>
      </Dialog.Footer>
    </Dialog>
  );
};
