import React from "react";
import { styled } from "@puzzle/ui";

import { CompanyOnboardChecklistTasktype } from "components/dashboard/Inbox/shared";
import { IntegrationsMainPage } from "components/integrations";
import { useActiveCompany } from "components/companies";

import { InviteUsersPane } from "./InviteUsersPane";
import { BookCallCTAPane } from "./BookCallPane";
import { OnboardingTopTransactionsPane } from "./OnboardingTopTransactionsPane";
import { FeedItem } from "@knocklabs/client";
import { ActionHeader } from "./ActionHeader";
import { BookkeepingTaxPrepPane } from "./BookkeepingTaxPrepPane";
import { Box } from "ve";

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
  padding: "$3 $4 $6 $5",
  overflow: "auto",

  defaultVariants: {
    minimal: false,
  },

  variants: {
    minimal: {
      true: {
        padding: 0,
      },
    },
    false: {},
  },
});

export const CompanyOnboardChecklistPane = ({
  item,
  checklistTaskType,
}: {
  item: FeedItem;
  checklistTaskType: CompanyOnboardChecklistTasktype;
}) => {
  const { company } = useActiveCompany<true>();

  switch (checklistTaskType) {
    case CompanyOnboardChecklistTasktype.BookkeepingTaxPrep: {
      return (
        <Box css={{ height: "100%", width: "100%" }}>
          <ActionHeader item={item} location="bookkeeping-tax-prep" />
          <BookkeepingTaxPrepPane />
        </Box>
      );
    }

    case CompanyOnboardChecklistTasktype.InviteTeam: {
      return (
        <Box css={{ height: "100%", width: "100%" }}>
          <ActionHeader item={item} location="invite-users" />
          <InviteUsersPane />
        </Box>
      );
    }

    case CompanyOnboardChecklistTasktype.ConnectAccounts: {
      return (
        <>
          <ActionHeader item={item} location="connect-accounts" />
          <Container>
            <IntegrationsMainPage />
          </Container>
        </>
      );
    }
    case CompanyOnboardChecklistTasktype.BookOnboardingCall: {
      return (
        <>
          <ActionHeader item={item} location="book-call" />
          <BookCallCTAPane />
        </>
      );
    }

    case CompanyOnboardChecklistTasktype.CategorizeTopTransactions: {
      return (
        <Box css={{ height: "100%", width: "100%" }}>
          <ActionHeader item={item} location="top-transactions" />
          <OnboardingTopTransactionsPane />
        </Box>
      );
    }

    default:
      return null;
  }
};
