import React from "react";
import { styled } from "@puzzle/ui";

const Logo = styled("div", {
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",

  variants: {
    borderRoudness: {
      normal: {
        borderRadius: "12px",
      },
      circular: {
        borderRadius: "50%",
      },
    },
  },
});

const IntegrationLogo = ({
  children,
  color = "transparent",
  width = "100%",
  height = "100%",
  circular = false,
  onClick,
  ...props
}: React.PropsWithChildren<{
  color?: string;
  width?: string;
  height?: string;
  circular?: boolean;
  onClick?: () => void;
}>) => {
  return (
    <Logo
      color={color}
      onClick={onClick}
      borderRoudness={circular ? "circular" : "normal"}
      css={{
        backgroundColor: color,
        width: width,
        height: height,
      }}
      {...props}
    >
      {children}
    </Logo>
  );
};

export default IntegrationLogo;
