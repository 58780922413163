import React from "react";
import * as Stitches from "@stitches/react";
import { Crown } from "@puzzle/icons";
import { Button } from "@puzzle/ui";

import useMonetization from "components/monetization/useMonetization";

interface UpgradeButtonProps {
  title?: string;
  onClick?: () => void;
  css?: Stitches.CSS;
  showIcon?: boolean;
  variant?: "outline" | "primary";
}

const UpgradeButton = ({
  onClick,
  css,
  title,
  variant = "primary",
  showIcon = true,
}: UpgradeButtonProps) => {
  const { showUpgradeModal } = useMonetization();

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    showUpgradeModal();
  };

  return (
    <Button
      prefix={showIcon ? <Crown css={{ marginRight: "$1" }} /> : undefined}
      onClick={handleClick}
      css={css}
      variant={variant}
    >
      {title || "Upgrade"}
    </Button>
  );
};

export default UpgradeButton;
