import React, { useCallback, useState, useMemo, ReactElement } from "react";
import { usePreviousDistinct } from "react-use";

import { AlertDialog, Stack } from "@puzzle/ui";

import DescriptionList from "components/common/DescriptionList";
import { useFile } from "components/common/files";
import {
  AssociatedEntity,
  FileFragment,
} from "graphql/types";
import { ApolloCache } from "@apollo/client";
import { BaseRow } from "./BaseRow";

const FileRow = ({ file, setPreviewFile, onDelete }: { file: File, setPreviewFile: (file: File) => void, onDelete: (file: File) => void }) => {
  return (
    <BaseRow
      size={file.size}
      fileName={file.name}
      downloadUrl={URL.createObjectURL(file)}
      setPreviewFile={() => setPreviewFile(file)}
      onDelete={e => {
        e.stopPropagation();
        onDelete(file);
      }}
    />
  );
};

const DocumentRow = ({
  document,
  setPreviewFile,
  onDeleteFileIntent,
  uploadedBy
}: {
  setPreviewFile: (file?: FileFragment) => void;
  document: { file: FileFragment };
  onDeleteFileIntent: (file: FileFragment) => void;
  uploadedBy?: (document: { file: FileFragment }) => ReactElement | null
}) => {
  const { downloadInfo } = useFile({
    file: document.file,
  });
  const { filename, size } = document.file;
  const onDelete: React.MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      onDeleteFileIntent(document.file);
    },
    [document.file, onDeleteFileIntent]
  );

  return (
    <BaseRow
      size={size}
      fileName={filename ?? ''}
      downloadUrl={downloadInfo?.signedUrl}
      setPreviewFile={() => setPreviewFile(document.file)}
      onDelete={onDelete}
      uploadedBy={uploadedBy?.(document)}
    />
  );
};

export const DocumentationSection = React.memo(function DocumentationSection({
  label,
  refetch,
  documents,
  uploadedBy,
  setPreviewFile,
  associatedEntity,
  inMemoryFiles = [],
  deleteInMemoryFile
}: {
  label?: string;
  setPreviewFile: (file?: FileFragment | File) => void;
  associatedEntity: AssociatedEntity;
  refetch?: <T>(cache: ApolloCache<T>, fileId: string) => void;
  documents: { file: FileFragment }[]
  uploadedBy?: (document: { file: FileFragment }) => ReactElement | null
  inMemoryFiles?: File[]
  deleteInMemoryFile?: (file: File) => void
}) {
  const hasDocumentation = documents.length > 0;
  const hasInMemoryDocumentation = inMemoryFiles.length > 0;
  const [deleteFileIntent, setDeleteFileIntent] = useState<FileFragment | null>(null);
  const [deleteInMemoryFileIntent, setDeleteInMemoryFileIntent] = useState<File | null>(null);

  const { deleteFile: _deleteFile } = useFile({
    entityType: associatedEntity,
  });
  const displayedDeleteFileIntent = usePreviousDistinct(deleteFileIntent) ?? deleteFileIntent;
  const displayedDeleteInMemoryFileIntent = usePreviousDistinct(deleteInMemoryFileIntent) ?? deleteInMemoryFileIntent;

  const deleteFile: React.MouseEventHandler = useCallback(async () => {

    if (deleteInMemoryFileIntent) {
      deleteInMemoryFile?.(deleteInMemoryFileIntent);
      return;
    }

    if (!deleteFileIntent) {
      return;
    }

    const fileId = deleteFileIntent.id;

    await _deleteFile({
      fileId,
      update(cache, { data }) {
        if (!data?.deleteFile.fileId) {
          return;
        }
        refetch?.(cache, data.deleteFile.fileId);
      },
    });
  }, [_deleteFile, deleteFileIntent, deleteInMemoryFileIntent, refetch, documents]);

  const list = useMemo(
    () => {
      const items = (
        <>
          {hasDocumentation && (
            <Stack
              // feels a bit squeezed?
              gap="0h"
            >
              {[...documents].reverse().map((document) => (
                <DocumentRow
                  setPreviewFile={setPreviewFile}
                  key={document.file.id}
                  document={document}
                  onDeleteFileIntent={setDeleteFileIntent}
                  uploadedBy={uploadedBy}
                />
              ))}
            </Stack>
          )}
          {hasInMemoryDocumentation && (
            <Stack
              // feels a bit squeezed?
              gap="0h"
            >
              {inMemoryFiles.map((file) => (
                <FileRow
                  key={file.name}
                  file={file}
                  setPreviewFile={setPreviewFile}
                  onDelete={setDeleteInMemoryFileIntent}
                />
              ))}
            </Stack>
          )}
          {!hasDocumentation && !hasInMemoryDocumentation && ("None")}
        </>
      );

      if (!label) {
        return items;
      }

      return (
        <DescriptionList
          itemGap="1"
          direction={(hasDocumentation || hasInMemoryDocumentation) ? "vertical" : "horizontal"}
          items={[
            [
              label,
              items
            ],
          ]}
        />
      );
    },
    [hasDocumentation, hasInMemoryDocumentation, inMemoryFiles, documents, label]
  );

  const deleteWarning = useMemo(
    () =>
      (displayedDeleteFileIntent || displayedDeleteInMemoryFileIntent) && (
        <AlertDialog
          size="small"
          open={Boolean(deleteFileIntent) || Boolean(deleteInMemoryFileIntent)}
          onOpenChange={(open) => {
            if (!open) {
              setDeleteFileIntent(null);
              setDeleteInMemoryFileIntent(null);
            }
          }}
        >
          <AlertDialog.Title>Delete this document?</AlertDialog.Title>
          <AlertDialog.Body>
            {`Are you sure you want to delete "${displayedDeleteFileIntent?.filename ?? displayedDeleteInMemoryFileIntent?.name}"? This cannot be undone.`}
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <AlertDialog.CancelButton>Cancel</AlertDialog.CancelButton>
            <AlertDialog.ConfirmButton onClick={deleteFile}>Delete</AlertDialog.ConfirmButton>
          </AlertDialog.Footer>
        </AlertDialog>
      ),
    [deleteFile, deleteFileIntent, deleteInMemoryFileIntent, displayedDeleteFileIntent, displayedDeleteInMemoryFileIntent, deleteInMemoryFile]
  );

  return (
    <>
      {list} {deleteWarning}
    </>
  );
});
