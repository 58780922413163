/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 175
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/puzzle-5299/Puzzle/implementation/web)
 */

import * as amplitude from "@amplitude/analytics-browser";

export type Environment = "production" | "development";

export const ApiKey: Record<Environment, string> = {
  production: "",
  development: "",
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: "175",
    branch: "main",
    source: "web",
    versionId: "0450757b-5c7b-4389-99ec-9c24e12129f9",
  },
  ...{
    ingestionMetadata: {
      sourceName: "browser-typescript-ampli",
      sourceVersion: "2.0.0",
    },
  },
};

export interface LoadOptionsBase {
  disabled?: boolean;
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & {
  environment: Environment;
  client?: { configuration?: BrowserOptions };
};
export type LoadOptionsWithApiKey = LoadOptionsBase & {
  client: { apiKey: string; configuration?: BrowserOptions };
};
export type LoadOptionsWithClientInstance = LoadOptionsBase & {
  client: { instance: BrowserClient };
};

export type LoadOptions =
  | LoadOptionsWithEnvironment
  | LoadOptionsWithApiKey
  | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  initial_utm_content?: string;
  initial_utm_medium?: string;
  initial_utm_source?: string;
  position?: string;
}

export interface AccountCreatedProperties {
  displayId: string;
  displayName: string;
  isParent: boolean;
}

export interface AccountDeactivatedProperties {
  ledgerAccountId: string;
}

export interface AccountRemovedProperties {
  accountId: string;
  automated?: boolean;
  errorCode?: string;
}

export interface AccountUpdatedProperties {
  displayId: string;
  displayName: string;
}

export interface AccountingRecordAttachmentDeletedProperties {
  fileId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Bill, ManualJournalEntry, LedgerReconciliation |
   */
  uploadEntityType: "Bill" | "ManualJournalEntry" | "LedgerReconciliation";
}

export interface AccountingRecordAttachmentUploadedProperties {
  contentType: string;
  fileName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  fileSize: number;
  id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Bill, ManualJournalEntry, LedgerReconciliation |
   */
  uploadEntityType: "Bill" | "ManualJournalEntry" | "LedgerReconciliation";
}

export interface AccountingRecordCommentAddedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  commentLength: number;
  entityId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | BILL, MANUAL_JOURNAL_ENTRY |
   */
  recordType: "BILL" | "MANUAL_JOURNAL_ENTRY";
}

export interface AccrualEventsEnabledProperties {
  companyId: string;
}

export interface AddClassTagProperties {
  /**
   * For distinguishing whether the event is created by a user or auto
   */
  actionType?: string;
  entityType: string;
}

export interface AiCategorizerEnabledProperties {
  companyId: string;
  enabled: boolean;
}

export interface AskAnAccountantButtonClickedProperties {
  location?: string;
}

export interface AssistedCategorizationEnabledProperties {
  companyId: string;
  userId?: string;
}

export interface AutomaticAssignmentEnabledProperties {
  companyId: string;
  userId?: string;
}

export interface AutonomousAccountingToggledProperties {
  companyId: string;
  enabled: boolean;
}

export interface BankRecAccountSelectedProperties {
  accountId: string;
}

export interface BankRecApprovedProperties {
  id: string;
}

export interface BankRecBalanceUpdatedProperties {
  accountId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Beginning, Ending |
   */
  balancePeriod: "Beginning" | "Ending";
  id: string;
  /**
   * Number of split rows
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalSplits: number;
}

export interface BankRecCreatedProperties {
  accountId: string;
  id: string;
  startDate: string;
}

export interface BankRecResumedProperties {
  hasWarnings: boolean;
  id: string;
  /**
   * Bank reconciliation status
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | SystemDrafted, Reconciled, InProgress |
   */
  status: "SystemDrafted" | "Reconciled" | "InProgress";
}

export interface BankRecStatementToggledProperties {
  isExpanding: boolean;
}

export interface BankRecStatementUploadedProperties {
  id: string;
}

export interface BankRecTransactionAddedProperties {
  id: string;
  transactionId: string;
}

export interface BankRecTransactionRemovedProperties {
  id: string;
  transactionId: string;
}

export interface BankRecUnapprovedProperties {
  id: string;
}

export interface BankRecUserTransactionsUpdatedProperties {
  id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  totalCount: number;
}

export interface BankRecViewedProperties {
  id: string;
}

export interface BillAttachmentDeletedProperties {
  fileId: string;
}

export interface BillAttachmentUploadedProperties {
  contentType: string;
  fileName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  fileSize: number;
  id: string;
}

export interface BillDeletedProperties {
  id: string;
}

export interface BillImportCsvUploadFailedProperties {
  contentType: string;
  fileName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  fileSize: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalRows: number;
}

export interface BillImportFailedProperties {
  contentType: string;
  fileName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  fileSize: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalRows: number;
}

export interface BillImportInvalidFormatProperties {
  contentType: string;
  fileName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  fileSize: number;
}

export interface BillImportSucceededProperties {
  contentType: string;
  fileName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  fileSize: number;
  id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalRows: number;
}

export interface BillImportTimedOutProperties {
  contentType: string;
  fileName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  fileSize: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalRows: number;
}

export interface BillPaymentToggledProperties {
  id: string;
  isPaid: boolean;
}

export interface BillPostFailedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalRows: number;
}

export interface BillPostedProperties {
  id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalRows: number;
}

export interface BillViewFailedProperties {
  id: string;
}

export interface BillViewedProperties {
  id: string;
}

export interface BillVoidedProperties {
  id: string;
}

export interface BurnTreemapZoomDecreasedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  breadcrumbCount: number;
  label: string;
}

export interface BurnTreemapZoomIncreasedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  breadcrumbCount: number;
  label: string;
}

export interface CategorizerDateRangeSelectedProperties {
  endDate: string;
  startDate: string;
}

export interface CategorizerLoadMoreClickedProperties {
  location?: string;
}

export interface CategorizerRoundFinishedProperties {
  closed?: boolean;
  endDate?: string;
  location?: string;
  startDate?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  transactionsCategorized: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  transactionsLoaded: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  transactionsViewed: number;
}

export interface CategorizerRoundStartedProperties {
  endDate?: string;
  location?: string;
  startDate?: string;
}

export interface ChatWithAnExpertClickedProperties {
  location?: string;
}

export interface ChecklistCompletedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | HistoricalBooks |
   */
  checklistType?: "HistoricalBooks";
  month?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  numberOfItems: number;
}

export interface ChecklistItemAddedProperties {
  description: string;
  id: string;
  repeat: boolean;
}

export interface ChecklistItemFinalizedProperties {
  id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | TransactionsFinalized, TransactionsCategorized, BalanceSheet, ProfitAndLoss |
   */
  itemSubType?:
    | "TransactionsFinalized"
    | "TransactionsCategorized"
    | "BalanceSheet"
    | "ProfitAndLoss";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | ProgressChecklistItem, AccountChecklistItem, StatementChecklistItem, CustomChecklistItem, BalanceChecklistItem, AmountsChecklistItem, ReviewChecklistItem, BasicChecklistItem, PaymentProcessingAccountChecklistItem |
   */
  itemType:
    | "ProgressChecklistItem"
    | "AccountChecklistItem"
    | "StatementChecklistItem"
    | "CustomChecklistItem"
    | "BalanceChecklistItem"
    | "AmountsChecklistItem"
    | "ReviewChecklistItem"
    | "BasicChecklistItem"
    | "PaymentProcessingAccountChecklistItem";
  key?: string;
  month?: string;
}

export interface ChecklistItemRemovedProperties {
  id: string;
  repeat: boolean;
}

export interface ChecklistItemUnfinalizedProperties {
  id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | TransactionsFinalized, TransactionsCategorized, BalanceSheet, ProfitAndLoss |
   */
  itemSubType?:
    | "TransactionsFinalized"
    | "TransactionsCategorized"
    | "BalanceSheet"
    | "ProfitAndLoss";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | ProgressChecklistItem, AccountChecklistItem, StatementChecklistItem, CustomChecklistItem, BalanceChecklistItem, AmountsChecklistItem, ReviewChecklistItem, BasicChecklistItem, PaymentProcessingAccountChecklistItem |
   */
  itemType:
    | "ProgressChecklistItem"
    | "AccountChecklistItem"
    | "StatementChecklistItem"
    | "CustomChecklistItem"
    | "BalanceChecklistItem"
    | "AmountsChecklistItem"
    | "ReviewChecklistItem"
    | "BasicChecklistItem"
    | "PaymentProcessingAccountChecklistItem";
  key?: string;
  month?: string;
}

export interface ChecklistViewedProperties {
  month: string;
}

export interface CheckoutReviewScreenViewedProperties {
  plan?: string;
}

export interface CompanyAttributesUpdatedProperties {
  hasBookkeeper?: boolean;
}

export interface CompanyChangedProperties {
  companyId: string;
  hasBookkeeper?: boolean;
}

export interface CompanyCreatedProperties {
  hasBookkeeper?: boolean;
  hasHistoricalData?: boolean;
  name: string;
  orgType?: string;
  partner?: string;
  referralCode?: string;
  /**
   * Company revenue model
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | AnnualSubscription, MonthlySubscription, PreRevenue, ServiceBased, TransactionBased, UsageBased, NotSure, MultipleRevenueModels, Subscription |
   */
  revenueModel:
    | "AnnualSubscription"
    | "MonthlySubscription"
    | "PreRevenue"
    | "ServiceBased"
    | "TransactionBased"
    | "UsageBased"
    | "NotSure"
    | "MultipleRevenueModels"
    | "Subscription";
  timeZone: string;
  /**
   * Company industry type
   */
  type?: string;
}

export interface CompanySettingsUpdatedProperties {
  name: string;
  /**
   * Company revenue model
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | AnnualSubscription, MonthlySubscription, PreRevenue, ServiceBased, TransactionBased, UsageBased, NotSure, MultipleRevenueModels, Subscription |
   */
  revenueModel:
    | "AnnualSubscription"
    | "MonthlySubscription"
    | "PreRevenue"
    | "ServiceBased"
    | "TransactionBased"
    | "UsageBased"
    | "NotSure"
    | "MultipleRevenueModels"
    | "Subscription";
  timeZone: string;
  /**
   * Company industry type
   */
  type?: string;
}

export interface CreateClassSegmentProperties {
  /**
   * For distinguishing whether the event is created by a user or auto
   */
  actionType?: string;
  className: string;
  classSegmentName: string;
}

export interface CreateReportingClassProperties {
  /**
   * For distinguishing whether the event is created by a user or auto
   */
  actionType?: string;
  className: string;
}

export interface DashboardChartHelpClickedProperties {
  id: string;
}

export interface DashboardChartHoveredProperties {
  id: string;
}

export interface DashboardChartLinkClickedProperties {
  id: string;
}

export interface DashboardChartsToggledProperties {
  isClosing: boolean;
}

export interface DashboardFdicTabButtonClickProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Brex, Mercury, Meow |
   */
  partnerName: "Brex" | "Mercury" | "Meow";
}

export interface DashboardMetricCardBlurToggledProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | cash, burn, runway, revenue |
   */
  card: "cash" | "burn" | "runway" | "revenue";
  isBlurring: boolean;
}

export interface DashboardMetricCardFormulaChangedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | cash, burn, runway, revenue |
   */
  card: "cash" | "burn" | "runway" | "revenue";
  formula: string;
  subFormula?: string;
}

export interface DashboardMetricCardOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | cash, burn, runway, revenue |
   */
  card: "cash" | "burn" | "runway" | "revenue";
}

export interface DashboardReportCategorizationStatusViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  categorizedPercent: number;
  columnName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  finalizedPercent: number;
  isComplete: boolean;
  isInProgress: boolean;
  isLocked: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | BalanceSheet, CashActivityReport, ProfitAndLoss, TrialBalance, TransactionReport, TaxPackage, GeneralLedger, Payroll, QBOTrialBalance, QBOAgedPayableDetail, QBOAgedReceivableDetail, QBOBalanceSheet, QBOCashFlow, QBOGeneralLedger, QBOProfitAndLoss, QBOProfitAndLossDetail, VendorSpending, StripeTransactions, Default, FinancialReportPackage, ChartOfAccounts, Vendor1099Tracking, ArAging, SquashSummary, Employee, Contractor, EmployeeChange, Invoices |
   */
  reportType:
    | "BalanceSheet"
    | "CashActivityReport"
    | "ProfitAndLoss"
    | "TrialBalance"
    | "TransactionReport"
    | "TaxPackage"
    | "GeneralLedger"
    | "Payroll"
    | "QBOTrialBalance"
    | "QBOAgedPayableDetail"
    | "QBOAgedReceivableDetail"
    | "QBOBalanceSheet"
    | "QBOCashFlow"
    | "QBOGeneralLedger"
    | "QBOProfitAndLoss"
    | "QBOProfitAndLossDetail"
    | "VendorSpending"
    | "StripeTransactions"
    | "Default"
    | "FinancialReportPackage"
    | "ChartOfAccounts"
    | "Vendor1099Tracking"
    | "ArAging"
    | "SquashSummary"
    | "Employee"
    | "Contractor"
    | "EmployeeChange"
    | "Invoices";
}

export interface DashboardReportCellClickedProperties {
  columnName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  depth: number;
  highlightModeEnabled: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | BalanceSheet, CashActivityReport, ProfitAndLoss, TrialBalance, TransactionReport, TaxPackage, GeneralLedger, Payroll, QBOTrialBalance, QBOAgedPayableDetail, QBOAgedReceivableDetail, QBOBalanceSheet, QBOCashFlow, QBOGeneralLedger, QBOProfitAndLoss, QBOProfitAndLossDetail, VendorSpending, StripeTransactions, Default, FinancialReportPackage, ChartOfAccounts, Vendor1099Tracking, ArAging, SquashSummary, Employee, Contractor, EmployeeChange, Invoices |
   */
  reportType:
    | "BalanceSheet"
    | "CashActivityReport"
    | "ProfitAndLoss"
    | "TrialBalance"
    | "TransactionReport"
    | "TaxPackage"
    | "GeneralLedger"
    | "Payroll"
    | "QBOTrialBalance"
    | "QBOAgedPayableDetail"
    | "QBOAgedReceivableDetail"
    | "QBOBalanceSheet"
    | "QBOCashFlow"
    | "QBOGeneralLedger"
    | "QBOProfitAndLoss"
    | "QBOProfitAndLossDetail"
    | "VendorSpending"
    | "StripeTransactions"
    | "Default"
    | "FinancialReportPackage"
    | "ChartOfAccounts"
    | "Vendor1099Tracking"
    | "ArAging"
    | "SquashSummary"
    | "Employee"
    | "Contractor"
    | "EmployeeChange"
    | "Invoices";
  rowName: string;
}

export interface DashboardReportDownloadRequestedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Compact, Summary, Detailed, Simplified, Rollforward |
   */
  reportLevel?: "Compact" | "Summary" | "Detailed" | "Simplified" | "Rollforward";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | BalanceSheet, CashActivityReport, ProfitAndLoss, TrialBalance, TransactionReport, TaxPackage, GeneralLedger, Payroll, QBOTrialBalance, QBOAgedPayableDetail, QBOAgedReceivableDetail, QBOBalanceSheet, QBOCashFlow, QBOGeneralLedger, QBOProfitAndLoss, QBOProfitAndLossDetail, VendorSpending, StripeTransactions, Default, FinancialReportPackage, ChartOfAccounts, Vendor1099Tracking, ArAging, SquashSummary, Employee, Contractor, EmployeeChange, Invoices |
   */
  reportType:
    | "BalanceSheet"
    | "CashActivityReport"
    | "ProfitAndLoss"
    | "TrialBalance"
    | "TransactionReport"
    | "TaxPackage"
    | "GeneralLedger"
    | "Payroll"
    | "QBOTrialBalance"
    | "QBOAgedPayableDetail"
    | "QBOAgedReceivableDetail"
    | "QBOBalanceSheet"
    | "QBOCashFlow"
    | "QBOGeneralLedger"
    | "QBOProfitAndLoss"
    | "QBOProfitAndLossDetail"
    | "VendorSpending"
    | "StripeTransactions"
    | "Default"
    | "FinancialReportPackage"
    | "ChartOfAccounts"
    | "Vendor1099Tracking"
    | "ArAging"
    | "SquashSummary"
    | "Employee"
    | "Contractor"
    | "EmployeeChange"
    | "Invoices";
}

export interface DashboardReportFilterChangedProperties {
  basis?: string;
  dateRangePreset?: string;
  endDate?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportingClassFilterCount?: number;
  reportingClassFilterIncludesNone?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Location, Department, UserCreated |
   */
  reportingClassFilterType?: "Location" | "Department" | "UserCreated";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | total, year, day, month, quarter, location, department, usercreated |
   */
  reportPeriodGrouping?:
    | "total"
    | "year"
    | "day"
    | "month"
    | "quarter"
    | "location"
    | "department"
    | "usercreated";
  startDate?: string;
}

export interface DashboardReportHighlightCellHoveredProperties {
  columnName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  depth: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | BalanceSheet, CashActivityReport, ProfitAndLoss, TrialBalance, TransactionReport, TaxPackage, GeneralLedger, Payroll, QBOTrialBalance, QBOAgedPayableDetail, QBOAgedReceivableDetail, QBOBalanceSheet, QBOCashFlow, QBOGeneralLedger, QBOProfitAndLoss, QBOProfitAndLossDetail, VendorSpending, StripeTransactions, Default, FinancialReportPackage, ChartOfAccounts, Vendor1099Tracking, ArAging, SquashSummary, Employee, Contractor, EmployeeChange, Invoices |
   */
  reportType:
    | "BalanceSheet"
    | "CashActivityReport"
    | "ProfitAndLoss"
    | "TrialBalance"
    | "TransactionReport"
    | "TaxPackage"
    | "GeneralLedger"
    | "Payroll"
    | "QBOTrialBalance"
    | "QBOAgedPayableDetail"
    | "QBOAgedReceivableDetail"
    | "QBOBalanceSheet"
    | "QBOCashFlow"
    | "QBOGeneralLedger"
    | "QBOProfitAndLoss"
    | "QBOProfitAndLossDetail"
    | "VendorSpending"
    | "StripeTransactions"
    | "Default"
    | "FinancialReportPackage"
    | "ChartOfAccounts"
    | "Vendor1099Tracking"
    | "ArAging"
    | "SquashSummary"
    | "Employee"
    | "Contractor"
    | "EmployeeChange"
    | "Invoices";
  rowName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  ruleIndex: number;
}

export interface DashboardReportHighlightQuestionHoveredProperties {
  description: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  ruleIndex: number;
}

export interface DashboardReportHighlightToggledProperties {
  enabled: boolean;
}

export interface DashboardReportLevelChangedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Compact, Summary, Detailed, Simplified, Rollforward |
   */
  reportLevel?: "Compact" | "Summary" | "Detailed" | "Simplified" | "Rollforward";
}

export interface DashboardReportRowToggledProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  depth: number;
  highlightModeEnabled: boolean;
  isExpanding: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Compact, Summary, Detailed, Simplified, Rollforward |
   */
  reportLevel?: "Compact" | "Summary" | "Detailed" | "Simplified" | "Rollforward";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | BalanceSheet, CashActivityReport, ProfitAndLoss, TrialBalance, TransactionReport, TaxPackage, GeneralLedger, Payroll, QBOTrialBalance, QBOAgedPayableDetail, QBOAgedReceivableDetail, QBOBalanceSheet, QBOCashFlow, QBOGeneralLedger, QBOProfitAndLoss, QBOProfitAndLossDetail, VendorSpending, StripeTransactions, Default, FinancialReportPackage, ChartOfAccounts, Vendor1099Tracking, ArAging, SquashSummary, Employee, Contractor, EmployeeChange, Invoices |
   */
  reportType:
    | "BalanceSheet"
    | "CashActivityReport"
    | "ProfitAndLoss"
    | "TrialBalance"
    | "TransactionReport"
    | "TaxPackage"
    | "GeneralLedger"
    | "Payroll"
    | "QBOTrialBalance"
    | "QBOAgedPayableDetail"
    | "QBOAgedReceivableDetail"
    | "QBOBalanceSheet"
    | "QBOCashFlow"
    | "QBOGeneralLedger"
    | "QBOProfitAndLoss"
    | "QBOProfitAndLossDetail"
    | "VendorSpending"
    | "StripeTransactions"
    | "Default"
    | "FinancialReportPackage"
    | "ChartOfAccounts"
    | "Vendor1099Tracking"
    | "ArAging"
    | "SquashSummary"
    | "Employee"
    | "Contractor"
    | "EmployeeChange"
    | "Invoices";
  rowName: string;
}

export interface DashboardReportStatusChecklistClickedProperties {
  month: string;
}

export interface DashboardReportTabChangedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | BalanceSheet, CashActivityReport, ProfitAndLoss, TrialBalance, TransactionReport, TaxPackage, GeneralLedger, Payroll, QBOTrialBalance, QBOAgedPayableDetail, QBOAgedReceivableDetail, QBOBalanceSheet, QBOCashFlow, QBOGeneralLedger, QBOProfitAndLoss, QBOProfitAndLossDetail, VendorSpending, StripeTransactions, Default, FinancialReportPackage, ChartOfAccounts, Vendor1099Tracking, ArAging, SquashSummary, Employee, Contractor, EmployeeChange, Invoices |
   */
  reportType:
    | "BalanceSheet"
    | "CashActivityReport"
    | "ProfitAndLoss"
    | "TrialBalance"
    | "TransactionReport"
    | "TaxPackage"
    | "GeneralLedger"
    | "Payroll"
    | "QBOTrialBalance"
    | "QBOAgedPayableDetail"
    | "QBOAgedReceivableDetail"
    | "QBOBalanceSheet"
    | "QBOCashFlow"
    | "QBOGeneralLedger"
    | "QBOProfitAndLoss"
    | "QBOProfitAndLossDetail"
    | "VendorSpending"
    | "StripeTransactions"
    | "Default"
    | "FinancialReportPackage"
    | "ChartOfAccounts"
    | "Vendor1099Tracking"
    | "ArAging"
    | "SquashSummary"
    | "Employee"
    | "Contractor"
    | "EmployeeChange"
    | "Invoices";
}

export interface DeleteDataReasonModalSubmittedProperties {
  reason?: string;
  reasonDescription?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  reasons?: string[];
}

export interface DeleteLedgerReconciliationFailedProperties {
  reconciliationId: string;
}

export interface DeletedLedgerReconciliationProperties {
  reconciliationId: string;
}

export interface ExploreFeaturesOptionSelectedProperties {
  feature: string;
  section: string;
  triggeredFrom: string;
}

export interface FeedbackButtonClickedProperties {
  location?: string;
}

export interface FeedbackStartedProperties {
  form: string;
}

export interface FeedbackSubmittedProperties {
  form: string;
}

export interface FixedAssetDeletedProperties {
  fixedAssetId: string;
}

export interface FixedAssetDisposedProperties {
  fixedAssetId: string;
}

export interface FixedAssetPausedProperties {
  fixedAssetId: string;
}

export interface FixedAssetPlacedInServiceProperties {
  fixedAssetId: string;
}

export interface FixedAssetPlacedInServiceFailedProperties {
  errorMessage?: string;
  fixedAssetId: string;
}

export interface FixedAssetResumedProperties {
  fixedAssetId: string;
}

export interface FixedAssetUpdateFailedProperties {
  errorMessage?: string;
  fixedAssetId: string;
}

export interface FixedAssetUpdatedProperties {
  fixedAssetId: string;
}

export interface FixedAssetVoidedProperties {
  fixedAssetId: string;
}

export interface FixedAssetsCreateFailedProperties {
  errorMessage?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  numberOfItems: number;
}

export interface FixedAssetsCreatedProperties {
  errorMessage?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  numberOfItems: number;
}

export interface HistoricalBooksChecklistStepCompleteProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  stepNumber: number;
}

export interface HjePostedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  entryCount: number;
}

export interface ImpactfulTransactionsCompleteMessageSeenProperties {
  endDate?: string;
  isFullyCategorized: boolean;
  startDate?: string;
}

export interface ImpactfulTransactionsFilterEnabledProperties {
  endDate?: string;
  startDate?: string;
}

export interface InboxItemStatusChangedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  action?: string;
  id: string;
  location?: string;
}

export interface InboxItemViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  createdHoursAgo: number;
  id: string;
  /**
   * Consider using Notification.source?
   */
  messageType?: string;
  source: string;
  status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | tasks, updates, archived |
   */
  tab: "tasks" | "updates" | "archived";
}

export interface InboxViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | tasks, updates, archived |
   */
  tab: "tasks" | "updates" | "archived";
}

export interface IntegrationConnectedProperties {
  connectionId: string;
  institutionName?: string;
  integrationType: string;
  isReconnecting?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalAccounts: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  totalCreditAccounts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  totalDepositoryAccounts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  totalFintechAccounts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  totalInvestmentAccounts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalLoanAccounts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  totalOtherAccounts?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  totalPaymentProcessingAccounts?: number;
}

export interface IntegrationConnectionFailedProperties {
  integrationType: string;
  reason?: string;
}

export interface IntegrationConnectionStartedProperties {
  integrationType: string;
}

export interface IntegrationDisconnectedProperties {
  automated?: boolean;
  connectionId: string;
  errorCode?: string;
  institutionName?: string;
  integrationType: string;
}

export interface IntegrationIngestionDateSetProperties {
  companyId: string;
  location?: string;
  startIngestionDate: string;
}

export interface IntegrationViewedProperties {
  connectionId: string;
  /**
   * Integration connection status
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | OK, Error, Disconnected |
   */
  connectionStatus: "OK" | "Error" | "Disconnected";
  institutionName?: string;
  integrationType: string;
}

export interface IntercomUnreadCountChangedProperties {
  /**
   * Tracks how many times the company's subscription has been changed. Increments with each change.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  count: number;
}

export interface InvoiceDeletedProperties {
  invoiceId: string;
}

export interface InvoiceDownloadedProperties {
  invoiceId: string;
}

export interface InvoiceDraftedProperties {
  invoiceId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  numberOfSchedules: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalRows: number;
}

export interface InvoiceLineRecategorizedProperties {
  invoiceId: string;
  invoiceLineId: string;
  newCategory: string;
  previousCategory: string;
}

export interface InvoicePostedProperties {
  invoiceId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  numberOfSchedules: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalRows: number;
}

export interface InvoiceVoidedProperties {
  invoiceId: string;
}

export interface LedgerReconEntryToggleFailedProperties {
  accountId: string;
  reconciliationId: string;
}

export interface LedgerReconEntryToggledProperties {
  accountId: string;
  reconciliationId: string;
}

export interface LedgerReconciliationCompletedProperties {
  accountId: string;
  companyId: string;
  reconciliationId: string;
  statementDate: string;
}

export interface ManualRampSyncFailedProperties {
  errorCode?: string;
}

export interface ManualTransactionsCreatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalRows: number;
}

export interface MatchedTransactionToBillsProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  numberOfBills: number;
  transactionId: string;
}

export interface MjeDeletedProperties {
  id: string;
}

export interface MjePostedProperties {
  hasAutoReversal: boolean;
  id: string;
  isHistorical?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalRows: number;
}

export interface MjeResumedProperties {
  id: string;
}

export interface MjeReversedProperties {
  id: string;
}

export interface MjeSavedProperties {
  id: string;
}

export interface MjeStartedProperties {
  /**
   * MJE origin (new, duplicate, reversal)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | new, duplicate, another |
   */
  origin: "new" | "duplicate" | "another";
}

export interface MjeViewedProperties {
  id: string;
}

export interface MjeVoidedProperties {
  id: string;
}

export interface MrrReportDownloadedProperties {
  cohort: string;
  reportView: string;
}

export interface NotificationBellClickedProperties {
  isUnread: boolean;
}

export interface NotificationClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  createdHoursAgo: number;
  id: string;
  isUnread: boolean;
  /**
   * Consider using Notification.source?
   */
  messageType?: string;
  source: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | tasks, updates, archived |
   */
  tab: "tasks" | "updates" | "archived";
}

export interface NotificationSettingsClickedProperties {
  location?: string;
}

export interface NotificationTabClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | tasks, updates, archived |
   */
  tab: "tasks" | "updates" | "archived";
}

export interface NotificationsViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  badgeCount: number;
}

export interface OnboardingChecklistItemHelpRequestedProperties {
  location?: string;
}

export interface OnboardingCompletedProperties {
  /**
   * Facebook ad click ID, needed for Facebook Conversions API
   */
  facebookClickId?: string;
  /**
   * Meta Pixel unique identifier, needed for Facebook Conversions API
   */
  facebookPixelId?: string;
  hasHistoricalData?: boolean;
  name: string;
  orgType?: string;
  partner?: string;
  referralCode?: string;
  /**
   * Company revenue model
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | AnnualSubscription, MonthlySubscription, PreRevenue, ServiceBased, TransactionBased, UsageBased, NotSure, MultipleRevenueModels, Subscription |
   */
  revenueModel:
    | "AnnualSubscription"
    | "MonthlySubscription"
    | "PreRevenue"
    | "ServiceBased"
    | "TransactionBased"
    | "UsageBased"
    | "NotSure"
    | "MultipleRevenueModels"
    | "Subscription";
  source: string;
  timeZone: string;
  /**
   * Company industry type
   */
  type?: string;
}

export interface OnboardingIngestionDateSetProperties {
  companyId: string;
  startIngestionDate: string;
}

export interface OnboardingManualPayrollProviderSelectedProperties {
  providerName: string;
}

export interface OnboardingQuestionAnsweredProperties {
  location?: string;
  question: string;
  response: string;
}

export interface OnboardingRipplingSelectedProperties {
  providerName: string;
}

export interface OnboardingStepCompletedProperties {
  source: string;
  /**
   * Onboarding step
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | CompanyName, CompanyInfo, Bank, CreditCard, Payroll, PaymentProcessor, Review, ExistingBooks, ConnectFinancials |
   */
  step:
    | "CompanyName"
    | "CompanyInfo"
    | "Bank"
    | "CreditCard"
    | "Payroll"
    | "PaymentProcessor"
    | "Review"
    | "ExistingBooks"
    | "ConnectFinancials";
}

export interface OnboardingStepViewedProperties {
  source: string;
  /**
   * Onboarding step
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | CompanyName, CompanyInfo, Bank, CreditCard, Payroll, PaymentProcessor, Review, ExistingBooks, ConnectFinancials |
   */
  step:
    | "CompanyName"
    | "CompanyInfo"
    | "Bank"
    | "CreditCard"
    | "Payroll"
    | "PaymentProcessor"
    | "Review"
    | "ExistingBooks"
    | "ConnectFinancials";
}

export interface OnboardingViewedProperties {
  source: string;
}

export interface OpenAiDataSharingConsentGrantedProperties {
  companyId: string;
  userId?: string;
}

export interface OpeningBalanceInboxTaskActionedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  action?: string;
  id: string;
}

export interface PaidTrialUpgradeModalViewedProperties {
  companyId: string;
  userId?: string;
}

export interface PaidTrialUpgradePlanSelectedProperties {
  companyId: string;
  currentPlan: string;
  downgradedToFree: boolean;
  plan?: string;
  previousPlan: string;
  upgradedToPremium: boolean;
  userId?: string;
}

export interface PayrollDeleteFailedProperties {
  id: string;
}

export interface PayrollDeletedProperties {
  id: string;
}

export interface PayrollUpdateFailedProperties {
  id: string;
}

export interface PayrollUpdatedProperties {
  id: string;
}

export interface PeopleTableFilterChangedProperties {
  endDate?: string;
  searchTerm?: string;
  startDate?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Terminated, Active, Contractor |
   */
  workerSubset?: "Terminated" | "Active" | "Contractor";
}

export interface PeopleTableRowClickedProperties {
  workerId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Contractor, Employee |
   */
  workerType: "Contractor" | "Employee";
}

export interface PostedBillLineRecategorizedProperties {
  billId: string;
  billLineId: string;
  companyId: string;
  newCategory: string;
  previousCategory: string;
}

export interface PrepaidExpenseCreatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | manual, transaction, bill |
   */
  prepaidSource: "manual" | "transaction" | "bill";
}

export interface PrepaidExpenseDeletedProperties {
  id: string;
}

export interface PrepaidExpenseVoidedProperties {
  id: string;
}

export interface RemoveClassTagProperties {
  /**
   * For distinguishing whether the event is created by a user or auto
   */
  actionType?: string;
  entityType: string;
}

export interface ReportDownloadedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | PDF, CSV, Excel |
   */
  reportFormat?: "PDF" | "CSV" | "Excel";
  reportId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | BalanceSheet, CashActivityReport, ProfitAndLoss, TrialBalance, TransactionReport, TaxPackage, GeneralLedger, Payroll, QBOTrialBalance, QBOAgedPayableDetail, QBOAgedReceivableDetail, QBOBalanceSheet, QBOCashFlow, QBOGeneralLedger, QBOProfitAndLoss, QBOProfitAndLossDetail, VendorSpending, StripeTransactions, Default, FinancialReportPackage, ChartOfAccounts, Vendor1099Tracking, ArAging, SquashSummary, Employee, Contractor, EmployeeChange, Invoices |
   */
  reportType:
    | "BalanceSheet"
    | "CashActivityReport"
    | "ProfitAndLoss"
    | "TrialBalance"
    | "TransactionReport"
    | "TaxPackage"
    | "GeneralLedger"
    | "Payroll"
    | "QBOTrialBalance"
    | "QBOAgedPayableDetail"
    | "QBOAgedReceivableDetail"
    | "QBOBalanceSheet"
    | "QBOCashFlow"
    | "QBOGeneralLedger"
    | "QBOProfitAndLoss"
    | "QBOProfitAndLossDetail"
    | "VendorSpending"
    | "StripeTransactions"
    | "Default"
    | "FinancialReportPackage"
    | "ChartOfAccounts"
    | "Vendor1099Tracking"
    | "ArAging"
    | "SquashSummary"
    | "Employee"
    | "Contractor"
    | "EmployeeChange"
    | "Invoices";
}

export interface ReportModalExportClickedProperties {
  endDate?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Compact, Summary, Detailed, Simplified, Rollforward |
   */
  reportLevel?: "Compact" | "Summary" | "Detailed" | "Simplified" | "Rollforward";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | BalanceSheet, CashActivityReport, ProfitAndLoss, TrialBalance, TransactionReport, TaxPackage, GeneralLedger, Payroll, QBOTrialBalance, QBOAgedPayableDetail, QBOAgedReceivableDetail, QBOBalanceSheet, QBOCashFlow, QBOGeneralLedger, QBOProfitAndLoss, QBOProfitAndLossDetail, VendorSpending, StripeTransactions, Default, FinancialReportPackage, ChartOfAccounts, Vendor1099Tracking, ArAging, SquashSummary, Employee, Contractor, EmployeeChange, Invoices |
   */
  reportType:
    | "BalanceSheet"
    | "CashActivityReport"
    | "ProfitAndLoss"
    | "TrialBalance"
    | "TransactionReport"
    | "TaxPackage"
    | "GeneralLedger"
    | "Payroll"
    | "QBOTrialBalance"
    | "QBOAgedPayableDetail"
    | "QBOAgedReceivableDetail"
    | "QBOBalanceSheet"
    | "QBOCashFlow"
    | "QBOGeneralLedger"
    | "QBOProfitAndLoss"
    | "QBOProfitAndLossDetail"
    | "VendorSpending"
    | "StripeTransactions"
    | "Default"
    | "FinancialReportPackage"
    | "ChartOfAccounts"
    | "Vendor1099Tracking"
    | "ArAging"
    | "SquashSummary"
    | "Employee"
    | "Contractor"
    | "EmployeeChange"
    | "Invoices";
  startDate?: string;
}

export interface ReportModalOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | BalanceSheet, CashActivityReport, ProfitAndLoss, TrialBalance, TransactionReport, TaxPackage, GeneralLedger, Payroll, QBOTrialBalance, QBOAgedPayableDetail, QBOAgedReceivableDetail, QBOBalanceSheet, QBOCashFlow, QBOGeneralLedger, QBOProfitAndLoss, QBOProfitAndLossDetail, VendorSpending, StripeTransactions, Default, FinancialReportPackage, ChartOfAccounts, Vendor1099Tracking, ArAging, SquashSummary, Employee, Contractor, EmployeeChange, Invoices |
   */
  reportType:
    | "BalanceSheet"
    | "CashActivityReport"
    | "ProfitAndLoss"
    | "TrialBalance"
    | "TransactionReport"
    | "TaxPackage"
    | "GeneralLedger"
    | "Payroll"
    | "QBOTrialBalance"
    | "QBOAgedPayableDetail"
    | "QBOAgedReceivableDetail"
    | "QBOBalanceSheet"
    | "QBOCashFlow"
    | "QBOGeneralLedger"
    | "QBOProfitAndLoss"
    | "QBOProfitAndLossDetail"
    | "VendorSpending"
    | "StripeTransactions"
    | "Default"
    | "FinancialReportPackage"
    | "ChartOfAccounts"
    | "Vendor1099Tracking"
    | "ArAging"
    | "SquashSummary"
    | "Employee"
    | "Contractor"
    | "EmployeeChange"
    | "Invoices";
}

export interface ResetLedgerReconciliationProperties {
  reconciliationId: string;
}

export interface ResetLedgerReconciliationFailedProperties {
  reconciliationId: string;
}

export interface RevenueScheduleActivatedProperties {
  id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  numberOfPeriods: number;
  total: string;
}

export interface RevenueScheduleClosedProperties {
  id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  numberOfPeriods: number;
  total: string;
}

export interface RevenueScheduleCreatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  distinct_id?: string;
  endDate?: string;
  invoiceDiscountLineId?: string;
  invoiceLineItemId?: string;
  startDate?: string;
}

export interface RevenueSchedulePausedProperties {
  id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  numberOfPeriods: number;
  total: string;
}

export interface RevenueScheduleVoidedProperties {
  id: string;
}

export interface RevenueTabChangedProperties {
  revenueTab: string;
}

export interface RuleDisabledProperties {
  ruleId: string;
}

export interface RuleModalOpenedProperties {
  isNew: boolean;
  location?: string;
  ruleId?: string;
  transactionId?: string;
}

export interface RuleSavedProperties {
  location?: string;
  ruleId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Transaction, Invoice |
   */
  ruleType?: "Transaction" | "Invoice";
}

export interface SetupChecklistStepCompletedProperties {
  skipped: boolean;
  stepName: string;
}

export interface SidebarMinimizeChangedProperties {
  minimized: boolean;
}

export interface SpendingRowClickedProperties {
  location?: string;
}

export interface SpendingTransactionsButtonClickedProperties {
  location?: string;
}

export interface StartedLedgerReconciliationProperties {
  accountId: string;
  id: string;
}

export interface StartedLedgerReconciliationErrorProperties {
  accountId: string;
}

export interface StripeTableRowClickedProperties {
  bucketId: string;
}

export interface TourClosedProperties {
  tourId: string;
}

export interface TourFinishedProperties {
  tourId: string;
}

export interface TourStepSkippedProperties {
  stepName: string;
  tourId: string;
}

export interface TourStepViewedProperties {
  stepName: string;
  tourId: string;
}

export interface TourViewedProperties {
  tourId: string;
}

export interface TransactionAccrualDateAddedProperties {
  transactionId: string;
}

export interface TransactionAiButtonClickedProperties {
  companyConsentsToAI: string;
  companyId: string;
  transactionId: string;
}

export interface TransactionAssignedProperties {
  assigneeId: string;
  request?: string;
  transactionId: string;
}

export interface TransactionAssignmentCanceledProperties {
  id: string;
  request?: string;
  transactionId: string;
}

export interface TransactionAssignmentCompletedProperties {
  id: string;
  request?: string;
  transactionId: string;
}

export interface TransactionCategoryChangedProperties {
  categoryId: string;
  /**
   * name of a view component
   */
  component?: string;
  location?: string;
  oldCategoryId?: string;
  source: string;
  transactionId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | FINALIZED, USER_ASSIGNED, AUTOMATED, LOCKED, USER_RULE_ASSIGNED, AI_ASSIGNED |
   */
  transactionStatus?:
    | "FINALIZED"
    | "USER_ASSIGNED"
    | "AUTOMATED"
    | "LOCKED"
    | "USER_RULE_ASSIGNED"
    | "AI_ASSIGNED";
}

export interface TransactionCommentAddedProperties {
  transactionId: string;
}

export interface TransactionDescriptionChangedProperties {
  description: string;
  transactionId: string;
}

export interface TransactionDocumentationAddedProperties {
  transactionId: string;
}

export interface TransactionDrawerArrowClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | next, previous |
   */
  direction: "next" | "previous";
}

export interface TransactionImportAccountSelectedProperties {
  accountId: string;
}

export interface TransactionImportCsvUploadFailedProperties {
  contentType: string;
  fileName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  fileSize: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalRows: number;
}

export interface TransactionImportFailedProperties {
  contentType: string;
  fileName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  fileSize: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalRows: number;
}

export interface TransactionImportInvalidFormatProperties {
  contentType: string;
  fileName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  fileSize: number;
}

export interface TransactionImportSucceededProperties {
  contentType: string;
  fileName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  fileSize: number;
  id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalRows: number;
}

export interface TransactionImportTimedOutProperties {
  contentType: string;
  fileName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  fileSize: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalRows: number;
}

export interface TransactionOpenedProperties {
  transactionId: string;
}

export interface TransactionRecurrenceChangedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | NONE, MONTHLY, QUARTERLY, YEARLY |
   */
  recurrence: "NONE" | "MONTHLY" | "QUARTERLY" | "YEARLY";
  transactionId: string;
}

export interface TransactionSplitsSavedProperties {
  /**
   * Number of split rows
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalSplits: number;
  transactionId: string;
}

export interface TransactionStatusChangedProperties {
  location?: string;
  transactionId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | FINALIZED, USER_ASSIGNED, AUTOMATED, LOCKED, USER_RULE_ASSIGNED, AI_ASSIGNED |
   */
  transactionStatus?:
    | "FINALIZED"
    | "USER_ASSIGNED"
    | "AUTOMATED"
    | "LOCKED"
    | "USER_RULE_ASSIGNED"
    | "AI_ASSIGNED";
}

export interface TransactionUnlinkedProperties {
  transactionId: string;
}

export interface TransactionVendorChangedProperties {
  transactionId: string;
  vendorId?: string;
}

export interface TransactionsBulkCategorizedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  category?: string;
}

export interface TransactionsRemovedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  transactionIds: string[];
}

export interface TransactionsTableCashflowChangedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | in, out, all |
   */
  cashFlow: "in" | "out" | "all";
}

export interface TransactionsTableRowClickedProperties {
  transactionId: string;
}

export interface UpdateAutomationSettingProperties {
  newStatus: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | AccrualAutomation, RevenueSubledger, PaymentSubledger, AutomaticAssignment, AutoBalanceAdjustments |
   */
  settingType:
    | "AccrualAutomation"
    | "RevenueSubledger"
    | "PaymentSubledger"
    | "AutomaticAssignment"
    | "AutoBalanceAdjustments";
}

export interface UserMembershipChangedProperties {
  status: string;
  userId?: string;
}

export interface UserPositionChangedProperties {
  newValue?: string;
  oldValue?: string;
}

export interface UserProposedStartIngestionDateProperties {
  date: string;
}

export interface UserRemovedProperties {
  invitationId?: string;
  userId?: string;
}

export interface UserRoleChangedProperties {
  oldRole: string;
  /**
   * The permission group for this user in this account.
   */
  role: string;
}

export interface UsersInvitedProperties {
  emails: string;
  mfaRequired: boolean;
  /**
   * The permission group for this user in this account.
   */
  role: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  totalCount: number;
}

export interface VendorMergedProperties {
  location?: string;
}

export interface VendorReportOpenedProperties {
  source: string;
}

export interface VendorUpdatedProperties {
  location?: string;
}

export interface Vendors1099StatusChangedProperties {
  location?: string;
  newValue?: string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(public event_properties?: IdentifyProperties) {
    this.event_properties = event_properties;
  }
}

export class AccountCreated implements BaseEvent {
  event_type = "Account Created";

  constructor(public event_properties: AccountCreatedProperties) {
    this.event_properties = event_properties;
  }
}

export class AccountDeactivated implements BaseEvent {
  event_type = "Account Deactivated";

  constructor(public event_properties: AccountDeactivatedProperties) {
    this.event_properties = event_properties;
  }
}

export class AccountRemoved implements BaseEvent {
  event_type = "Account Removed";

  constructor(public event_properties: AccountRemovedProperties) {
    this.event_properties = event_properties;
  }
}

export class AccountUpdated implements BaseEvent {
  event_type = "Account Updated";

  constructor(public event_properties: AccountUpdatedProperties) {
    this.event_properties = event_properties;
  }
}

export class AccountingRecordAttachmentDeleted implements BaseEvent {
  event_type = "Accounting Record Attachment Deleted";

  constructor(public event_properties: AccountingRecordAttachmentDeletedProperties) {
    this.event_properties = event_properties;
  }
}

export class AccountingRecordAttachmentUploaded implements BaseEvent {
  event_type = "Accounting Record Attachment Uploaded";

  constructor(public event_properties: AccountingRecordAttachmentUploadedProperties) {
    this.event_properties = event_properties;
  }
}

export class AccountingRecordCommentAdded implements BaseEvent {
  event_type = "Accounting Record Comment Added";

  constructor(public event_properties: AccountingRecordCommentAddedProperties) {
    this.event_properties = event_properties;
  }
}

export class AccrualEventsEnabled implements BaseEvent {
  event_type = "Accrual Events Enabled";

  constructor(public event_properties: AccrualEventsEnabledProperties) {
    this.event_properties = event_properties;
  }
}

export class AddClassTag implements BaseEvent {
  event_type = "Add Class Tag";

  constructor(public event_properties: AddClassTagProperties) {
    this.event_properties = event_properties;
  }
}

export class AiCategorizerEnabled implements BaseEvent {
  event_type = "AI-Categorizer Enabled";

  constructor(public event_properties: AiCategorizerEnabledProperties) {
    this.event_properties = event_properties;
  }
}

export class AskAnAccountantButtonClicked implements BaseEvent {
  event_type = "Ask An Accountant Button Clicked";

  constructor(public event_properties?: AskAnAccountantButtonClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class AssistedCategorizationEnabled implements BaseEvent {
  event_type = "Assisted Categorization Enabled";

  constructor(public event_properties: AssistedCategorizationEnabledProperties) {
    this.event_properties = event_properties;
  }
}

export class AutomaticAssignmentEnabled implements BaseEvent {
  event_type = "Automatic Assignment Enabled";

  constructor(public event_properties: AutomaticAssignmentEnabledProperties) {
    this.event_properties = event_properties;
  }
}

export class AutonomousAccountingToggled implements BaseEvent {
  event_type = "Autonomous Accounting Toggled";

  constructor(public event_properties: AutonomousAccountingToggledProperties) {
    this.event_properties = event_properties;
  }
}

export class BankRecAccountSelected implements BaseEvent {
  event_type = "Bank Rec Account Selected";

  constructor(public event_properties: BankRecAccountSelectedProperties) {
    this.event_properties = event_properties;
  }
}

export class BankRecApproved implements BaseEvent {
  event_type = "Bank Rec Approved";

  constructor(public event_properties: BankRecApprovedProperties) {
    this.event_properties = event_properties;
  }
}

export class BankRecBalanceUpdated implements BaseEvent {
  event_type = "Bank Rec Balance Updated";

  constructor(public event_properties: BankRecBalanceUpdatedProperties) {
    this.event_properties = event_properties;
  }
}

export class BankRecCreated implements BaseEvent {
  event_type = "Bank Rec Created";

  constructor(public event_properties: BankRecCreatedProperties) {
    this.event_properties = event_properties;
  }
}

export class BankRecResumed implements BaseEvent {
  event_type = "Bank Rec Resumed";

  constructor(public event_properties: BankRecResumedProperties) {
    this.event_properties = event_properties;
  }
}

export class BankRecStarted implements BaseEvent {
  event_type = "Bank Rec Started";
}

export class BankRecStatementToggled implements BaseEvent {
  event_type = "Bank Rec Statement Toggled";

  constructor(public event_properties: BankRecStatementToggledProperties) {
    this.event_properties = event_properties;
  }
}

export class BankRecStatementUploaded implements BaseEvent {
  event_type = "Bank Rec Statement Uploaded";

  constructor(public event_properties: BankRecStatementUploadedProperties) {
    this.event_properties = event_properties;
  }
}

export class BankRecTransactionAdded implements BaseEvent {
  event_type = "Bank Rec Transaction Added";

  constructor(public event_properties: BankRecTransactionAddedProperties) {
    this.event_properties = event_properties;
  }
}

export class BankRecTransactionRemoved implements BaseEvent {
  event_type = "Bank Rec Transaction Removed";

  constructor(public event_properties: BankRecTransactionRemovedProperties) {
    this.event_properties = event_properties;
  }
}

export class BankRecUnapproved implements BaseEvent {
  event_type = "Bank Rec Unapproved";

  constructor(public event_properties: BankRecUnapprovedProperties) {
    this.event_properties = event_properties;
  }
}

export class BankRecUserTransactionsUpdated implements BaseEvent {
  event_type = "Bank Rec User Transactions Updated";

  constructor(public event_properties: BankRecUserTransactionsUpdatedProperties) {
    this.event_properties = event_properties;
  }
}

export class BankRecViewed implements BaseEvent {
  event_type = "Bank Rec Viewed";

  constructor(public event_properties: BankRecViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class BillAttachmentDeleted implements BaseEvent {
  event_type = "Bill Attachment Deleted";

  constructor(public event_properties: BillAttachmentDeletedProperties) {
    this.event_properties = event_properties;
  }
}

export class BillAttachmentUploaded implements BaseEvent {
  event_type = "Bill Attachment Uploaded";

  constructor(public event_properties: BillAttachmentUploadedProperties) {
    this.event_properties = event_properties;
  }
}

export class BillDeleted implements BaseEvent {
  event_type = "Bill Deleted";

  constructor(public event_properties: BillDeletedProperties) {
    this.event_properties = event_properties;
  }
}

export class BillImportCsvUploadFailed implements BaseEvent {
  event_type = "Bill Import CSV Upload Failed";

  constructor(public event_properties: BillImportCsvUploadFailedProperties) {
    this.event_properties = event_properties;
  }
}

export class BillImportFailed implements BaseEvent {
  event_type = "Bill Import Failed";

  constructor(public event_properties: BillImportFailedProperties) {
    this.event_properties = event_properties;
  }
}

export class BillImportInvalidFormat implements BaseEvent {
  event_type = "Bill Import Invalid Format";

  constructor(public event_properties: BillImportInvalidFormatProperties) {
    this.event_properties = event_properties;
  }
}

export class BillImportSucceeded implements BaseEvent {
  event_type = "Bill Import Succeeded";

  constructor(public event_properties: BillImportSucceededProperties) {
    this.event_properties = event_properties;
  }
}

export class BillImportTemplateLinkClicked implements BaseEvent {
  event_type = "Bill Import Template Link Clicked";
}

export class BillImportTimedOut implements BaseEvent {
  event_type = "Bill Import Timed Out";

  constructor(public event_properties: BillImportTimedOutProperties) {
    this.event_properties = event_properties;
  }
}

export class BillPaymentToggled implements BaseEvent {
  event_type = "Bill Payment Toggled";

  constructor(public event_properties: BillPaymentToggledProperties) {
    this.event_properties = event_properties;
  }
}

export class BillPostFailed implements BaseEvent {
  event_type = "Bill Post Failed";

  constructor(public event_properties: BillPostFailedProperties) {
    this.event_properties = event_properties;
  }
}

export class BillPosted implements BaseEvent {
  event_type = "Bill Posted";

  constructor(public event_properties: BillPostedProperties) {
    this.event_properties = event_properties;
  }
}

export class BillViewFailed implements BaseEvent {
  event_type = "Bill View Failed";

  constructor(public event_properties: BillViewFailedProperties) {
    this.event_properties = event_properties;
  }
}

export class BillViewed implements BaseEvent {
  event_type = "Bill Viewed";

  constructor(public event_properties: BillViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class BillVoided implements BaseEvent {
  event_type = "Bill Voided";

  constructor(public event_properties: BillVoidedProperties) {
    this.event_properties = event_properties;
  }
}

export class BurnTreemapZoomDecreased implements BaseEvent {
  event_type = "Burn Treemap Zoom Decreased";

  constructor(public event_properties: BurnTreemapZoomDecreasedProperties) {
    this.event_properties = event_properties;
  }
}

export class BurnTreemapZoomIncreased implements BaseEvent {
  event_type = "Burn Treemap Zoom Increased";

  constructor(public event_properties: BurnTreemapZoomIncreasedProperties) {
    this.event_properties = event_properties;
  }
}

export class CategorizeTopTransactionsInboxCtaClicked implements BaseEvent {
  event_type = "Categorize Top Transactions Inbox CTA Clicked";
}

export class CategorizerDateRangeSelected implements BaseEvent {
  event_type = "Categorizer Date Range Selected";

  constructor(public event_properties: CategorizerDateRangeSelectedProperties) {
    this.event_properties = event_properties;
  }
}

export class CategorizerDateRangeStepViewed implements BaseEvent {
  event_type = "Categorizer Date Range Step Viewed";
}

export class CategorizerIntroViewed implements BaseEvent {
  event_type = "Categorizer Intro Viewed";
}

export class CategorizerLoadMoreClicked implements BaseEvent {
  event_type = "Categorizer Load More Clicked";

  constructor(public event_properties?: CategorizerLoadMoreClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CategorizerRoundFinished implements BaseEvent {
  event_type = "Categorizer Round Finished";

  constructor(public event_properties: CategorizerRoundFinishedProperties) {
    this.event_properties = event_properties;
  }
}

export class CategorizerRoundStarted implements BaseEvent {
  event_type = "Categorizer Round Started";

  constructor(public event_properties?: CategorizerRoundStartedProperties) {
    this.event_properties = event_properties;
  }
}

export class ChatWithAnExpertClicked implements BaseEvent {
  event_type = "Chat With An Expert Clicked";

  constructor(public event_properties?: ChatWithAnExpertClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class ChecklistCompleted implements BaseEvent {
  event_type = "Checklist Completed";

  constructor(public event_properties: ChecklistCompletedProperties) {
    this.event_properties = event_properties;
  }
}

export class ChecklistItemAdded implements BaseEvent {
  event_type = "Checklist Item Added";

  constructor(public event_properties: ChecklistItemAddedProperties) {
    this.event_properties = event_properties;
  }
}

export class ChecklistItemFinalized implements BaseEvent {
  event_type = "Checklist Item Finalized";

  constructor(public event_properties: ChecklistItemFinalizedProperties) {
    this.event_properties = event_properties;
  }
}

export class ChecklistItemRemoved implements BaseEvent {
  event_type = "Checklist Item Removed";

  constructor(public event_properties: ChecklistItemRemovedProperties) {
    this.event_properties = event_properties;
  }
}

export class ChecklistItemUnfinalized implements BaseEvent {
  event_type = "Checklist Item Unfinalized";

  constructor(public event_properties: ChecklistItemUnfinalizedProperties) {
    this.event_properties = event_properties;
  }
}

export class ChecklistViewed implements BaseEvent {
  event_type = "Checklist Viewed";

  constructor(public event_properties: ChecklistViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class CheckoutReviewCtaClicked implements BaseEvent {
  event_type = "Checkout Review CTA Clicked";
}

export class CheckoutReviewScreenViewed implements BaseEvent {
  event_type = "Checkout Review Screen Viewed";

  constructor(public event_properties?: CheckoutReviewScreenViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class CompanyAttributesUpdated implements BaseEvent {
  event_type = "Company Attributes Updated";

  constructor(public event_properties?: CompanyAttributesUpdatedProperties) {
    this.event_properties = event_properties;
  }
}

export class CompanyChanged implements BaseEvent {
  event_type = "Company Changed";

  constructor(public event_properties: CompanyChangedProperties) {
    this.event_properties = event_properties;
  }
}

export class CompanyCreated implements BaseEvent {
  event_type = "Company Created";

  constructor(public event_properties: CompanyCreatedProperties) {
    this.event_properties = event_properties;
  }
}

export class CompanySettingsUpdated implements BaseEvent {
  event_type = "Company Settings Updated";

  constructor(public event_properties: CompanySettingsUpdatedProperties) {
    this.event_properties = event_properties;
  }
}

export class CreateClassSegment implements BaseEvent {
  event_type = "Create Class Segment";

  constructor(public event_properties: CreateClassSegmentProperties) {
    this.event_properties = event_properties;
  }
}

export class CreateReportingClass implements BaseEvent {
  event_type = "Create Reporting Class";

  constructor(public event_properties: CreateReportingClassProperties) {
    this.event_properties = event_properties;
  }
}

export class CustomReportRequested implements BaseEvent {
  event_type = "Custom Report Requested";
}

export class DashboardChartHelpClicked implements BaseEvent {
  event_type = "Dashboard Chart Help Clicked";

  constructor(public event_properties: DashboardChartHelpClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class DashboardChartHovered implements BaseEvent {
  event_type = "Dashboard Chart Hovered";

  constructor(public event_properties: DashboardChartHoveredProperties) {
    this.event_properties = event_properties;
  }
}

export class DashboardChartLinkClicked implements BaseEvent {
  event_type = "Dashboard Chart Link Clicked";

  constructor(public event_properties: DashboardChartLinkClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class DashboardChartsToggled implements BaseEvent {
  event_type = "Dashboard Charts Toggled";

  constructor(public event_properties: DashboardChartsToggledProperties) {
    this.event_properties = event_properties;
  }
}

export class DashboardFdicTabButtonClick implements BaseEvent {
  event_type = "Dashboard FDIC Tab Button Click";

  constructor(public event_properties: DashboardFdicTabButtonClickProperties) {
    this.event_properties = event_properties;
  }
}

export class DashboardFdicTabOpened implements BaseEvent {
  event_type = "Dashboard FDIC Tab Opened";
}

export class DashboardMetricCardBlurToggled implements BaseEvent {
  event_type = "Dashboard Metric Card Blur Toggled";

  constructor(public event_properties: DashboardMetricCardBlurToggledProperties) {
    this.event_properties = event_properties;
  }
}

export class DashboardMetricCardFormulaChanged implements BaseEvent {
  event_type = "Dashboard Metric Card Formula Changed";

  constructor(public event_properties: DashboardMetricCardFormulaChangedProperties) {
    this.event_properties = event_properties;
  }
}

export class DashboardMetricCardOpened implements BaseEvent {
  event_type = "Dashboard Metric Card Opened";

  constructor(public event_properties: DashboardMetricCardOpenedProperties) {
    this.event_properties = event_properties;
  }
}

export class DashboardReportCategorizationStatusViewed implements BaseEvent {
  event_type = "Dashboard Report Categorization Status Viewed";

  constructor(public event_properties: DashboardReportCategorizationStatusViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class DashboardReportCellClicked implements BaseEvent {
  event_type = "Dashboard Report Cell Clicked";

  constructor(public event_properties: DashboardReportCellClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class DashboardReportDownloadRequested implements BaseEvent {
  event_type = "Dashboard Report Download Requested";

  constructor(public event_properties: DashboardReportDownloadRequestedProperties) {
    this.event_properties = event_properties;
  }
}

export class DashboardReportFilterChanged implements BaseEvent {
  event_type = "Dashboard Report Filter Changed";

  constructor(public event_properties?: DashboardReportFilterChangedProperties) {
    this.event_properties = event_properties;
  }
}

export class DashboardReportHighlightCellHovered implements BaseEvent {
  event_type = "Dashboard Report Highlight Cell Hovered";

  constructor(public event_properties: DashboardReportHighlightCellHoveredProperties) {
    this.event_properties = event_properties;
  }
}

export class DashboardReportHighlightQuestionHovered implements BaseEvent {
  event_type = "Dashboard Report Highlight Question Hovered";

  constructor(public event_properties: DashboardReportHighlightQuestionHoveredProperties) {
    this.event_properties = event_properties;
  }
}

export class DashboardReportHighlightToggled implements BaseEvent {
  event_type = "Dashboard Report Highlight Toggled";

  constructor(public event_properties: DashboardReportHighlightToggledProperties) {
    this.event_properties = event_properties;
  }
}

export class DashboardReportLevelChanged implements BaseEvent {
  event_type = "Dashboard Report Level Changed";

  constructor(public event_properties?: DashboardReportLevelChangedProperties) {
    this.event_properties = event_properties;
  }
}

export class DashboardReportRowToggled implements BaseEvent {
  event_type = "Dashboard Report Row Toggled";

  constructor(public event_properties: DashboardReportRowToggledProperties) {
    this.event_properties = event_properties;
  }
}

export class DashboardReportStatusChecklistClicked implements BaseEvent {
  event_type = "Dashboard Report Status Checklist Clicked";

  constructor(public event_properties: DashboardReportStatusChecklistClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class DashboardReportTabChanged implements BaseEvent {
  event_type = "Dashboard Report Tab Changed";

  constructor(public event_properties: DashboardReportTabChangedProperties) {
    this.event_properties = event_properties;
  }
}

export class DashboardViewed implements BaseEvent {
  event_type = "Dashboard Viewed";
}

export class DeleteDataModalCompleted implements BaseEvent {
  event_type = "Delete Data Modal Completed";
}

export class DeleteDataModalOpened implements BaseEvent {
  event_type = "Delete Data Modal Opened";
}

export class DeleteDataReasonModalSubmitted implements BaseEvent {
  event_type = "Delete Data Reason Modal Submitted";

  constructor(public event_properties?: DeleteDataReasonModalSubmittedProperties) {
    this.event_properties = event_properties;
  }
}

export class DeleteDataReasonModalViewed implements BaseEvent {
  event_type = "Delete Data Reason Modal Viewed";
}

export class DeleteLedgerReconciliationFailed implements BaseEvent {
  event_type = "Delete Ledger Reconciliation Failed";

  constructor(public event_properties: DeleteLedgerReconciliationFailedProperties) {
    this.event_properties = event_properties;
  }
}

export class DeletedLedgerReconciliation implements BaseEvent {
  event_type = "Deleted Ledger Reconciliation";

  constructor(public event_properties: DeletedLedgerReconciliationProperties) {
    this.event_properties = event_properties;
  }
}

export class DontHaveAnEntityLearnMoreClicked implements BaseEvent {
  event_type = "Dont Have An Entity Learn More Clicked";
}

export class ExploreFeaturesOptionSelected implements BaseEvent {
  event_type = "Explore Features Option Selected";

  constructor(public event_properties: ExploreFeaturesOptionSelectedProperties) {
    this.event_properties = event_properties;
  }
}

export class FeedbackButtonClicked implements BaseEvent {
  event_type = "Feedback Button Clicked";

  constructor(public event_properties?: FeedbackButtonClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class FeedbackStarted implements BaseEvent {
  event_type = "Feedback Started";

  constructor(public event_properties: FeedbackStartedProperties) {
    this.event_properties = event_properties;
  }
}

export class FeedbackSubmitted implements BaseEvent {
  event_type = "Feedback Submitted";

  constructor(public event_properties: FeedbackSubmittedProperties) {
    this.event_properties = event_properties;
  }
}

export class FitTestPassed implements BaseEvent {
  event_type = "Fit Test Passed";
}

export class FitTestSubmitted implements BaseEvent {
  event_type = "Fit Test Submitted";
}

export class FixedAssetDeleted implements BaseEvent {
  event_type = "Fixed Asset Deleted";

  constructor(public event_properties: FixedAssetDeletedProperties) {
    this.event_properties = event_properties;
  }
}

export class FixedAssetDisposed implements BaseEvent {
  event_type = "Fixed Asset Disposed";

  constructor(public event_properties: FixedAssetDisposedProperties) {
    this.event_properties = event_properties;
  }
}

export class FixedAssetPaused implements BaseEvent {
  event_type = "Fixed Asset Paused";

  constructor(public event_properties: FixedAssetPausedProperties) {
    this.event_properties = event_properties;
  }
}

export class FixedAssetPlacedInService implements BaseEvent {
  event_type = "Fixed Asset Placed in Service";

  constructor(public event_properties: FixedAssetPlacedInServiceProperties) {
    this.event_properties = event_properties;
  }
}

export class FixedAssetPlacedInServiceFailed implements BaseEvent {
  event_type = "Fixed Asset Placed in Service Failed";

  constructor(public event_properties: FixedAssetPlacedInServiceFailedProperties) {
    this.event_properties = event_properties;
  }
}

export class FixedAssetResumed implements BaseEvent {
  event_type = "Fixed Asset Resumed";

  constructor(public event_properties: FixedAssetResumedProperties) {
    this.event_properties = event_properties;
  }
}

export class FixedAssetUpdateFailed implements BaseEvent {
  event_type = "Fixed Asset Update Failed";

  constructor(public event_properties: FixedAssetUpdateFailedProperties) {
    this.event_properties = event_properties;
  }
}

export class FixedAssetUpdated implements BaseEvent {
  event_type = "Fixed Asset Updated";

  constructor(public event_properties: FixedAssetUpdatedProperties) {
    this.event_properties = event_properties;
  }
}

export class FixedAssetVoided implements BaseEvent {
  event_type = "Fixed Asset Voided";

  constructor(public event_properties: FixedAssetVoidedProperties) {
    this.event_properties = event_properties;
  }
}

export class FixedAssetsCreateFailed implements BaseEvent {
  event_type = "Fixed Assets Create Failed";

  constructor(public event_properties: FixedAssetsCreateFailedProperties) {
    this.event_properties = event_properties;
  }
}

export class FixedAssetsCreated implements BaseEvent {
  event_type = "Fixed Assets Created";

  constructor(public event_properties: FixedAssetsCreatedProperties) {
    this.event_properties = event_properties;
  }
}

export class GettingStartedClicked implements BaseEvent {
  event_type = "Getting Started Clicked";
}

export class HistoricalBooksChecklistStepComplete implements BaseEvent {
  event_type = "Historical Books Checklist Step Complete";

  constructor(public event_properties: HistoricalBooksChecklistStepCompleteProperties) {
    this.event_properties = event_properties;
  }
}

export class HjePosted implements BaseEvent {
  event_type = "HJE Posted";

  constructor(public event_properties: HjePostedProperties) {
    this.event_properties = event_properties;
  }
}

export class ImpactfulTransactionsCompleteMessageSeen implements BaseEvent {
  event_type = "Impactful Transactions Complete Message Seen";

  constructor(public event_properties: ImpactfulTransactionsCompleteMessageSeenProperties) {
    this.event_properties = event_properties;
  }
}

export class ImpactfulTransactionsContinueCategorizingClicked implements BaseEvent {
  event_type = "Impactful Transactions Continue Categorizing Clicked";
}

export class ImpactfulTransactionsFilterEnabled implements BaseEvent {
  event_type = "Impactful Transactions Filter Enabled";

  constructor(public event_properties?: ImpactfulTransactionsFilterEnabledProperties) {
    this.event_properties = event_properties;
  }
}

export class ImpactfulTransactionsGoToDashboardClicked implements BaseEvent {
  event_type = "Impactful Transactions Go To Dashboard Clicked";
}

export class InboxItemStatusChanged implements BaseEvent {
  event_type = "Inbox Item Status Changed";

  constructor(public event_properties: InboxItemStatusChangedProperties) {
    this.event_properties = event_properties;
  }
}

export class InboxItemViewed implements BaseEvent {
  event_type = "Inbox Item Viewed";

  constructor(public event_properties: InboxItemViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class InboxViewed implements BaseEvent {
  event_type = "Inbox Viewed";

  constructor(public event_properties: InboxViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class IntegrationConnected implements BaseEvent {
  event_type = "Integration Connected";

  constructor(public event_properties: IntegrationConnectedProperties) {
    this.event_properties = event_properties;
  }
}

export class IntegrationConnectionFailed implements BaseEvent {
  event_type = "Integration Connection Failed";

  constructor(public event_properties: IntegrationConnectionFailedProperties) {
    this.event_properties = event_properties;
  }
}

export class IntegrationConnectionStarted implements BaseEvent {
  event_type = "Integration Connection Started";

  constructor(public event_properties: IntegrationConnectionStartedProperties) {
    this.event_properties = event_properties;
  }
}

export class IntegrationDisconnected implements BaseEvent {
  event_type = "Integration Disconnected";

  constructor(public event_properties: IntegrationDisconnectedProperties) {
    this.event_properties = event_properties;
  }
}

export class IntegrationIngestionDateSet implements BaseEvent {
  event_type = "Integration Ingestion Date Set";

  constructor(public event_properties: IntegrationIngestionDateSetProperties) {
    this.event_properties = event_properties;
  }
}

export class IntegrationViewed implements BaseEvent {
  event_type = "Integration Viewed";

  constructor(public event_properties: IntegrationViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class IntercomOpened implements BaseEvent {
  event_type = "Intercom Opened";
}

export class IntercomUnreadCountChanged implements BaseEvent {
  event_type = "Intercom Unread Count Changed";

  constructor(public event_properties: IntercomUnreadCountChangedProperties) {
    this.event_properties = event_properties;
  }
}

export class InviteModalOpened implements BaseEvent {
  event_type = "Invite Modal Opened";
}

export class InviteYourTeamInboxCtaClicked implements BaseEvent {
  event_type = "Invite Your Team Inbox CTA Clicked";
}

export class InvoiceDeleted implements BaseEvent {
  event_type = "Invoice Deleted";

  constructor(public event_properties: InvoiceDeletedProperties) {
    this.event_properties = event_properties;
  }
}

export class InvoiceDownloaded implements BaseEvent {
  event_type = "Invoice Downloaded";

  constructor(public event_properties: InvoiceDownloadedProperties) {
    this.event_properties = event_properties;
  }
}

export class InvoiceDrafted implements BaseEvent {
  event_type = "Invoice Drafted";

  constructor(public event_properties: InvoiceDraftedProperties) {
    this.event_properties = event_properties;
  }
}

export class InvoiceLineRecategorized implements BaseEvent {
  event_type = "Invoice Line Recategorized";

  constructor(public event_properties: InvoiceLineRecategorizedProperties) {
    this.event_properties = event_properties;
  }
}

export class InvoicePosted implements BaseEvent {
  event_type = "Invoice Posted";

  constructor(public event_properties: InvoicePostedProperties) {
    this.event_properties = event_properties;
  }
}

export class InvoiceVoided implements BaseEvent {
  event_type = "Invoice Voided";

  constructor(public event_properties: InvoiceVoidedProperties) {
    this.event_properties = event_properties;
  }
}

export class LedgerReconEntryToggleFailed implements BaseEvent {
  event_type = "Ledger Recon Entry Toggle Failed";

  constructor(public event_properties: LedgerReconEntryToggleFailedProperties) {
    this.event_properties = event_properties;
  }
}

export class LedgerReconEntryToggled implements BaseEvent {
  event_type = "Ledger Recon Entry Toggled";

  constructor(public event_properties: LedgerReconEntryToggledProperties) {
    this.event_properties = event_properties;
  }
}

export class LedgerReconciliationCompleted implements BaseEvent {
  event_type = "Ledger Reconciliation Completed";

  constructor(public event_properties: LedgerReconciliationCompletedProperties) {
    this.event_properties = event_properties;
  }
}

export class ManualRampSyncFailed implements BaseEvent {
  event_type = "Manual Ramp Sync Failed";

  constructor(public event_properties?: ManualRampSyncFailedProperties) {
    this.event_properties = event_properties;
  }
}

export class ManualRampSyncStarted implements BaseEvent {
  event_type = "Manual Ramp Sync Started";
}

export class ManualRampSyncSucceeded implements BaseEvent {
  event_type = "Manual Ramp Sync Succeeded";
}

export class ManualTransactionsCreated implements BaseEvent {
  event_type = "Manual Transactions Created";

  constructor(public event_properties: ManualTransactionsCreatedProperties) {
    this.event_properties = event_properties;
  }
}

export class ManualTransactionsFailed implements BaseEvent {
  event_type = "Manual Transactions Failed";
}

export class MatchedTransactionToBills implements BaseEvent {
  event_type = "Matched Transaction To Bills";

  constructor(public event_properties: MatchedTransactionToBillsProperties) {
    this.event_properties = event_properties;
  }
}

export class MjeDeleted implements BaseEvent {
  event_type = "MJE Deleted";

  constructor(public event_properties: MjeDeletedProperties) {
    this.event_properties = event_properties;
  }
}

export class MjePosted implements BaseEvent {
  event_type = "MJE Posted";

  constructor(public event_properties: MjePostedProperties) {
    this.event_properties = event_properties;
  }
}

export class MjeResumed implements BaseEvent {
  event_type = "MJE Resumed";

  constructor(public event_properties: MjeResumedProperties) {
    this.event_properties = event_properties;
  }
}

export class MjeReversed implements BaseEvent {
  event_type = "MJE Reversed";

  constructor(public event_properties: MjeReversedProperties) {
    this.event_properties = event_properties;
  }
}

export class MjeSaved implements BaseEvent {
  event_type = "MJE Saved";

  constructor(public event_properties: MjeSavedProperties) {
    this.event_properties = event_properties;
  }
}

export class MjeStarted implements BaseEvent {
  event_type = "MJE Started";

  constructor(public event_properties: MjeStartedProperties) {
    this.event_properties = event_properties;
  }
}

export class MjeViewed implements BaseEvent {
  event_type = "MJE Viewed";

  constructor(public event_properties: MjeViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class MjeVoided implements BaseEvent {
  event_type = "MJE Voided";

  constructor(public event_properties: MjeVoidedProperties) {
    this.event_properties = event_properties;
  }
}

export class MrrReportDownloaded implements BaseEvent {
  event_type = "MRR Report Downloaded";

  constructor(public event_properties: MrrReportDownloadedProperties) {
    this.event_properties = event_properties;
  }
}

export class NotificationBellClicked implements BaseEvent {
  event_type = "Notification Bell Clicked";

  constructor(public event_properties: NotificationBellClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class NotificationClicked implements BaseEvent {
  event_type = "Notification Clicked";

  constructor(public event_properties: NotificationClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class NotificationSettingsClicked implements BaseEvent {
  event_type = "Notification Settings Clicked";

  constructor(public event_properties?: NotificationSettingsClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class NotificationTabClicked implements BaseEvent {
  event_type = "Notification Tab Clicked";

  constructor(public event_properties: NotificationTabClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class NotificationsViewed implements BaseEvent {
  event_type = "Notifications Viewed";

  constructor(public event_properties: NotificationsViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class OnboardingChecklistItemHelpRequested implements BaseEvent {
  event_type = "Onboarding Checklist Item Help Requested";

  constructor(public event_properties?: OnboardingChecklistItemHelpRequestedProperties) {
    this.event_properties = event_properties;
  }
}

export class OnboardingCompleted implements BaseEvent {
  event_type = "Onboarding Completed";

  constructor(public event_properties: OnboardingCompletedProperties) {
    this.event_properties = event_properties;
  }
}

export class OnboardingDemoVideoClosed implements BaseEvent {
  event_type = "Onboarding Demo Video Closed";
}

export class OnboardingDemoVideoOpened implements BaseEvent {
  event_type = "Onboarding Demo Video Opened";
}

export class OnboardingDontHaveABankAccountSelected implements BaseEvent {
  event_type = "Onboarding Dont Have a Bank Account Selected";
}

export class OnboardingDontHaveAnEntitySelected implements BaseEvent {
  event_type = "Onboarding Dont Have an Entity Selected";
}

export class OnboardingHireAccountantButtonClicked implements BaseEvent {
  event_type = "Onboarding Hire Accountant Button Clicked";
}

export class OnboardingIngestionDateSet implements BaseEvent {
  event_type = "Onboarding Ingestion Date Set";

  constructor(public event_properties: OnboardingIngestionDateSetProperties) {
    this.event_properties = event_properties;
  }
}

export class OnboardingManualPayrollProviderSelected implements BaseEvent {
  event_type = "Onboarding Manual Payroll Provider Selected";

  constructor(public event_properties: OnboardingManualPayrollProviderSelectedProperties) {
    this.event_properties = event_properties;
  }
}

export class OnboardingPartnerReturnButtonClicked implements BaseEvent {
  event_type = "Onboarding Partner Return Button Clicked";
}

export class OnboardingQuestionAnswered implements BaseEvent {
  event_type = "Onboarding Question Answered";

  constructor(public event_properties: OnboardingQuestionAnsweredProperties) {
    this.event_properties = event_properties;
  }
}

export class OnboardingReviewTransactionsButtonClicked implements BaseEvent {
  event_type = "Onboarding Review Transactions Button Clicked";
}

export class OnboardingRipplingSelected implements BaseEvent {
  event_type = "Onboarding Rippling Selected";

  constructor(public event_properties: OnboardingRipplingSelectedProperties) {
    this.event_properties = event_properties;
  }
}

export class OnboardingScheduleWalkthroughButtonClicked implements BaseEvent {
  event_type = "Onboarding Schedule Walkthrough Button Clicked";
}

export class OnboardingStepCompleted implements BaseEvent {
  event_type = "Onboarding Step Completed";

  constructor(public event_properties: OnboardingStepCompletedProperties) {
    this.event_properties = event_properties;
  }
}

export class OnboardingStepViewed implements BaseEvent {
  event_type = "Onboarding Step Viewed";

  constructor(public event_properties: OnboardingStepViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class OnboardingViewDashboardClicked implements BaseEvent {
  event_type = "Onboarding View Dashboard Clicked";
}

export class OnboardingViewed implements BaseEvent {
  event_type = "Onboarding Viewed";

  constructor(public event_properties: OnboardingViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class OnboardingWalkthroughScheduled implements BaseEvent {
  event_type = "Onboarding Walkthrough Scheduled";
}

export class OpenAiDataSharingConsentGranted implements BaseEvent {
  event_type = "Open AI Data Sharing Consent Granted";

  constructor(public event_properties: OpenAiDataSharingConsentGrantedProperties) {
    this.event_properties = event_properties;
  }
}

export class OpeningBalanceInboxTaskActioned implements BaseEvent {
  event_type = "Opening Balance Inbox Task Actioned";

  constructor(public event_properties: OpeningBalanceInboxTaskActionedProperties) {
    this.event_properties = event_properties;
  }
}

export class PaidTrialUpgradeModalViewed implements BaseEvent {
  event_type = "Paid Trial Upgrade Modal Viewed";

  constructor(public event_properties: PaidTrialUpgradeModalViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class PaidTrialUpgradePlanSelected implements BaseEvent {
  event_type = "Paid Trial Upgrade Plan Selected";

  constructor(public event_properties: PaidTrialUpgradePlanSelectedProperties) {
    this.event_properties = event_properties;
  }
}

export class PayrollCreateFailed implements BaseEvent {
  event_type = "Payroll Create Failed";
}

export class PayrollCreated implements BaseEvent {
  event_type = "Payroll Created";
}

export class PayrollDeleteFailed implements BaseEvent {
  event_type = "Payroll Delete Failed";

  constructor(public event_properties: PayrollDeleteFailedProperties) {
    this.event_properties = event_properties;
  }
}

export class PayrollDeleted implements BaseEvent {
  event_type = "Payroll Deleted";

  constructor(public event_properties: PayrollDeletedProperties) {
    this.event_properties = event_properties;
  }
}

export class PayrollUpdateFailed implements BaseEvent {
  event_type = "Payroll Update Failed";

  constructor(public event_properties: PayrollUpdateFailedProperties) {
    this.event_properties = event_properties;
  }
}

export class PayrollUpdated implements BaseEvent {
  event_type = "Payroll Updated";

  constructor(public event_properties: PayrollUpdatedProperties) {
    this.event_properties = event_properties;
  }
}

export class PeopleTableFilterChanged implements BaseEvent {
  event_type = "People Table Filter Changed";

  constructor(public event_properties?: PeopleTableFilterChangedProperties) {
    this.event_properties = event_properties;
  }
}

export class PeopleTableRowClicked implements BaseEvent {
  event_type = "People Table Row Clicked";

  constructor(public event_properties: PeopleTableRowClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class PostedBillLineRecategorized implements BaseEvent {
  event_type = "Posted Bill Line Recategorized";

  constructor(public event_properties: PostedBillLineRecategorizedProperties) {
    this.event_properties = event_properties;
  }
}

export class PrepaidExpenseCreated implements BaseEvent {
  event_type = "Prepaid Expense Created";

  constructor(public event_properties: PrepaidExpenseCreatedProperties) {
    this.event_properties = event_properties;
  }
}

export class PrepaidExpenseDeleted implements BaseEvent {
  event_type = "Prepaid Expense Deleted";

  constructor(public event_properties: PrepaidExpenseDeletedProperties) {
    this.event_properties = event_properties;
  }
}

export class PrepaidExpenseVoided implements BaseEvent {
  event_type = "Prepaid Expense Voided";

  constructor(public event_properties: PrepaidExpenseVoidedProperties) {
    this.event_properties = event_properties;
  }
}

export class ProfileMenuOpened implements BaseEvent {
  event_type = "Profile Menu Opened";
}

export class RemoveClassTag implements BaseEvent {
  event_type = "Remove Class Tag";

  constructor(public event_properties: RemoveClassTagProperties) {
    this.event_properties = event_properties;
  }
}

export class ReportDownloaded implements BaseEvent {
  event_type = "Report Downloaded";

  constructor(public event_properties: ReportDownloadedProperties) {
    this.event_properties = event_properties;
  }
}

export class ReportModalExportClicked implements BaseEvent {
  event_type = "Report Modal Export Clicked";

  constructor(public event_properties: ReportModalExportClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class ReportModalOpened implements BaseEvent {
  event_type = "Report Modal Opened";

  constructor(public event_properties: ReportModalOpenedProperties) {
    this.event_properties = event_properties;
  }
}

export class ResetLedgerReconciliation implements BaseEvent {
  event_type = "Reset Ledger Reconciliation";

  constructor(public event_properties: ResetLedgerReconciliationProperties) {
    this.event_properties = event_properties;
  }
}

export class ResetLedgerReconciliationFailed implements BaseEvent {
  event_type = "Reset Ledger Reconciliation Failed";

  constructor(public event_properties: ResetLedgerReconciliationFailedProperties) {
    this.event_properties = event_properties;
  }
}

export class RevenueScheduleActivated implements BaseEvent {
  event_type = "Revenue Schedule Activated";

  constructor(public event_properties: RevenueScheduleActivatedProperties) {
    this.event_properties = event_properties;
  }
}

export class RevenueScheduleClosed implements BaseEvent {
  event_type = "Revenue Schedule Closed";

  constructor(public event_properties: RevenueScheduleClosedProperties) {
    this.event_properties = event_properties;
  }
}

export class RevenueScheduleCreated implements BaseEvent {
  event_type = "Revenue Schedule Created";

  constructor(public event_properties?: RevenueScheduleCreatedProperties) {
    this.event_properties = event_properties;
  }
}

export class RevenueSchedulePaused implements BaseEvent {
  event_type = "Revenue Schedule Paused";

  constructor(public event_properties: RevenueSchedulePausedProperties) {
    this.event_properties = event_properties;
  }
}

export class RevenueScheduleVoided implements BaseEvent {
  event_type = "Revenue Schedule Voided";

  constructor(public event_properties: RevenueScheduleVoidedProperties) {
    this.event_properties = event_properties;
  }
}

export class RevenueTabChanged implements BaseEvent {
  event_type = "Revenue Tab Changed";

  constructor(public event_properties: RevenueTabChangedProperties) {
    this.event_properties = event_properties;
  }
}

export class RuleDisabled implements BaseEvent {
  event_type = "Rule Disabled";

  constructor(public event_properties: RuleDisabledProperties) {
    this.event_properties = event_properties;
  }
}

export class RuleModalOpened implements BaseEvent {
  event_type = "Rule Modal Opened";

  constructor(public event_properties: RuleModalOpenedProperties) {
    this.event_properties = event_properties;
  }
}

export class RuleSaved implements BaseEvent {
  event_type = "Rule Saved";

  constructor(public event_properties?: RuleSavedProperties) {
    this.event_properties = event_properties;
  }
}

export class SandboxButtonClicked implements BaseEvent {
  event_type = "Sandbox Button Clicked";
}

export class SetupChecklistCompleted implements BaseEvent {
  event_type = "Setup Checklist Completed";
}

export class SetupChecklistDismissed implements BaseEvent {
  event_type = "Setup Checklist Dismissed";
}

export class SetupChecklistOpened implements BaseEvent {
  event_type = "Setup Checklist Opened";
}

export class SetupChecklistStepCompleted implements BaseEvent {
  event_type = "Setup Checklist Step Completed";

  constructor(public event_properties: SetupChecklistStepCompletedProperties) {
    this.event_properties = event_properties;
  }
}

export class ShowHighPriorityReviewClicked implements BaseEvent {
  event_type = "Show High Priority Review Clicked";
}

export class SidebarMinimizeChanged implements BaseEvent {
  event_type = "Sidebar Minimize Changed";

  constructor(public event_properties: SidebarMinimizeChangedProperties) {
    this.event_properties = event_properties;
  }
}

export class SpendingExplorerViewed implements BaseEvent {
  event_type = "Spending Explorer Viewed";
}

export class SpendingPayrollTabClicked implements BaseEvent {
  event_type = "Spending Payroll Tab Clicked";
}

export class SpendingRecurringOnlyClicked implements BaseEvent {
  event_type = "Spending Recurring Only Clicked";
}

export class SpendingRowClicked implements BaseEvent {
  event_type = "Spending Row Clicked";

  constructor(public event_properties?: SpendingRowClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class SpendingTransactionsButtonClicked implements BaseEvent {
  event_type = "Spending Transactions Button Clicked";

  constructor(public event_properties?: SpendingTransactionsButtonClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class StartYourCompanyStripeAtlasClicked implements BaseEvent {
  event_type = "Start Your Company Stripe Atlas Clicked";
}

export class StartedLedgerReconciliation implements BaseEvent {
  event_type = "Started Ledger Reconciliation";

  constructor(public event_properties: StartedLedgerReconciliationProperties) {
    this.event_properties = event_properties;
  }
}

export class StartedLedgerReconciliationError implements BaseEvent {
  event_type = "Started Ledger Reconciliation Error";

  constructor(public event_properties: StartedLedgerReconciliationErrorProperties) {
    this.event_properties = event_properties;
  }
}

export class StripePostAllClicked implements BaseEvent {
  event_type = "Stripe Post All Clicked";
}

export class StripeTableRowClicked implements BaseEvent {
  event_type = "Stripe Table Row Clicked";

  constructor(public event_properties: StripeTableRowClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class TourClosed implements BaseEvent {
  event_type = "Tour Closed";

  constructor(public event_properties: TourClosedProperties) {
    this.event_properties = event_properties;
  }
}

export class TourFinished implements BaseEvent {
  event_type = "Tour Finished";

  constructor(public event_properties: TourFinishedProperties) {
    this.event_properties = event_properties;
  }
}

export class TourStepSkipped implements BaseEvent {
  event_type = "Tour Step Skipped";

  constructor(public event_properties: TourStepSkippedProperties) {
    this.event_properties = event_properties;
  }
}

export class TourStepViewed implements BaseEvent {
  event_type = "Tour Step Viewed";

  constructor(public event_properties: TourStepViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class TourViewed implements BaseEvent {
  event_type = "Tour Viewed";

  constructor(public event_properties: TourViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class TransactionAccrualDateAdded implements BaseEvent {
  event_type = "Transaction Accrual Date Added";

  constructor(public event_properties: TransactionAccrualDateAddedProperties) {
    this.event_properties = event_properties;
  }
}

export class TransactionAiButtonClicked implements BaseEvent {
  event_type = "Transaction AI Button Clicked";

  constructor(public event_properties: TransactionAiButtonClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class TransactionAssigned implements BaseEvent {
  event_type = "Transaction Assigned";

  constructor(public event_properties: TransactionAssignedProperties) {
    this.event_properties = event_properties;
  }
}

export class TransactionAssignmentCanceled implements BaseEvent {
  event_type = "Transaction Assignment Canceled";

  constructor(public event_properties: TransactionAssignmentCanceledProperties) {
    this.event_properties = event_properties;
  }
}

export class TransactionAssignmentCompleted implements BaseEvent {
  event_type = "Transaction Assignment Completed";

  constructor(public event_properties: TransactionAssignmentCompletedProperties) {
    this.event_properties = event_properties;
  }
}

export class TransactionCategoryChanged implements BaseEvent {
  event_type = "Transaction Category Changed";

  constructor(public event_properties: TransactionCategoryChangedProperties) {
    this.event_properties = event_properties;
  }
}

export class TransactionCommentAdded implements BaseEvent {
  event_type = "Transaction Comment Added";

  constructor(public event_properties: TransactionCommentAddedProperties) {
    this.event_properties = event_properties;
  }
}

export class TransactionDescriptionChanged implements BaseEvent {
  event_type = "Transaction Description Changed";

  constructor(public event_properties: TransactionDescriptionChangedProperties) {
    this.event_properties = event_properties;
  }
}

export class TransactionDocumentationAdded implements BaseEvent {
  event_type = "Transaction Documentation Added";

  constructor(public event_properties: TransactionDocumentationAddedProperties) {
    this.event_properties = event_properties;
  }
}

export class TransactionDrawerArrowClicked implements BaseEvent {
  event_type = "Transaction Drawer Arrow Clicked";

  constructor(public event_properties: TransactionDrawerArrowClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class TransactionImportAccountSelected implements BaseEvent {
  event_type = "Transaction Import Account Selected";

  constructor(public event_properties: TransactionImportAccountSelectedProperties) {
    this.event_properties = event_properties;
  }
}

export class TransactionImportCsvUploadFailed implements BaseEvent {
  event_type = "Transaction Import CSV Upload Failed";

  constructor(public event_properties: TransactionImportCsvUploadFailedProperties) {
    this.event_properties = event_properties;
  }
}

export class TransactionImportFailed implements BaseEvent {
  event_type = "Transaction Import Failed";

  constructor(public event_properties: TransactionImportFailedProperties) {
    this.event_properties = event_properties;
  }
}

export class TransactionImportHelpLinkClicked implements BaseEvent {
  event_type = "Transaction Import Help Link Clicked";
}

export class TransactionImportInvalidFormat implements BaseEvent {
  event_type = "Transaction Import Invalid Format";

  constructor(public event_properties: TransactionImportInvalidFormatProperties) {
    this.event_properties = event_properties;
  }
}

export class TransactionImportModalOpened implements BaseEvent {
  event_type = "Transaction Import Modal Opened";
}

export class TransactionImportSucceeded implements BaseEvent {
  event_type = "Transaction Import Succeeded";

  constructor(public event_properties: TransactionImportSucceededProperties) {
    this.event_properties = event_properties;
  }
}

export class TransactionImportTemplateLinkClicked implements BaseEvent {
  event_type = "Transaction Import Template Link Clicked";
}

export class TransactionImportTimedOut implements BaseEvent {
  event_type = "Transaction Import Timed Out";

  constructor(public event_properties: TransactionImportTimedOutProperties) {
    this.event_properties = event_properties;
  }
}

export class TransactionOpened implements BaseEvent {
  event_type = "Transaction Opened";

  constructor(public event_properties: TransactionOpenedProperties) {
    this.event_properties = event_properties;
  }
}

export class TransactionRecurrenceChanged implements BaseEvent {
  event_type = "Transaction Recurrence Changed";

  constructor(public event_properties: TransactionRecurrenceChangedProperties) {
    this.event_properties = event_properties;
  }
}

export class TransactionSplitsSaved implements BaseEvent {
  event_type = "Transaction Splits Saved";

  constructor(public event_properties: TransactionSplitsSavedProperties) {
    this.event_properties = event_properties;
  }
}

export class TransactionStatusChanged implements BaseEvent {
  event_type = "Transaction Status Changed";

  constructor(public event_properties: TransactionStatusChangedProperties) {
    this.event_properties = event_properties;
  }
}

export class TransactionUnlinked implements BaseEvent {
  event_type = "Transaction Unlinked";

  constructor(public event_properties: TransactionUnlinkedProperties) {
    this.event_properties = event_properties;
  }
}

export class TransactionVendorChanged implements BaseEvent {
  event_type = "Transaction Vendor Changed";

  constructor(public event_properties: TransactionVendorChangedProperties) {
    this.event_properties = event_properties;
  }
}

export class TransactionsBulkCategorized implements BaseEvent {
  event_type = "Transactions Bulk Categorized";

  constructor(public event_properties?: TransactionsBulkCategorizedProperties) {
    this.event_properties = event_properties;
  }
}

export class TransactionsRemoved implements BaseEvent {
  event_type = "Transactions Removed";

  constructor(public event_properties: TransactionsRemovedProperties) {
    this.event_properties = event_properties;
  }
}

export class TransactionsTableCashflowChanged implements BaseEvent {
  event_type = "Transactions Table Cashflow Changed";

  constructor(public event_properties: TransactionsTableCashflowChangedProperties) {
    this.event_properties = event_properties;
  }
}

export class TransactionsTableRowClicked implements BaseEvent {
  event_type = "Transactions Table Row Clicked";

  constructor(public event_properties: TransactionsTableRowClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class UpdateAutomationSetting implements BaseEvent {
  event_type = "Update Automation Setting";

  constructor(public event_properties: UpdateAutomationSettingProperties) {
    this.event_properties = event_properties;
  }
}

export class UpdateVendorModalOpened implements BaseEvent {
  event_type = "Update Vendor Modal Opened";
}

export class UserGuideClicked implements BaseEvent {
  event_type = "User Guide Clicked";
}

export class UserMembershipChanged implements BaseEvent {
  event_type = "User Membership Changed";

  constructor(public event_properties: UserMembershipChangedProperties) {
    this.event_properties = event_properties;
  }
}

export class UserPositionChanged implements BaseEvent {
  event_type = "User Position Changed";

  constructor(public event_properties?: UserPositionChangedProperties) {
    this.event_properties = event_properties;
  }
}

export class UserProposedStartIngestionDate implements BaseEvent {
  event_type = "User Proposed Start Ingestion Date";

  constructor(public event_properties: UserProposedStartIngestionDateProperties) {
    this.event_properties = event_properties;
  }
}

export class UserRemoved implements BaseEvent {
  event_type = "User Removed";

  constructor(public event_properties?: UserRemovedProperties) {
    this.event_properties = event_properties;
  }
}

export class UserRoleChanged implements BaseEvent {
  event_type = "User Role Changed";

  constructor(public event_properties: UserRoleChangedProperties) {
    this.event_properties = event_properties;
  }
}

export class UserViewedCompany implements BaseEvent {
  event_type = "User Viewed Company";
}

export class UsersInvited implements BaseEvent {
  event_type = "Users Invited";

  constructor(public event_properties: UsersInvitedProperties) {
    this.event_properties = event_properties;
  }
}

export class VendorListPeriodSpendingClicked implements BaseEvent {
  event_type = "Vendor List Period Spending Clicked";
}

export class VendorMerged implements BaseEvent {
  event_type = "Vendor Merged";

  constructor(public event_properties?: VendorMergedProperties) {
    this.event_properties = event_properties;
  }
}

export class VendorReportOpened implements BaseEvent {
  event_type = "Vendor Report Opened";

  constructor(public event_properties: VendorReportOpenedProperties) {
    this.event_properties = event_properties;
  }
}

export class VendorUpdated implements BaseEvent {
  event_type = "Vendor Updated";

  constructor(public event_properties?: VendorUpdatedProperties) {
    this.event_properties = event_properties;
  }
}

export class Vendors1099StatusChanged implements BaseEvent {
  event_type = "Vendors 1099 Status Changed";

  constructor(public event_properties?: Vendors1099StatusChangedProperties) {
    this.event_properties = event_properties;
  }
}

export class Vendors1099TabOpened implements BaseEvent {
  event_type = "Vendors 1099 Tab Opened";
}

export class Vendors1099TemplateExported implements BaseEvent {
  event_type = "Vendors 1099 Template Exported";
}

export class WelcomeModalClosed implements BaseEvent {
  event_type = "Welcome Modal Closed";
}

export class WelcomeModalViewed implements BaseEvent {
  event_type = "Welcome Modal Viewed";
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Account Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Account%20Created)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. displayId)
   * @param options Amplitude event options.
   */
  accountCreated(
    properties: AccountCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountCreated(properties), options);
  }

  /**
   * Account Deactivated
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Account%20Deactivated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. ledgerAccountId)
   * @param options Amplitude event options.
   */
  accountDeactivated(
    properties: AccountDeactivatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountDeactivated(properties), options);
  }

  /**
   * Account Removed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Account%20Removed)
   *
   * This event tracks when a single account from a financial institution is removed from the Integrations page.
   *
   * @param properties The event's properties (e.g. accountId)
   * @param options Amplitude event options.
   */
  accountRemoved(
    properties: AccountRemovedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountRemoved(properties), options);
  }

  /**
   * Account Updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Account%20Updated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. displayId)
   * @param options Amplitude event options.
   */
  accountUpdated(
    properties: AccountUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountUpdated(properties), options);
  }

  /**
   * Accounting Record Attachment Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Accounting%20Record%20Attachment%20Deleted)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. fileId)
   * @param options Amplitude event options.
   */
  accountingRecordAttachmentDeleted(
    properties: AccountingRecordAttachmentDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountingRecordAttachmentDeleted(properties), options);
  }

  /**
   * Accounting Record Attachment Uploaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Accounting%20Record%20Attachment%20Uploaded)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. contentType)
   * @param options Amplitude event options.
   */
  accountingRecordAttachmentUploaded(
    properties: AccountingRecordAttachmentUploadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountingRecordAttachmentUploaded(properties), options);
  }

  /**
   * Accounting Record Comment Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Accounting%20Record%20Comment%20Added)
   *
   * Tracks when a comment was added to a:
   *
   * * Bill
   *
   * * MJE
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. commentLength)
   * @param options Amplitude event options.
   */
  accountingRecordCommentAdded(
    properties: AccountingRecordCommentAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountingRecordCommentAdded(properties), options);
  }

  /**
   * Accrual Events Enabled
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Accrual%20Events%20Enabled)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. companyId)
   * @param options Amplitude event options.
   */
  accrualEventsEnabled(
    properties: AccrualEventsEnabledProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccrualEventsEnabled(properties), options);
  }

  /**
   * Add Class Tag
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Add%20Class%20Tag)
   *
   * A classification (value/segment) is applied to a Puzzle object
   *
   * * Which object?
   *
   *   * transaction (or split)
   *
   *   * invoice
   *
   *   * bill
   *
   *   * MJE
   *
   *   * payroll
   *
   * @param properties The event's properties (e.g. actionType)
   * @param options Amplitude event options.
   */
  addClassTag(
    properties: AddClassTagProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddClassTag(properties), options);
  }

  /**
   * AI-Categorizer Enabled
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/AI-Categorizer%20Enabled)
   *
   * Owner: Facundo Rebord
   *
   * @param properties The event's properties (e.g. companyId)
   * @param options Amplitude event options.
   */
  aiCategorizerEnabled(
    properties: AiCategorizerEnabledProperties,
    options?: EventOptions,
  ) {
    return this.track(new AiCategorizerEnabled(properties), options);
  }

  /**
   * Ask An Accountant Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Ask%20An%20Accountant%20Button%20Clicked)
   *
   * Owner: Nazli Gungor
   *
   * @param properties The event's properties (e.g. location)
   * @param options Amplitude event options.
   */
  askAnAccountantButtonClicked(
    properties?: AskAnAccountantButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AskAnAccountantButtonClicked(properties), options);
  }

  /**
   * Assisted Categorization Enabled
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Assisted%20Categorization%20Enabled)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. companyId)
   * @param options Amplitude event options.
   */
  assistedCategorizationEnabled(
    properties: AssistedCategorizationEnabledProperties,
    options?: EventOptions,
  ) {
    return this.track(new AssistedCategorizationEnabled(properties), options);
  }

  /**
   * Automatic Assignment Enabled
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Automatic%20Assignment%20Enabled)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. companyId)
   * @param options Amplitude event options.
   */
  automaticAssignmentEnabled(
    properties: AutomaticAssignmentEnabledProperties,
    options?: EventOptions,
  ) {
    return this.track(new AutomaticAssignmentEnabled(properties), options);
  }

  /**
   * Autonomous Accounting Toggled
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Autonomous%20Accounting%20Toggled)
   *
   * Owner: Facundo Rebord
   *
   * @param properties The event's properties (e.g. companyId)
   * @param options Amplitude event options.
   */
  autonomousAccountingToggled(
    properties: AutonomousAccountingToggledProperties,
    options?: EventOptions,
  ) {
    return this.track(new AutonomousAccountingToggled(properties), options);
  }

  /**
   * Bank Rec Account Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bank%20Rec%20Account%20Selected)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. accountId)
   * @param options Amplitude event options.
   */
  bankRecAccountSelected(
    properties: BankRecAccountSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BankRecAccountSelected(properties), options);
  }

  /**
   * Bank Rec Approved
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bank%20Rec%20Approved)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  bankRecApproved(
    properties: BankRecApprovedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BankRecApproved(properties), options);
  }

  /**
   * Bank Rec Balance Updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bank%20Rec%20Balance%20Updated)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. accountId)
   * @param options Amplitude event options.
   */
  bankRecBalanceUpdated(
    properties: BankRecBalanceUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BankRecBalanceUpdated(properties), options);
  }

  /**
   * Bank Rec Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bank%20Rec%20Created)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. accountId)
   * @param options Amplitude event options.
   */
  bankRecCreated(
    properties: BankRecCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BankRecCreated(properties), options);
  }

  /**
   * Bank Rec Resumed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bank%20Rec%20Resumed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. hasWarnings)
   * @param options Amplitude event options.
   */
  bankRecResumed(
    properties: BankRecResumedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BankRecResumed(properties), options);
  }

  /**
   * Bank Rec Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bank%20Rec%20Started)
   *
   * User started bank reconciliation flow
   *
   * Owner: patrick@puzzle.io
   *
   * @param options Amplitude event options.
   */
  bankRecStarted(
    options?: EventOptions,
  ) {
    return this.track(new BankRecStarted(), options);
  }

  /**
   * Bank Rec Statement Toggled
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bank%20Rec%20Statement%20Toggled)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. isExpanding)
   * @param options Amplitude event options.
   */
  bankRecStatementToggled(
    properties: BankRecStatementToggledProperties,
    options?: EventOptions,
  ) {
    return this.track(new BankRecStatementToggled(properties), options);
  }

  /**
   * Bank Rec Statement Uploaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bank%20Rec%20Statement%20Uploaded)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  bankRecStatementUploaded(
    properties: BankRecStatementUploadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BankRecStatementUploaded(properties), options);
  }

  /**
   * Bank Rec Transaction Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bank%20Rec%20Transaction%20Added)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  bankRecTransactionAdded(
    properties: BankRecTransactionAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BankRecTransactionAdded(properties), options);
  }

  /**
   * Bank Rec Transaction Removed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bank%20Rec%20Transaction%20Removed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  bankRecTransactionRemoved(
    properties: BankRecTransactionRemovedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BankRecTransactionRemoved(properties), options);
  }

  /**
   * Bank Rec Unapproved
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bank%20Rec%20Unapproved)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  bankRecUnapproved(
    properties: BankRecUnapprovedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BankRecUnapproved(properties), options);
  }

  /**
   * Bank Rec User Transactions Updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bank%20Rec%20User%20Transactions%20Updated)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  bankRecUserTransactionsUpdated(
    properties: BankRecUserTransactionsUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BankRecUserTransactionsUpdated(properties), options);
  }

  /**
   * Bank Rec Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bank%20Rec%20Viewed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  bankRecViewed(
    properties: BankRecViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BankRecViewed(properties), options);
  }

  /**
   * Bill Attachment Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bill%20Attachment%20Deleted)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. fileId)
   * @param options Amplitude event options.
   */
  billAttachmentDeleted(
    properties: BillAttachmentDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BillAttachmentDeleted(properties), options);
  }

  /**
   * Bill Attachment Uploaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bill%20Attachment%20Uploaded)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. contentType)
   * @param options Amplitude event options.
   */
  billAttachmentUploaded(
    properties: BillAttachmentUploadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BillAttachmentUploaded(properties), options);
  }

  /**
   * Bill Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bill%20Deleted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  billDeleted(
    properties: BillDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BillDeleted(properties), options);
  }

  /**
   * Bill Import CSV Upload Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bill%20Import%20CSV%20Upload%20Failed)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. contentType)
   * @param options Amplitude event options.
   */
  billImportCsvUploadFailed(
    properties: BillImportCsvUploadFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BillImportCsvUploadFailed(properties), options);
  }

  /**
   * Bill Import Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bill%20Import%20Failed)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. contentType)
   * @param options Amplitude event options.
   */
  billImportFailed(
    properties: BillImportFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BillImportFailed(properties), options);
  }

  /**
   * Bill Import Invalid Format
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bill%20Import%20Invalid%20Format)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. contentType)
   * @param options Amplitude event options.
   */
  billImportInvalidFormat(
    properties: BillImportInvalidFormatProperties,
    options?: EventOptions,
  ) {
    return this.track(new BillImportInvalidFormat(properties), options);
  }

  /**
   * Bill Import Succeeded
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bill%20Import%20Succeeded)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. contentType)
   * @param options Amplitude event options.
   */
  billImportSucceeded(
    properties: BillImportSucceededProperties,
    options?: EventOptions,
  ) {
    return this.track(new BillImportSucceeded(properties), options);
  }

  /**
   * Bill Import Template Link Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bill%20Import%20Template%20Link%20Clicked)
   *
   * Owner: Kevin Ferri
   *
   * @param options Amplitude event options.
   */
  billImportTemplateLinkClicked(
    options?: EventOptions,
  ) {
    return this.track(new BillImportTemplateLinkClicked(), options);
  }

  /**
   * Bill Import Timed Out
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bill%20Import%20Timed%20Out)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. contentType)
   * @param options Amplitude event options.
   */
  billImportTimedOut(
    properties: BillImportTimedOutProperties,
    options?: EventOptions,
  ) {
    return this.track(new BillImportTimedOut(properties), options);
  }

  /**
   * Bill Payment Toggled
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bill%20Payment%20Toggled)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  billPaymentToggled(
    properties: BillPaymentToggledProperties,
    options?: EventOptions,
  ) {
    return this.track(new BillPaymentToggled(properties), options);
  }

  /**
   * Bill Post Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bill%20Post%20Failed)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. totalRows)
   * @param options Amplitude event options.
   */
  billPostFailed(
    properties: BillPostFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BillPostFailed(properties), options);
  }

  /**
   * Bill Posted
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bill%20Posted)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  billPosted(
    properties: BillPostedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BillPosted(properties), options);
  }

  /**
   * Bill View Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bill%20View%20Failed)
   *
   *
   *
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  billViewFailed(
    properties: BillViewFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BillViewFailed(properties), options);
  }

  /**
   * Bill Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bill%20Viewed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  billViewed(
    properties: BillViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BillViewed(properties), options);
  }

  /**
   * Bill Voided
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Bill%20Voided)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  billVoided(
    properties: BillVoidedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BillVoided(properties), options);
  }

  /**
   * Burn Treemap Zoom Decreased
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Burn%20Treemap%20Zoom%20Decreased)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. breadcrumbCount)
   * @param options Amplitude event options.
   */
  burnTreemapZoomDecreased(
    properties: BurnTreemapZoomDecreasedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BurnTreemapZoomDecreased(properties), options);
  }

  /**
   * Burn Treemap Zoom Increased
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Burn%20Treemap%20Zoom%20Increased)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. breadcrumbCount)
   * @param options Amplitude event options.
   */
  burnTreemapZoomIncreased(
    properties: BurnTreemapZoomIncreasedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BurnTreemapZoomIncreased(properties), options);
  }

  /**
   * Categorize Top Transactions Inbox CTA Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Categorize%20Top%20Transactions%20Inbox%20CTA%20Clicked)
   *
   * Owner: Scott Cormier
   *
   * @param options Amplitude event options.
   */
  categorizeTopTransactionsInboxCtaClicked(
    options?: EventOptions,
  ) {
    return this.track(new CategorizeTopTransactionsInboxCtaClicked(), options);
  }

  /**
   * Categorizer Date Range Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Categorizer%20Date%20Range%20Selected)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. endDate)
   * @param options Amplitude event options.
   */
  categorizerDateRangeSelected(
    properties: CategorizerDateRangeSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CategorizerDateRangeSelected(properties), options);
  }

  /**
   * Categorizer Date Range Step Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Categorizer%20Date%20Range%20Step%20Viewed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param options Amplitude event options.
   */
  categorizerDateRangeStepViewed(
    options?: EventOptions,
  ) {
    return this.track(new CategorizerDateRangeStepViewed(), options);
  }

  /**
   * Categorizer Intro Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Categorizer%20Intro%20Viewed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param options Amplitude event options.
   */
  categorizerIntroViewed(
    options?: EventOptions,
  ) {
    return this.track(new CategorizerIntroViewed(), options);
  }

  /**
   * Categorizer Load More Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Categorizer%20Load%20More%20Clicked)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. location)
   * @param options Amplitude event options.
   */
  categorizerLoadMoreClicked(
    properties?: CategorizerLoadMoreClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CategorizerLoadMoreClicked(properties), options);
  }

  /**
   * Categorizer Round Finished
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Categorizer%20Round%20Finished)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. closed)
   * @param options Amplitude event options.
   */
  categorizerRoundFinished(
    properties: CategorizerRoundFinishedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CategorizerRoundFinished(properties), options);
  }

  /**
   * Categorizer Round Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Categorizer%20Round%20Started)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. endDate)
   * @param options Amplitude event options.
   */
  categorizerRoundStarted(
    properties?: CategorizerRoundStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CategorizerRoundStarted(properties), options);
  }

  /**
   * Chat With An Expert Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Chat%20With%20An%20Expert%20Clicked)
   *
   * Owner: Nazli Gungor
   *
   * @param properties The event's properties (e.g. location)
   * @param options Amplitude event options.
   */
  chatWithAnExpertClicked(
    properties?: ChatWithAnExpertClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChatWithAnExpertClicked(properties), options);
  }

  /**
   * Checklist Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Checklist%20Completed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. checklistType)
   * @param options Amplitude event options.
   */
  checklistCompleted(
    properties: ChecklistCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChecklistCompleted(properties), options);
  }

  /**
   * Checklist Item Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Checklist%20Item%20Added)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. description)
   * @param options Amplitude event options.
   */
  checklistItemAdded(
    properties: ChecklistItemAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChecklistItemAdded(properties), options);
  }

  /**
   * Checklist Item Finalized
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Checklist%20Item%20Finalized)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  checklistItemFinalized(
    properties: ChecklistItemFinalizedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChecklistItemFinalized(properties), options);
  }

  /**
   * Checklist Item Removed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Checklist%20Item%20Removed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  checklistItemRemoved(
    properties: ChecklistItemRemovedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChecklistItemRemoved(properties), options);
  }

  /**
   * Checklist Item Unfinalized
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Checklist%20Item%20Unfinalized)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  checklistItemUnfinalized(
    properties: ChecklistItemUnfinalizedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChecklistItemUnfinalized(properties), options);
  }

  /**
   * Checklist Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Checklist%20Viewed)
   *
   * A monthly checklist was viewed
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. month)
   * @param options Amplitude event options.
   */
  checklistViewed(
    properties: ChecklistViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChecklistViewed(properties), options);
  }

  /**
   * Checkout Review CTA Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Checkout%20Review%20CTA%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  checkoutReviewCtaClicked(
    options?: EventOptions,
  ) {
    return this.track(new CheckoutReviewCtaClicked(), options);
  }

  /**
   * Checkout Review Screen Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Checkout%20Review%20Screen%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. plan)
   * @param options Amplitude event options.
   */
  checkoutReviewScreenViewed(
    properties?: CheckoutReviewScreenViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckoutReviewScreenViewed(properties), options);
  }

  /**
   * Company Attributes Updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Company%20Attributes%20Updated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. hasBookkeeper)
   * @param options Amplitude event options.
   */
  companyAttributesUpdated(
    properties?: CompanyAttributesUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CompanyAttributesUpdated(properties), options);
  }

  /**
   * Company Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Company%20Changed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. companyId)
   * @param options Amplitude event options.
   */
  companyChanged(
    properties: CompanyChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CompanyChanged(properties), options);
  }

  /**
   * Company Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Company%20Created)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. hasBookkeeper)
   * @param options Amplitude event options.
   */
  companyCreated(
    properties: CompanyCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CompanyCreated(properties), options);
  }

  /**
   * Company Settings Updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Company%20Settings%20Updated)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. name)
   * @param options Amplitude event options.
   */
  companySettingsUpdated(
    properties: CompanySettingsUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CompanySettingsUpdated(properties), options);
  }

  /**
   * Create Class Segment
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Create%20Class%20Segment)
   *
   * Triggered when a user adds a class segment (i.e engineering, design) to an existing class (i.e location, department, custom)
   *
   * @param properties The event's properties (e.g. actionType)
   * @param options Amplitude event options.
   */
  createClassSegment(
    properties: CreateClassSegmentProperties,
    options?: EventOptions,
  ) {
    return this.track(new CreateClassSegment(properties), options);
  }

  /**
   * Create Reporting Class
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Create%20Reporting%20Class)
   *
   * Triggered when a user creates a custom top-level class for classifications and departments
   *
   * @param properties The event's properties (e.g. actionType)
   * @param options Amplitude event options.
   */
  createReportingClass(
    properties: CreateReportingClassProperties,
    options?: EventOptions,
  ) {
    return this.track(new CreateReportingClass(properties), options);
  }

  /**
   * Custom Report Requested
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Custom%20Report%20Requested)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  customReportRequested(
    options?: EventOptions,
  ) {
    return this.track(new CustomReportRequested(), options);
  }

  /**
   * Dashboard Chart Help Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Dashboard%20Chart%20Help%20Clicked)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  dashboardChartHelpClicked(
    properties: DashboardChartHelpClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardChartHelpClicked(properties), options);
  }

  /**
   * Dashboard Chart Hovered
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Dashboard%20Chart%20Hovered)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  dashboardChartHovered(
    properties: DashboardChartHoveredProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardChartHovered(properties), options);
  }

  /**
   * Dashboard Chart Link Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Dashboard%20Chart%20Link%20Clicked)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  dashboardChartLinkClicked(
    properties: DashboardChartLinkClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardChartLinkClicked(properties), options);
  }

  /**
   * Dashboard Charts Toggled
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Dashboard%20Charts%20Toggled)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. isClosing)
   * @param options Amplitude event options.
   */
  dashboardChartsToggled(
    properties: DashboardChartsToggledProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardChartsToggled(properties), options);
  }

  /**
   * Dashboard FDIC Tab Button Click
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Dashboard%20FDIC%20Tab%20Button%20Click)
   *
   * Owner: Nazli Gungor
   *
   * @param properties The event's properties (e.g. partnerName)
   * @param options Amplitude event options.
   */
  dashboardFdicTabButtonClick(
    properties: DashboardFdicTabButtonClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardFdicTabButtonClick(properties), options);
  }

  /**
   * Dashboard FDIC Tab Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Dashboard%20FDIC%20Tab%20Opened)
   *
   * Owner: Nazli Gungor
   *
   * @param options Amplitude event options.
   */
  dashboardFdicTabOpened(
    options?: EventOptions,
  ) {
    return this.track(new DashboardFdicTabOpened(), options);
  }

  /**
   * Dashboard Metric Card Blur Toggled
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Dashboard%20Metric%20Card%20Blur%20Toggled)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. card)
   * @param options Amplitude event options.
   */
  dashboardMetricCardBlurToggled(
    properties: DashboardMetricCardBlurToggledProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardMetricCardBlurToggled(properties), options);
  }

  /**
   * Dashboard Metric Card Formula Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Dashboard%20Metric%20Card%20Formula%20Changed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. card)
   * @param options Amplitude event options.
   */
  dashboardMetricCardFormulaChanged(
    properties: DashboardMetricCardFormulaChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardMetricCardFormulaChanged(properties), options);
  }

  /**
   * Dashboard Metric Card Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Dashboard%20Metric%20Card%20Opened)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. card)
   * @param options Amplitude event options.
   */
  dashboardMetricCardOpened(
    properties: DashboardMetricCardOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardMetricCardOpened(properties), options);
  }

  /**
   * Dashboard Report Categorization Status Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Dashboard%20Report%20Categorization%20Status%20Viewed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. categorizedPercent)
   * @param options Amplitude event options.
   */
  dashboardReportCategorizationStatusViewed(
    properties: DashboardReportCategorizationStatusViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardReportCategorizationStatusViewed(properties), options);
  }

  /**
   * Dashboard Report Cell Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Dashboard%20Report%20Cell%20Clicked)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. columnName)
   * @param options Amplitude event options.
   */
  dashboardReportCellClicked(
    properties: DashboardReportCellClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardReportCellClicked(properties), options);
  }

  /**
   * Dashboard Report Download Requested
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Dashboard%20Report%20Download%20Requested)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. reportLevel)
   * @param options Amplitude event options.
   */
  dashboardReportDownloadRequested(
    properties: DashboardReportDownloadRequestedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardReportDownloadRequested(properties), options);
  }

  /**
   * Dashboard Report Filter Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Dashboard%20Report%20Filter%20Changed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. basis)
   * @param options Amplitude event options.
   */
  dashboardReportFilterChanged(
    properties?: DashboardReportFilterChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardReportFilterChanged(properties), options);
  }

  /**
   * Dashboard Report Highlight Cell Hovered
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Dashboard%20Report%20Highlight%20Cell%20Hovered)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. columnName)
   * @param options Amplitude event options.
   */
  dashboardReportHighlightCellHovered(
    properties: DashboardReportHighlightCellHoveredProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardReportHighlightCellHovered(properties), options);
  }

  /**
   * Dashboard Report Highlight Question Hovered
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Dashboard%20Report%20Highlight%20Question%20Hovered)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. description)
   * @param options Amplitude event options.
   */
  dashboardReportHighlightQuestionHovered(
    properties: DashboardReportHighlightQuestionHoveredProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardReportHighlightQuestionHovered(properties), options);
  }

  /**
   * Dashboard Report Highlight Toggled
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Dashboard%20Report%20Highlight%20Toggled)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. enabled)
   * @param options Amplitude event options.
   */
  dashboardReportHighlightToggled(
    properties: DashboardReportHighlightToggledProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardReportHighlightToggled(properties), options);
  }

  /**
   * Dashboard Report Level Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Dashboard%20Report%20Level%20Changed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. reportLevel)
   * @param options Amplitude event options.
   */
  dashboardReportLevelChanged(
    properties?: DashboardReportLevelChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardReportLevelChanged(properties), options);
  }

  /**
   * Dashboard Report Row Toggled
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Dashboard%20Report%20Row%20Toggled)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. depth)
   * @param options Amplitude event options.
   */
  dashboardReportRowToggled(
    properties: DashboardReportRowToggledProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardReportRowToggled(properties), options);
  }

  /**
   * Dashboard Report Status Checklist Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Dashboard%20Report%20Status%20Checklist%20Clicked)
   *
   *
   *
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. month)
   * @param options Amplitude event options.
   */
  dashboardReportStatusChecklistClicked(
    properties: DashboardReportStatusChecklistClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardReportStatusChecklistClicked(properties), options);
  }

  /**
   * Dashboard Report Tab Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Dashboard%20Report%20Tab%20Changed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. reportType)
   * @param options Amplitude event options.
   */
  dashboardReportTabChanged(
    properties: DashboardReportTabChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardReportTabChanged(properties), options);
  }

  /**
   * Dashboard Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Dashboard%20Viewed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param options Amplitude event options.
   */
  dashboardViewed(
    options?: EventOptions,
  ) {
    return this.track(new DashboardViewed(), options);
  }

  /**
   * Delete Data Modal Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Delete%20Data%20Modal%20Completed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param options Amplitude event options.
   */
  deleteDataModalCompleted(
    options?: EventOptions,
  ) {
    return this.track(new DeleteDataModalCompleted(), options);
  }

  /**
   * Delete Data Modal Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Delete%20Data%20Modal%20Opened)
   *
   * Owner: patrick@puzzle.io
   *
   * @param options Amplitude event options.
   */
  deleteDataModalOpened(
    options?: EventOptions,
  ) {
    return this.track(new DeleteDataModalOpened(), options);
  }

  /**
   * Delete Data Reason Modal Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Delete%20Data%20Reason%20Modal%20Submitted)
   *
   * Owner: Scott Cormier
   *
   * @param properties The event's properties (e.g. reason)
   * @param options Amplitude event options.
   */
  deleteDataReasonModalSubmitted(
    properties?: DeleteDataReasonModalSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeleteDataReasonModalSubmitted(properties), options);
  }

  /**
   * Delete Data Reason Modal Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Delete%20Data%20Reason%20Modal%20Viewed)
   *
   * Owner: Scott Cormier
   *
   * @param options Amplitude event options.
   */
  deleteDataReasonModalViewed(
    options?: EventOptions,
  ) {
    return this.track(new DeleteDataReasonModalViewed(), options);
  }

  /**
   * Delete Ledger Reconciliation Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Delete%20Ledger%20Reconciliation%20Failed)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. reconciliationId)
   * @param options Amplitude event options.
   */
  deleteLedgerReconciliationFailed(
    properties: DeleteLedgerReconciliationFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeleteLedgerReconciliationFailed(properties), options);
  }

  /**
   * Deleted Ledger Reconciliation
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Deleted%20Ledger%20Reconciliation)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. reconciliationId)
   * @param options Amplitude event options.
   */
  deletedLedgerReconciliation(
    properties: DeletedLedgerReconciliationProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeletedLedgerReconciliation(properties), options);
  }

  /**
   * Dont Have An Entity Learn More Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Dont%20Have%20An%20Entity%20Learn%20More%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  dontHaveAnEntityLearnMoreClicked(
    options?: EventOptions,
  ) {
    return this.track(new DontHaveAnEntityLearnMoreClicked(), options);
  }

  /**
   * Explore Features Option Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Explore%20Features%20Option%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. feature)
   * @param options Amplitude event options.
   */
  exploreFeaturesOptionSelected(
    properties: ExploreFeaturesOptionSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExploreFeaturesOptionSelected(properties), options);
  }

  /**
   * Feedback Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Feedback%20Button%20Clicked)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. location)
   * @param options Amplitude event options.
   */
  feedbackButtonClicked(
    properties?: FeedbackButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeedbackButtonClicked(properties), options);
  }

  /**
   * Feedback Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Feedback%20Started)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. form)
   * @param options Amplitude event options.
   */
  feedbackStarted(
    properties: FeedbackStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeedbackStarted(properties), options);
  }

  /**
   * Feedback Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Feedback%20Submitted)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. form)
   * @param options Amplitude event options.
   */
  feedbackSubmitted(
    properties: FeedbackSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeedbackSubmitted(properties), options);
  }

  /**
   * Fit Test Passed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Fit%20Test%20Passed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param options Amplitude event options.
   */
  fitTestPassed(
    options?: EventOptions,
  ) {
    return this.track(new FitTestPassed(), options);
  }

  /**
   * Fit Test Submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Fit%20Test%20Submitted)
   *
   * Owner: patrick@puzzle.io
   *
   * @param options Amplitude event options.
   */
  fitTestSubmitted(
    options?: EventOptions,
  ) {
    return this.track(new FitTestSubmitted(), options);
  }

  /**
   * Fixed Asset Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Fixed%20Asset%20Deleted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. fixedAssetId)
   * @param options Amplitude event options.
   */
  fixedAssetDeleted(
    properties: FixedAssetDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FixedAssetDeleted(properties), options);
  }

  /**
   * Fixed Asset Disposed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Fixed%20Asset%20Disposed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. fixedAssetId)
   * @param options Amplitude event options.
   */
  fixedAssetDisposed(
    properties: FixedAssetDisposedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FixedAssetDisposed(properties), options);
  }

  /**
   * Fixed Asset Paused
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Fixed%20Asset%20Paused)
   *
   * User event indicating that a fixed asset has been paused
   *
   * @param properties The event's properties (e.g. fixedAssetId)
   * @param options Amplitude event options.
   */
  fixedAssetPaused(
    properties: FixedAssetPausedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FixedAssetPaused(properties), options);
  }

  /**
   * Fixed Asset Placed in Service
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Fixed%20Asset%20Placed%20in%20Service)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. fixedAssetId)
   * @param options Amplitude event options.
   */
  fixedAssetPlacedInService(
    properties: FixedAssetPlacedInServiceProperties,
    options?: EventOptions,
  ) {
    return this.track(new FixedAssetPlacedInService(properties), options);
  }

  /**
   * Fixed Asset Placed in Service Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Fixed%20Asset%20Placed%20in%20Service%20Failed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. errorMessage)
   * @param options Amplitude event options.
   */
  fixedAssetPlacedInServiceFailed(
    properties: FixedAssetPlacedInServiceFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FixedAssetPlacedInServiceFailed(properties), options);
  }

  /**
   * Fixed Asset Resumed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Fixed%20Asset%20Resumed)
   *
   * User event that tracks when a fixed asset has been resumed or put back into service after a period of being paused.
   *
   * @param properties The event's properties (e.g. fixedAssetId)
   * @param options Amplitude event options.
   */
  fixedAssetResumed(
    properties: FixedAssetResumedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FixedAssetResumed(properties), options);
  }

  /**
   * Fixed Asset Update Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Fixed%20Asset%20Update%20Failed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. errorMessage)
   * @param options Amplitude event options.
   */
  fixedAssetUpdateFailed(
    properties: FixedAssetUpdateFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FixedAssetUpdateFailed(properties), options);
  }

  /**
   * Fixed Asset Updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Fixed%20Asset%20Updated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. fixedAssetId)
   * @param options Amplitude event options.
   */
  fixedAssetUpdated(
    properties: FixedAssetUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FixedAssetUpdated(properties), options);
  }

  /**
   * Fixed Asset Voided
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Fixed%20Asset%20Voided)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. fixedAssetId)
   * @param options Amplitude event options.
   */
  fixedAssetVoided(
    properties: FixedAssetVoidedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FixedAssetVoided(properties), options);
  }

  /**
   * Fixed Assets Create Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Fixed%20Assets%20Create%20Failed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. errorMessage)
   * @param options Amplitude event options.
   */
  fixedAssetsCreateFailed(
    properties: FixedAssetsCreateFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FixedAssetsCreateFailed(properties), options);
  }

  /**
   * Fixed Assets Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Fixed%20Assets%20Created)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. errorMessage)
   * @param options Amplitude event options.
   */
  fixedAssetsCreated(
    properties: FixedAssetsCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FixedAssetsCreated(properties), options);
  }

  /**
   * Getting Started Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Getting%20Started%20Clicked)
   *
   * Owner: patrick@puzzle.io
   *
   * @param options Amplitude event options.
   */
  gettingStartedClicked(
    options?: EventOptions,
  ) {
    return this.track(new GettingStartedClicked(), options);
  }

  /**
   * Historical Books Checklist Step Complete
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Historical%20Books%20Checklist%20Step%20Complete)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. stepNumber)
   * @param options Amplitude event options.
   */
  historicalBooksChecklistStepComplete(
    properties: HistoricalBooksChecklistStepCompleteProperties,
    options?: EventOptions,
  ) {
    return this.track(new HistoricalBooksChecklistStepComplete(properties), options);
  }

  /**
   * HJE Posted
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/HJE%20Posted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. entryCount)
   * @param options Amplitude event options.
   */
  hjePosted(
    properties: HjePostedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HjePosted(properties), options);
  }

  /**
   * Impactful Transactions Complete Message Seen
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Impactful%20Transactions%20Complete%20Message%20Seen)
   *
   *
   *
   *
   * If the user is fully categorized (`isFullyCategorized`), it shows a different message.
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. endDate)
   * @param options Amplitude event options.
   */
  impactfulTransactionsCompleteMessageSeen(
    properties: ImpactfulTransactionsCompleteMessageSeenProperties,
    options?: EventOptions,
  ) {
    return this.track(new ImpactfulTransactionsCompleteMessageSeen(properties), options);
  }

  /**
   * Impactful Transactions Continue Categorizing Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Impactful%20Transactions%20Continue%20Categorizing%20Clicked)
   *
   *
   *
   *
   * Owner: patrick@puzzle.io
   *
   * @param options Amplitude event options.
   */
  impactfulTransactionsContinueCategorizingClicked(
    options?: EventOptions,
  ) {
    return this.track(new ImpactfulTransactionsContinueCategorizingClicked(), options);
  }

  /**
   * Impactful Transactions Filter Enabled
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Impactful%20Transactions%20Filter%20Enabled)
   *
   *
   *
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. endDate)
   * @param options Amplitude event options.
   */
  impactfulTransactionsFilterEnabled(
    properties?: ImpactfulTransactionsFilterEnabledProperties,
    options?: EventOptions,
  ) {
    return this.track(new ImpactfulTransactionsFilterEnabled(properties), options);
  }

  /**
   * Impactful Transactions Go To Dashboard Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Impactful%20Transactions%20Go%20To%20Dashboard%20Clicked)
   *
   *
   *
   *
   * Owner: patrick@puzzle.io
   *
   * @param options Amplitude event options.
   */
  impactfulTransactionsGoToDashboardClicked(
    options?: EventOptions,
  ) {
    return this.track(new ImpactfulTransactionsGoToDashboardClicked(), options);
  }

  /**
   * Inbox Item Status Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Inbox%20Item%20Status%20Changed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  inboxItemStatusChanged(
    properties: InboxItemStatusChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InboxItemStatusChanged(properties), options);
  }

  /**
   * Inbox Item Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Inbox%20Item%20Viewed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. createdHoursAgo)
   * @param options Amplitude event options.
   */
  inboxItemViewed(
    properties: InboxItemViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InboxItemViewed(properties), options);
  }

  /**
   * Inbox Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Inbox%20Viewed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. tab)
   * @param options Amplitude event options.
   */
  inboxViewed(
    properties: InboxViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InboxViewed(properties), options);
  }

  /**
   * Integration Connected
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Integration%20Connected)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. connectionId)
   * @param options Amplitude event options.
   */
  integrationConnected(
    properties: IntegrationConnectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new IntegrationConnected(properties), options);
  }

  /**
   * Integration Connection Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Integration%20Connection%20Failed)
   *
   * When a user attempts to connect an integration, but it fails.
   *
   * Add the `reason` if available.
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. integrationType)
   * @param options Amplitude event options.
   */
  integrationConnectionFailed(
    properties: IntegrationConnectionFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new IntegrationConnectionFailed(properties), options);
  }

  /**
   * Integration Connection Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Integration%20Connection%20Started)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. integrationType)
   * @param options Amplitude event options.
   */
  integrationConnectionStarted(
    properties: IntegrationConnectionStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new IntegrationConnectionStarted(properties), options);
  }

  /**
   * Integration Disconnected
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Integration%20Disconnected)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. automated)
   * @param options Amplitude event options.
   */
  integrationDisconnected(
    properties: IntegrationDisconnectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new IntegrationDisconnected(properties), options);
  }

  /**
   * Integration Ingestion Date Set
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Integration%20Ingestion%20Date%20Set)
   *
   * Owner: Nazli Gungor
   *
   * @param properties The event's properties (e.g. companyId)
   * @param options Amplitude event options.
   */
  integrationIngestionDateSet(
    properties: IntegrationIngestionDateSetProperties,
    options?: EventOptions,
  ) {
    return this.track(new IntegrationIngestionDateSet(properties), options);
  }

  /**
   * Integration Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Integration%20Viewed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. connectionId)
   * @param options Amplitude event options.
   */
  integrationViewed(
    properties: IntegrationViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new IntegrationViewed(properties), options);
  }

  /**
   * Intercom Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Intercom%20Opened)
   *
   * Owner: patrick@puzzle.io
   *
   * @param options Amplitude event options.
   */
  intercomOpened(
    options?: EventOptions,
  ) {
    return this.track(new IntercomOpened(), options);
  }

  /**
   * Intercom Unread Count Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Intercom%20Unread%20Count%20Changed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. count)
   * @param options Amplitude event options.
   */
  intercomUnreadCountChanged(
    properties: IntercomUnreadCountChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new IntercomUnreadCountChanged(properties), options);
  }

  /**
   * Invite Modal Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Invite%20Modal%20Opened)
   *
   * Owner: patrick@puzzle.io
   *
   * @param options Amplitude event options.
   */
  inviteModalOpened(
    options?: EventOptions,
  ) {
    return this.track(new InviteModalOpened(), options);
  }

  /**
   * Invite Your Team Inbox CTA Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Invite%20Your%20Team%20Inbox%20CTA%20Clicked)
   *
   * Owner: Scott Cormier
   *
   * @param options Amplitude event options.
   */
  inviteYourTeamInboxCtaClicked(
    options?: EventOptions,
  ) {
    return this.track(new InviteYourTeamInboxCtaClicked(), options);
  }

  /**
   * Invoice Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Invoice%20Deleted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. invoiceId)
   * @param options Amplitude event options.
   */
  invoiceDeleted(
    properties: InvoiceDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InvoiceDeleted(properties), options);
  }

  /**
   * Invoice Downloaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Invoice%20Downloaded)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. invoiceId)
   * @param options Amplitude event options.
   */
  invoiceDownloaded(
    properties: InvoiceDownloadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InvoiceDownloaded(properties), options);
  }

  /**
   * Invoice Drafted
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Invoice%20Drafted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. invoiceId)
   * @param options Amplitude event options.
   */
  invoiceDrafted(
    properties: InvoiceDraftedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InvoiceDrafted(properties), options);
  }

  /**
   * Invoice Line Recategorized
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Invoice%20Line%20Recategorized)
   *
   * A line on a posted invoice was recategorized
   *
   * @param properties The event's properties (e.g. invoiceId)
   * @param options Amplitude event options.
   */
  invoiceLineRecategorized(
    properties: InvoiceLineRecategorizedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InvoiceLineRecategorized(properties), options);
  }

  /**
   * Invoice Posted
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Invoice%20Posted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. invoiceId)
   * @param options Amplitude event options.
   */
  invoicePosted(
    properties: InvoicePostedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InvoicePosted(properties), options);
  }

  /**
   * Invoice Voided
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Invoice%20Voided)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. invoiceId)
   * @param options Amplitude event options.
   */
  invoiceVoided(
    properties: InvoiceVoidedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InvoiceVoided(properties), options);
  }

  /**
   * Ledger Recon Entry Toggle Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Ledger%20Recon%20Entry%20Toggle%20Failed)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. accountId)
   * @param options Amplitude event options.
   */
  ledgerReconEntryToggleFailed(
    properties: LedgerReconEntryToggleFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LedgerReconEntryToggleFailed(properties), options);
  }

  /**
   * Ledger Recon Entry Toggled
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Ledger%20Recon%20Entry%20Toggled)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. accountId)
   * @param options Amplitude event options.
   */
  ledgerReconEntryToggled(
    properties: LedgerReconEntryToggledProperties,
    options?: EventOptions,
  ) {
    return this.track(new LedgerReconEntryToggled(properties), options);
  }

  /**
   * Ledger Reconciliation Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Ledger%20Reconciliation%20Completed)
   *
   * Owner: Lucy Richards
   *
   * @param properties The event's properties (e.g. accountId)
   * @param options Amplitude event options.
   */
  ledgerReconciliationCompleted(
    properties: LedgerReconciliationCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LedgerReconciliationCompleted(properties), options);
  }

  /**
   * Manual Ramp Sync Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Manual%20Ramp%20Sync%20Failed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. errorCode)
   * @param options Amplitude event options.
   */
  manualRampSyncFailed(
    properties?: ManualRampSyncFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ManualRampSyncFailed(properties), options);
  }

  /**
   * Manual Ramp Sync Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Manual%20Ramp%20Sync%20Started)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  manualRampSyncStarted(
    options?: EventOptions,
  ) {
    return this.track(new ManualRampSyncStarted(), options);
  }

  /**
   * Manual Ramp Sync Succeeded
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Manual%20Ramp%20Sync%20Succeeded)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  manualRampSyncSucceeded(
    options?: EventOptions,
  ) {
    return this.track(new ManualRampSyncSucceeded(), options);
  }

  /**
   * Manual Transactions Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Manual%20Transactions%20Created)
   *
   * Owner: Nazli Gungor
   *
   * @param properties The event's properties (e.g. totalRows)
   * @param options Amplitude event options.
   */
  manualTransactionsCreated(
    properties: ManualTransactionsCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ManualTransactionsCreated(properties), options);
  }

  /**
   * Manual Transactions Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Manual%20Transactions%20Failed)
   *
   * Owner: Nazli Gungor
   *
   * @param options Amplitude event options.
   */
  manualTransactionsFailed(
    options?: EventOptions,
  ) {
    return this.track(new ManualTransactionsFailed(), options);
  }

  /**
   * Matched Transaction To Bills
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Matched%20Transaction%20To%20Bills)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. numberOfBills)
   * @param options Amplitude event options.
   */
  matchedTransactionToBills(
    properties: MatchedTransactionToBillsProperties,
    options?: EventOptions,
  ) {
    return this.track(new MatchedTransactionToBills(properties), options);
  }

  /**
   * MJE Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/MJE%20Deleted)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  mjeDeleted(
    properties: MjeDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MjeDeleted(properties), options);
  }

  /**
   * MJE Posted
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/MJE%20Posted)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. hasAutoReversal)
   * @param options Amplitude event options.
   */
  mjePosted(
    properties: MjePostedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MjePosted(properties), options);
  }

  /**
   * MJE Resumed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/MJE%20Resumed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  mjeResumed(
    properties: MjeResumedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MjeResumed(properties), options);
  }

  /**
   * MJE Reversed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/MJE%20Reversed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  mjeReversed(
    properties: MjeReversedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MjeReversed(properties), options);
  }

  /**
   * MJE Saved
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/MJE%20Saved)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  mjeSaved(
    properties: MjeSavedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MjeSaved(properties), options);
  }

  /**
   * MJE Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/MJE%20Started)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. origin)
   * @param options Amplitude event options.
   */
  mjeStarted(
    properties: MjeStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MjeStarted(properties), options);
  }

  /**
   * MJE Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/MJE%20Viewed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  mjeViewed(
    properties: MjeViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MjeViewed(properties), options);
  }

  /**
   * MJE Voided
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/MJE%20Voided)
   *
   * Owner: Nazli Gungor
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  mjeVoided(
    properties: MjeVoidedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MjeVoided(properties), options);
  }

  /**
   * MRR Report Downloaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/MRR%20Report%20Downloaded)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. cohort)
   * @param options Amplitude event options.
   */
  mrrReportDownloaded(
    properties: MrrReportDownloadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MrrReportDownloaded(properties), options);
  }

  /**
   * Notification Bell Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Notification%20Bell%20Clicked)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. isUnread)
   * @param options Amplitude event options.
   */
  notificationBellClicked(
    properties: NotificationBellClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NotificationBellClicked(properties), options);
  }

  /**
   * Notification Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Notification%20Clicked)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. createdHoursAgo)
   * @param options Amplitude event options.
   */
  notificationClicked(
    properties: NotificationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NotificationClicked(properties), options);
  }

  /**
   * Notification Settings Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Notification%20Settings%20Clicked)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. location)
   * @param options Amplitude event options.
   */
  notificationSettingsClicked(
    properties?: NotificationSettingsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NotificationSettingsClicked(properties), options);
  }

  /**
   * Notification Tab Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Notification%20Tab%20Clicked)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. tab)
   * @param options Amplitude event options.
   */
  notificationTabClicked(
    properties: NotificationTabClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NotificationTabClicked(properties), options);
  }

  /**
   * Notifications Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Notifications%20Viewed)
   *
   * Fires when a user views the inbox.
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. badgeCount)
   * @param options Amplitude event options.
   */
  notificationsViewed(
    properties: NotificationsViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NotificationsViewed(properties), options);
  }

  /**
   * Onboarding Checklist Item Help Requested
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Onboarding%20Checklist%20Item%20Help%20Requested)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. location)
   * @param options Amplitude event options.
   */
  onboardingChecklistItemHelpRequested(
    properties?: OnboardingChecklistItemHelpRequestedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingChecklistItemHelpRequested(properties), options);
  }

  /**
   * Onboarding Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Onboarding%20Completed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. facebookClickId)
   * @param options Amplitude event options.
   */
  onboardingCompleted(
    properties: OnboardingCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingCompleted(properties), options);
  }

  /**
   * Onboarding Demo Video Closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Onboarding%20Demo%20Video%20Closed)
   *
   * Owner: Bryan Kang
   *
   * @param options Amplitude event options.
   */
  onboardingDemoVideoClosed(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingDemoVideoClosed(), options);
  }

  /**
   * Onboarding Demo Video Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Onboarding%20Demo%20Video%20Opened)
   *
   * Owner: Bryan Kang
   *
   * @param options Amplitude event options.
   */
  onboardingDemoVideoOpened(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingDemoVideoOpened(), options);
  }

  /**
   * Onboarding Dont Have a Bank Account Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Onboarding%20Dont%20Have%20a%20Bank%20Account%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  onboardingDontHaveABankAccountSelected(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingDontHaveABankAccountSelected(), options);
  }

  /**
   * Onboarding Dont Have an Entity Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Onboarding%20Dont%20Have%20an%20Entity%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  onboardingDontHaveAnEntitySelected(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingDontHaveAnEntitySelected(), options);
  }

  /**
   * Onboarding Hire Accountant Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Onboarding%20Hire%20Accountant%20Button%20Clicked)
   *
   * Owner: patrick@puzzle.io
   *
   * @param options Amplitude event options.
   */
  onboardingHireAccountantButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingHireAccountantButtonClicked(), options);
  }

  /**
   * Onboarding Ingestion Date Set
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Onboarding%20Ingestion%20Date%20Set)
   *
   * Owner: Nazli Gungor
   *
   * @param properties The event's properties (e.g. companyId)
   * @param options Amplitude event options.
   */
  onboardingIngestionDateSet(
    properties: OnboardingIngestionDateSetProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingIngestionDateSet(properties), options);
  }

  /**
   * Onboarding Manual Payroll Provider Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Onboarding%20Manual%20Payroll%20Provider%20Selected)
   *
   * Non-integrated payroll provider selected during onboarding step
   *
   * @param properties The event's properties (e.g. providerName)
   * @param options Amplitude event options.
   */
  onboardingManualPayrollProviderSelected(
    properties: OnboardingManualPayrollProviderSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingManualPayrollProviderSelected(properties), options);
  }

  /**
   * Onboarding Partner Return Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Onboarding%20Partner%20Return%20Button%20Clicked)
   *
   * Owner: patrick@puzzle.io
   *
   * @param options Amplitude event options.
   */
  onboardingPartnerReturnButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingPartnerReturnButtonClicked(), options);
  }

  /**
   * Onboarding Question Answered
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Onboarding%20Question%20Answered)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. location)
   * @param options Amplitude event options.
   */
  onboardingQuestionAnswered(
    properties: OnboardingQuestionAnsweredProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingQuestionAnswered(properties), options);
  }

  /**
   * Onboarding Review Transactions Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Onboarding%20Review%20Transactions%20Button%20Clicked)
   *
   * Owner: patrick@puzzle.io
   *
   * @param options Amplitude event options.
   */
  onboardingReviewTransactionsButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingReviewTransactionsButtonClicked(), options);
  }

  /**
   * Onboarding Rippling Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Onboarding%20Rippling%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. providerName)
   * @param options Amplitude event options.
   */
  onboardingRipplingSelected(
    properties: OnboardingRipplingSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingRipplingSelected(properties), options);
  }

  /**
   * Onboarding Schedule Walkthrough Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Onboarding%20Schedule%20Walkthrough%20Button%20Clicked)
   *
   * Owner: patrick@puzzle.io
   *
   * @param options Amplitude event options.
   */
  onboardingScheduleWalkthroughButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingScheduleWalkthroughButtonClicked(), options);
  }

  /**
   * Onboarding Step Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Onboarding%20Step%20Completed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  onboardingStepCompleted(
    properties: OnboardingStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingStepCompleted(properties), options);
  }

  /**
   * Onboarding Step Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Onboarding%20Step%20Viewed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  onboardingStepViewed(
    properties: OnboardingStepViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingStepViewed(properties), options);
  }

  /**
   * Onboarding View Dashboard Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Onboarding%20View%20Dashboard%20Clicked)
   *
   * Owner: patrick@puzzle.io
   *
   * @param options Amplitude event options.
   */
  onboardingViewDashboardClicked(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingViewDashboardClicked(), options);
  }

  /**
   * Onboarding Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Onboarding%20Viewed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  onboardingViewed(
    properties: OnboardingViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingViewed(properties), options);
  }

  /**
   * Onboarding Walkthrough Scheduled
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Onboarding%20Walkthrough%20Scheduled)
   *
   * Owner: patrick@puzzle.io
   *
   * @param options Amplitude event options.
   */
  onboardingWalkthroughScheduled(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingWalkthroughScheduled(), options);
  }

  /**
   * Open AI Data Sharing Consent Granted
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Open%20AI%20Data%20Sharing%20Consent%20Granted)
   *
   * Owner: Scott Cormier
   *
   * @param properties The event's properties (e.g. companyId)
   * @param options Amplitude event options.
   */
  openAiDataSharingConsentGranted(
    properties: OpenAiDataSharingConsentGrantedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenAiDataSharingConsentGranted(properties), options);
  }

  /**
   * Opening Balance Inbox Task Actioned
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Opening%20Balance%20Inbox%20Task%20Actioned)
   *
   * Owner: Scott Cormier
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  openingBalanceInboxTaskActioned(
    properties: OpeningBalanceInboxTaskActionedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpeningBalanceInboxTaskActioned(properties), options);
  }

  /**
   * Paid Trial Upgrade Modal Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Paid%20Trial%20Upgrade%20Modal%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. companyId)
   * @param options Amplitude event options.
   */
  paidTrialUpgradeModalViewed(
    properties: PaidTrialUpgradeModalViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaidTrialUpgradeModalViewed(properties), options);
  }

  /**
   * Paid Trial Upgrade Plan Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Paid%20Trial%20Upgrade%20Plan%20Selected)
   *
   * Owner: Scott Cormier
   *
   * @param properties The event's properties (e.g. companyId)
   * @param options Amplitude event options.
   */
  paidTrialUpgradePlanSelected(
    properties: PaidTrialUpgradePlanSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaidTrialUpgradePlanSelected(properties), options);
  }

  /**
   * Payroll Create Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Payroll%20Create%20Failed)
   *
   * Owner: Kevin Ferri
   *
   * @param options Amplitude event options.
   */
  payrollCreateFailed(
    options?: EventOptions,
  ) {
    return this.track(new PayrollCreateFailed(), options);
  }

  /**
   * Payroll Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Payroll%20Created)
   *
   * Owner: Kevin Ferri
   *
   * @param options Amplitude event options.
   */
  payrollCreated(
    options?: EventOptions,
  ) {
    return this.track(new PayrollCreated(), options);
  }

  /**
   * Payroll Delete Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Payroll%20Delete%20Failed)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  payrollDeleteFailed(
    properties: PayrollDeleteFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PayrollDeleteFailed(properties), options);
  }

  /**
   * Payroll Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Payroll%20Deleted)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  payrollDeleted(
    properties: PayrollDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PayrollDeleted(properties), options);
  }

  /**
   * Payroll Update Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Payroll%20Update%20Failed)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  payrollUpdateFailed(
    properties: PayrollUpdateFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PayrollUpdateFailed(properties), options);
  }

  /**
   * Payroll Updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Payroll%20Updated)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  payrollUpdated(
    properties: PayrollUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PayrollUpdated(properties), options);
  }

  /**
   * People Table Filter Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/People%20Table%20Filter%20Changed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. endDate)
   * @param options Amplitude event options.
   */
  peopleTableFilterChanged(
    properties?: PeopleTableFilterChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PeopleTableFilterChanged(properties), options);
  }

  /**
   * People Table Row Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/People%20Table%20Row%20Clicked)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. workerId)
   * @param options Amplitude event options.
   */
  peopleTableRowClicked(
    properties: PeopleTableRowClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PeopleTableRowClicked(properties), options);
  }

  /**
   * Posted Bill Line Recategorized
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Posted%20Bill%20Line%20Recategorized)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. billId)
   * @param options Amplitude event options.
   */
  postedBillLineRecategorized(
    properties: PostedBillLineRecategorizedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PostedBillLineRecategorized(properties), options);
  }

  /**
   * Prepaid Expense Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Prepaid%20Expense%20Created)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. prepaidSource)
   * @param options Amplitude event options.
   */
  prepaidExpenseCreated(
    properties: PrepaidExpenseCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrepaidExpenseCreated(properties), options);
  }

  /**
   * Prepaid Expense Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Prepaid%20Expense%20Deleted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  prepaidExpenseDeleted(
    properties: PrepaidExpenseDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrepaidExpenseDeleted(properties), options);
  }

  /**
   * Prepaid Expense Voided
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Prepaid%20Expense%20Voided)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  prepaidExpenseVoided(
    properties: PrepaidExpenseVoidedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PrepaidExpenseVoided(properties), options);
  }

  /**
   * Profile Menu Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Profile%20Menu%20Opened)
   *
   * Owner: patrick@puzzle.io
   *
   * @param options Amplitude event options.
   */
  profileMenuOpened(
    options?: EventOptions,
  ) {
    return this.track(new ProfileMenuOpened(), options);
  }

  /**
   * Remove Class Tag
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Remove%20Class%20Tag)
   *
   * * A classification (value/segment) is removed from a Puzzle object
   *
   *   * Which object?
   *
   *     * transaction (or split)
   *
   *     * invoice
   *
   *     * bill
   *
   *     * MJE
   *
   *     * payroll
   *
   * @param properties The event's properties (e.g. actionType)
   * @param options Amplitude event options.
   */
  removeClassTag(
    properties: RemoveClassTagProperties,
    options?: EventOptions,
  ) {
    return this.track(new RemoveClassTag(properties), options);
  }

  /**
   * Report Downloaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Report%20Downloaded)
   *
   * Owner: Nazli Gungor
   *
   * @param properties The event's properties (e.g. reportFormat)
   * @param options Amplitude event options.
   */
  reportDownloaded(
    properties: ReportDownloadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReportDownloaded(properties), options);
  }

  /**
   * Report Modal Export Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Report%20Modal%20Export%20Clicked)
   *
   * Owner: Nazli Gungor
   *
   * @param properties The event's properties (e.g. endDate)
   * @param options Amplitude event options.
   */
  reportModalExportClicked(
    properties: ReportModalExportClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReportModalExportClicked(properties), options);
  }

  /**
   * Report Modal Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Report%20Modal%20Opened)
   *
   * Report export modal opened
   *
   * Owner: Nazli Gungor
   *
   * @param properties The event's properties (e.g. reportType)
   * @param options Amplitude event options.
   */
  reportModalOpened(
    properties: ReportModalOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReportModalOpened(properties), options);
  }

  /**
   * Reset Ledger Reconciliation
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Reset%20Ledger%20Reconciliation)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. reconciliationId)
   * @param options Amplitude event options.
   */
  resetLedgerReconciliation(
    properties: ResetLedgerReconciliationProperties,
    options?: EventOptions,
  ) {
    return this.track(new ResetLedgerReconciliation(properties), options);
  }

  /**
   * Reset Ledger Reconciliation Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Reset%20Ledger%20Reconciliation%20Failed)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. reconciliationId)
   * @param options Amplitude event options.
   */
  resetLedgerReconciliationFailed(
    properties: ResetLedgerReconciliationFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ResetLedgerReconciliationFailed(properties), options);
  }

  /**
   * Revenue Schedule Activated
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Revenue%20Schedule%20Activated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  revenueScheduleActivated(
    properties: RevenueScheduleActivatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RevenueScheduleActivated(properties), options);
  }

  /**
   * Revenue Schedule Closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Revenue%20Schedule%20Closed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  revenueScheduleClosed(
    properties: RevenueScheduleClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RevenueScheduleClosed(properties), options);
  }

  /**
   * Revenue Schedule Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Revenue%20Schedule%20Created)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. distinct_id)
   * @param options Amplitude event options.
   */
  revenueScheduleCreated(
    properties?: RevenueScheduleCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RevenueScheduleCreated(properties), options);
  }

  /**
   * Revenue Schedule Paused
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Revenue%20Schedule%20Paused)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  revenueSchedulePaused(
    properties: RevenueSchedulePausedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RevenueSchedulePaused(properties), options);
  }

  /**
   * Revenue Schedule Voided
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Revenue%20Schedule%20Voided)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  revenueScheduleVoided(
    properties: RevenueScheduleVoidedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RevenueScheduleVoided(properties), options);
  }

  /**
   * Revenue Tab Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Revenue%20Tab%20Changed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. revenueTab)
   * @param options Amplitude event options.
   */
  revenueTabChanged(
    properties: RevenueTabChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RevenueTabChanged(properties), options);
  }

  /**
   * Rule Disabled
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Rule%20Disabled)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. ruleId)
   * @param options Amplitude event options.
   */
  ruleDisabled(
    properties: RuleDisabledProperties,
    options?: EventOptions,
  ) {
    return this.track(new RuleDisabled(properties), options);
  }

  /**
   * Rule Modal Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Rule%20Modal%20Opened)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. isNew)
   * @param options Amplitude event options.
   */
  ruleModalOpened(
    properties: RuleModalOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RuleModalOpened(properties), options);
  }

  /**
   * Rule Saved
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Rule%20Saved)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. location)
   * @param options Amplitude event options.
   */
  ruleSaved(
    properties?: RuleSavedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RuleSaved(properties), options);
  }

  /**
   * Sandbox Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Sandbox%20Button%20Clicked)
   *
   * Owner: patrick@puzzle.io
   *
   * @param options Amplitude event options.
   */
  sandboxButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new SandboxButtonClicked(), options);
  }

  /**
   * Setup Checklist Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Setup%20Checklist%20Completed)
   *
   * Owner: Bryan Kang
   *
   * @param options Amplitude event options.
   */
  setupChecklistCompleted(
    options?: EventOptions,
  ) {
    return this.track(new SetupChecklistCompleted(), options);
  }

  /**
   * Setup Checklist Dismissed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Setup%20Checklist%20Dismissed)
   *
   * Owner: Bryan Kang
   *
   * @param options Amplitude event options.
   */
  setupChecklistDismissed(
    options?: EventOptions,
  ) {
    return this.track(new SetupChecklistDismissed(), options);
  }

  /**
   * Setup Checklist Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Setup%20Checklist%20Opened)
   *
   * Owner: Bryan Kang
   *
   * @param options Amplitude event options.
   */
  setupChecklistOpened(
    options?: EventOptions,
  ) {
    return this.track(new SetupChecklistOpened(), options);
  }

  /**
   * Setup Checklist Step Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Setup%20Checklist%20Step%20Completed)
   *
   * Owner: Bryan Kang
   *
   * @param properties The event's properties (e.g. skipped)
   * @param options Amplitude event options.
   */
  setupChecklistStepCompleted(
    properties: SetupChecklistStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SetupChecklistStepCompleted(properties), options);
  }

  /**
   * Show High Priority Review Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Show%20High%20Priority%20Review%20Clicked)
   *
   * on transaction page
   *
   * @param options Amplitude event options.
   */
  showHighPriorityReviewClicked(
    options?: EventOptions,
  ) {
    return this.track(new ShowHighPriorityReviewClicked(), options);
  }

  /**
   * Sidebar Minimize Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Sidebar%20Minimize%20Changed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. minimized)
   * @param options Amplitude event options.
   */
  sidebarMinimizeChanged(
    properties: SidebarMinimizeChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SidebarMinimizeChanged(properties), options);
  }

  /**
   * Spending Explorer Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Spending%20Explorer%20Viewed)
   *
   * When the spending explorer page is viewed. Note this is redundant with page view, but was explicitly desired.
   *
   * Owner: Scott Cormier
   *
   * @param options Amplitude event options.
   */
  spendingExplorerViewed(
    options?: EventOptions,
  ) {
    return this.track(new SpendingExplorerViewed(), options);
  }

  /**
   * Spending Payroll Tab Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Spending%20Payroll%20Tab%20Clicked)
   *
   * Owner: Nazli Gungor
   *
   * @param options Amplitude event options.
   */
  spendingPayrollTabClicked(
    options?: EventOptions,
  ) {
    return this.track(new SpendingPayrollTabClicked(), options);
  }

  /**
   * Spending Recurring Only Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Spending%20Recurring%20Only%20Clicked)
   *
   * Owner: Nazli Gungor
   *
   * @param options Amplitude event options.
   */
  spendingRecurringOnlyClicked(
    options?: EventOptions,
  ) {
    return this.track(new SpendingRecurringOnlyClicked(), options);
  }

  /**
   * Spending Row Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Spending%20Row%20Clicked)
   *
   * Owner: Nazli Gungor
   *
   * @param properties The event's properties (e.g. location)
   * @param options Amplitude event options.
   */
  spendingRowClicked(
    properties?: SpendingRowClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SpendingRowClicked(properties), options);
  }

  /**
   * Spending Transactions Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Spending%20Transactions%20Button%20Clicked)
   *
   * Owner: Nazli Gungor
   *
   * @param properties The event's properties (e.g. location)
   * @param options Amplitude event options.
   */
  spendingTransactionsButtonClicked(
    properties?: SpendingTransactionsButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SpendingTransactionsButtonClicked(properties), options);
  }

  /**
   * Start Your Company Stripe Atlas Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Start%20Your%20Company%20Stripe%20Atlas%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  startYourCompanyStripeAtlasClicked(
    options?: EventOptions,
  ) {
    return this.track(new StartYourCompanyStripeAtlasClicked(), options);
  }

  /**
   * Started Ledger Reconciliation
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Started%20Ledger%20Reconciliation)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. accountId)
   * @param options Amplitude event options.
   */
  startedLedgerReconciliation(
    properties: StartedLedgerReconciliationProperties,
    options?: EventOptions,
  ) {
    return this.track(new StartedLedgerReconciliation(properties), options);
  }

  /**
   * Started Ledger Reconciliation Error
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Started%20Ledger%20Reconciliation%20Error)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. accountId)
   * @param options Amplitude event options.
   */
  startedLedgerReconciliationError(
    properties: StartedLedgerReconciliationErrorProperties,
    options?: EventOptions,
  ) {
    return this.track(new StartedLedgerReconciliationError(properties), options);
  }

  /**
   * Stripe Post All Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Stripe%20Post%20All%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  stripePostAllClicked(
    options?: EventOptions,
  ) {
    return this.track(new StripePostAllClicked(), options);
  }

  /**
   * Stripe Table Row Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Stripe%20Table%20Row%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. bucketId)
   * @param options Amplitude event options.
   */
  stripeTableRowClicked(
    properties: StripeTableRowClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new StripeTableRowClicked(properties), options);
  }

  /**
   * Tour Closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Tour%20Closed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. tourId)
   * @param options Amplitude event options.
   */
  tourClosed(
    properties: TourClosedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TourClosed(properties), options);
  }

  /**
   * Tour Finished
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Tour%20Finished)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. tourId)
   * @param options Amplitude event options.
   */
  tourFinished(
    properties: TourFinishedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TourFinished(properties), options);
  }

  /**
   * Tour Step Skipped
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Tour%20Step%20Skipped)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. stepName)
   * @param options Amplitude event options.
   */
  tourStepSkipped(
    properties: TourStepSkippedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TourStepSkipped(properties), options);
  }

  /**
   * Tour Step Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Tour%20Step%20Viewed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. stepName)
   * @param options Amplitude event options.
   */
  tourStepViewed(
    properties: TourStepViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TourStepViewed(properties), options);
  }

  /**
   * Tour Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Tour%20Viewed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. tourId)
   * @param options Amplitude event options.
   */
  tourViewed(
    properties: TourViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TourViewed(properties), options);
  }

  /**
   * Transaction Accrual Date Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transaction%20Accrual%20Date%20Added)
   *
   * Owner: Nazli Gungor
   *
   * @param properties The event's properties (e.g. transactionId)
   * @param options Amplitude event options.
   */
  transactionAccrualDateAdded(
    properties: TransactionAccrualDateAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionAccrualDateAdded(properties), options);
  }

  /**
   * Transaction AI Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transaction%20AI%20Button%20Clicked)
   *
   * Emitted when the `Ask AI` button is clicked in the transaction sidebar
   *
   * Owner: Lucy Richards
   *
   * @param properties The event's properties (e.g. companyConsentsToAI)
   * @param options Amplitude event options.
   */
  transactionAiButtonClicked(
    properties: TransactionAiButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionAiButtonClicked(properties), options);
  }

  /**
   * Transaction Assigned
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transaction%20Assigned)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. assigneeId)
   * @param options Amplitude event options.
   */
  transactionAssigned(
    properties: TransactionAssignedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionAssigned(properties), options);
  }

  /**
   * Transaction Assignment Canceled
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transaction%20Assignment%20Canceled)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  transactionAssignmentCanceled(
    properties: TransactionAssignmentCanceledProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionAssignmentCanceled(properties), options);
  }

  /**
   * Transaction Assignment Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transaction%20Assignment%20Completed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. id)
   * @param options Amplitude event options.
   */
  transactionAssignmentCompleted(
    properties: TransactionAssignmentCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionAssignmentCompleted(properties), options);
  }

  /**
   * Transaction Category Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transaction%20Category%20Changed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. categoryId)
   * @param options Amplitude event options.
   */
  transactionCategoryChanged(
    properties: TransactionCategoryChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionCategoryChanged(properties), options);
  }

  /**
   * Transaction Comment Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transaction%20Comment%20Added)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. transactionId)
   * @param options Amplitude event options.
   */
  transactionCommentAdded(
    properties: TransactionCommentAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionCommentAdded(properties), options);
  }

  /**
   * Transaction Description Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transaction%20Description%20Changed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. description)
   * @param options Amplitude event options.
   */
  transactionDescriptionChanged(
    properties: TransactionDescriptionChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionDescriptionChanged(properties), options);
  }

  /**
   * Transaction Documentation Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transaction%20Documentation%20Added)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. transactionId)
   * @param options Amplitude event options.
   */
  transactionDocumentationAdded(
    properties: TransactionDocumentationAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionDocumentationAdded(properties), options);
  }

  /**
   * Transaction Drawer Arrow Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transaction%20Drawer%20Arrow%20Clicked)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. direction)
   * @param options Amplitude event options.
   */
  transactionDrawerArrowClicked(
    properties: TransactionDrawerArrowClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionDrawerArrowClicked(properties), options);
  }

  /**
   * Transaction Import Account Selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transaction%20Import%20Account%20Selected)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. accountId)
   * @param options Amplitude event options.
   */
  transactionImportAccountSelected(
    properties: TransactionImportAccountSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionImportAccountSelected(properties), options);
  }

  /**
   * Transaction Import CSV Upload Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transaction%20Import%20CSV%20Upload%20Failed)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. contentType)
   * @param options Amplitude event options.
   */
  transactionImportCsvUploadFailed(
    properties: TransactionImportCsvUploadFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionImportCsvUploadFailed(properties), options);
  }

  /**
   * Transaction Import Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transaction%20Import%20Failed)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. contentType)
   * @param options Amplitude event options.
   */
  transactionImportFailed(
    properties: TransactionImportFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionImportFailed(properties), options);
  }

  /**
   * Transaction Import Help Link Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transaction%20Import%20Help%20Link%20Clicked)
   *
   * Owner: Kevin Ferri
   *
   * @param options Amplitude event options.
   */
  transactionImportHelpLinkClicked(
    options?: EventOptions,
  ) {
    return this.track(new TransactionImportHelpLinkClicked(), options);
  }

  /**
   * Transaction Import Invalid Format
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transaction%20Import%20Invalid%20Format)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. contentType)
   * @param options Amplitude event options.
   */
  transactionImportInvalidFormat(
    properties: TransactionImportInvalidFormatProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionImportInvalidFormat(properties), options);
  }

  /**
   * Transaction Import Modal Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transaction%20Import%20Modal%20Opened)
   *
   * Owner: Kevin Ferri
   *
   * @param options Amplitude event options.
   */
  transactionImportModalOpened(
    options?: EventOptions,
  ) {
    return this.track(new TransactionImportModalOpened(), options);
  }

  /**
   * Transaction Import Succeeded
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transaction%20Import%20Succeeded)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. contentType)
   * @param options Amplitude event options.
   */
  transactionImportSucceeded(
    properties: TransactionImportSucceededProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionImportSucceeded(properties), options);
  }

  /**
   * Transaction Import Template Link Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transaction%20Import%20Template%20Link%20Clicked)
   *
   * Owner: Kevin Ferri
   *
   * @param options Amplitude event options.
   */
  transactionImportTemplateLinkClicked(
    options?: EventOptions,
  ) {
    return this.track(new TransactionImportTemplateLinkClicked(), options);
  }

  /**
   * Transaction Import Timed Out
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transaction%20Import%20Timed%20Out)
   *
   * Owner: Kevin Ferri
   *
   * @param properties The event's properties (e.g. contentType)
   * @param options Amplitude event options.
   */
  transactionImportTimedOut(
    properties: TransactionImportTimedOutProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionImportTimedOut(properties), options);
  }

  /**
   * Transaction Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transaction%20Opened)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. transactionId)
   * @param options Amplitude event options.
   */
  transactionOpened(
    properties: TransactionOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionOpened(properties), options);
  }

  /**
   * Transaction Recurrence Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transaction%20Recurrence%20Changed)
   *
   * Owner: Nazli Gungor
   *
   * @param properties The event's properties (e.g. recurrence)
   * @param options Amplitude event options.
   */
  transactionRecurrenceChanged(
    properties: TransactionRecurrenceChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionRecurrenceChanged(properties), options);
  }

  /**
   * Transaction Splits Saved
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transaction%20Splits%20Saved)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. totalSplits)
   * @param options Amplitude event options.
   */
  transactionSplitsSaved(
    properties: TransactionSplitsSavedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionSplitsSaved(properties), options);
  }

  /**
   * Transaction Status Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transaction%20Status%20Changed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. location)
   * @param options Amplitude event options.
   */
  transactionStatusChanged(
    properties: TransactionStatusChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionStatusChanged(properties), options);
  }

  /**
   * Transaction Unlinked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transaction%20Unlinked)
   *
   * Owner: Nazli Gungor
   *
   * @param properties The event's properties (e.g. transactionId)
   * @param options Amplitude event options.
   */
  transactionUnlinked(
    properties: TransactionUnlinkedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionUnlinked(properties), options);
  }

  /**
   * Transaction Vendor Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transaction%20Vendor%20Changed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. transactionId)
   * @param options Amplitude event options.
   */
  transactionVendorChanged(
    properties: TransactionVendorChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionVendorChanged(properties), options);
  }

  /**
   * Transactions Bulk Categorized
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transactions%20Bulk%20Categorized)
   *
   * Owner: Nazli Gungor
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  transactionsBulkCategorized(
    properties?: TransactionsBulkCategorizedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionsBulkCategorized(properties), options);
  }

  /**
   * Transactions Removed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transactions%20Removed)
   *
   * Owner: Nazli Gungor
   *
   * @param properties The event's properties (e.g. transactionIds)
   * @param options Amplitude event options.
   */
  transactionsRemoved(
    properties: TransactionsRemovedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionsRemoved(properties), options);
  }

  /**
   * Transactions Table Cashflow Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transactions%20Table%20Cashflow%20Changed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. cashFlow)
   * @param options Amplitude event options.
   */
  transactionsTableCashflowChanged(
    properties: TransactionsTableCashflowChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionsTableCashflowChanged(properties), options);
  }

  /**
   * Transactions Table Row Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Transactions%20Table%20Row%20Clicked)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. transactionId)
   * @param options Amplitude event options.
   */
  transactionsTableRowClicked(
    properties: TransactionsTableRowClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TransactionsTableRowClicked(properties), options);
  }

  /**
   * Update Automation Setting
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Update%20Automation%20Setting)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. newStatus)
   * @param options Amplitude event options.
   */
  updateAutomationSetting(
    properties: UpdateAutomationSettingProperties,
    options?: EventOptions,
  ) {
    return this.track(new UpdateAutomationSetting(properties), options);
  }

  /**
   * Update Vendor Modal Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Update%20Vendor%20Modal%20Opened)
   *
   * Modal to update vendor was triggered to open by the user
   *
   * @param options Amplitude event options.
   */
  updateVendorModalOpened(
    options?: EventOptions,
  ) {
    return this.track(new UpdateVendorModalOpened(), options);
  }

  /**
   * User Guide Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/User%20Guide%20Clicked)
   *
   * Owner: Nazli Gungor
   *
   * @param options Amplitude event options.
   */
  userGuideClicked(
    options?: EventOptions,
  ) {
    return this.track(new UserGuideClicked(), options);
  }

  /**
   * User Membership Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/User%20Membership%20Changed)
   *
   * Owner: Nazli Gungor
   *
   * @param properties The event's properties (e.g. status)
   * @param options Amplitude event options.
   */
  userMembershipChanged(
    properties: UserMembershipChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserMembershipChanged(properties), options);
  }

  /**
   * User Position Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/User%20Position%20Changed)
   *
   * end-user changed their position role value
   *
   * @param properties The event's properties (e.g. newValue)
   * @param options Amplitude event options.
   */
  userPositionChanged(
    properties?: UserPositionChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserPositionChanged(properties), options);
  }

  /**
   * User Proposed Start Ingestion Date
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/User%20Proposed%20Start%20Ingestion%20Date)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. date)
   * @param options Amplitude event options.
   */
  userProposedStartIngestionDate(
    properties: UserProposedStartIngestionDateProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserProposedStartIngestionDate(properties), options);
  }

  /**
   * User Removed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/User%20Removed)
   *
   * Owner: Nazli Gungor
   *
   * @param properties The event's properties (e.g. invitationId)
   * @param options Amplitude event options.
   */
  userRemoved(
    properties?: UserRemovedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserRemoved(properties), options);
  }

  /**
   * User Role Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/User%20Role%20Changed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. oldRole)
   * @param options Amplitude event options.
   */
  userRoleChanged(
    properties: UserRoleChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserRoleChanged(properties), options);
  }

  /**
   * User Viewed Company
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/User%20Viewed%20Company)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  userViewedCompany(
    options?: EventOptions,
  ) {
    return this.track(new UserViewedCompany(), options);
  }

  /**
   * Users Invited
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Users%20Invited)
   *
   * Owner: patrick@puzzle.io
   *
   * @param properties The event's properties (e.g. emails)
   * @param options Amplitude event options.
   */
  usersInvited(
    properties: UsersInvitedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UsersInvited(properties), options);
  }

  /**
   * Vendor List Period Spending Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Vendor%20List%20Period%20Spending%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  vendorListPeriodSpendingClicked(
    options?: EventOptions,
  ) {
    return this.track(new VendorListPeriodSpendingClicked(), options);
  }

  /**
   * Vendor Merged
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Vendor%20Merged)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. location)
   * @param options Amplitude event options.
   */
  vendorMerged(
    properties?: VendorMergedProperties,
    options?: EventOptions,
  ) {
    return this.track(new VendorMerged(properties), options);
  }

  /**
   * Vendor Report Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Vendor%20Report%20Opened)
   *
   * This event tracks when a user clicks on the Vendor List tab, or the "View all vendors" button in the spending section.
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  vendorReportOpened(
    properties: VendorReportOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new VendorReportOpened(properties), options);
  }

  /**
   * Vendor Updated
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Vendor%20Updated)
   *
   * Vendor edited from the Vendor List page
   *
   * @param properties The event's properties (e.g. location)
   * @param options Amplitude event options.
   */
  vendorUpdated(
    properties?: VendorUpdatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new VendorUpdated(properties), options);
  }

  /**
   * Vendors 1099 Status Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Vendors%201099%20Status%20Changed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. location)
   * @param options Amplitude event options.
   */
  vendors1099StatusChanged(
    properties?: Vendors1099StatusChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new Vendors1099StatusChanged(properties), options);
  }

  /**
   * Vendors 1099 Tab Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Vendors%201099%20Tab%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  vendors1099TabOpened(
    options?: EventOptions,
  ) {
    return this.track(new Vendors1099TabOpened(), options);
  }

  /**
   * Vendors 1099 Template Exported
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Vendors%201099%20Template%20Exported)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  vendors1099TemplateExported(
    options?: EventOptions,
  ) {
    return this.track(new Vendors1099TemplateExported(), options);
  }

  /**
   * Welcome Modal Closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Welcome%20Modal%20Closed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param options Amplitude event options.
   */
  welcomeModalClosed(
    options?: EventOptions,
  ) {
    return this.track(new WelcomeModalClosed(), options);
  }

  /**
   * Welcome Modal Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/puzzle-5299/Puzzle/events/main/latest/Welcome%20Modal%20Viewed)
   *
   * Owner: patrick@puzzle.io
   *
   * @param options Amplitude event options.
   */
  welcomeModalViewed(
    options?: EventOptions,
  ) {
    return this.track(new WelcomeModalViewed(), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
