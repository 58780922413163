import React from "react";
import { Tag, Tooltip, Text } from "@puzzle/ui";

import { ClassSegment } from "components/common/Classifications/TagEntity";
import ClassificationsSection from "components/common/Classifications/ClassificationsSection";
import { ClassAwareType } from "graphql/types";
import HoverCard from "components/common/HoverCard";
import { Box } from "ve";

type Props = { classSegments: ClassSegment[] };

export const TransactionClassesCell = ({ classSegments }: Props) => {
  if (classSegments.length === 0) return null;

  const showClassificationSum = classSegments.length > 1;
  const visibleSegmentName = classSegments[0].name;

  // Fake entity since users won't be able to edit the classifications from this cell
  const entity = {
    id: "",
    name: "",
    type: ClassAwareType.TransactionDetail,
    classSegments: classSegments,
  };

  if (showClassificationSum) {
    return (
      <HoverCard
        side="left"
        trigger={
          <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
            <Tag size="medium" style={{ cursor: "pointer" }}>
              <Text style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                {visibleSegmentName}
              </Text>
            </Tag>
            <Text size={"bodyS"} css={{ paddingLeft: "$1h", color: "$gray300" }}>{`+${
              classSegments.length - 1
            }`}</Text>
          </div>
        }
      >
        <HoverCard.Body>
          <ClassificationsSection readOnly={true} hideAddButton={true} entity={entity} />
        </HoverCard.Body>
      </HoverCard>
    );
  } else {
    return (
      <Box css={{ display: "flex" }}>
        <Tooltip content={visibleSegmentName}>
          <Tag size="medium">
            <Text size={"bodyS"} style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              {visibleSegmentName}
            </Text>
          </Tag>
        </Tooltip>
      </Box>
    );
  }
};
