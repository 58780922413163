import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSetState } from "react-use";

import { styled, Button, Stack, Menu, RadioGroup, NumberInput } from "@puzzle/ui";
import { formatMoney } from "@puzzle/utils";
import { Text } from "ve";

const Actions = styled("div", {
  display: "flex",
  padding: "$1h $2",
  justifyContent: "space-between",
  borderTop: "1px solid $mauve680",

  "& > *": {
    margin: "0",
    padding: "$1h 2",
    minWidth: "auto",
  },
});

type Operator = "equal" | "between" | "less" | "greater";
export type AmountFilterValue = { minAmount: number | null; maxAmount: number | null };
type AmountFilterState = AmountFilterValue & { operator: Operator };

const StyledNumberInput = styled(NumberInput, {
  borderColor: "#44445C",
});

export function sanitizeAmountFiltersForQuery(filter: {
  minAmount: number | null;
  maxAmount: number | null;
}) {
  const amountFilters = {
    minAmount: filter.minAmount?.toString(),
    maxAmount: filter.maxAmount?.toString(),
  };

  if (
    typeof filter.minAmount === "number" &&
    typeof filter.maxAmount === "number" &&
    filter.minAmount > filter.maxAmount
  ) {
    amountFilters.minAmount = filter.maxAmount.toString();
    amountFilters.maxAmount = filter.minAmount.toString();
  }

  return amountFilters;
}

export function getAmountTagDispalyText(filter: {
  minAmount: number | null;
  maxAmount: number | null;
}) {
  const minAmount =
    typeof filter.minAmount === "number"
      ? formatMoney({ currency: "USD", amount: filter.minAmount }, { truncateValue: true })
      : null;
  const maxAmount =
    typeof filter.maxAmount === "number"
      ? formatMoney({ currency: "USD", amount: filter.maxAmount }, { truncateValue: true })
      : null;

  let displayText = minAmount || maxAmount;
  if (minAmount && maxAmount) {
    if (minAmount === maxAmount) {
      displayText = minAmount;
    } else {
      if (filter.minAmount! > filter.maxAmount!) {
        displayText = `${maxAmount} - ${minAmount}`;
      } else {
        displayText = `${minAmount} - ${maxAmount}`;
      }
    }
  } else if (minAmount) {
    displayText = `> ${minAmount}`;
  } else if (maxAmount) {
    displayText = `< ${maxAmount}`;
  }

  return displayText;
}

const getOperatorFromValues = (values: AmountFilterValue): Operator => {
  if (typeof values.minAmount === "number" && typeof values.maxAmount === "number") {
    if (values.minAmount === values.maxAmount) {
      return "equal";
    }

    return "between";
  } else if (typeof values.minAmount === "number") {
    return "greater";
  } else if (typeof values.maxAmount === "number") {
    return "less";
  }

  return "between";
};

const AmountForm = ({
  onChange,
  value,
  onKeyDown,
  ...props
}: {
  onChange: (values: AmountFilterValue) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  value: AmountFilterValue;
}) => {
  const stateFromProps = useMemo(() => {
    const operator = getOperatorFromValues(value);
    if (operator === "between") {
      return {
        minAmount: value.minAmount,
        maxAmount: value.maxAmount,
        operator,
      };
    }

    return {
      minAmount: value.minAmount || value.maxAmount,
      maxAmount: null,
      operator,
    };
  }, [value]);
  const [state, setState] = useSetState<AmountFilterState>(stateFromProps);

  useEffect(() => {
    setState(stateFromProps);
  }, [setState, stateFromProps, value]);

  useEffect(() => {
    if (state.operator !== "between") {
      setState({ maxAmount: null });
    }
  }, [setState, state.operator]);

  const apply = (e?: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }
    const { operator } = state;
    const minOrOnlyAmount = state.minAmount;
    const maxAmount = state.maxAmount;
    if (operator === "less") {
      onChange({
        minAmount: null,
        maxAmount: minOrOnlyAmount,
      });
    } else if (operator === "greater") {
      onChange({
        minAmount: minOrOnlyAmount,
        maxAmount: null,
      });
    } else if (operator === "equal") {
      onChange({
        minAmount: minOrOnlyAmount,
        maxAmount: minOrOnlyAmount,
      });
    } else if (operator === "between") {
      onChange({
        minAmount: minOrOnlyAmount,
        maxAmount,
      });
    }
  };

  const clear = () => {
    onChange({ minAmount: null, maxAmount: null });
  };

  const minRef = useRef<HTMLInputElement | null>(null);

  const inputProps: Partial<React.ComponentProps<typeof NumberInput>> = {
    size: "small",
    thousandSeparator: true,
    decimalScale: 2,
    allowNegative: false,
  };

  const minOrSoloInput = (
    <StyledNumberInput
      {...inputProps}
      value={state.minAmount ?? ""}
      onValueChange={(values) => setState({ minAmount: values.floatValue })}
      placeholder="0"
      autoFocus
      ref={minRef}
    />
  );

  const maxInput = (
    <StyledNumberInput
      {...inputProps}
      value={state.maxAmount ?? ""}
      onValueChange={(values) => setState({ maxAmount: values.floatValue })}
      placeholder="100"
    />
  );

  return (
    <form
      onSubmit={apply}
      onKeyDown={(e) => {
        if (!(e.key === "Escape")) {
          e.stopPropagation();
        }
      }}
    >
      <Stack css={{ padding: "$2 $2 $1" }} gap="2">
        <RadioGroup
          value={state.operator}
          onValueChange={(operator) =>
            setState({ operator: operator as AmountFilterState["operator"] })
          }
          options={[
            {
              label: "is equal to",
              value: "equal",
            },
            {
              label: "is between",
              value: "between",
            },
            {
              label: "is greater than",
              value: "greater",
            },
            {
              label: "is less than",
              value: "less",
            },
          ]}
        />

        <Stack gap="1">
          <Stack gap="1" direction="horizontal" css={{ alignItems: "center" }}>
            {state.operator === "between" ? (
              <>
                {minOrSoloInput}
                to
                {maxInput}
              </>
            ) : (
              minOrSoloInput
            )}
          </Stack>

          <div
            style={{
              marginLeft: "auto",
            }}
          >
            <Text variant="bodyXS" color="gray400">
              (absolute value)
            </Text>
          </div>
        </Stack>
      </Stack>

      <Actions>
        <Button size="small" variant="minimal" type="button" onClick={clear}>
          Clear
        </Button>

        <Button
          size="small"
          variant="primary"
          type="submit"
          onKeyDown={(e) => {
            // For some reason tabbing doesn't loop
            if (e.key === "Tab") {
              e.preventDefault();
              minRef.current?.focus();
            }
          }}
        >
          Apply
        </Button>
      </Actions>
    </form>
  );
};

export const AmountMenu = ({
  onChange,
  value,
  ...props
}: React.ComponentProps<typeof AmountForm>) => {
  const [open, setOpen] = useState(false);

  // DropdownMenu wasn't intended to work with inputs, or something. I had to add this hack to close the form when you refocus the menu trigger.
  const amountTriggerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const currentRef = amountTriggerRef.current;
    const listener = currentRef?.addEventListener("focus", () => {
      setOpen(false);
    });

    return () => {
      if (listener) {
        currentRef?.removeEventListener("focus", listener);
      }
    };
  });

  return (
    <Menu
      css={{ width: 300 }}
      subMenuTrigger="Amount"
      subMenuTriggerProps={{
        ref: amountTriggerRef,
      }}
      open={open}
      onOpenChange={setOpen}
    >
      <AmountForm onChange={onChange} value={value} {...props} />
    </Menu>
  );
};
