/* eslint-disable react/display-name */
import React, { useCallback, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { compact } from "lodash";
import { Row } from "@tanstack/react-table";
import TransactionsPageProvider from "./TransactionsProvider";
import { styled, Toolbar, DataTable, useDataTable, Button, Tag, Text } from "@puzzle/ui";
import { FilterList } from "@puzzle/icons";
import { useActiveCompany, useCompanyDateFormatter } from "components/companies";
import { CategoryFragment } from "graphql/types";
import { useExtraTransactionState, useGetTransaction } from "./hooks/useSingleTransaction";
import useEditSplits from "./hooks/useEditSplits";
import { CategoriesFilter } from "components/common/CategoriesFilter";

import { InfiniteScrollFooter } from "components/common/InfiniteScrollFooter";
import SelectCategoryInput from "components/transactions/SelectCategoryInput";

import { useTransactionsPage } from "./TransactionsProvider";
import CategorizationMessage from "./CategorizationMessage";
import SecondaryToolbar from "./SecondaryToolbar";
import FilterToolbar from "./FilterToolbar";
import { DetailDrawer } from "./DetailDrawer";
import Analytics, { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import useCategories from "components/common/hooks/useCategories";
import useBulkFinalizeTransactions from "./hooks/useBulkFinalizeTransactions";
import { TransactionsFloatingActions } from "./TransactionsFloatingActions";
import {
  TransactionRowData,
  transactionToRowData,
  SplitRowData,
  defaultColumnVisibility,
} from "./shared";
import { useLocalStorage } from "react-use";
import { Box, S } from "ve";

import {
  accountColumn,
  dateColumn,
  vendorCustomerColumn,
  descriptionColumn,
  amountColumn,
  categoryColumn,
  statusColumn,
  assigneeColumn,
  helpMenuColumn,
  classificationsColumn,
  departmentColumn,
  locationColumn,
  classesColumn,
  selectColumn,
} from "./TransactionsColumns";

const TableContainer = styled("div", {
  overflowX: "auto",
  marginLeft: "-$3",
  paddingBottom: "1px",

  'tbody tr[role="row"]': {
    height: "56px",
  },

  "th[data-column-id=select]": {
    overflow: "visible",
  },

  tbody: {
    "[role=checkbox][aria-checked=false]": {
      opacity: 1,
    },
  },

  "td, th": {
    "&:first-child(:only-child)": {
      borderBottomColor: "transparent !important",
      background: "transparent !important",
    },
  },

  [`${Tag}`]: {
    marginLeft: "-$1",
  },
});

export const EditSplitCategoryCell = ({
  data,
  wrap,
}: {
  data: Pick<SplitRowData, "transactionId" | "detailId" | "splitIndex">;
  wrap?: boolean;
}) => {
  const transactionResult = useGetTransaction(data.transactionId);
  const transaction = transactionResult.data?.transaction;
  const { canEditSplits } = useExtraTransactionState(transaction);
  const { updateSplitCategory, persistSplits, getSplit } = useEditSplits(transaction);
  const { categories } = useCategories();
  const handleUpdate = (c: CategoryFragment) => {
    const newSplits = updateSplitCategory(data.detailId, c);
    persistSplits(newSplits);
  };

  const split = getSplit(data.detailId) || transaction?.splits[data.splitIndex];
  if (!split) {
    return null;
  }

  return (
    <SelectCategoryInput
      value={split.category}
      categories={categories}
      canEdit={canEditSplits}
      onChange={handleUpdate}
      wrap={wrap}
      isBillPayment={!!split.isBillPayment}
      isInvoicePayment={!!split.isInvoicePayment}
    />
  );
};

const useTable = ({
  matchAll,
  setMatchAll,
  data,
  fullColumns,
}: {
  matchAll: boolean;
  setMatchAll: React.Dispatch<React.SetStateAction<boolean>>;
  data: TransactionRowData[];
  fullColumns: boolean;
}) => {
  const { sortOptions, setSortOptions, categories, totals, filter } = useTransactionsPage();
  const { isWithinLockedPeriod } = useActiveCompany<true>();
  const [columnVisibility = defaultColumnVisibility, setColumnVisibility] = useLocalStorage(
    "transactions-col-vis",
    defaultColumnVisibility
  );

  const dateFormatter = useCompanyDateFormatter({
    dateStyle: "medium",
  });

  const columns = useMemo(
    () =>
      compact(
        fullColumns
          ? [
              // Full columns for transactions page
              selectColumn(matchAll, setMatchAll, isWithinLockedPeriod, data, totals),

              dateColumn(filter, dateFormatter),

              accountColumn,

              vendorCustomerColumn,

              descriptionColumn,

              amountColumn,

              // TODO UpdateCategoryMenu is the biggest performance hit.
              // Either start virtualizing or attempt to extract it instead of completely embedding it.
              categoryColumn(categories),

              !isPosthogFeatureFlagEnabled(FeatureFlag.ClassesAndDeptsCols) &&
                classificationsColumn,
              isPosthogFeatureFlagEnabled(FeatureFlag.ClassesAndDeptsCols) && departmentColumn,
              isPosthogFeatureFlagEnabled(FeatureFlag.ClassesAndDeptsCols) && locationColumn,
              isPosthogFeatureFlagEnabled(FeatureFlag.ClassesAndDeptsCols) && classesColumn,

              statusColumn,

              assigneeColumn,

              helpMenuColumn(columnVisibility, setColumnVisibility),
            ]
          : [
              // Reduced columns for burn explorer
              selectColumn(matchAll, setMatchAll, isWithinLockedPeriod, data, totals),

              dateColumn(filter, dateFormatter),

              accountColumn,

              vendorCustomerColumn,

              descriptionColumn,

              categoryColumn(categories),

              amountColumn,
            ]
      ),
    [
      matchAll,
      setMatchAll,
      data.length,
      totals.count,
      isWithinLockedPeriod,
      filter.showAvailableDate,
      dateFormatter,
      categories,
      columnVisibility,
      setColumnVisibility,
      fullColumns,
    ]
  );

  return useDataTable({
    data,
    columns,
    state: {
      expanded: true,
      sorting: sortOptions,
      columnVisibility,
    },

    manualSorting: true,
    onSortingChange: (options) => setSortOptions(options),
    getSubRows: (row) => row.subRows,
    enableRowSelection: (row) => !isWithinLockedPeriod(row?.original?.date),
  });
};

const CategoriesFilterPortal = () => {
  const { selectedCategories, setFilter } = useTransactionsPage();
  const rootNode = document.querySelector('[data-column-id="category"]');

  if (!rootNode) return null;

  return (
    <>
      {createPortal(
        <Box css={{ display: "flex", alignItems: "center", gap: S["1"] }}>
          Category{" "}
          <CategoriesFilter
            trigger={
              <Button
                variant="minimal"
                css={{
                  width: selectedCategories.length > 0 ? "fit-content" : 24,
                  height: 24,
                  padding: "$1",
                }}
              >
                <FilterList />
                {selectedCategories.length > 0 && (
                  <Text css={{ fontSize: "10px", marginLeft: "$1" }}>
                    {selectedCategories.length}
                  </Text>
                )}
              </Button>
            }
            onCategoriesChange={(values: CategoryFragment[]) => {
              setFilter({
                ledgerCoaKeys: values.map((c) => c.permaKey),
              });
            }}
            selectedCategories={selectedCategories}
            footer
          />
        </Box>,
        rootNode
      )}
    </>
  );
};

const Transactions = ({ id }: { id?: string }) => {
  const {
    transactions,
    companyId,
    setActiveTransaction,
    loading,
    hasMore,
    fetchNextPage,
    impactfulTransactionsMetadata: {
      loading: isImpactfulMetadataLoading,
      isCurrentViewCategorized,
    },
    totals,
    queryVariables,
  } = useTransactionsPage();
  const { bulkFinalizeFilter: _bulkFinalizeFilter } = useBulkFinalizeTransactions(queryVariables);
  const [matchAll, setMatchAll] = useState(false);

  const data: TransactionRowData[] = useMemo(
    () => transactionToRowData(transactions),
    [transactions]
  );

  const table = useTable({ data, matchAll, setMatchAll, fullColumns: true });

  const onRowClick = useCallback(
    (row: Row<TransactionRowData>) => {
      row.original && setActiveTransaction(row.original.transactionId);
      Analytics.transactionsTableRowClicked({
        transactionId: row.original.transactionId,
      });
    },
    [setActiveTransaction]
  );

  const selectedRows = table.getSelectedRowModel().rows;

  const toggleMatch = useCallback(() => {
    if (matchAll) {
      table.getToggleAllRowsSelectedHandler()({
        target: { checked: true },
      });
      setMatchAll(false);
    } else {
      setMatchAll(true);
    }
  }, [table, matchAll]);

  const resetSelection = useCallback(() => {
    setMatchAll(false);
    table.getToggleAllRowsSelectedHandler()({
      target: { checked: false },
    });
  }, [table]);

  return (
    <>
      <DetailDrawer transactions={transactions} id={id} />

      <Toolbar.Group>
        <FilterToolbar />
        <SecondaryToolbar />
      </Toolbar.Group>

      <TableContainer>
        <CategoriesFilterPortal />
        <DataTable
          table={table}
          loading={loading}
          onRowClick={onRowClick}
          density="large"
          customBody={
            !isImpactfulMetadataLoading && isCurrentViewCategorized && <CategorizationMessage />
          }
          data-testid="transactions-table"
        />
      </TableContainer>

      {hasMore && <InfiniteScrollFooter loading={loading} fetchNextPage={fetchNextPage} />}

      <TransactionsFloatingActions
        data={data}
        selectedRows={selectedRows}
        matchAll={matchAll}
        resetSelection={resetSelection}
        toggleMatch={toggleMatch}
        companyId={companyId}
        loading={loading}
        hasMore={hasMore}
        totalCount={totals.count}
        queryVariables={queryVariables}
      />
    </>
  );
};

const TransactionsWithProviders = ({ id }: { id?: string }) => {
  return (
    <TransactionsPageProvider>
      <Transactions id={id} />
    </TransactionsPageProvider>
  );
};

export default TransactionsWithProviders;
