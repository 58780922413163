import { styled, Button, Tag, CSS } from "@puzzle/ui";
import { BillDateFilter } from "./BillsTable/BillsTableContext";

export const errorToastConfig = {
  title: "The bill hasn't been saved",
  message: `Something went wrong, and our team has been notified. We apologize for the inconvenience. Please try again later.`,
  status: "warning",
} as const;

export const CONTAINER_WIDTH = 1460;

export const Container = styled("div", {
  display: "grid",
  border: "1px solid $mauve400",
  borderRadius: "$2",
  maxWidth: CONTAINER_WIDTH,
  transition: "200ms",

  variants: {
    sidebarOpen: {
      true: { gridTemplateColumns: "500px 1fr" },
      false: { gridTemplateColumns: "80px 1fr" },
    },
  },
});

export const FormContainer = styled("div", {
  padding: "$4",
});

export const Header = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const FieldsAndActions = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row-reverse",
  marginTop: "$4",
  marginBottom: "$8",
  gap: "$4",
});

export const Fields = styled("div", {
  flex: 1,
});

export const Actions = styled("div", {
  display: "flex",
  gap: "$2",
  flexDirection: "column",

  [`${Button}`]: {
    width: "100%",
  },
});

export const Footer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "$2",
  marginTop: "$8",
});

export const Table = styled("table", {
  maxWidth: "100%",
  tableLayout: "fixed",
  borderCollapse: "collapse",

  "th, td": {
    textAlign: "left",
    color: "$gray500",
    textVariant: "$bodyXS",
    fontSize: "$bodyS",
    fontWeight: "$bold",

    borderColor: "$mauve650",
    borderStyle: "solid",
    borderWidth: 0,
    borderBottomWidth: 1,
  },

  th: {
    padding: "$1h $1",
  },

  td: {
    padding: "0",

    [`&[data-border="true"]`]: {
      borderLeftWidth: 1,
    },

    "&:first-of-type": {
      paddingLeft: "$1h",
    },
  },

  tfoot: {
    td: {
      textAlign: "right",
      borderBottomWidth: 0,
    },
  },
});

export const AddRow = styled("tr", {
  td: {
    padding: "$1 0",
    position: "relative",
    left: "-$1",
  },
});

export const BorderedFooterContent = styled("div", {
  borderColor: "$mauve650",
  borderStyle: "solid",
  borderWidth: 0,
  borderBottomWidth: 1,
  padding: "$2 $1",
  height: "100%",
});

export const BillDateOptions = [
  { value: BillDateFilter.IssueDate, label: "Bill date" },
  { value: BillDateFilter.DueDate, label: "Due date" },
];

export const StyledTag = styled(Tag, {
  marginBottom: "$1",
  display: "inline-flex",
  marginRight: "$1",

  "&:last-of-type": {
    marginRight: 0,
  },
});

export const statusFilterCSS: CSS = {
  color: "$gray300",
  width: 100,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
};
