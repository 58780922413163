import { keyBy } from "lodash";
import {
  useCreateProductMutation,
  useProductsQuery,
  ProductsDocument,
  ProductsQuery,
} from "./graphql.generated";
import { useActiveCompany } from "components/companies";

export default function useProducts() {
  const [_createProduct] = useCreateProductMutation();
  const { company } = useActiveCompany<true>();
  const { data, loading } = useProductsQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: { companyId: company.id },
  });

  const createProduct = (name: string) =>
    _createProduct({
      variables: {
        input: { companyId: company.id, name },
      },

      update(cache, { data }) {
        const product = data?.createProduct.product;
        if (!product) {
          return;
        }
        const variables = { companyId: company.id };

        const productData = cache.readQuery<ProductsQuery>({
          query: ProductsDocument,
          variables,
        });

        cache.writeQuery<ProductsQuery>({
          variables,
          query: ProductsDocument,
          data: {
            products: { items: [...(productData?.products.items || []), product] },
          },
        });
      },
    });

  return {
    createProduct,
    products: data?.products.items ?? [],
    productsById: keyBy(data?.products.items, "id"),
    loading,
  };
}
