import { MRT_RowData } from "material-react-table";

export const formatMrtCol = <RowData extends MRT_RowData>(col: RowData[]) => {
  return col.map((obj) => {
    if (obj.meta?.align) {
      return {
        ...obj,
        muiTableHeadCellProps: { align: obj.meta.headerAlign || obj.meta.align },
        muiTableBodyCellProps: { align: obj.meta.align },
      };
    }
    return obj;
  });
};
