import {
  AccountsDocument,
  AccountsWithIntegrationsDocument,
  IntegrationConnectionFragment,
  IntegrationConnectionsForCompanyDocument,
  useAccountsWithIntegrationsQuery,
  useDisconnectIntegrationMutation,
  useRemoveAccountMutation,
  useResetAccountMutation,
  useSetAccountArchivedMutation,
  useSetAccountSyncStatusMutation,
} from "graphql/types";
import { useActiveCompany } from "components/companies";
import React, { useCallback, useMemo } from "react";
import { PendingAccount, UseFinancialInstitutionsResult } from "./types";
import { FinancialInstitutionGroup, IntegrationCategory } from "../setup/types";
import { groupBy } from "lodash";
import { getIntegrationCategoryForAccountType } from "../setup/utils";
import { GetTransactionSearchOptionsDocument } from "../../dashboard/Transactions/graphql.generated";

const FinancialInstitutionsProviderContext = React.createContext<UseFinancialInstitutionsResult>({
  disconnectAccount: () => Promise.resolve({}),
  removeAccount: () => Promise.resolve({}),
  resetAccount: () => Promise.resolve({}),
  reconnectAccount: () => Promise.resolve({}),
  refetchAccountsAndIntegrations: () => ({}),
  startPollingIntegrationConnections: (pollInterval: number) => ({}),
  stopPollingIntegrationConnections: () => ({}),
  accountsLoading: true,
  accounts: [],
  disconnectIntegrationConnection: async () => ({}),
  integrationConnections: [],
  integrationConnectionsLoading: true,
  archiveAccount: () => Promise.resolve({}),
  financialInstitutionGroups: new Map<IntegrationCategory, FinancialInstitutionGroup[]>(),
  addPendingAccount: () => {},
  removePendingAccount: () => {},
  pendingAccounts: [],
});

interface FinancialInstitutionsProviderProps {
  children: React.ReactNode;
}

const useAccountsQuery = (companyId: string) => {
  const { data, loading, refetch } = useAccountsWithIntegrationsQuery({
    fetchPolicy: "cache-and-network",
    skip: !companyId,
    variables: {
      filter: {
        companyId,
      },
    },
  });

  return useMemo(() => {
    return {
      accounts: data?.accounts || [],
      accountsLoading: loading,
      refetchAccounts: refetch,
    };
  }, [data, loading, refetch]);
};

const FinancialInstitutionsProvider = ({ children }: FinancialInstitutionsProviderProps) => {
  const {
    company,
    integrationConnections,
    integrationConnectionsLoading,
    refetchIntegrationConnections,
    startPollingIntegrationConnections,
    stopPollingIntegrationConnections,
  } = useActiveCompany<true>();

  const [pendingAccounts, setPendingAccounts] = React.useState<PendingAccount[]>([]);

  const addPendingAccount = (pendingAccount: PendingAccount) => {
    setPendingAccounts((prev) => [...prev, pendingAccount]);
  };

  const removePendingAccount = (accountId: string) => {
    setPendingAccounts((prev) =>
      prev.filter((pendingAccount) => pendingAccount.accountId !== accountId)
    );
  };

  const { accounts: allAccounts, accountsLoading, refetchAccounts } = useAccountsQuery(company?.id);

  const financialInstitutionGroups: Map<IntegrationCategory, FinancialInstitutionGroup[]> =
    useMemo(() => {
      const result = new Map<IntegrationCategory, FinancialInstitutionGroup[]>();

      // First, group the accounts by integration category
      const accountsByType = groupBy(allAccounts, (account) =>
        getIntegrationCategoryForAccountType(account.type)
      );

      // Then, group them by financial institution name
      for (const [integrationCategory, accounts] of Object.entries(accountsByType)) {
        const category = integrationCategory as IntegrationCategory;
        const groupedByFinancialInstitution = groupBy(
          accounts,
          (account) => account.financialInstitution?.name
        );

        const sortedEntries = Object.entries(groupedByFinancialInstitution).sort(
          ([nameA], [nameB]) => nameA.localeCompare(nameB)
        );

        // Then, create a FinancialInstitutionGroup for each financial institution name/integration category combo
        const financialInstitutions = sortedEntries.map(([financialInstitutionName, accounts]) => {
          const connections = accounts
            .filter((account) => account.connection)
            .map(
              (account) => account.connection
            ) as IntegrationConnectionFragment[];

           const uniqueConnections = Array.from(
            connections.reduce((map, connection) => map.set(connection.id, connection), new Map<string, IntegrationConnectionFragment>()).values()
          );

          const financialInstitution: FinancialInstitutionGroup = {
            financialInstitutionName,
            accounts,
            integrationConnections: uniqueConnections,
          };
          return financialInstitution;
        });

        const existing = result.get(category) || [];
        result.set(category, [...existing, ...financialInstitutions]);
      }

      return result;
    }, [allAccounts]);

  const refetchAccountsAndIntegrations = useCallback(async () => {
    await refetchAccounts();
    await refetchIntegrationConnections();
  }, [refetchAccounts, refetchIntegrationConnections]);

  const [setSyncStatusMutation] = useSetAccountSyncStatusMutation();
  const [removeAccountMutation] = useRemoveAccountMutation();
  const [resetAccountMutation] = useResetAccountMutation();
  const [disconnectIntegration] = useDisconnectIntegrationMutation();
  const [archiveAccountMutation] = useSetAccountArchivedMutation();

  const disconnectAccount = (accountId: string) => {
    return setSyncStatusMutation({
      variables: { input: { accountId, shouldSync: false } },
    });
  };

  const removeAccount = (accountId: string) => {
    return removeAccountMutation({
      variables: { input: { accountId, companyId: company.id } },
      refetchQueries: [
        AccountsWithIntegrationsDocument,
        AccountsDocument,
        GetTransactionSearchOptionsDocument,
      ],
    });
  };

  const resetAccount = (accountId: string) => {
    return resetAccountMutation({
      variables: { input: { accountId, companyId: company.id } },
      refetchQueries: [
        AccountsWithIntegrationsDocument,
        AccountsDocument,
        GetTransactionSearchOptionsDocument,
      ],
    });
  };

  const reconnectAccount = (accountId: string) => {
    return setSyncStatusMutation({ variables: { input: { accountId, shouldSync: true } } });
  };

  const archiveAccount = (accountId: string, isArchived: boolean) => {
    return archiveAccountMutation({
      variables: { input: { accountId, isArchived } },
      refetchQueries: [AccountsWithIntegrationsDocument, AccountsDocument],
    });
  };

  const disconnectIntegrationConnection = (integrationConnectionId: string) => {
    return disconnectIntegration({
      variables: {
        input: {
          connectionId: integrationConnectionId,
        },
      },
      refetchQueries: [IntegrationConnectionsForCompanyDocument, AccountsWithIntegrationsDocument],
    });
  };

  return (
    <FinancialInstitutionsProviderContext.Provider
      value={{
        disconnectAccount,
        removeAccount,
        resetAccount,
        reconnectAccount,
        refetchAccountsAndIntegrations,
        startPollingIntegrationConnections,
        stopPollingIntegrationConnections,
        accountsLoading,
        accounts: allAccounts,
        disconnectIntegrationConnection,
        integrationConnections,
        integrationConnectionsLoading,
        archiveAccount,
        financialInstitutionGroups,
        addPendingAccount,
        removePendingAccount,
        pendingAccounts,
      }}
    >
      {children}
    </FinancialInstitutionsProviderContext.Provider>
  );
};

export default FinancialInstitutionsProvider;

export const useFinancialInstitutions = () =>
  React.useContext(FinancialInstitutionsProviderContext);
