/* eslint-disable react/display-name */
import React, { useEffect, useRef } from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { usePrevious } from "react-use";
import { rgba } from "polished";
import { colors, CSS } from "@puzzle/theme";
import { styled, keyframes, css } from "./stitches";
import { VariantProps } from "@stitches/react";
import { BackArrow, Close as CloseIcon } from "@puzzle/icons";
import { InDialogProvider } from "./InDialogContext";
import { IconButton } from "./Button";

// Matching MUI's zIndex for now.
// See Modal to finish replacing usages

const bodyTextStyles = {
  textVariant: "$bodyS",
  color: "$gray400",

  a: {
    color: "$white",
    textDecoration: "underline",
  },
};

const fadeIn = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
});

const fadeOut = keyframes({
  from: { opacity: 1 },
  to: { opacity: 0 },
});

const scaleFadeIn = keyframes({
  from: { transform: "scale(0.95)", opacity: 0 },
  to: { transform: "scale(1)", opacity: 1 },
});

const scaleFadeOut = keyframes({
  from: { transform: "scale(1)", opacity: 1 },
  to: { transform: "scale(0.95)", opacity: 0 },
});

const durations = {
  open: 200,
  close: 150,
};

const createAnimationVariants = (openAnimation: string, closeAnimation: string) => ({
  variants: {
    disableAnimation: {
      true: {},
      false: {},
    },

    disableOpenAnimation: {
      true: {},
      false: {},
    },

    disableCloseAnimation: {
      true: {},
      false: {},
    },
  },

  defaultVariants: {
    disableAnimation: false,
    disableOpenAnimation: false,
    disableCloseAnimation: false,
  },

  compoundVariants: [
    {
      disableAnimation: false,
      disableOpenAnimation: false,
      css: {
        '&[data-state="open"]': {
          animation: openAnimation,
        },
      },
    },

    {
      disableAnimation: false,
      disableCloseAnimation: false,
      css: {
        '&[data-state="closed"]': {
          animation: closeAnimation,
        },
      },
    },
  ],
});

const styles = {
  durations,
  fadeIn,
  fadeOut,
  scaleFadeIn,
  scaleFadeOut,

  overlay: css({
    position: "fixed",
    top: "0",
    left: "0",
    width: "100vw",
    height: "100vh",
    backgroundColor: rgba(colors.mauve800, 0.6),

    ...createAnimationVariants(
      `${fadeIn} ${durations.open}ms cubic-bezier(0.4, 0, 0.2, 1)`,
      `${fadeOut} ${durations.close}ms cubic-bezier(0.4, 0, 0.2, 1)`
    ),
  }),

  content: css({
    position: "fixed",
    top: "0",
    left: "0",
    width: "100vw",
    height: "100vh",
    padding: "$2",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    pointerEvents: "none !important",
    outline: "none",

    ...createAnimationVariants(
      `${scaleFadeIn} ${durations.open}ms cubic-bezier(0.4, 0, 0.2, 1)`,
      `${scaleFadeOut} ${durations.close}ms cubic-bezier(0.4, 0, 0.2, 1)`
    ),

    // TODO: CSS reset
    p: {
      margin: 0,
    },
  }),

  contentInner: css({
    display: "flex",
    flexDirection: "column",

    pointerEvents: "initial",
    background: "$rhino700",
    border: "1px solid",
    borderColor: "$rhino600",
    boxSizing: "border-box",
    borderRadius: "8px",

    maxHeight: "100%",
    width: "100%",
    maxWidth: 780,
    boxShadow: "0px 4px 12px 0 $colors$blackA10, 0px 16px 40px 0 $colors$blackA20",
    overflow: "hidden",

    variants: {
      // TODO verify standard sizes
      size: {
        xsmall: {
          maxWidth: 480,
        },
        small: {
          maxWidth: 520,
        },
        medium: {
          maxWidth: 580,
        },
        regular: {
          maxWidth: 640,
        },
        large: {
          maxWidth: 800,
        },
        xlarge: {
          maxWidth: 1024,
        },
        fullScreen: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          maxWidth: "100vw",
        },
      },
      borderRadius: {
        borderless: {
          borderRadius: "0px",
          border: "none",
        },
      },
    },
  }),

  titleRoot: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "$1h $1h $1h $3",
    borderBottom: "1px solid",
    borderColor: "$rhino600",
    color: "$white",
    textVariant: "$headingM",

    // TODO FDSjfdsffds RESET H ELEMENTS
    margin: 0,

    variants: {
      // This padding is all over the place in the mocks.
      // We might need to split this up into more variants.
      // The scenarios are:
      // - no divider, no close button
      // - no divider, close button (hides depending on loading state)
      // - divider, with or without close button
      // - no divider, slightly more vertical margin (informational dialog)
      basic: {
        true: {
          border: "none",
        },

        false: {},
      },

      showClose: {
        true: {},
        false: {},
      },
    },
  }),

  titleText: css({
    // textVariant: "$headingM",
    flexGrow: 1,
    variants: {
      center: {
        true: {
          textAlign: "center",
        },
      },
    },
  }),

  body: css({
    padding: "$2 $3",
    ...bodyTextStyles,
  }),

  footer: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "$2 $3",

    defaultVariants: {
      align: "right",
    },

    variants: {
      align: {
        right: {
          justifyContent: "right",
        },

        left: {},
      },

      fullWidth: {
        true: {
          "&, & > *": {
            width: "100%",
          },
        },
      },

      divider: {
        true: {
          borderTop: "1px solid $mauve680",
        },
      },
    },
  }),

  actions: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "$2",
  }),
};

const Overlay = styled(DialogPrimitive.Overlay, styles.overlay);
const Content = styled(DialogPrimitive.Content, styles.content);
const TitleRoot = styled(DialogPrimitive.Title, styles.titleRoot);
const ContentInner = styled("div", styles.contentInner);
const TitleText = styled("div", styles.titleText);
const Body = styled("div", styles.body);
const Footer = styled("div", styles.footer);
const Actions = styled("div", styles.actions);

const Title = ({
  children,
  basic = false,
  center,
  showClose = !basic,
  onBack,
  onClose,
  ...props
}: {
  children: React.ReactNode;
  showClose?: boolean;
  basic?: boolean;
  onBack?: () => void;
  onClose?: () => void;
  center?: boolean;
  css?: CSS;
}) => {
  return (
    <TitleRoot basic={basic} showClose={showClose} {...props}>
      {(center || onBack) && (
        <IconButton
          onClick={() => {
            onBack?.();
          }}
          css={{
            width: 32,
            textAlign: "left",
            visibility: !onBack ? "hidden" : "visible",
          }}
        >
          <BackArrow color="currentColor" />
        </IconButton>
      )}

      <TitleText center={center ?? typeof onBack !== "undefined"}>{children}</TitleText>

      <DialogPrimitive.Close
        // Not sure if this is a good idea (you can still Esc), but it's otherwise the first focused element.
        tabIndex={-1}
        asChild
      >
        <IconButton
          css={{
            width: "32px",
            height: "32px",
            justifyContent: "center",
            display: showClose ? "block" : "none",
            lineHeight: 0,
          }}
          onClick={() => onClose?.()}
        >
          <CloseIcon color="currentColor" />
        </IconButton>
      </DialogPrimitive.Close>
    </TitleRoot>
  );
};

const Close = DialogPrimitive.Close;

export const Dialog = Object.assign(
  React.forwardRef<
    HTMLDivElement,
    DialogPrimitive.DialogProps &
      DialogPrimitive.DialogContentProps &
      VariantProps<typeof Content> &
      VariantProps<typeof ContentInner> & {
        trigger?: React.ReactNode;
        showOverlay?: boolean;
        width?: number | string;
      }
  >(
    (
      {
        defaultOpen,
        open,
        onOpenChange,
        children,
        trigger,
        width,
        modal = true,
        showOverlay = modal,
        size,
        ...props
      },
      ref
    ) => {
      return (
        <InDialogProvider>
          <DialogPrimitive.Root
            defaultOpen={defaultOpen}
            open={open}
            onOpenChange={onOpenChange}
            modal={modal}
          >
            {trigger && <DialogPrimitive.Trigger asChild>{trigger}</DialogPrimitive.Trigger>}

            <DialogPrimitive.Portal>
              {showOverlay && (
                <Overlay
                  onClick={(e) => e.stopPropagation()}
                  disableAnimation={props.disableAnimation}
                  disableCloseAnimation={props.disableCloseAnimation}
                  disableOpenAnimation={props.disableOpenAnimation}
                />
              )}

              <Content {...props} ref={ref}>
                <ContentInner
                  borderRadius={props.borderRadius}
                  size={size}
                  css={size !== "fullScreen" ? { maxWidth: width } : {}}
                >
                  {children}
                </ContentInner>
              </Content>
            </DialogPrimitive.Portal>
          </DialogPrimitive.Root>
        </InDialogProvider>
      );
    }
  ),
  {
    Title,
    Body,
    Footer,
    Actions,
    Close,
    styles,
  }
);

export type DialogProps = React.ComponentProps<typeof Dialog>;

export const useDialogReset = (open: boolean | undefined, callback: () => void) => {
  const wasEverOpen = useRef(Boolean(open));
  const wasJustOpen = usePrevious(open);
  useEffect(() => {
    if (open) {
      wasEverOpen.current = true;
    }

    if (wasEverOpen.current && !wasJustOpen && open) {
      callback();
    }
  }, [callback, open, wasJustOpen]);
};
