import React from "react";
import Icon, { IconProps } from "./Icon";

export default function FilterList({
  width = 10,
  height = 8,
  color = "currentColor",
  fill = color,
  viewBox = "0 0 10 8",
  ...iconProps
}: IconProps) {
  return (
    <Icon viewBox={viewBox} width={width} height={height} {...iconProps}>
      <path
        fill={fill}
        d="M4.125 7.15a.525.525 0 110-1.05h1.75a.525.525 0 010 1.05h-1.75zm-2.1-2.624a.525.525 0 110-1.05h5.95a.525.525 0 010 1.05h-5.95zM.625 1.9a.525.525 0 110-1.05h8.75a.525.525 0 010 1.05H.625z"
      ></path>
    </Icon>
  );
}
