import React from "react";
import Icon, { IconProps } from "../Icon";

export default function BillDotCom({
  viewBox = "0 0 48 48",
  width = 48,
  height = 48,
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} {...iconProps} viewBox={viewBox}>
      <path
        opacity="0.55"
        d="M7.71191 21.796V19.3997L10.1899 21.2112C11.0481 21.8467 11.2108 22.7719 11.1907 22.7488C10.632 22.093 9.88587 21.8053 9.37222 21.7975L7.71191 21.796Z"
        fill="white"
      />
      <path d="M14.3888 22.3142H12.5347V28.6521H14.3888V22.3142Z" fill="white" />
      <path d="M17.6388 19.3199H15.7847V28.65H17.6388V19.3199Z" fill="white" />
      <path d="M20.8883 19.3199H19.0342V28.65H20.8883V19.3199Z" fill="white" />
      <path
        d="M13.4746 19.2063C12.8571 19.2063 12.3574 19.702 12.3574 20.3144C12.3574 20.9269 12.8571 21.4226 13.4746 21.4226C14.092 21.4226 14.5917 20.9269 14.5917 20.3144C14.5917 19.7017 14.092 19.2063 13.4746 19.2063Z"
        fill="white"
      />
      <path
        d="M9.33498 26.4601C9.33498 26.7424 9.07575 26.9742 8.78268 26.9742H7.20011V23.9575H8.78268C9.07757 23.9575 9.33498 24.1865 9.33498 24.4715V26.4601ZM7.21245 22.3137V19.3234H5.36768V28.6487H9.22822C10.3143 28.6487 11.1942 27.7759 11.1942 26.6986V24.2638C11.1942 23.1865 10.3143 22.3137 9.22822 22.3137H7.21245Z"
        fill="white"
      />
      <g opacity="0.38">
        <path
          d="M26.178 28.8355C24.2882 28.8355 23.8164 27.6644 23.8164 26.6331V24.3506C23.8164 23.0978 24.4899 22.1373 26.1547 22.1373C27.7963 22.1373 28.3751 22.9099 28.3751 23.9534V24.4797H27.407V24.0457C27.407 23.4255 27.089 22.9559 26.1796 22.9559C25.1292 22.9559 24.8097 23.5069 24.8097 24.3611V26.4696C24.8097 27.3131 25.1635 27.9918 26.1906 27.9918C27.1 27.9918 27.418 27.57 27.418 26.9374V26.5172H28.3861V26.985C28.3861 28.0274 27.7359 28.8355 26.178 28.8355Z"
          fill="white"
        />
        <path
          d="M31.7161 28.8359C29.851 28.8359 29.3188 27.7586 29.3188 26.5518V24.4555C29.3188 23.3429 29.8977 22.1361 31.7984 22.1361C33.7116 22.1361 34.2191 23.3073 34.2191 24.397V26.5287C34.2188 27.6307 33.7472 28.8359 31.7161 28.8359ZM31.7984 27.9924C32.8971 27.9924 33.2274 27.3244 33.2274 26.4702V24.4555C33.2274 23.6599 32.9435 22.9796 31.7984 22.9796C30.6998 22.9796 30.3105 23.6476 30.3105 24.4909V26.458C30.3103 27.4076 30.6765 27.9924 31.7984 27.9924Z"
          fill="white"
        />
        <path
          d="M35.3174 28.6492V22.3233H36.1909L36.2265 23.2022C36.5104 22.6635 37.1233 22.1478 38.104 22.1478C39.108 22.1478 39.6276 22.6635 39.8402 23.2605C40.194 22.6403 40.8083 22.1478 41.7643 22.1478C43.1932 22.1478 43.6294 23.1666 43.6294 24.1503V28.6474H42.6487V24.409C42.6487 23.624 42.4485 22.9916 41.4911 22.9916C40.4514 22.9916 39.9675 23.7997 39.9675 24.3861V28.6492H38.9761V24.3151C38.9761 23.624 38.7758 22.9916 37.8185 22.9916C36.7914 22.9916 36.2949 23.7997 36.2949 24.3861V28.6492H35.3174Z"
          fill="white"
        />
        <path
          d="M22.4883 28.8412C22.8688 28.8412 23.1773 28.5352 23.1773 28.1578C23.1773 27.7803 22.8688 27.4744 22.4883 27.4744C22.1078 27.4744 21.7993 27.7803 21.7993 28.1578C21.7993 28.5352 22.1078 28.8412 22.4883 28.8412Z"
          fill="white"
        />
      </g>
    </Icon>
  );
}
