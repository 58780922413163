import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Tag({
  color = "currentColor",
  stroke = color,
  fill = "none",
  viewBox = "0 0 16 16",
  ...iconProps
}: IconProps) {
  return (
    <Icon viewBox={viewBox} {...iconProps}>
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={fill}
        d="M2.5 2.5h5l5.707 5.707a1 1 0 010 1.414l-3.586 3.586a1 1 0 01-1.414 0L2.5 7.5v-5z"
      ></path>

      <path fill={fill} d="M0 0H12V12H0z" transform="translate(2 2)"></path>
    </Icon>
  );
}
