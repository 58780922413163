import React, { useMemo } from "react";
import { Row } from "@tanstack/react-table";
import { TransactionRowData } from "./shared";
import { Dialog, Text, useToasts, styled } from "@puzzle/ui";
import CategorySearch from "./../../transactions/CategorySearch";
import { useChartOfAccounts } from "components/dashboard/Accounting/shared/useChartOfAccounts";
import { keyBy } from "lodash";
import {
  useRecategorizeMultipleTransactionsMutation,
  BasicTransactionFragment,
  useSetSplitsMutation,
} from "./graphql.generated";
import { DetailConfirmedState, CategoryFragment } from "graphql/types";
import { pluralize } from "@puzzle/utils";
import Loader from "components/common/Loader";
import Analytics from "lib/analytics";
import Big from "big.js";

interface BulkRecategorizeProps {
  transactions: Row<TransactionRowData>[];
  categories: CategoryFragment[];
  open: boolean;
  onOpenChange: (open: boolean) => void;
  companyId: string;
}

const WarningText = styled(Text, {
  fontWeight: "$heavy",
  color: "$gray50",
  padding: "$0h $3",
  fontSize: "$bodyS",
});

const BulkRecategorizeModal = ({
  transactions,
  open,
  onOpenChange,
  categories,
  companyId,
}: BulkRecategorizeProps) => {
  const { categories: coaData } = useChartOfAccounts();
  const { toast } = useToasts();
  const [recategorizeMultipleTransactionsMutation, { loading }] =
    useRecategorizeMultipleTransactionsMutation();
  const [persistSplitsMutation] = useSetSplitsMutation();
  const categoryTypes = useMemo(() => {
    const types = coaData.map((account) => {
      return { id: account.displayId, type: account.type };
    });
    return keyBy(types, "id");
  }, [coaData]);

  const eligible = useMemo(
    () =>
      transactions.filter(
        (t) => t.original.transaction.detail.confirmedState !== DetailConfirmedState.Finalized
      ),
    [transactions]
  );

  const finalizedTotal = useMemo(
    () => transactions.length - eligible.length,
    [transactions, eligible]
  );

  const handleSplits = (transaction: BasicTransactionFragment, category: CategoryFragment) => {
    return persistSplitsMutation({
      variables: {
        input: {
          transactionId: transaction.id,
          splits: transaction.splits.map((c) => ({
            descriptor: c.descriptor,
            ledgerCoaKey: category.permaKey,
            amount: Big(c.amount || 0).toString(),
          })),
        },
      },
    });
  };

  const handleSubmit = (category: CategoryFragment) => {
    eligible.map((t) => {
      if (t.original.transaction.splits.length > 0) {
        handleSplits(t.original.transaction, category);
      }
    });

    const updates = eligible
      .filter((t) => t.original.transaction.splits.length === 0)
      .map((t) => {
        return { id: t.original.transactionId, ledgerCoaKey: category.coaKey! };
      });

    return recategorizeMultipleTransactionsMutation({
      variables: {
        input: {
          companyId,
          updates,
        },
      },
      onCompleted(d) {
        onOpenChange(false);
        const numUpdated = d.recategorizeMultipleTransactions.updated.length;
        transactions.forEach((t) => t.toggleSelected(false));
        toast({
          message:
              numUpdated !== transactions.length
              ? `${numUpdated} out of ${transactions.length} transactions categorized. Already finalized or linked transactions are not updated.`
              : `${pluralize(transactions.length, "transaction")} categorized`,
        });
        Analytics.transactionsBulkCategorized({ category: category.coaKey! });
      },
    });
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange} size="medium">
      <Dialog.Title>Categorize transactions</Dialog.Title>
      <Text css={{ color: "$gray400", padding: "$2 $3 $0 $3", textVariant: "$bodyS" }}>
        Choose a category to assign to selected transactions.{" "}
      </Text>
      {finalizedTotal > 0 && (
        <WarningText>
          Of the selected transactions,{" "}
          {pluralize(finalizedTotal, " already finalized transaction")} will not be updated.{" "}
        </WarningText>
      )}

      {loading ? (
        <Loader css={{ padding: "$4" }} />
      ) : (
        <CategorySearch
          open={open}
          categories={categories}
          categoryTypes={categoryTypes}
          onChange={(val) => handleSubmit(val)}
        />
      )}
    </Dialog>
  );
};

export default BulkRecategorizeModal;
