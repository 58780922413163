import React, { useMemo, useState } from "react";
import { compact, uniqBy } from "lodash";

import { VendorFragment, YesNoUnknown } from "graphql/types";

import { useVendorSearch } from "./useVendorSearch";
import { AutocompleteMenu, AutocompleteMenuProps } from "@puzzle/ui";

type VendorSearchProps = {
  onSelect: (vendors: VendorFragment[]) => void;
  value?: VendorFragment[];
} & Pick<
  AutocompleteMenuProps<VendorFragment, true, false, false>,
  "subMenuTrigger" | "trigger" | "label"
>;
export const noVendor = {
  id: "00000000-0000-0000-0000-000000000000",
  value: "no_vendor",
  name: "No vendor",
  permaName: "",
  is1099Vendor: YesNoUnknown.Unknown,
} as VendorFragment;

export const VendorSearch = ({ onSelect, value, trigger, ...props }: VendorSearchProps) => {
  const [filterInput, setFilterInput] = useState<string>("");

  const { data, loading } = useVendorSearch(filterInput);
  const vendorsFromQuery = loading ? undefined : data?.company?.vendors.items;
  const vendors = useMemo(
    () => uniqBy(compact([noVendor, ...(value || []), ...(vendorsFromQuery || [])]), "id"),
    [value, vendorsFromQuery]
  );

  return (
    <AutocompleteMenu<VendorFragment, true, false, false>
      inputValue={filterInput}
      onInputChange={(event, value) => {
        if (event) {
          setFilterInput?.(value);
        }
      }}
      getOptionLabel={(v) => v.name}
      getOptionKey={(o) => o.id}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      placeholder="Search..."
      options={vendors}
      value={value ?? []}
      label="Pick vendors"
      onChange={(e, value) => {
        onSelect(value);

        // This is currently only used in the Transactions filter. It stays open and currently does not need resets.
        // Somehow something else resets it...

        // TODO this causes flickers while closing.. delay it or do it on open?
        // setFilterInput?.("");
      }}
      autoHighlight
      loading={loading}
      multiple
      {...props}
    >
      {trigger}
    </AutocompleteMenu>
  );
};
