import React, { useState } from "react";
import useStripe from "./useStripeIntegration";
import IntegrationLoading from "../setup/IntegrationLoading";
import { IntegrationConnectionCondition, IntegrationType } from "graphql/types";
import { SelectStartDateModal } from "../setup/modals/SelectStartDateModal";
import { IS_PROD } from "lib/config";
import { usePollIntegration } from "../shared/usePollIntegration";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";

interface ConnectStripePageProps {
  companyId: string;
  code: string;
  state: string;
  isOnboarding: boolean;
}

const ConnectStripePage = ({ companyId, code, state, isOnboarding }: ConnectStripePageProps) => {
  const { connectStripe, connectionError, connection, loading } = useStripe({ companyId });
  const { financialInstitutions, accounts, startPolling } = usePollIntegration(
    IntegrationType.Stripe,
    !isOnboarding
  );
  const [openSelectDate, onOpenSelectDateChange] = useState(false);
  const [isReconnect, setIsReconnect] = useState(false);

  const connect = async () => {
    if (connection?.id) {
      setIsReconnect(true);
    }

    const result = await connectStripe({
      code,
      companyId,
      state,
      condition: isOnboarding ? undefined : IntegrationConnectionCondition.WaitingForUserEpoch,
    });

    // start syncing data while we go back to the integrations page
    if (!result?.data || result.errors) {
      throw new Error("Error connecting stripe");
    }
    if (financialInstitutions.length === 0) {
      startPolling(1000);
    }
    onOpenSelectDateChange(true);
  };

  return (
    <>
      <IntegrationLoading
        integrationType={IntegrationType.Stripe}
        title="Connecting to Stripe"
        loading={loading}
        connection={connection}
        connectIntegration={connect}
        error={!!connectionError}
      />
      {!isOnboarding && isPosthogFeatureFlagEnabled(FeatureFlag.SetStripeEpoch) && (
        <SelectStartDateModal
          companyId={companyId}
          open={openSelectDate}
          accounts={accounts}
          onOpenChange={onOpenSelectDateChange}
          isReconnect={isReconnect}
          connectionId={connection?.id}
          accountsLoading={accounts.length === 0 ?? false}
        />
      )}
    </>
  );
};

export default ConnectStripePage;
