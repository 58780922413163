import React from "react";

import { Help, Stack, CheckboxField } from "@puzzle/ui";
import { StackOfPaper } from "@puzzle/icons";

import { EditSplitsSection } from "./EditSplitsSection";
import { FullTransactionFragment } from "../graphql.generated";

import { CategoryFragment } from "graphql/types";
import DescriptionList from "components/common/DescriptionList";
import { useActiveCompany } from "components/companies";

import {
  useUpdateBillPayment,
  useUpdateInvoicePayment,
  useExtraTransactionState,
} from "../hooks/useSingleTransaction";
import { Box, S } from "ve";

export const CategorySection = React.memo(function CategorySection({
  transaction,
  canEdit,
  categories,
}: {
  transaction: FullTransactionFragment;
  canEdit: boolean;
  categories: CategoryFragment[];
}) {
  const { company } = useActiveCompany();
  const { canBeBillPayment, canBeInvoicePayment } = useExtraTransactionState(transaction);
  const updateBillPayment = useUpdateBillPayment(transaction);
  const updateInvoicePayment = useUpdateInvoicePayment(transaction);
  const billPaymentChecked = !!transaction.detail.isBillPayment;
  const invoicePaymentChecked = !!transaction.detail.isInvoicePayment;

  return (
    <DescriptionList
      itemGap="2"
      direction={"vertical"}
      items={[
        [
          <>Category {canEdit && <StackOfPaper />}</>,
          <Stack key="category" gap="2">
            <EditSplitsSection
              transaction={transaction}
              categories={categories}
              canEdit={!!company && canEdit}
            />

            {canBeBillPayment && transaction.linkedBills.length === 0 && (
              <Box css={{ display: "flex", alignItems: "center" }}>
                <CheckboxField
                  disabled={!canEdit}
                  checked={billPaymentChecked}
                  onCheckedChange={(checked) => {
                    if (!updateBillPayment.loading) {
                      updateBillPayment.mutation(checked);
                    }
                  }}
                  label={
                    <Box css={{ display: "flex", alignItems: "center", gap: S["1"] }}>
                      Bill payment
                      <Help
                        content={
                          <div>
                            Indicate if this transaction is a cash payment on a bill that exists in
                            the Puzzle system. When selected, this causes the accrual accounting
                            treatment to reduce accounts payable instead of record an expense.
                          </div>
                        }
                      />
                    </Box>
                  }
                />
              </Box>
            )}
            {canBeInvoicePayment && (
              <Box css={{ display: "flex", alignItems: "center" }}>
                <CheckboxField
                  disabled={!canEdit}
                  checked={invoicePaymentChecked}
                  onCheckedChange={(checked) => {
                    if (!updateInvoicePayment.loading) {
                      updateInvoicePayment.mutation(checked);
                    }
                  }}
                  label={
                    <Box css={{ display: "flex", alignItems: "center", gap: S["1"] }}>
                      Invoice payment
                      <Help
                        content={
                          <div>
                            Indicate if this transaction is a cash payment received for an invoice
                            that exists in the Puzzle system. When selected, this causes the accrual
                            accounting treatment to reduce accounts receivable instead of record an
                            expense.
                          </div>
                        }
                      />
                    </Box>
                  }
                />
              </Box>
            )}
          </Stack>,
        ],
      ]}
    />
  );
});
