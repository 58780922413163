// Common links that aren't hosted within this app.
export default {
  terms: "https://www.puzzle.io/legal/terms-of-service",
  privacy: "https://www.puzzle.io/legal/privacy",
  hireAccountant:
    "mailto:luke@puzzle.io?subject=Help me find an accountant or bookkeeper&body=Hi Luke, I would be interested in connecting with a Puzzle partner to manage our bookkeeping and/or taxes. Can you match me to someone who would be a good fit? Thanks!",
  userGuide: "https://help.puzzle.io/en/collections/3857498-puzzle-overview",
  freeConciergeOnboarding: "https://cal.com/team/puzzle/puzzle-concierge-onboarding",
  historialFinancialMigration: "https://cal.com/andrew-puzzle/historical-financials-migration",
  stripeCustomerPortal: "https://billing.puzzle.io/p/login/9AQ29K5DuaADdXi8ww",
  pricingPage: "https://puzzle.io/pricing",
  faqPage: "https://puzzle.io/legal/faq",
  taxDates:
    "https://puzzle.io/blog/navigating-tax-season-2024-key-dates-filing-tips-and-professional-assistance?utm_medium=blog&utm_source=blog&utm_campaign=taxes2024",
  pricingFaq: "https://help.puzzle.io/en/articles/8891734-pricing-faqs",
};
