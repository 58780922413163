import * as Types from '../../../../graphql/types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RevenueRecognitionConfigurationFragment = { __typename?: 'RevenueRecognitionConfigurationConfig', method: Types.AccountingRecognitionTiming, calculation?: Types.AccountingRecognitionCalculation | null, firstPeriodAllocation?: Types.FirstPeriodAllocation | null, revenueAccount: any, revenueDeferralAccount?: any | null, discountAccount: any, refundAccount: any };

export type AccountingConfigurationFragment = { __typename: 'RevenueRecognitionConfiguration', id: any, name: string, description: string, type: Types.AccountingConfigurationType, updatedAt: CalendarDateTimeString, config?: { __typename?: 'RevenueRecognitionConfigurationConfig', method: Types.AccountingRecognitionTiming, calculation?: Types.AccountingRecognitionCalculation | null, firstPeriodAllocation?: Types.FirstPeriodAllocation | null, revenueAccount: any, revenueDeferralAccount?: any | null, discountAccount: any, refundAccount: any } | null };

export type AccountingConfigurationsQueryVariables = Types.Exact<{
  input: Types.AccountingConfigurationsQueryInput;
}>;


export type AccountingConfigurationsQuery = { __typename?: 'Query', accountingConfigurations: { __typename?: 'AccountingConfigurationResponse', configurations: Array<{ __typename: 'RevenueRecognitionConfiguration', id: any, name: string, description: string, type: Types.AccountingConfigurationType, updatedAt: CalendarDateTimeString, config?: { __typename?: 'RevenueRecognitionConfigurationConfig', method: Types.AccountingRecognitionTiming, calculation?: Types.AccountingRecognitionCalculation | null, firstPeriodAllocation?: Types.FirstPeriodAllocation | null, revenueAccount: any, revenueDeferralAccount?: any | null, discountAccount: any, refundAccount: any } | null }> } };

export type UpsertRevenueRecognitionConfigurationMutationVariables = Types.Exact<{
  input: Types.UpsertRevenueRecognitionConfigurationInput;
}>;


export type UpsertRevenueRecognitionConfigurationMutation = { __typename?: 'Mutation', upsertRevenueRecognitionConfiguration: { __typename?: 'UpsertAccountingConfigurationResponse', configuration: { __typename: 'RevenueRecognitionConfiguration', id: any, name: string, description: string, type: Types.AccountingConfigurationType, updatedAt: CalendarDateTimeString, config?: { __typename?: 'RevenueRecognitionConfigurationConfig', method: Types.AccountingRecognitionTiming, calculation?: Types.AccountingRecognitionCalculation | null, firstPeriodAllocation?: Types.FirstPeriodAllocation | null, revenueAccount: any, revenueDeferralAccount?: any | null, discountAccount: any, refundAccount: any } | null } } };

export const RevenueRecognitionConfigurationFragmentDoc = gql`
    fragment revenueRecognitionConfiguration on RevenueRecognitionConfigurationConfig {
  method
  calculation
  firstPeriodAllocation
  revenueAccount
  revenueDeferralAccount
  discountAccount
  refundAccount
}
    `;
export const AccountingConfigurationFragmentDoc = gql`
    fragment accountingConfiguration on AccountingConfiguration {
  __typename
  id
  name
  description
  type
  updatedAt
  config {
    ...revenueRecognitionConfiguration
  }
}
    ${RevenueRecognitionConfigurationFragmentDoc}`;
export const AccountingConfigurationsDocument = gql`
    query accountingConfigurations($input: AccountingConfigurationsQueryInput!) {
  accountingConfigurations(input: $input) {
    configurations {
      ...accountingConfiguration
    }
  }
}
    ${AccountingConfigurationFragmentDoc}`;

/**
 * __useAccountingConfigurationsQuery__
 *
 * To run a query within a React component, call `useAccountingConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountingConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountingConfigurationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAccountingConfigurationsQuery(baseOptions: Apollo.QueryHookOptions<AccountingConfigurationsQuery, AccountingConfigurationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountingConfigurationsQuery, AccountingConfigurationsQueryVariables>(AccountingConfigurationsDocument, options);
      }
export function useAccountingConfigurationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountingConfigurationsQuery, AccountingConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountingConfigurationsQuery, AccountingConfigurationsQueryVariables>(AccountingConfigurationsDocument, options);
        }
export type AccountingConfigurationsQueryHookResult = ReturnType<typeof useAccountingConfigurationsQuery>;
export type AccountingConfigurationsLazyQueryHookResult = ReturnType<typeof useAccountingConfigurationsLazyQuery>;
export type AccountingConfigurationsQueryResult = Apollo.QueryResult<AccountingConfigurationsQuery, AccountingConfigurationsQueryVariables>;
export const UpsertRevenueRecognitionConfigurationDocument = gql`
    mutation upsertRevenueRecognitionConfiguration($input: UpsertRevenueRecognitionConfigurationInput!) {
  upsertRevenueRecognitionConfiguration(input: $input) {
    configuration {
      ...accountingConfiguration
    }
  }
}
    ${AccountingConfigurationFragmentDoc}`;
export type UpsertRevenueRecognitionConfigurationMutationFn = Apollo.MutationFunction<UpsertRevenueRecognitionConfigurationMutation, UpsertRevenueRecognitionConfigurationMutationVariables>;

/**
 * __useUpsertRevenueRecognitionConfigurationMutation__
 *
 * To run a mutation, you first call `useUpsertRevenueRecognitionConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRevenueRecognitionConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRevenueRecognitionConfigurationMutation, { data, loading, error }] = useUpsertRevenueRecognitionConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertRevenueRecognitionConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<UpsertRevenueRecognitionConfigurationMutation, UpsertRevenueRecognitionConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertRevenueRecognitionConfigurationMutation, UpsertRevenueRecognitionConfigurationMutationVariables>(UpsertRevenueRecognitionConfigurationDocument, options);
      }
export type UpsertRevenueRecognitionConfigurationMutationHookResult = ReturnType<typeof useUpsertRevenueRecognitionConfigurationMutation>;
export type UpsertRevenueRecognitionConfigurationMutationResult = Apollo.MutationResult<UpsertRevenueRecognitionConfigurationMutation>;
export type UpsertRevenueRecognitionConfigurationMutationOptions = Apollo.BaseMutationOptions<UpsertRevenueRecognitionConfigurationMutation, UpsertRevenueRecognitionConfigurationMutationVariables>;