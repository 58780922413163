import React, { useCallback } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import * as yup from "yup";

import { Alert, Button, Dialog, Field, Input, Stack, StatusIcon, styled, Text } from "@puzzle/ui";
import { Arrow, Exclamation } from "@puzzle/icons";

import { useWizardContext } from "components/common/Wizard";
import { useActiveCompany } from "components/companies";
import Link from "components/common/Link";
import { BillDotComCoaImage } from "components/integrations/shared/BillDotComCoaImage";
import { useBillDotCom } from "components/integrations/billdotcom/useBillDotCom";
import {
  useCoaReportLazyQuery,
  useVendorReportLazyQuery,
} from "components/reports/graphql.generated";
import { downloadFileFromPath } from "components/common/files/utils";
import { IntegrationType } from "graphql/types";
import { Route } from "lib/routes";
import Loader from "components/common/Loader";
import { useImportBillsContext } from "./ImportBillsProvider";
import Analytics from "lib/analytics";
import { Box, S } from "ve";

export type FormValues = {
  username: string;
  password: string;
};

export const validationSchema = yup.object({
  username: yup.string().required(),
  password: yup.string().required(),
});

export const List = styled("ul", {
  li: {
    color: "$gray50",
    marginBottom: "$1",
    fontSize: "$bodyM",
  },
});

export const FancyList = styled("ul", {
  listStyle: "none",
  marginLeft: 0,
  paddingLeft: 0,
  color: "$gray50",
  fontSize: "$bodyM",

  li: {
    display: "flex",
    alignItems: "center",
    marginBottom: "$1h",

    "&:before": {
      backgroundColor: "$mauve700",
      content: "",
      height: "25px",
      width: "25px",
      borderRadius: "$ellipse",
      display: "inline-flex",
      marginRight: "$1",
    },
  },
});

export const Spinner = () => (
  <Loader css={{ display: "inline-flex", width: "fit-content" }} size={14} />
);

export const Stepper = () => {
  const { activeStepIndex, steps } = useWizardContext();

  return (
    <Text color="$gray300" size="headingS">
      Step {activeStepIndex} of {steps.length - 2}
    </Text>
  );
};

export const NextFooter = () => {
  const { goToNextStep } = useWizardContext();

  return (
    <Dialog.Footer>
      <Dialog.Actions>
        <Button suffix={<Arrow color="$black" />} variant="primary" onClick={goToNextStep}>
          Next
        </Button>
      </Dialog.Actions>
    </Dialog.Footer>
  );
};

export const Title = ({ text = "Import bills automatically from BILL" }: { text?: string }) => {
  const { goToPreviousStep, activeStepIndex, steps } = useWizardContext();

  return (
    <Dialog.Title
      onBack={
        activeStepIndex > 0 && activeStepIndex !== steps.length - 1 ? goToPreviousStep : undefined
      }
    >
      {text}
    </Dialog.Title>
  );
};

export const Intro = () => {
  const { setMode } = useImportBillsContext();

  return (
    <div>
      <Title />
      <Dialog.Body>
        <Box css={{ marginBottom: S["1h"] }}>
          <Text size="bodyM" color="gray50">
            With <Text weight="heavy">2 steps</Text>, you can directly import all your bills from
            BILL. Advantages:
          </Text>
        </Box>
        <Box css={{ marginBottom: S["3"] }}>
          <FancyList>
            <li>Reduces work</li>
            <li>Increase accounting accuracy</li>
            <li>Match cash to bills in Puzzle</li>
          </FancyList>
        </Box>
        <div>
          <Link
            css={{ fontSize: "$bodyM" }}
            color="greenalpha"
            onClick={() => {
              setMode("manual");
            }}
          >
            Import manually instead
          </Link>
        </div>
      </Dialog.Body>
      <NextFooter />
    </div>
  );
};

export const UploadCoa = () => {
  const [_getCoaCsv, { loading: coaLoading }] = useCoaReportLazyQuery();
  const [_getVendorsCsv, { loading: vendorsLoading }] = useVendorReportLazyQuery();
  const { company } = useActiveCompany<true>();

  const getCoaCsv = useCallback(async () => {
    _getCoaCsv({
      variables: {
        input: {
          integration: IntegrationType.BillDotCom,
          companyId: company.id,
        },
      },

      onCompleted({ coaReport }) {
        const signedUrl = coaReport.file?.downloadInfo.signedUrl;
        if (signedUrl) downloadFileFromPath(signedUrl);
        Analytics.reportDownloaded({ reportType: "ChartOfAccounts" });
      },
    });
  }, [_getCoaCsv, company.id]);

  const getVendorsCsv = useCallback(async () => {
    _getVendorsCsv({
      variables: {
        input: {
          integration: IntegrationType.BillDotCom,
          companyId: company.id,
        },
      },

      onCompleted({ vendorReport }) {
        const signedUrl = vendorReport.file?.downloadInfo.signedUrl;
        if (signedUrl) downloadFileFromPath(signedUrl);
      },
    });
  }, [_getVendorsCsv, company.id]);

  return (
    <div>
      <Title />
      <Dialog.Body>
        <Box css={{ marginBottom: S["3"] }}>
          <Stepper />
        </Box>
        <Box css={{ marginBottom: S["3"] }}>
          <Text weight="heading3" size="heading3" color="$gray50">
            Upload CoA and vendors to BILL
          </Text>
        </Box>
        <div>
          <List>
            <li>
              Download your{" "}
              <Link css={{ color: "$green600" }} onClick={getCoaCsv}>
                Chart of Accounts {coaLoading && <Spinner />}
              </Link>{" "}
              and{" "}
              <Link css={{ color: "$green600" }} onClick={getVendorsCsv}>
                Vendors {vendorsLoading && <Spinner />}
              </Link>
              .
            </li>
            <li>
              Sign into BILL in a new tab. On the top right, find the &ldquo;Settings&rdquo;
              icon and click on it.
              <Box css={{ marginTop: S["1"] }}>
                <BillDotComCoaImage />
              </Box>
            </li>
            <li>Under Settings, click on Import / Export on the left hand side.</li>
            <li>On Accounts row, click Import to import your Chart of Accounts.</li>
            <li>On Vendors row, click Import to import your Vendors.</li>
          </List>
        </div>
      </Dialog.Body>
      <NextFooter />
    </div>
  );
};

export const Connected = () => {
  return (
    <div>
      <Title text="Your bills are being imported into Puzzle" />
      <Dialog.Body>
        <Box css={{ display: "flex", alignItems: "center" }}>
          <StatusIcon status="success" size={48} active css={{ marginRight: "$2" }} />
          <Text size="headingM" color="gray50">
            You've connected your BILL credentials successfully! Your bills will be ready shortly.
          </Text>
        </Box>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.Actions>
          <Dialog.Close asChild>
            <Link href={Route.bills}>
              <Button variant="primary">Go to Bills</Button>
            </Link>
          </Dialog.Close>
        </Dialog.Actions>
      </Dialog.Footer>
    </div>
  );
};

export const Integrate = ({ form }: { form: UseFormReturn<FormValues> }) => {
  const { goToNextStep } = useWizardContext();
  const { company } = useActiveCompany<true>();
  const { connect, connectingError, connecting } = useBillDotCom({ companyId: company.id });

  const submit = useCallback(async () => {
    connect({ formValues: form.getValues(), onCompleted: goToNextStep });
  }, [form, goToNextStep, connect]);

  return (
    <div>
      <Title />
      <Dialog.Body css={{ paddingBottom: 0 }}>
        <Box css={{ marginBottom: S["3"] }}>
          <Stepper />
        </Box>
        <Box css={{ marginBottom: S["3"] }}>
          <Text weight="heading3" size="heading3" color="$gray50">
            Integrate with BILL
          </Text>
        </Box>
        <Box css={{ marginBottom: S["3"] }}>
          <Text color="gray50" size="bodyM">
            Provide your credentials so we can connect to the BILL API and import your bills. If
            you do not have your <Text weight="heavy">Sync Name</Text> and{" "}
            <Text weight="heavy">Sync Token</Text>, log in to your BILL account, then go to Settings
            {">"} Sync & Integrations {">"} Tokens to create a Sync Name and Sync Token (Password).
          </Text>
        </Box>

        <Stack direction="horizontal" css={{ marginBottom: "$3" }}>
          <Field label="Sync Name" css={{ flex: 1 }}>
            <Controller
              control={form.control}
              name="username"
              render={({ field }) => {
                return <Input {...field} />;
              }}
            />
          </Field>
          <Field label="Sync Token" css={{ flex: 1 }}>
            <Controller
              control={form.control}
              name="password"
              render={({ field }) => {
                return <Input type="password" {...field} />;
              }}
            />
          </Field>
        </Stack>
        {connectingError && (
          <Box css={{ marginBottom: S["3"] }}>
            <Alert
              kind="error"
              icon={<Exclamation />}
              css={{ backgroundColor: "$red500", color: "#EFF3FE" }}
            >
              Cannot connect to BILL. Please check your credentials.
            </Alert>
          </Box>
        )}
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.Actions>
          <Button
            variant="primary"
            disabled={!form.formState.isValid}
            onClick={submit}
            loading={connecting}
          >
            Connect
          </Button>
        </Dialog.Actions>
      </Dialog.Footer>
    </div>
  );
};
