import { useToasts } from "@puzzle/ui";
import {
  useConnectRipplingMutation,
  RipplingIntegrationDocument,
  useRipplingIntegrationQuery,
  RipplingIntegrationQuery,
  IntegrationType,
} from "graphql/types";
import { ConnectRipplingInput, UseRipplingResult } from "./types";
import { merge } from "lodash";
import { usePendingConnections } from "../shared/PendingConnectionsProvider";
import { saveRequestedIntegrationCookie } from "lib/cookies";
import useSavePage from "../shared/useSavePage";
import { UseIntegrationHook, useFinancialInstitutions } from "../shared";
import config from "lib/config";
import Analytics from "lib/analytics";
import { useMemo } from "react";

const useRippling: UseIntegrationHook<UseRipplingResult> = ({
  companyId,
  onClickConnect: baseOnClickConnect,
}): UseRipplingResult => {
  const { toast } = useToasts();
  const { addPendingConnection, removePendingConnection } = usePendingConnections();
  const { data, loading } = useRipplingIntegrationQuery({
    variables: {
      companyId,
    },
  });
  const { integrations } = data || {};
  const { integrationConnections } = useFinancialInstitutions();
  const [connectRipplingMutation, { loading: connecting, error: connectionError }] =
    useConnectRipplingMutation();

  const { savePage } = useSavePage();

  const initializationInfo = integrations?.rippling.initializationInfo;

  const connection = useMemo(() => {
    const connectionId = integrations?.rippling.connection?.id;
    return integrationConnections.find((ic) => ic.id === connectionId);
  }, [integrationConnections, integrations]);

  const onClickConnect = () => {
    baseOnClickConnect && baseOnClickConnect();
    savePage();
    saveRequestedIntegrationCookie(null, IntegrationType.Rippling);

    if (!initializationInfo) {
      if (config.IS_LOCAL_DEVELOPMENT) {
        toast({
          message: "useRippling's onClickConnect was called before initialization info was defined",
          status: "error",
        });
      }
      return;
    }
    window.location.href = initializationInfo.connectUrl;
  };

  const connectRippling = async (input: ConnectRipplingInput) => {
    addPendingConnection(IntegrationType.Rippling);

    return connectRipplingMutation({
      variables: { input },

      update(cache, { data }) {
        if (!data) return;
        const ripplingIntegration = cache.readQuery<RipplingIntegrationQuery>({
          query: RipplingIntegrationDocument,
          variables: {
            companyId,
          },
        });

        cache.writeQuery({
          query: RipplingIntegrationDocument,
          variables: {
            companyId,
          },
          data: merge({}, ripplingIntegration, {
            integrations: {
              __typename: "Integrations",
              rippling: {
                __typename: "RipplingIntegration",
                connection: {
                  __typename: "IntegrationConnection",
                  ...data.connectRippling.connection,
                },
              },
            },
          }),
        });
      },

      onCompleted(data) {
        const { connection } = data.connectRippling;
        removePendingConnection(IntegrationType.Rippling);

        // todo - this is a short term solution to communicating to the user
        // that there is stuff that's processing and to expect loading indicators
        toast({
          message: `Your account has been connected! Data ingestion may take a couple minutes.`,
          status: "success",
        });

        Analytics.integrationConnected({
          connectionId: connection.id,
          integrationType: "Rippling",
          totalAccounts: 1,
        });
      },

      onError({ message }) {
        Analytics.integrationConnectionFailed({
          integrationType: "Rippling",
          reason: message,
        });
      },
    });
  };

  return {
    connectionError,
    connectRippling,
    loading,
    connecting,
    onClickConnect,
    connection,
  };
};

export default useRippling;
