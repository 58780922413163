import { SubscriptionBillingCycle, Plan } from "../types";
import { formatMoney } from "@puzzle/utils";

export const actualPrice = (cycle: SubscriptionBillingCycle, plan?: Plan) => {
  if (plan && plan.price && plan.priceYear) {
    const val =
      cycle === SubscriptionBillingCycle.Monthly ? plan.price : Math.round(plan.priceYear / 12);
    return formatMoney({ currency: "USD", amount: val }, { truncateValue: false });
  }
  return null;
};

export const actualPriceAddon = (
  cycle: SubscriptionBillingCycle,
  monthlyPrice?: number,
  yearlyPrice?: number
) => {
  if (monthlyPrice && yearlyPrice) {
    const val =
      cycle === SubscriptionBillingCycle.Monthly ? monthlyPrice : Math.round(yearlyPrice / 12);
    return formatMoney({ currency: "USD", amount: val }, { truncateValue: false });
  }
  return null;
};

/**
 * Joins an array using , and `and`
 * @param array string array
 * @returns array joined with , except the last one.
 */
export const joinWithCommasAnd = (array: string[]) => {
  if (array.length === 0) return "";
  if (array.length === 1) return array[0];
  if (array.length === 2) return `${array[0]} and ${array[1]}`;
  const joinedExceptLast = array.slice(0, length - 1).join(", ");
  const lastItem = array[length - 1];
  return `${joinedExceptLast} and ${lastItem}`;
};
