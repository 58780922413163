import React, { useMemo } from "react";
import { Button, CSS, styled } from "@puzzle/ui";

import { Close } from "@puzzle/icons";
import { IntegrationType } from "graphql/types";

// TODO Integrations could be a lot DRYer.
// Components and custom logic should be an exception, not a requirement.
import GustoDetails from "./GustoDetails";
import BrexDetails from "./BrexDetails";
import RampDetails from "./RampDetails";
import RipplingDetails from "./RipplingDetails";
import StripeDetails from "./StripeDetails";
import QuickbooksDetails from "./QuickbooksDetails";
import MercuryDetails from "./MercuryDetails";
import SvbOfxDetails from "./SvbOfxDetails";
import BillDotComDetails from "./BillDotComDetails";
import { useActiveCompany } from "components/companies";
import PlaidConnectionDetails from "./PlaidConnectionDetails";
import AccountDetails from "./AccountDetails";
import { useFinancialInstitutions } from "../shared";
import DeelDetails from "./DeelDetails";
import EveryDetails from "./EveryDetails";
import PushIntegrationDetails from "./PushIntegrationDetails";
import { isPushIntegationType } from "../setup/utils";

const Container = styled("div", {
  height: "100%",
  display: "flex",
  flexDirection: "column",
});

const Header = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  textVariant: "$bodyM",
  fontWeight: "$bold",
  color: "$gray300",
});

export const DetailPane = ({
  onClose,
  accountId,
  integrationType,
  integrationConnectionId,
  financialInstitutionId,
  css,
}: {
  onClose?: () => void;
  integrationType?: string;
  integrationConnectionId?: string;
  financialInstitutionId?: string;
  accountId?: string;
  css?: CSS;
}) => {
  const { company } = useActiveCompany<true>();
  const { accounts } = useFinancialInstitutions();

  const account = useMemo(() => {
    if (accountId) {
      return accounts.find((account) => account.id === accountId);
    }
  }, [accounts, accountId]);

  const type = useMemo(
    () =>
      Object.values(IntegrationType).find(
        (x) => x.toLowerCase() === integrationType?.toLowerCase()
      ),
    [integrationType]
  );

  const ConnectionDetailsComponent = useMemo(() => {
    if (type) {
      if (isPushIntegationType(type)) {
        return PushIntegrationDetails;
      }

      switch (type) {
        case IntegrationType.Gusto:
          return GustoDetails;
        case IntegrationType.Plaid:
          return PlaidConnectionDetails;
        case IntegrationType.Brex:
          return BrexDetails;
        case IntegrationType.Ramp:
          return RampDetails;
        case IntegrationType.Rippling:
          return RipplingDetails;
        case IntegrationType.Stripe:
          return StripeDetails;
        case IntegrationType.QuickBooks:
          return QuickbooksDetails;
        case IntegrationType.Mercury:
          return MercuryDetails;
        case IntegrationType.SvbOfx:
          return SvbOfxDetails;
        case IntegrationType.BillDotCom:
          return BillDotComDetails;
        case IntegrationType.Deel:
          return DeelDetails;
        case IntegrationType.Every:
          return EveryDetails;
      }
    }
  }, [type]);

  if (!ConnectionDetailsComponent && !account) {
    return null;
  }

  return (
    <Container css={css}>
      <Header>
        {onClose && (
          <Button variant="minimal" size="mini" onClick={onClose}>
            <Close fill="currentColor" />
          </Button>
        )}
      </Header>

      {ConnectionDetailsComponent ? (
        <ConnectionDetailsComponent
          companyId={company.id}
          accountId={accountId}
          integrationConnectionId={integrationConnectionId ?? ""}
          financialInstitutionId={financialInstitutionId}
          onClose={onClose}
        />
      ) : (
        account && <AccountDetails account={account} />
      )}
    </Container>
  );
};
