import React from "react";
import { Button } from "@puzzle/ui";
import { useActiveCompany, PricingFeatures } from "components/companies";
import { DataConsentModal } from "components/common/DataConsentModal";
import useMonetization from "components/monetization/useMonetization";
import { useAI } from "components/AI/useAI";
import { Sparkle, Crown } from "@puzzle/icons";

export const BaseAskAIButton = ({ onClick, text }: { text: string; onClick: () => void }) => {
  const { consentToAI, enableAutonomousAccounting } = useAI();
  const [consentModalOpen, setConsentModalOpen] = React.useState(false);
  const { company, pricePlanFeatureEnabled } = useActiveCompany<true>();
  const { showUpgradeModal } = useMonetization();

  const isFeatureGated = !pricePlanFeatureEnabled.has(PricingFeatures.puzzle_ai);

  const featureDisabled = !company.features.assistedCategorizationEnabled || !company.consentsToAI;
  return (
    <>
      <DataConsentModal
        open={consentModalOpen}
        onOpenChange={setConsentModalOpen}
        onConfirm={async () => {
          await consentToAI();
          await enableAutonomousAccounting();
          setConsentModalOpen(false);
          onClick?.();
        }}
      />
      <Button
        onClick={() => {
          if (isFeatureGated) {
            showUpgradeModal();
          } else {
            if (featureDisabled) {
              setConsentModalOpen(true);
            } else {
              onClick();
            }
          }
        }}
        variant="secondary"
        size="small"
        width={500}
        css={{ padding: 10 }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 6,
          }}
        >
          <Sparkle />
          {text}
          {isFeatureGated && <Crown />}
        </div>
      </Button>
    </>
  );
};
