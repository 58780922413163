import { useMemo } from "react";
import { useActiveCompany } from "components/companies";
import { useAccountingConfigurationsQuery } from "../graphql.generated";

export function useConfigurationsQuery() {
  const { company } = useActiveCompany<true>();
  const { data, loading } = useAccountingConfigurationsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        companyId: company.id,
      },
    },
  });

  return useMemo(
    () => ({
      configurations: data?.accountingConfigurations?.configurations,
      loading,
    }),
    [data, loading]
  );
}
