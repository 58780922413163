import React from "react";

import { isNil } from "lodash";

import {
  styled,
  createColumnHelper,
  Tooltip,
  CountIndicator,
  Menu,
  Checkbox,
  Button,
} from "@puzzle/ui";
import { CaretDown, Split } from "@puzzle/icons";
import { DateFormatterResult, formatMoney, pluralize } from "@puzzle/utils";
import { DateValue } from "@internationalized/date";

import { CategoryFragment, ClassAwareType } from "graphql/types";

import UpdateCategoryMenu from "./UpdateField/UpdateCategoryMenu";
import { AccountCell, StatusIcon, VendorCell, DescriptorCell } from "components/transactions/Cells";
import { LockedPeriodStatus } from "components/transactions/Cells/LockedPeriodStatus";
import { AvailableDateTooltip, ManualTransactionTooltip } from "./AsteriskTooltip";
import SelectCategoryInput from "components/transactions/SelectCategoryInput";
import type {
  FilterState,
  PageTotals,
} from "components/dashboard/Transactions/TransactionsProvider";
import { TransactionStatus } from "./TransactionStatus";
import AssignedUserAvatar from "./AssignedUserAvatar";
import { HelpMenu } from "./HelpMenu";
import ClassificationsSection from "components/common/Classifications/ClassificationsSection";
import { ClassSegment } from "components/common/Classifications/TagEntity";
import HoverCard from "components/common/HoverCard";
import { TransactionClassesCell } from "./TransactionClassesCell";

import {
  UpdateCategoryMetricsLocations,
  UpdateCategoryMetricsView,
  useExtraTransactionState,
  useGetTransaction,
} from "./hooks/useSingleTransaction";
import useEditSplits from "./hooks/useEditSplits";
import useCategories from "components/common/hooks/useCategories";

import {
  TransactionRowData,
  TransactionColHeaderMapping,
  TransactionColId,
  SplitRowData,
  StatusRoot,
  ColumnVisibility,
} from "./shared";
import { AccountRoot } from "components/transactions/Cells/AccountCell";

const isSplit = (data?: TransactionRowData): data is SplitRowData => {
  return !isNil((data as SplitRowData).splitIndex);
};

const hasSplits = (data?: TransactionRowData) => {
  return !isSplit(data) && data?.subRows && data?.subRows?.length > 0;
};

const getClassSegments = (transactionRow: TransactionRowData): ClassSegment[] => {
  const isSplitRow = isSplit(transactionRow);
  if (isSplitRow && transactionRow.classSegments.length <= 0) return [];
  if (!isSplitRow && transactionRow.transaction.detail.classSegments.length <= 0) return [];
  const interimEntity = isSplitRow ? transactionRow : transactionRow.transaction.detail;

  return interimEntity.classSegments;
};

const CheckboxAndDropdown = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "$0h",
});

const columnHelper = createColumnHelper<TransactionRowData>();

export const EditSplitCategoryCell = ({
  data,
  wrap,
}: {
  data: Pick<SplitRowData, "transactionId" | "detailId" | "splitIndex">;
  wrap?: boolean;
}) => {
  const transactionResult = useGetTransaction(data.transactionId);
  const transaction = transactionResult.data?.transaction;
  const { canEditSplits } = useExtraTransactionState(transaction);
  const { updateSplitCategory, persistSplits, getSplit } = useEditSplits(transaction);
  const { categories } = useCategories();
  const handleUpdate = (c: CategoryFragment) => {
    const newSplits = updateSplitCategory(data.detailId, c);
    persistSplits(newSplits);
  };

  const split = getSplit(data.detailId) || transaction?.splits[data.splitIndex];
  if (!split) {
    return null;
  }

  return (
    <SelectCategoryInput
      value={split.category}
      categories={categories}
      canEdit={canEditSplits}
      onChange={handleUpdate}
      wrap={wrap}
      isBillPayment={!!split.isBillPayment}
      isInvoicePayment={!!split.isInvoicePayment}
    />
  );
};

export const accountColumn = columnHelper.accessor((row) => row.account, {
  id: TransactionColId.account,
  size: 80,
  header: TransactionColHeaderMapping.account,
  enableHiding: true,
  meta: {
    align: "center",
    getCellProps: () => ({
      muted: true,
    }),
  },
  cell: (info) => {
    if (isSplit(info.row.original)) {
      return null;
    }

    const account = info.getValue();
    return (
      <AccountRoot>
        <AccountCell account={account} />
      </AccountRoot>
    );
  },
});

export const dateColumn = (filter: FilterState, dateFormatter: DateFormatterResult) =>
  columnHelper.accessor((row) => (filter.showAvailableDate && row.availableOn) || row.date, {
    id: TransactionColId.date,
    size: 130,
    header: TransactionColHeaderMapping.date,
    enableSorting: true,
    enableHiding: true,
    meta: {
      getCellProps: () => ({
        muted: true,
      }),
    },
    cell: ({ getValue, row }) => {
      const transaction = row.original?.transaction;
      if (!transaction || isSplit(row.original)) {
        return null;
      }

      const date = getValue();

      return (
        <StatusRoot>
          <div>
            {dateFormatter.format(date)}

            {filter.showAvailableDate && row.original.availableOn && <AvailableDateTooltip />}
          </div>

          <LockedPeriodStatus
            transactionDate={row.original.date}
            posted={Boolean(transaction.detail.postedAt)}
            transactionId={transaction.id}
            onClick={(e) => e.stopPropagation()}
          />
        </StatusRoot>
      );
    },
  });

export const vendorCustomerColumn = columnHelper.display({
  id: TransactionColId.vendor_customer,
  size: 150,
  header: TransactionColHeaderMapping.vendor_customer,
  cell: ({ row }) => {
    const detail = row.original.transaction.detail;
    if (detail.category.__typename !== "LedgerCategory") {
      throw new Error("Wrong category type");
    }
    const isRevenueTransaction = detail.category.defaultCashlike === "REVENUE";

    return (
      <VendorCell
        transaction={row.original.transaction}
        vendor={detail.vendor}
        customer={detail.customer}
        isRevenueTransaction={isRevenueTransaction}
      />
    );
  },
});

export const descriptionColumn = columnHelper.accessor((row) => row.descriptor, {
  id: TransactionColId.descriptor,
  enableHiding: true,
  size: 240,
  meta: {
    getHeaderProps: () => ({
      css: { paddingLeft: "$3 !important" },
    }),
    getCellProps: () => ({
      css: { paddingLeft: "$3 !important" },
    }),
  },
  header: TransactionColHeaderMapping.descriptor,
  cell: ({ row: { original }, getValue }) => {
    const originalDescriptor = getValue();
    const updatedDescriptor = original.transaction.detail.descriptor;
    const descriptor = updatedDescriptor ?? originalDescriptor;
    const amount = original.amount;
    if (isSplit(original)) {
      return (
        <Tooltip content="Split" side="left" sideOffset={4}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Split style={{ marginRight: 8 }} />

            {descriptor}
          </div>
        </Tooltip>
      );
    }

    return (
      <DescriptorCell
        transaction={original.transaction}
        descriptor={descriptor}
        originalDescriptor={originalDescriptor}
        amount={amount}
      />
    );
  },
});

export const amountColumn = columnHelper.accessor((row) => row.amount, {
  id: TransactionColId.amount,
  enableSorting: true,
  enableHiding: true,
  size: 120,
  meta: {
    type: "number",
    align: "right",
    getCellProps: (row) => ({
      css: {
        color: isSplit(row.original) ? "$gray500" : "inherit",
      },
    }),
  },
  header: "Amount",
  cell: ({ row: { original, index }, getValue }) => {
    const formatted = formatMoney({ currency: "USD", amount: getValue() });

    return (
      <>
        {formatted}
        <ManualTransactionTooltip integrationType={original.transaction.integrationType} />
      </>
    );
  },
});

export const categoryColumn = (categories: CategoryFragment[]) =>
  columnHelper.accessor((row) => row.category, {
    id: TransactionColId.category,
    enableHiding: true,
    size: 240,
    header: () => {
      return null; // handled via portal
    },
    cell: ({ row: { original } }) => {
      if (!original) {
        return null;
      }

      if (hasSplits(original)) {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Split style={{ marginRight: 8 }} />
          </div>
        );
      }

      if (isSplit(original)) {
        return <EditSplitCategoryCell data={original} />;
      }

      return (
        <UpdateCategoryMenu
          categories={categories}
          id={original.transactionId}
          metrics={{
            location: UpdateCategoryMetricsLocations.TransactionsTable,
            component: UpdateCategoryMetricsView.CategoryModal,
          }}
        />
      );
    },
  });

export const statusColumn = columnHelper.accessor((row) => row, {
  id: TransactionColId.status,
  enableHiding: true,
  size: 80,
  header: TransactionColHeaderMapping.status,
  cell: ({ row: { original }, getValue }) => {
    const { transaction } = getValue();

    if (isSplit(original)) return null;

    return (
      <StatusRoot>
        <StatusIcon
          transaction={transaction}
          onClick={(e) => {
            e.stopPropagation();
          }}
          tooltipProps={{
            side: "bottom",
            align: "start",
            alignOffset: 8,
            arrow: false,
            sideOffset: 14,
          }}
          location="transactionTable"
        />
        <TransactionStatus transaction={transaction} />
      </StatusRoot>
    );
  },
});

export const assigneeColumn = columnHelper.accessor((row) => row.transactionId, {
  id: "assignee",
  size: 50,
  header: undefined,
  cell: ({ row: { original }, getValue }) => {
    if (isSplit(original)) {
      return null;
    }

    return <AssignedUserAvatar id={getValue()} />;
  },
});

export const helpMenuColumn = (
  columnVisibility: ColumnVisibility,
  setColumnVisibility: React.Dispatch<React.SetStateAction<ColumnVisibility | undefined>>
) =>
  columnHelper.display({
    id: "helpMenu",
    size: 48,
    meta: {
      align: "left",
    },
    header: () => {
      return (
        <HelpMenu columnVisibility={columnVisibility} setColumnVisibility={setColumnVisibility} />
      );
    },
  });

export const classificationsColumn = columnHelper.display({
  id: "classifications",
  size: 120,
  enableHiding: true,
  meta: {
    align: "center",
  },
  header: "Classifications",
  cell: ({ row: { original } }) => {
    if (isSplit(original) && original.classSegments.length <= 0) return null;
    if (!isSplit(original) && original.transaction.detail.classSegments.length <= 0) return null;

    const interimEntity = isSplit(original) ? original : original.transaction.detail;

    const entity = {
      id: interimEntity.id,
      name: interimEntity.descriptor,
      type: ClassAwareType.TransactionDetail,
      classSegments: interimEntity.classSegments,
    };

    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <HoverCard
          side="left"
          arrow={true}
          trigger={
            <CountIndicator shape="circle" tone="dark">
              {isSplit(original)
                ? original.classSegments.length
                : original.transaction.detail.classSegments.length}
            </CountIndicator>
          }
        >
          <HoverCard.Body>
            <ClassificationsSection entity={entity} hideAddButton={true} />
          </HoverCard.Body>
        </HoverCard>
      </div>
    );
  },
});

export const departmentColumn = columnHelper.display({
  id: TransactionColId.department,
  size: 120,
  enableHiding: true,
  meta: {
    align: "left",
  },
  header: TransactionColHeaderMapping.department,
  cell: ({ row: { original } }) => {
    const classSegments = getClassSegments(original);
    if (classSegments.length <= 0) return null;

    const departmentTags = classSegments.filter(
      (segment) => segment.reportingClass.type === "Department"
    );

    return (
      <TransactionClassesCell key={TransactionColId.department} classSegments={departmentTags} />
    );
  },
});

export const locationColumn = columnHelper.display({
  id: TransactionColId.location,
  size: 120,
  enableHiding: true,
  meta: {
    align: "left",
  },
  header: TransactionColHeaderMapping.location,
  cell: ({ row: { original } }) => {
    const classSegments = getClassSegments(original);
    if (classSegments.length <= 0) return null;

    const locationTags = classSegments.filter(
      (segment) => segment.reportingClass.type === "Location"
    );

    return <TransactionClassesCell key={TransactionColId.location} classSegments={locationTags} />;
  },
});

export const classesColumn = columnHelper.display({
  id: TransactionColId.classes,
  size: 120,
  enableHiding: true,
  meta: {
    align: "left",
  },
  header: TransactionColHeaderMapping.classes,
  cell: ({ row: { original } }) => {
    const classSegments = getClassSegments(original);
    if (classSegments.length <= 0) return null;

    const customTags = classSegments.filter(
      (segment) => segment.reportingClass.type === "UserCreated"
    );

    return <TransactionClassesCell key={TransactionColId.classes} classSegments={customTags} />;
  },
});

export const selectColumn = (
  matchAll: boolean,
  setMatchAll: React.Dispatch<React.SetStateAction<boolean>>,
  isWithinLockedPeriod: (date: DateValue) => boolean,
  data: TransactionRowData[],
  totals: PageTotals
) =>
  columnHelper.display({
    id: "select",
    size: 48,
    meta: {
      getHeaderProps: () => ({
        css: { padding: 0, paddingLeft: "$1" },
      }),
      getCellProps: (row) => ({
        css: { padding: 0, paddingLeft: "$1" },
        onClick: (e) => {
          if (isSplit(row.original)) {
            return;
          }

          e.stopPropagation();
          if (matchAll) {
            setMatchAll(false);
          }
          row.getToggleSelectedHandler()(e);
        },
      }),
    },

    header: ({ table }) => {
      const selectAll = (checked = true, match = false) => {
        setMatchAll(match);
        table.getToggleAllRowsSelectedHandler()({
          target: { checked },
        });
      };
      const deselect = () => selectAll(false, false);
      const selectAllDisplayed = () => selectAll(true, false);
      const selectAllMatching = () => selectAll(true, true);

      const checked = table.getIsSomeRowsSelected()
        ? "indeterminate"
        : table.getIsAllRowsSelected();

      return (
        <CheckboxAndDropdown>
          <Tooltip
            content={
              matchAll || checked === true
                ? "Unselect transactions"
                : `Select ${pluralize(data.length, "transaction")}`
            }
            arrow={false}
            align="start"
          >
            <Checkbox
              checked={matchAll || checked}
              interactive={checked === true}
              onClick={(e) => {
                if (checked === true) {
                  deselect();
                } else {
                  selectAllDisplayed();
                }
              }}
              css={{ marginLeft: "10px" }}
            />
          </Tooltip>

          <Menu
            modal={false}
            trigger={
              <Button
                size="compact"
                variant="minimal"
                css={{
                  px: "$0h",
                  transform: "translateX(0px)",
                }}
              >
                <CaretDown color="currentColor" />
              </Button>
            }
          >
            <Menu.Group>
              <Menu.Item onSelect={selectAllDisplayed}>Only displayed transactions</Menu.Item>
              <Menu.Item onSelect={selectAllMatching}>
                All {pluralize(totals.count, "transaction")}
              </Menu.Item>
            </Menu.Group>
          </Menu>
        </CheckboxAndDropdown>
      );
    },

    cell: ({ row }) => {
      const checked = matchAll || row.getIsSelected();
      const disabled = isWithinLockedPeriod(row.original.date);
      return isSplit(row.original) ? null : (
        <Tooltip
          content={
            disabled
              ? "You cannot select a transaction that is part of a locked period."
              : checked
              ? "Unselect"
              : "Select"
          }
          arrow={false}
          align="start"
        >
          <Checkbox
            checked={checked}
            onCheckedChange={(checked) => {
              if (matchAll) {
                setMatchAll(false);
              }

              row.getToggleSelectedHandler()(checked);
            }}
            css={{ marginLeft: "10px" }}
          />
        </Tooltip>
      );
    },
  });
