import React, { useMemo, useState, useCallback } from "react";
import { Row } from "@tanstack/react-table";
import { compact } from "lodash";
import { FloatingActions, colors } from "@puzzle/ui";
import { Check, Delete, Tag as TagIcon } from "@puzzle/icons";
import { pluralize } from "@puzzle/utils";
import { DetailConfirmedState } from "graphql/types";
import useCategories from "components/common/hooks/useCategories";
import useBulkFinalizeTransactions from "./hooks/useBulkFinalizeTransactions";
import BulkRecategorizeModal from "./BulkRecategorizeModal";
import DeleteTransactionsModal from "./DeleteTransactionsModal";
import { TransactionRowData } from "./shared";
import { GetTransactionsQueryVariables } from "./graphql.generated";

export const TransactionsFloatingActions = ({
  data,
  selectedRows,
  matchAll,
  resetSelection,
  toggleMatch,
  companyId,
  loading,
  hasMore,
  totalCount,
  queryVariables,
}: {
  data: TransactionRowData[];
  selectedRows: Row<TransactionRowData>[];
  matchAll: boolean;
  resetSelection: () => void;
  toggleMatch?: () => void;
  companyId: string;
  loading: boolean;
  hasMore: boolean;
  totalCount: number;
  queryVariables: GetTransactionsQueryVariables;
}) => {
  const {
    bulkFinalizeFilter: _bulkFinalizeFilter,
    bulkFinalizeIds,
    isBulkUpdating,
  } = useBulkFinalizeTransactions(queryVariables);
  const { categories } = useCategories();
  const [openDelete, setOpenDelete] = useState(false);
  const [openBulkCategorize, setOpenBulkCategorize] = useState(false);

  const selectedCount = matchAll ? totalCount : selectedRows.length;
  const isAllFinalized = useMemo(
    () =>
      !selectedRows.find(
        (row) => row.original.transaction.detail.confirmedState !== DetailConfirmedState.Finalized
      ),
    [selectedRows]
  );

  const bulkFinalizeSelected = useCallback(
    async (isUnfinalized?: boolean) => {
      const result = await bulkFinalizeIds(
        compact(selectedRows.map((x) => x.original?.transactionId)),
        isUnfinalized
      );
      if (result) {
        resetSelection();
      }
    },
    [bulkFinalizeIds, resetSelection, selectedRows]
  );
  const bulkFinalizeFilter = useCallback(
    async (isUnfinalized?: boolean) => {
      const result = await _bulkFinalizeFilter(isUnfinalized);
      if (result) {
        resetSelection();
      }
    },
    [_bulkFinalizeFilter, resetSelection]
  );

  return (
    <>
      {useMemo(
        () => (
          <DeleteTransactionsModal
            transactions={selectedRows}
            open={openDelete}
            onOpenChange={setOpenDelete}
            selectAll={matchAll}
          />
        ),
        [selectedRows, openDelete, setOpenDelete, matchAll]
      )}
      {useMemo(
        () => (
          <BulkRecategorizeModal
            transactions={selectedRows}
            categories={categories}
            open={openBulkCategorize}
            onOpenChange={setOpenBulkCategorize}
            companyId={companyId}
          />
        ),
        [selectedRows, openBulkCategorize, setOpenBulkCategorize, categories, companyId]
      )}
      {useMemo(
        () => (
          <FloatingActions present={selectedCount > 0}>
            <FloatingActions.MultiActionBar
              actions={[
                {
                  label: `${
                    isBulkUpdating ? "Finalizing" : isAllFinalized ? "Unfinalize" : "Finalize"
                  }`,
                  icon: <Check color={isBulkUpdating ? colors.gray600 : colors.green600} />,
                  disabled: isBulkUpdating,
                  onClick: () => {
                    if (matchAll) {
                      bulkFinalizeFilter(isAllFinalized);
                    } else {
                      bulkFinalizeSelected(isAllFinalized);
                    }
                  },
                },
                {
                  label: "Categorize",
                  icon: <TagIcon color={isAllFinalized ? colors.gray600 : colors.blue300} />,
                  hide: hasMore && matchAll,
                  disabled: isAllFinalized,
                  onClick: () => {
                    setOpenBulkCategorize(true);
                  },
                },
                {
                  label: "Remove",
                  icon: <Delete />,
                  hide: hasMore && matchAll,
                  onClick: () => {
                    setOpenDelete(true);
                  },
                },
              ]}
            >
              {selectedCount} selected
            </FloatingActions.MultiActionBar>

            {toggleMatch && selectedCount >= data.length && (
              <FloatingActions.ActionBar onClick={toggleMatch}>
                {matchAll
                  ? "Select only displayed transactions"
                  : `Select all ${pluralize(totalCount, "matching transaction")}`}
              </FloatingActions.ActionBar>
            )}
          </FloatingActions>
        ),
        [
          selectedCount,
          isBulkUpdating,
          data.length,
          toggleMatch,
          matchAll,
          totalCount,
          bulkFinalizeFilter,
          bulkFinalizeSelected,
          setOpenDelete,
          hasMore,
          isAllFinalized,
        ]
      )}
    </>
  );
};
