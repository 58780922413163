import React from "react";
import { Label } from "@radix-ui/react-label";
import * as SwitchPrimitive from "@radix-ui/react-switch";
import * as Stitches from "@stitches/react";

import { styled } from "./stitches";
import { useInDialogContext } from "./InDialogContext";

const StyledLabel = styled(Label, {
  display: "flex",
  alignItems: "center",
  fontWeight: "$bold",
  fontSize: "$bodyS",
  lineHeight: "$bodyXS",
  letterSpacing: "$bodyXS",
  gap: "$1",
  cursor: "pointer",
  color: "$gray400",

  defaultVariants: {
    inDialog: false,
  },

  variants: {
    inDialog: {
      true: {
        color: "$gray200",
      },
      false: {},
    },
  },
});

const ROOT_WIDTH = 32;
const THUMB_SIZE = 16;
const THUMB_MARGIN_ENABLED = 2;
const THUMB_MARGIN_DISABLED = 2;

const Thumb = styled(SwitchPrimitive.Thumb, {
  display: "block",
  width: THUMB_SIZE,
  height: THUMB_SIZE,
  backgroundColor: "$gray50",
  border: "0.5px solid $gray500",
  borderRadius: "$ellipse",
  transition: "all 0.15s",
  transform: `translateX(${THUMB_MARGIN_ENABLED}px)`,
  willChange: "transform",
  '&[data-state="checked"]': {
    transform: `translateX(${ROOT_WIDTH - THUMB_SIZE - THUMB_MARGIN_DISABLED}px)`,
  },

  boxShadow: "0px 1px 2px $colors$blackA24",
});
const Root = styled(SwitchPrimitive.Root, {
  all: "unset",
  width: ROOT_WIDTH,
  minWidth: ROOT_WIDTH,
  height: 22,
  backgroundColor: "$gray500",
  borderRadius: "9999px",
  position: "relative",
  cursor: "pointer",
  transition: "all 0.15s",

  "&:hover": {
    backgroundColor: "$gray300",
    [`${Thumb}`]: {
      borderColor: "$gray300",
    },
  },

  "&:focus": {
    backgroundColor: "$gray400",
    [`${Thumb}`]: {
      borderColor: "$gray400",
    },
  },

  "&:disabled": {
    opacity: 0.4,
    cursor: "initial",
  },

  '&[data-state="checked"]': {
    backgroundColor: "$green700",
    "&:hover": { backgroundColor: "$greenalpha" },
    "&:focus": { backgroundColor: "$green600" },

    [`${Thumb}`]: {
      borderColor: "#73E6A1",
      boxShadow: "0px 1px 2px $colors$mauve800A24",
    },
  },
});

const SwitchButton = ({
  switchCss,
  thumbCss,
  ...props
}: React.ComponentProps<typeof Root> & {
  switchCss?: Stitches.CSS;
  thumbCss?: Stitches.CSS;
}) => (
  <Root css={switchCss} {...props}>
    <Thumb css={thumbCss} />
  </Root>
);

export const Switch = ({
  labelPosition = "left",
  children,
  switchCss,
  thumbCss,
  css,
  ...props
}: React.ComponentProps<typeof SwitchButton> & {
  labelPosition?: "left" | "right";
  switchCss?: Stitches.CSS;
  thumbCss?: Stitches.CSS;
}) => {
  const inDialog = useInDialogContext();
  return (
    <StyledLabel inDialog={inDialog} css={css}>
      {labelPosition === "left" && children}

      <SwitchButton switchCss={switchCss} thumbCss={thumbCss} {...props} />

      {labelPosition === "right" && children}
    </StyledLabel>
  );
};
