import { Button, ButtonGroup, AlertDialog } from "@puzzle/ui";
import { useDeleteLinkMutation } from "components/dashboard/Transactions/graphql.generated";
import Analytics from "lib/analytics";
import React, { useCallback } from "react";

type UnlinkTransactionModalProps = React.ComponentPropsWithoutRef<typeof AlertDialog> & {
  companyId: string;
  transactionId: string;
};

export const UnlinkTransactionModal = ({
  companyId,
  transactionId,
  open,
  onOpenChange,
  ...props
}: UnlinkTransactionModalProps) => {
  const [deleteLinkMutation] = useDeleteLinkMutation({
    onCompleted() {
      onOpenChange?.(false);
      Analytics.transactionUnlinked({ transactionId });
    },
    update: (cache, { data }) => {
      const transaction = data?.deleteLink.link.sentTransaction;
      const linkedTransaction = data?.deleteLink.link.receivedTransaction;
      cache.modify({
        id: cache.identify(transaction!.detail),
        fields: {
          linkedTransaction: () => transaction!.detail.linkedTransaction,
        },
      });
      cache.modify({
        id: cache.identify(linkedTransaction!.detail),
        fields: {
          linkedTransaction: () => linkedTransaction!.detail.linkedTransaction,
        },
      });
    },
  });

  const handleUnlink = useCallback(async () => {
    const input = { companyId, transactionId };
    await deleteLinkMutation({
      variables: { input },
    });
  }, [deleteLinkMutation, transactionId, companyId]);

  return (
    <AlertDialog
      size="medium"
      {...props}
      open={open}
      onOpenChange={onOpenChange}
      onClick={(e) => e.stopPropagation()}
    >
      <AlertDialog.Title> Are you sure you want to unlink?</AlertDialog.Title>
      <AlertDialog.Body css={{ textVariant: "$bodyS" }}>
        <p>
          Puzzle automatically links transactions that reflect the same underlying activity in
          different accounts (e.g. credit card payments, bank transfers) based on transaction
          amounts and dates.
        </p>
        <p>
          If linking is incorrect, you can unlink the transactions and change the category of the
          transactions. The transactions cannot be re-linked.
        </p>
      </AlertDialog.Body>
      <AlertDialog.Footer>
        <ButtonGroup>
          <Button variant="secondary" onClick={() => onOpenChange?.(false)}>
            Cancel
          </Button>
          <Button variant="negative" onClick={handleUnlink}>
            Unlink
          </Button>
        </ButtonGroup>
      </AlertDialog.Footer>
    </AlertDialog>
  );
};
