import React, { useMemo, useState } from "react";
import { Button, styled } from "@puzzle/ui";
import { useActiveCompany } from "components/companies";
import {
  IngressType,
  IntegrationConnectionCondition,
  IntegrationConnectionStatus,
  IntegrationType,
} from "graphql/types";
import CardIcon from "@mui/material/ListItemIcon";
import { FinancialInstitutionGroup, IntegrationCategory } from "../setup/types";
import AccountListItem from "./AccountListItem";
import {
  getConnectionStatusForAccount,
  getIntegrationTypeForAccount,
  hasIndeterminateConnection,
} from "./utils";
import InstitutionLogo from "../bank/InstitutionLogo";
import Link from "components/common/Link";

import { Exclamation } from "@puzzle/icons";
import Loader from "../../common/Loader";
import { CalendarDateTimeString, parseAbsoluteToLocal, useLocalDateFormatter } from "@puzzle/utils";
import useAppRouter from "lib/useAppRouter";
import { ConnectionSubText } from "../DetailDrawer/shared";
import { SelectStartDateModal } from "../setup/modals/SelectStartDateModal";
import { Route } from "lib/routes";

const Container = styled("div", {
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "relative",
});

const AccountsList = styled(Container, {
  marginTop: "$1",
});

const Header = styled("div", {
  display: "flex",
  alignItems: "center",
  width: "100%",
  position: "relative",

  textStyle: "$headingS",
  fontWeight: "$heavy",
  color: "$gray200",
  flexDirection: "row",
  justifyContent: "space-between",

  marginBottom: "$2",

  variants: {
    disabled: {
      true: { color: "$gray500" },
      false: { color: "$gray200" },
    },
  },
});

const Icon = styled(CardIcon, {
  height: 54,
  width: 54,
  margin: "0 2 0 0",
  marginRight: "$2",

  variants: {
    disabled: {
      true: { filter: "grayscale(100%)" },
    },
  },
});

const Card = styled("div", {
  border: "1px solid $rhino750",
  borderRadius: "$2",
  width: "100%",
  padding: "$3",
  marginBottom: "$0",
  display: "flex",
  flexDirection: "column",
});

const CheckInboxCard = styled("div", {
  background: "$mauve950",
  border: "1px solid $mauve550",
  borderRadius: "$1",
  maxWidth: 900,
  padding: "$2h",
  marginTop: "$1",
});

const Title = styled("div", {
  display: "flex",
  textVariant: "$headingM",
  flexDirection: "row",
});

const TitleInner = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "flex-start",
});

const Action = styled("div", {
  width: "100%",
  textAlign: "right",
  alignSelf: "flex-end",
});

type IntegrationConnectionInfo = {
  integrationConnectionId?: string;
  status: IntegrationConnectionStatus;
  type: IntegrationType;
  lastSyncedAt?: CalendarDateTimeString | null;
  financialInstitutionId?: string;
  condition?: IntegrationConnectionCondition | null;
  ingressType?: IngressType;
};

const FinancialInstitutionCard = ({
  integrationCategory,
  financialInstitution,
  showFullContent,
}: {
  integrationCategory: IntegrationCategory;
  financialInstitution: FinancialInstitutionGroup;
  showFullContent: boolean;
}) => {
  const {
    company,
    integrationConnections: allIntegrationConnections,
    timeZone,
  } = useActiveCompany<true>();
  const { router, isIntegrationRoute, getCurrentRoute } = useAppRouter();
  const [openSelectDate, onOpenSelectDateChange] = useState(false);

  const dateFormatter = useLocalDateFormatter({ month: "short", day: "numeric", year: "numeric" });
  const timeFormatter = useLocalDateFormatter({ timeStyle: "short" });

  const {
    financialInstitutionName,
    accounts,
    integrationConnections: connectionsForFinancialInstitution,
  } = financialInstitution;
  const testId = `financial-institution-section-${financialInstitutionName}`;
  const testIdTitle = `financial-institution-title-${financialInstitutionName}`;

  const isArchived = accounts.length > 0 && accounts.every((account) => !!account.archivedAt);
  const hasActiveAccounts =
    accounts.length > 0 &&
    accounts.some(
      (account) => !account.archivedAt && account.status === IntegrationConnectionStatus.Ok
    );

  const sortedAccounts = useMemo(() => {
    return accounts.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }, [accounts]);

  const connectionInfo: IntegrationConnectionInfo | undefined = useMemo(() => {
    const integratedAccount =
      accounts.find((account) => account.manuallyAdded === false) ?? undefined;

    if (integratedAccount) {
      let connection = integratedAccount.connection;

      if (!connection) {
        const integrationType = getIntegrationTypeForAccount(integratedAccount);
        const possibleConnections = allIntegrationConnections.filter(
          (ic) => ic.type === integrationType
        );

        if (possibleConnections.length === 1) {
          connection = possibleConnections[0];
        }
      }

      return {
        ...connection,
        integrationConnectionId: connection?.id,
        status: connection?.status ?? getConnectionStatusForAccount(integratedAccount),
        type: connection?.type ?? getIntegrationTypeForAccount(integratedAccount),
        financialInstitutionId: integratedAccount.financialInstitution.id,
        condition: connection?.condition ?? null,
        ingressType: connection?.ingressType,
      };
    } else if (
      connectionsForFinancialInstitution.length === 1 &&
      connectionsForFinancialInstitution[0].type !== IntegrationType.DirectIngest
    ) {
      const connection = connectionsForFinancialInstitution[0];
      return {
        ...connection,
        integrationConnectionId: connection?.id,
      };
    }
    return undefined;
  }, [accounts, connectionsForFinancialInstitution, allIntegrationConnections]);

  const connectionSubDetail = useMemo(() => {
    const connectionStatus: IntegrationConnectionStatus | undefined = connectionInfo?.status;

    if (connectionStatus === IntegrationConnectionStatus.Error) {
      return (
        <ConnectionSubText status={hasActiveAccounts ? "warning" : "ok"}>
          <Exclamation /> Temporary outage, no action needed.
        </ConnectionSubText>
      );
    }
    if (connectionStatus === IntegrationConnectionStatus.Disconnected) {
      return (
        <ConnectionSubText status={hasActiveAccounts ? "error" : "ok"}>
          <Exclamation /> Your integration is disconnected.
        </ConnectionSubText>
      );
    }

    if (connectionStatus === IntegrationConnectionStatus.Ok) {
      // Only show this in integrations page, not onboarding
      if (
        showFullContent &&
        connectionInfo?.condition === IntegrationConnectionCondition.WaitingForUserEpoch
      ) {
        return (
          <ConnectionSubText status="error">
            <Link underline onClick={() => onOpenSelectDateChange(true)}>
              <Exclamation /> Set allowable date range to sync
            </Link>
          </ConnectionSubText>
        );
      }

      if (connectionInfo?.lastSyncedAt) {
        const shouldShowWarning = hasIndeterminateConnection(
          {
            lastSyncedAt: connectionInfo.lastSyncedAt,
            ingressType: connectionInfo.ingressType ?? IngressType.Pull,
            status: connectionInfo.status,
          },
          timeZone
        );

        const dateString = `${dateFormatter.format(
          parseAbsoluteToLocal(connectionInfo.lastSyncedAt)
        )} · ${timeFormatter.format(parseAbsoluteToLocal(connectionInfo.lastSyncedAt))}`;

        return (
          <ConnectionSubText status={shouldShowWarning ? "warning" : "ok"}>
            {shouldShowWarning && <Exclamation />} Last synced {dateString}
          </ConnectionSubText>
        );
      }
    }

    return <></>;
  }, [connectionInfo, hasActiveAccounts, timeZone, dateFormatter, timeFormatter]);

  const showConnectionDetails = (
    integrationConnectionId: string,
    financialInstitutionId?: string
  ) => {
    const integrationPath = ["connection", integrationConnectionId];

    if (financialInstitutionId) {
      integrationPath.push(financialInstitutionId);
    }

    router.push(
      {
        pathname: "/integrations/[[...integrationPath]]",
        query: {
          integrationPath,
        },
      },
      undefined,
      { shallow: isIntegrationRoute(getCurrentRoute()) } // Shallow breaks routing in from inbox tasks
    );
  };

  return (
    <>
      <Card data-testid={testId}>
        {!financialInstitution ? (
          <Loader />
        ) : (
          <Container>
            <Header disabled={isArchived}>
              <Title>
                <Icon disabled={isArchived}>
                  <InstitutionLogo
                    institution={{ name: financialInstitutionName }}
                    circular={true}
                  />
                </Icon>
                <TitleInner>
                  <span data-testid={testIdTitle}>{financialInstitutionName}</span>
                  {showFullContent && !isArchived && <Action>{connectionSubDetail}</Action>}
                </TitleInner>
              </Title>
              {showFullContent && connectionInfo?.integrationConnectionId && !isArchived && (
                <Button
                  variant="secondary"
                  shape="pill"
                  onClick={() => {
                    showConnectionDetails(
                      connectionInfo.integrationConnectionId ?? "",
                      connectionInfo.financialInstitutionId
                    );
                  }}
                >
                  {connectionInfo?.status === IntegrationConnectionStatus.Ok
                    ? "Manage integration"
                    : "Reconnect"}
                </Button>
              )}
            </Header>

            {financialInstitutionName === IntegrationType.QuickBooks &&
              !company.userProposedStartIngestionDate && (
                <CheckInboxCard>
                  To begin the process of bringing your historical books into Puzzle, check{" "}
                  <Link css={{ color: "$green600" }} href={Route.inbox}>
                    {" "}
                    your inbox
                  </Link>
                  .
                </CheckInboxCard>
              )}
            {sortedAccounts.length > 0 && (
              <AccountsList>
                {sortedAccounts.map((account) => (
                  <AccountListItem
                    key={account.id}
                    account={account}
                    companyId={company.id}
                    showFullContent={showFullContent}
                  />
                ))}
              </AccountsList>
            )}

            {sortedAccounts.length === 0 && integrationCategory === IntegrationCategory.Other && (
              <ConnectionSubText status="warning">
                <Exclamation /> No accounts received
              </ConnectionSubText>
            )}
            {financialInstitutionName === IntegrationType.Stripe && (
              <CheckInboxCard>
                Note: Puzzle currently supports USD invoices.{" "}
                <Link
                  css={{ color: "$green600" }}
                  href={
                    "https://help.puzzle.io/en/articles/9797533-foreign-currency-invoices-in-stripe"
                  }
                >
                  Learn more here
                </Link>
              </CheckInboxCard>
            )}
          </Container>
        )}
      </Card>

      <SelectStartDateModal
        open={openSelectDate}
        companyId={company.id}
        accounts={accounts}
        onOpenChange={onOpenSelectDateChange}
        isReconnect={true}
        connectionId={connectionInfo?.integrationConnectionId ?? ""}
        accountsLoading={accounts.length === 0 ?? false}
      />
    </>
  );
};

export default FinancialInstitutionCard;
