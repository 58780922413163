import * as Types from '../types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
export type CustomerFragment = { __typename?: 'Customer', id: string, name?: string | null, email?: string | null };

export const CustomerFragmentDoc = gql`
    fragment customer on Customer {
  id
  name
  email
}
    `;