import React, { useCallback, useMemo } from "react";
import { styled, Autocomplete, Button } from "@puzzle/ui";

import Loader from "components/common/Loader";

import { useActiveCompany, PricingFeatures } from "components/companies";
import { FullTransactionFragment, TransactionPageUserFragment } from "../graphql.generated";
import useAssignments from "../hooks/useAssignments";
import useTransactionPageUsers from "../hooks/useTransactionPageUsers";
import DescriptionList from "components/common/DescriptionList";
import DropdownField from "components/transactions/DropdownField";
import useSelf from "components/users/useSelf";
import { FeatureGate } from "components/featureGate/FeatureGate";
import UpgradeButton from "components/featureGate/UpgradeButton";

const Text = styled("div", {
  fontSize: "14px",
  color: "$white",
});

const IsRequestingRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "$1",
});

const CurrentAssignmentContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$2",
  color: "$gray400",

  strong: {
    color: "$white",
  },
});

const AssignmentInfo = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  whiteSpace: "pre",
});

const AssignmentActions = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "$1",
});

export const AssignmentsSection = React.memo(function AssignmentsSection({
  transaction,
}: {
  transaction: FullTransactionFragment;
}) {
  const { company } = useActiveCompany<true>();
  const { self } = useSelf();
  const { activeUsers } = useTransactionPageUsers({ companyId: company.id });

  const {
    currentAssignment,
    pendingAssignment,
    completeAssignmentLoading,
    cancelAssignmentLoading,
    assignUserLoading,
    saveAssignment,
    updateAssignmentText,
    defaultRequests,
    setCurrentlyAssigningTo,
    startNewAssignment,
    markAssignmentAsComplete,
    markAssignmentAsCanceled,
  } = useAssignments(activeUsers, transaction);

  const confirmAssignment = useCallback(() => {
    saveAssignment();
  }, [saveAssignment]);

  const handleAssignmentComplete = useCallback(async () => {
    await markAssignmentAsComplete();
  }, [markAssignmentAsComplete]);

  const handleAssignmentCancel = useCallback(async () => {
    await markAssignmentAsCanceled();
  }, [markAssignmentAsCanceled]);

  const content = useMemo(() => {
    if (!(completeAssignmentLoading || cancelAssignmentLoading) && currentAssignment) {
      // TODO: Can possibly use optimisticResponse during mutations here instead of loader?
      return (
        <CurrentAssignmentContainer>
          <AssignmentInfo>
            {currentAssignment.createdByUser ? currentAssignment.createdByUser.name : "System"}{" "}
            requested <strong>{currentAssignment.request}</strong> from{" "}
            <strong>{currentAssignment.targetUser?.name}</strong>
          </AssignmentInfo>

          <AssignmentActions>
            <Button variant="primary" size="small" onClick={handleAssignmentComplete}>
              Completed
            </Button>
            <Button variant="secondary" size="small" onClick={handleAssignmentCancel}>
              Unassign
            </Button>

            <Button
              variant="secondary"
              size="small"
              onClick={async () => {
                await handleAssignmentComplete();
                startNewAssignment("review", currentAssignment.createdByUser ?? self!);
              }}
            >
              Request a Review
            </Button>
          </AssignmentActions>
        </CurrentAssignmentContainer>
      );
    }

    return (
      // TODO implement onClickOutside and call clearPendingAssignment
      <div>
        {completeAssignmentLoading || cancelAssignmentLoading || assignUserLoading ? (
          <Loader size={32} />
        ) : (
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <IsRequestingRow>
              <Text>Request</Text>

              <FeatureGate
                pricingFeature={PricingFeatures.collaboration_tools_assignments}
                gate={<UpgradeButton />}
              >
                <DropdownField<{ request: string; id: string }>
                  items={defaultRequests}
                  onSelect={(r) => r && updateAssignmentText(r?.request)}
                  canEdit={true}
                  onCreate={(r) => updateAssignmentText(r)}
                  value={
                    pendingAssignment
                      ? { request: pendingAssignment.request, id: pendingAssignment.request }
                      : null
                  }
                  getOptionLabel={(v) => v?.request || ""}
                  getFreeSoloOption={(name) => ({ request: name, id: name })}
                />

                <Text>from</Text>

                <Autocomplete<TransactionPageUserFragment, false, false, false>
                  size="small"
                  // email is nullable for ...reasons?
                  placeholder="Select someone"
                  getOptionLabel={(option) => option.name || option.email || ""}
                  getOptionKey={(o) => o.email || ""}
                  onChange={(e, value) =>
                    value ? setCurrentlyAssigningTo(value) : setCurrentlyAssigningTo(undefined)
                  }
                  value={pendingAssignment.targetUser}
                  options={activeUsers}
                />

                <Button
                  variant="secondary"
                  size="small"
                  disabled={assignUserLoading}
                  onClick={confirmAssignment}
                >
                  Send
                </Button>
              </FeatureGate>
            </IsRequestingRow>
          </div>
        )}
      </div>
    );
  }, [
    completeAssignmentLoading,
    cancelAssignmentLoading,
    currentAssignment,
    assignUserLoading,
    defaultRequests,
    pendingAssignment,
    activeUsers,
    confirmAssignment,
    handleAssignmentComplete,
    handleAssignmentCancel,
    startNewAssignment,
    updateAssignmentText,
    setCurrentlyAssigningTo,
    self,
  ]);

  return (
    <DescriptionList
      // horizontal is awkward; intended for 3 items, not 4
      direction="vertical"
      itemGap="1h"
      items={[["Assignments", content]]}
    />
  );
});
