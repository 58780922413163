import React, { Fragment, ReactNode, useEffect, useMemo } from "react";
import { styled, Input, CurrencyInput, Button, IconButton, DateInput, Tooltip } from "@puzzle/ui";
import { formatMoney } from "@puzzle/utils";
import { FullTransactionFragment } from "../graphql.generated";
import UpdateCategoryMenu from "../UpdateField/UpdateCategoryMenu";
import { Split, Add, Edit, Close, Clear } from "@puzzle/icons";
import { NumberFormatValues } from "react-number-format";
import SelectCategoryInput from "components/transactions/SelectCategoryInput";
import useEditSplits, { SplitInput } from "../hooks/useEditSplits";
import { EditSplitCategoryCell } from "../Transactions";
import {
  CategoryFragment,
  ClassAwareType,
  IntegrationType,
  TransactionDetailActorType,
} from "graphql/types";
import { useActiveCompany, useCompanyDateFormatter } from "components/companies";
import { CalendarDate, parseDate, toCalendarDate } from "@internationalized/date";
import { TriggerLastMileButton } from "../AI/TriggerLastMileButton";
import { useDetailPaneContext } from "./DetailPaneContext";
import { AskAIAboutTransactionButton } from "../AI/AskAIAboutTransactionButton";
import {
  UpdateCategoryMetricsLocations,
  UpdateCategoryMetricsView,
} from "../hooks/useSingleTransaction";
import { useEffectOnFirstRender } from "components/common/reactHooks";
import ClassificationsSection from "components/common/Classifications/ClassificationsSection";
import { IS_DEV } from "lib/config";
import { Box, S } from "ve";

const Editor = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$2",
});

const DisplayText = styled("div", {
  fontSize: "13px",
  color: "$gray100",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const LightDisplayText = styled(DisplayText, {
  color: "$gray300",
});

const Actions = styled("div", {
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  textAlign: "right",
  gap: "$2",
  borderTop: "1px solid #44445C",
  paddingTop: "$2",
});

const ListActions = styled("div", {
  display: "flex",
  flexDirection: "horizontal",
  justifyContent: "space-between",
});

const Amount = styled("span");

const Remainder = styled("div", {
  color: "gray400",
  paddingRight: "$4",

  [`${Amount}`]: {
    color: "$red500",
  },
});

const SplitsRowContainer = styled("div", {
  position: "relative",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "$1",

  borderTop: "1px solid #44445C",
  paddingTop: "$2",
});

const SplitRow = styled("div", {
  position: "relative",
  width: "100%",
  display: "flex",
  gap: "$1",
  alignItems: "center",
});

const Splits = styled("div", {
  position: "relative",
  width: "100%",
  display: "grid",
  gridAutoFlow: "row",
  columnGap: "$1",
  rowGap: "$1",
  alignItems: "center",
  gridTemplateColumns: "min-content auto 56px 108px $space$3",
  alignContent: "center",
  border: "1px solid #44445C",
  borderRadius: "$1",
  padding: "$1",
});

const Container = styled("div", {
  display: "flex",
  alignItems: "flex-start",
  gap: "$1",
  flexDirection: "column",
});

interface SplitsSectionProps {
  transaction: FullTransactionFragment;
  categories: CategoryFragment[];
  canEdit: boolean;
}

export const EditSplitsSection = ({
  transaction,
  categories,
  canEdit: _canEdit,
}: SplitsSectionProps) => {
  const { isWithinLockedPeriod } = useActiveCompany<true>();
  const {
    splitRemainder,
    addSplit,
    splits,
    removeSplit,
    persistSplits: _persistSplits,
    updateSplitAmount,
    updateSplitCategory,
    updateSplitDescriptor,
    updateSplitAccrualDate,
    canSave,
    resetSplits,
    deleteSplits: _deleteSplits,
    startSplit,
  } = useEditSplits(transaction);
  const { isEditingSplit, toggleEditingSplit, splitsOpenByDefault } = useDetailPaneContext();
  const dateFormatter = useCompanyDateFormatter({ dateStyle: "short" });

  const scrollToBottomOfActivity = () => {
    // use setTimeout out so that by the time we scroll down,
    // the "AI response loading" placeholder activity element is already
    // there
    setTimeout(() => {
      document.getElementById("activity-footer")?.scrollIntoView({
        behavior: "smooth",
      });
    }, 100);
  };

  const showLockWarning = useMemo(
    () =>
      transaction &&
      isWithinLockedPeriod(parseDate(transaction.date)) &&
      !transaction.detail.postedAt,
    [isWithinLockedPeriod, transaction]
  );

  const canEdit = _canEdit && !showLockWarning;

  useEffect(() => {
    if (!isEditingSplit) {
      resetSplits();
    }
  }, [isEditingSplit, resetSplits]);

  const deleteSplits = () => {
    _deleteSplits();
    toggleEditingSplit();
  };
  const persistSplits = (splits: SplitInput[]) => {
    _persistSplits(splits);
    toggleEditingSplit();
  };

  const handleUpdateCategory = (id: string) => (c: CategoryFragment) => updateSplitCategory(id, c);

  const handleUpdateDescriptor = (id: string) => (e: React.ChangeEvent<{ value: string }>) =>
    updateSplitDescriptor(id, e.target.value);

  const handleUpdateAmount = (id: string) => (values?: NumberFormatValues) =>
    updateSplitAmount(id, values?.value);

  const handleStartSplitTransaction = () => {
    startSplit();
    toggleEditingSplit();
  };

  const handleRemoveSplit = (id: string) => () => {
    if (splits.length === 1) {
      deleteSplits();
    } else {
      removeSplit(id);
    }
  };

  useEffectOnFirstRender(() => {
    // If splits shouldn't be open on mount or user can't edit, stop
    if (!splitsOpenByDefault || !canEdit) return;

    // No splits yet, start split
    if (transaction.splits.length === 0) {
      handleStartSplitTransaction();
      return;
    }

    // Already has splits, start edit
    toggleEditingSplit();
  });

  if (isEditingSplit) {
    return (
      <Editor>
        <SplitsRowContainer>
          {splits.map((s, i) => (
            <Fragment key={i}>
              <SplitRow css={{ marginTop: "$1h" }}>
                <SelectCategoryInput
                  value={s.category}
                  categories={categories}
                  onChange={handleUpdateCategory(s.id)}
                  canEdit
                  tooltipProps={{
                    align: "center",
                    side: "left",
                  }}
                  maxWidth={108}
                />
                <Input
                  autoFocus={i === 0 && splitsOpenByDefault}
                  value={s.descriptor}
                  placeholder={`Description`}
                  aria-label={`Description for split ${i}`}
                  onChange={handleUpdateDescriptor(s.id)}
                  size="small"
                />
                {!transaction.linkedBills.length && (
                  <AccrualDateInput
                    transaction={transaction}
                    accrualDate={s.accrualDate && parseDate(s.accrualDate)}
                    onChange={(accrualDate) => {
                      updateSplitAccrualDate(s.id, accrualDate);
                    }}
                    onClear={() => {
                      updateSplitAccrualDate(s.id, null);
                    }}
                  />
                )}
                <CurrencyInput
                  truncateWhenInactive={false}
                  value={s.amount}
                  aria-label={`Value ${i}`}
                  onValueChange={handleUpdateAmount(s.id)}
                  thousandSeparator
                  prefix="$"
                  size="small"
                  width={128}
                  css={{
                    flexShrink: 0,
                    width: 128,
                  }}
                />
                <IconButton
                  onClick={handleRemoveSplit(s.id)}
                  size="small"
                  css={{
                    flexShrink: 0,
                  }}
                >
                  <Close size={10} color="currentColor" />
                </IconButton>
              </SplitRow>
              {!s.id.includes("split") ? (
                <Box css={{ marginTop: S["1h"] }}>
                  <ClassificationsSection
                    hideAddButton={
                      transaction.integrationType === IntegrationType.Stripe && !IS_DEV
                    }
                    entity={{
                      id: s.id,
                      name: s.descriptor,
                      type: ClassAwareType.TransactionDetail,
                      classSegments: s.classSegments,
                    }}
                  />
                </Box>
              ) : null}
            </Fragment>
          ))}
        </SplitsRowContainer>

        <ListActions>
          <Button prefix={<Add />} variant="minimal" size="mini" onClick={addSplit}>
            Add split
          </Button>

          {!splitRemainder.eq(0) && (
            <Remainder>
              Remaining:{" "}
              <Amount>{formatMoney({ currency: "USD", amount: splitRemainder.toString() })}</Amount>
            </Remainder>
          )}
        </ListActions>

        <Actions>
          <Button variant="minimal" onClick={toggleEditingSplit} size="compact">
            Cancel
          </Button>

          <Button
            variant="primary"
            disabled={!canSave}
            size="compact"
            onClick={() => {
              persistSplits(splits);
            }}
          >
            Save
          </Button>
        </Actions>
      </Editor>
    );
  }

  return (
    <Container>
      {transaction.splits.length === 0 ? (
        <>
          <Box css={{ display: "flex", alignItems: "center", gap: S["1"] }}>
            <UpdateCategoryMenu
              id={transaction.id}
              categories={categories}
              metrics={{
                location: UpdateCategoryMetricsLocations.TransactionsDrawer,
                component: UpdateCategoryMetricsView.CategoryModal,
              }}
            />
            {canEdit &&
            transaction.detail.actorType &&
            [TransactionDetailActorType.SystemActor, TransactionDetailActorType.AiActor].includes(
              transaction.detail.actorType
            )
              ? [
                  <AskAIAboutTransactionButton
                    key="ask-ai-about-transaction-button"
                    onClick={scrollToBottomOfActivity}
                    transaction={transaction}
                  />,
                  <TriggerLastMileButton
                    key="trigger-last-mile-button"
                    onClick={scrollToBottomOfActivity}
                    transaction={transaction}
                  />,
                ]
              : null}
          </Box>

          {canEdit && (
            <Button
              variant="minimal"
              onClick={handleStartSplitTransaction}
              size="small"
              prefix={<Split />}
            >
              Split Transaction
            </Button>
          )}
        </>
      ) : (
        <Splits>
          {transaction.splits.map(
            (s, i): ReactNode =>
              s.category && (
                <Fragment key={s.id}>
                  <EditSplitCategoryCell
                    data={{
                      transactionId: transaction.id,
                      detailId: s.id,
                      splitIndex: i,
                    }}
                  />
                  <LightDisplayText title={s.descriptor}>{s.descriptor}</LightDisplayText>
                  <LightDisplayText title={s.descriptor}>
                    {s.accrualDate && dateFormatter.format(parseDate(s.accrualDate))}
                  </LightDisplayText>
                  <DisplayText css={{ justifySelf: "flex-end" }}>
                    {formatMoney({ currency: "USD", amount: s.amount ?? 0 })}
                  </DisplayText>
                  {canEdit && i === 0 ? (
                    <IconButton
                      onClick={toggleEditingSplit}
                      css={{ justifySelf: "flex-end", alignSelf: "flex-start" }}
                    >
                      <Edit color="currentColor" />
                    </IconButton>
                  ) : (
                    <div />
                  )}
                  {transaction.integrationType === IntegrationType.Stripe && !IS_DEV ? null : (
                    <Box css={{ margin: `${S["1h"]} ${S["1"]}`, gridColumn: "1 / -1" }}>
                      <ClassificationsSection
                        entity={{
                          id: s.id,
                          name: s.descriptor,
                          type: ClassAwareType.TransactionDetail,
                          classSegments: s.classSegments,
                        }}
                      />
                    </Box>
                  )}
                </Fragment>
              )
          )}
        </Splits>
      )}
    </Container>
  );
};

const AccrualDateInput = ({
  transaction,
  accrualDate,
  onChange,
  onClear,
  ...props
}: {
  transaction: FullTransactionFragment;
  accrualDate?: CalendarDate | null;
  onChange: (date: CalendarDate | null) => void;
  onClear: React.MouseEventHandler<HTMLButtonElement>;
  css?: React.CSSProperties;
}) => {
  const { lockedPeriodDate } = useActiveCompany<true>();

  return (
    <Tooltip open={accrualDate ? false : undefined} content="Accrual date" arrow={false}>
      <Box {...props}>
        <div style={{ position: "relative" }}>
          <DateInput
            value={accrualDate}
            onChange={(value) => onChange(value && toCalendarDate(value))}
            hideIcon={!!accrualDate}
            inputReadOnly
            minDate={lockedPeriodDate && parseDate(lockedPeriodDate.toString()).add({ days: 1 })}
            maxDate={parseDate(transaction.date)}
            css={{
              width: accrualDate ? undefined : 41,
              height: 35,
              paddingRight: "$2h",
              overflow: "hidden",
            }}
          />
          {accrualDate && (
            <IconButton
              onClick={onClear}
              size="small"
              css={{
                position: "absolute",
                top: 10,
                right: 6,
              }}
            >
              <Clear size={12} color="currentColor" />
            </IconButton>
          )}
        </div>
      </Box>
    </Tooltip>
  );
};
