/* eslint-disable react/display-name */
import React, { useCallback, useState } from "react";
import {
  NumericFormat,
  NumericFormatProps,
  PatternFormat,
  PatternFormatProps,
} from "react-number-format";
import { formatMoney } from "@puzzle/utils";
import { CSSProps } from "../stitches";
import { Input } from "./Input";

type NumberInputProps = Omit<NumericFormatProps, "size"> &
  Omit<React.ComponentPropsWithoutRef<typeof Input>, "value" | "defaultValue"> &
  CSSProps & {
    textAlign?: "right" | "left";
  };

export const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  ({ css, size, textAlign, value, ...props }, ref) => {
    return (
      <NumericFormat
        // @ts-expect-error ignore
        customInput={Input}
        // @ts-expect-error ignore
        size={size}
        css={css}
        // The above is specific to Input props
        // Before, customInput was a function which generated new elements when css changed
        style={{ textAlign }}
        getInputRef={ref}
        type="text"
        value={value === "-0" ? "-" : value}
        {...props}
        onValueChange={(values, sourceInfo) => {
          // handle weird edge case while removing digits from a negative value.
          const isHyphen = values.value === "-";

          props.onValueChange?.(
            {
              value: isHyphen ? "-0" : values.value,
              formattedValue: isHyphen ? "-0" : values.formattedValue,
              floatValue: isHyphen ? -0 : values.floatValue,
            },
            sourceInfo
          );
        }}
      />
    );
  }
);

type PatternInputProps = Omit<PatternFormatProps, "size"> &
  Omit<React.ComponentPropsWithoutRef<typeof Input>, "value" | "defaultValue"> &
  CSSProps & {
    textAlign?: "right" | "left";
  };
// react-number-format v5 split this into a separate component. Excuse the copypasta.
export const NumberPatternInput = React.forwardRef<HTMLInputElement, PatternInputProps>(
  ({ css, size, textAlign, ...props }, ref) => {
    const CustomInput = useCallback(
      (p: Record<string, unknown>) => <Input {...p} size={size} css={css} />,
      [css, size]
    );

    return (
      <PatternFormat
        style={{ textAlign }}
        getInputRef={ref}
        customInput={CustomInput}
        type="text"
        {...props}
      />
    );
  }
);

// NOTE: Only for USD
export const CurrencyInput = React.forwardRef<
  HTMLInputElement,
  NumberInputProps & {
    // We should probably separate truncating behavior from formatDollarAmount?
    truncateWhenInactive?: boolean;
  }
>(({ truncateWhenInactive = true, ...props }, ref) => {
  const [active, setActive] = useState(false);

  const onFocus = useCallback<React.FocusEventHandler<HTMLInputElement>>(
    (e) => {
      if (props.readOnly) {
        return;
      }
      setActive(true);
      props.onFocus?.(e);
    },
    [props]
  );

  const onBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>(
    (e) => {
      setActive(false);
      props.onBlur?.(e);
    },
    [props]
  );

  // We can't use formatDollarAmount while editing because of this:
  // https://github.com/s-yadav/react-number-format/issues/438
  // There's a stale PR that implements parentheses for react-number-format
  // https://github.com/s-yadav/react-number-format/issues/252
  //
  // Unfortunately it may never get merged. For now we swap out the input,
  // which makes it impossible to click into a specific character. Not ideal.
  // Look into alternatives or forking. Maybe highlight the whole text instead of reaching the end?
  if (!active) {
    return (
      <Input
        /**
         * Using the same refs for different outputs is buggy, at least with react-hook-form.
         * It retains the input value from when it was active, until a re-render overrides it.
         * This shouldn't cause bugs... but it's not ideal.
         */
        // ref={ref}
        css={props.css}
        style={{
          textAlign: props.textAlign,
          ...props.style,
        }}
        rootProps={props.rootProps}
        tabIndex={props.readOnly ? -1 : props.tabIndex}
        size={props.size}
        placeholder={props.placeholder}
        hideInitialBorder={props.hideInitialBorder}
        value={
          typeof props.value !== "undefined" && props.value !== ""
            ? formatMoney(
                {
                  currency: "USD",
                  amount: props.value || 0,
                },
                { truncateValue: truncateWhenInactive }
              )
            : undefined
        }
        readOnly={props.readOnly}
        onFocus={onFocus}
      />
    );
  }

  return (
    <NumberInput
      placeholder="$0"
      thousandSeparator={true}
      prefix="$"
      valueIsNumericString
      decimalScale={2}
      allowLeadingZeros={false}
      // Don't force a decimal scale when the input is active.
      // Deleting the whole string causes the cursor to move at the end when you type a new value.
      // This started happening in react-number-format v5
      fixedDecimalScale={!active && props.value ? !Number.isInteger(props.value) : false}
      {...props}
      ref={ref}
      autoFocus={active}
      onBlur={onBlur}
    />
  );
});
