/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import * as Stitches from "@stitches/react";
import { rgba } from "polished";

import { CaretDown } from "@puzzle/icons";
import { colors } from "@puzzle/theme";

import { styled } from "./stitches";
import { Spinner, SpinnerProps } from "./Spinner";

const ButtonBase = styled("button", {
  unstyled: true,
  textDecoration: "none",
  cursor: "pointer",
  fontFamily: "$inter",
  whiteSpace: "nowrap",

  position: "relative",
  width: "fit-content",
  height: "fit-content",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "$1",

  fontWeight: "$bold",
  fontSize: "14px",
  lineHeight: "16px",
  letterSpacing: "0.2px",

  backgroundColor: "transparent",

  transition: "all 0.1s ease-in",

  defaultVariants: {
    variant: "primary",
    size: "medium",
    shape: "button",
    loading: false,
    color: undefined,
    wrapText: false,
  },

  "&:disabled, &[aria-disabled=true]": {
    pointerEvents: "none",
  },

  variants: {
    // TODO suffix/prefix should be merged
    // need to represent: icon only, left icon, right icon
    icon: {
      true: {
        lineHeight: 0,
      },
    },

    fullWidth: {
      true: {
        width: "100%",
      },
    },

    // Only paired with secondary/minimal for now
    // Not a fan of the overlap between variant/color, but this is sparingly used and barely defined.
    color: {
      primary: {
        $$color: "$colors$green600",
        $$hoverColor: "$colors$green700",
        $$focusColor: "$colors$green800",
      },

      negative: {
        $$color: "$colors$red600",
        $$hoverColor: "$colors$red700",
        $$focusColor: "$colors$red800",
      },
    },

    variant: {
      primary: {
        backgroundColor: "$green600",
        color: "$black",

        "&:hover": {
          backgroundColor: "$green700",
        },

        "&:focus, &[data-state='open']": {
          backgroundColor: "$green800",
        },

        "&:disabled, &[aria-disabled=true]": {
          color: "$gray500",
          backgroundColor: "$gray300",
          textShadow: `0px 1px 0px ${rgba(colors.white, 0.24)}`,
        },
      },

      negative: {
        backgroundColor: "$red600",
        color: "$gray50",

        "&:hover": {
          backgroundColor: "$red700",
        },

        "&:focus, &[data-state='open']": {
          backgroundColor: "$red800",
        },

        "&:disabled, &[aria-disabled=true]": {
          color: "$gray500",
          backgroundColor: "$gray300",
        },
      },

      secondary: {
        border: "1px solid",
        borderColor: "var(---color, $gray600)",
        color: "var(---color, $gray300)",

        "&:hover": {
          borderColor: "var(---hoverColor, $gray500)",
          color: "var(---hoverColor, $gray100)",
        },

        "&:focus, &[data-state='open']": {
          borderColor: "var(---focusColor, $gray300)",
          color: "var(---focusColor, $gray50)",
        },

        "&:disabled, &[aria-disabled=true]": {
          borderColor: "$gray700",
          color: "$gray600",
        },
      },

      outline: {
        border: "1px solid",
        borderColor: "var(---color, $greenalpha)",
        color: "var(---color, $greenalpha)",

        "&:disabled, &[aria-disabled=true]": {
          borderColor: "$gray700",
          color: "$gray600",
        },
      },

      // TODO rename to tertiary to match figma
      minimal: {
        backgroundColor: "transparent",
        color: "var(---color, $gray300)",

        "&:hover": {
          color: "var(---hoverColor, $gray100)",
          backgroundColor: "rgba(248, 248, 250, 0.04)",
        },

        "&:focus, &[data-state='open']": {
          color: "var(---focusColor, $gray50)",
          backgroundColor: "rgba(248, 248, 250, 0.12)",
        },

        "&:disabled": {
          color: "$gray700",
          textShadow: "none",
        },
      },
    },

    size: {
      mini: {
        padding: "$0h $1",
        fontSize: "12px",
        lineHeight: "14px",
        height: "24px",
      },

      small: {
        padding: "6px $1h",
        fontSize: "13px",
        lineHeight: "14px",
        height: "28px",
      },

      compact: {
        padding: "$1 $2",
        height: "32px",
      },

      medium: {
        padding: "$1h $2",
        height: "40px",
      },

      large: {
        padding: "$1h $5",
        height: "40px",
      },
    },

    shape: {
      button: {
        borderRadius: "$1",
      },

      pill: {
        borderRadius: "40px",
      },
    },

    loading: {
      true: {
        pointerEvents: "none",
      },
      false: {},
    },

    wrapText: {
      true: {
        whiteSpace: "normal",
      },
      false: {},
    },
  },

  compoundVariants: [
    {
      shape: "pill",
      size: "default",
      css: {
        borderRadius: "56px",
      },
    },
  ],
});

const Content = styled("div", {
  width: "fit-content",
  height: "fit-content",
  display: "flex",
  alignItems: "center",
  gap: "$0h",

  variants: {
    hide: {
      true: {
        visibility: "hidden",
      },
      false: {
        visibility: "visible",
      },
    },
  },
});

const Addon = styled("div", {
  lineHeight: 0,

  variants: {
    side: {
      prefix: {
        marginLeft: "-$0h",
      },

      suffix: {
        marginRight: "-$0h",
      },
    },
  },
});

const Text = styled("div", {
  // textAlign: "center",
  "&:only-child": {
    margin: "0 auto",
  },
});

type ButtonVariants = Stitches.VariantProps<typeof ButtonBase>;
type OwnButtonProps = Omit<
  React.HTMLProps<HTMLButtonElement | HTMLAnchorElement>,
  "prefix" | "size"
> &
  ButtonVariants & {
    as?: "button" | "a";
    prefix?: React.ReactElement;
    suffix?: React.ReactElement;
    disabled?: boolean;
    fullWidth?: boolean;
    href?: string;
    wrapText?: boolean;

    // For some reason, using as={} on a styled(Button, {}) will skip Button entirely...
    // Seems safer to just infer "a" from the props for now
    target?: string;
    rel?: string;
    css?: Stitches.CSS;

    // For adding extra css on addons
    prefixCss?: Stitches.CSS;
    suffixCss?: Stitches.CSS;
  };

// WARNING: Composing stitches with wrapper components doesn't work. Use css prop, or export ButtonBase.
export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, OwnButtonProps>(
  ({ as = "button", href, prefix, suffix, children, prefixCss, suffixCss, ...props }, ref) => {
    const showSpinner = props.loading && !props.disabled;
    return (
      <ButtonBase
        {...props}
        as={href ? "a" : as}
        href={href}
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        ref={ref as any}
        // disabled doesn't work on anchors
        aria-disabled={props.disabled}
        // can't leverage disabled because it has its own unique style
        tabIndex={props.loading ? -1 : props.tabIndex}
      >
        <Content hide={showSpinner}>
          {prefix && (
            <Addon side="prefix" css={prefixCss}>
              {prefix}
            </Addon>
          )}
          <Text>{children}</Text>
          {suffix && (
            <Addon side="suffix" css={suffixCss}>
              {suffix}
            </Addon>
          )}
        </Content>
        {showSpinner && <Spinner colors={getSpinnerColors(props.variant)} />}
      </ButtonBase>
    );
  }
);

const getSpinnerColors = (variant: ButtonVariants["variant"]): SpinnerProps["colors"] => {
  if (typeof variant === "undefined" || variant === "primary") {
    return {
      trackColor: colors.gray600,
      thumbColor: colors.black,
    };
  } else if (variant === "negative") {
    return {
      trackColor: colors.white,
      thumbColor: colors.white,
    };
  } else {
    return {
      trackColor: colors.white,
      thumbColor: colors.gray300,
    };
  }
};

Button.toString = ButtonBase.toString;
export default Button;

export const UnstyledButton = styled("button", {
  unstyled: true,
  color: "inherit",
  padding: 0,

  "&:not(:disabled)": {
    cursor: "pointer",
  },
});

const fixedSize = (size: number) => ({
  width: size,
  height: size,

  // We haven't decided standardizing between 14 and 16
  // svg: {
  //   width: 14,
  //   height: "auto",
  // },
});

// TODO Not sure how to approach larger hit areas vs icon sizes.
// Should IconButton sizes resize the SVG and set padding?
export const IconButton = styled(UnstyledButton, {
  lineHeight: 0,
  color: "$gray600",
  cursor: "pointer",

  transition: "all 0.1s ease-in",

  "&:hover, &:focus-visible, &[data-state=open]": {
    color: "$gray300",
  },

  "&:disabled": {
    color: "$gray700",
    cursor: "not-allowed",
  },

  svg: {
    maxWidth: "100%",
    height: "auto",
  },

  defaultVariants: {
    circle: false,
    size: "medium",
  },

  variants: {
    size: {
      "fit-content": {
        width: "fit-content",
        height: "fit-content",
      },

      small: fixedSize(14),

      medium: fixedSize(24),

      compact: fixedSize(28),

      large: fixedSize(32),
    },

    circle: {
      true: {
        color: "$gray300",
        border: "1px solid $gray700",
        borderRadius: "$ellipse",

        "&:hover, &:focus": {
          borderColor: "$gray300",
        },
      },
      false: {},
    },
  },
});

export const MenuButton = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<
    ButtonVariants & {
      disabled?: boolean;
      css?: Stitches.CSS;
    }
  >
>(({ size = "small", variant = "secondary", children, ...props }, ref) => {
  const showSpinner = props.loading && !props.disabled;
  return (
    <ButtonBase size={size} variant={variant} {...props} ref={ref}>
      <Content hide={showSpinner}>
        <Text
          css={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            flex: 1,
            textAlign: "left",
          }}
        >
          {children}
        </Text>
        <Addon side="suffix">
          <CaretDown fill="currentColor" />
        </Addon>
      </Content>
      {showSpinner && <Spinner colors={getSpinnerColors(variant)} />}
    </ButtonBase>
  );
});
MenuButton.toString = ButtonBase.toString;
