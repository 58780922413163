import React, { useMemo, useState } from "react";

import { Menu, IconButton, Button, styled } from "@puzzle/ui";
import { Ellipsis, Columns, Download } from "@puzzle/icons";

import { TransactionReportExportModal } from "components/reports/modals/TransactionReportExportModal";
import { ColumnVisibility, TransactionColHeaderMapping, TransactionColId } from "./shared";
import { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { ColumnVisibilitySwitch } from "components/common/ColumnVisibilitySwitch";

const MenuItem = styled("div", { display: "grid", gridTemplateColumns: "24px auto" });

const Footer = styled("div", {
  padding: "$0h $1",
  display: "flex",
  justifyContent: "flex-end",
});

type Props = {
  columnVisibility: ColumnVisibility;
  setColumnVisibility: React.Dispatch<React.SetStateAction<ColumnVisibility | undefined>>;
};
export const HelpMenu = ({ columnVisibility, setColumnVisibility }: Props) => {
  const [showExportModal, setShowExportModal] = useState(false);

  const renderToggleColMenuItem = (colId: TransactionColId) => {
    return (
      <Menu.Item key={colId}>
        <ColumnVisibilitySwitch
          label={TransactionColHeaderMapping[colId]}
          checked={columnVisibility[colId]}
          onCheckedChange={(checked) => {
            setColumnVisibility({ ...columnVisibility, [colId]: checked });
          }}
          draggable
        />
      </Menu.Item>
    );
  };

  const allCols = useMemo(() => {
    const allKeys = Object.keys(TransactionColId);
    if (!isPosthogFeatureFlagEnabled(FeatureFlag.ClassesAndDeptsCols)) {
      const keysToExclude: string[] = [
        TransactionColId.department,
        TransactionColId.location,
        TransactionColId.classes,
      ];
      return allKeys.filter((key) => !keysToExclude.includes(key));
    }
    if (isPosthogFeatureFlagEnabled(FeatureFlag.ClassesAndDeptsCols)) {
      const keysToExclude: string[] = [TransactionColId.classifications];
      return allKeys.filter((key) => !keysToExclude.includes(key));
    }
    return allKeys;
  }, []);

  return (
    <>
      <Menu
        trigger={
          <IconButton>
            <Ellipsis rotate={90} />
          </IconButton>
        }
      >
        <Menu.Group>
          <Menu
            showSubMenuArrow={false}
            subMenuTrigger={
              <MenuItem>
                <Columns />
                Manage Columns
              </MenuItem>
            }
            sideOffset={4}
          >
            <Menu.Group>
              {allCols.map((col) => {
                return renderToggleColMenuItem(col as TransactionColId);
              })}

              <Menu.Separator />
              <Footer>
                <Button
                  variant="minimal"
                  size="small"
                  onClick={() => {
                    setColumnVisibility({
                      date: true,
                      account: true,
                      vendor_customer: true,
                      descriptor: true,
                      category: true,
                      location: true,
                      department: true,
                      classes: true,
                      classifications: true,
                      status: true,
                      amount: true,
                    });
                  }}
                >
                  Show all
                </Button>
              </Footer>
            </Menu.Group>
          </Menu>
          <Menu.Item onClick={() => setShowExportModal(true)}>
            <MenuItem>
              <Download />
              Export
            </MenuItem>
          </Menu.Item>
        </Menu.Group>
      </Menu>
      <TransactionReportExportModal
        open={showExportModal}
        onConfirm={() => setShowExportModal(false)}
        onCancel={() => setShowExportModal(false)}
        title="Consolidated Transactions"
      />
    </>
  );
};
