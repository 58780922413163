import posthog from "posthog-js";

export enum FeatureFlag {
  SetupChecklist = "setup-checklist",
  FixedAssetsAutoDepreciation = "fixed-assets-auto-depreciation",
  InexactBillMatch = "inexact-bill-match",
  ExpensesDateFeatureGate = "expenses-date-feature-gate",
  ARAging = "ar-aging-by-customer",
  ClassesAndDeptsCols = "classes-and-depts-cols",
  ClassesAndDeptsM1 = "classes-and-depts-m1",
  DashboardSpotlightShowGroupingDropdown = "dashboard-spotlight-show-grouping-dropdown",
  ShowAddonPuzzleAI = "show-addon-puzzleai",
  SubscriptionYearly = "subscription-yearly",
  AutomationSettings = "automation-settings",
  EnableEventBreakout = "enable-event-breakout",
  DashboardGraphsTopRow = "dashboard-graphs-top-row",
  DashboardGraphsSecondRow = "dashboard-graphs-second-row",
  UnlinkBills = "unlink-bills",
  PostOnboardModalV3Invitee = "post-onboard-modal-v3-invitee",
  DashboardShowPercentageChanged = "dashboard-show-percentage-changed",
  InvoicingV2 = "invoicing-v2",
  ApolloCachePersistor = "apollo-cache-persistor",
  OnboardingConsolidatedConnections = "onboarding_consolidated_connections",
  StripeTraceability = "stripe-traceability",
  DashboardChartBurnComparison = "dashboard-chart-burn-comparison",
  TransactionsMRT = "transactions-mrt",
  Payments = "payments",
  RipplingIntegrationConnection = "rippling-integration-connection",
  TransactionsDetailsV2 = "transactions-details-v2",
  VendorsV2 = "vendors-v2",
  SetStripeEpoch = "set-stripe-epoch"
}

// Manually override flags to be enabled on local + dev for testing
export const FLAGS_ENABLED_ON_DEV = [
  FeatureFlag.FixedAssetsAutoDepreciation,
  FeatureFlag.ARAging,
  FeatureFlag.ClassesAndDeptsM1,
  FeatureFlag.UnlinkBills,
  FeatureFlag.ApolloCachePersistor,
  FeatureFlag.StripeTraceability,
  FeatureFlag.SetStripeEpoch,
  // FeatureFlag.DashboardChartBurnComparison, // for testing Net Burn MoM card
];

// Manually override flags to be enabled on staging
// Careful! staging is used for demos, make sure the feature is suited for a demo environment
export const FLAGS_ENABLED_ON_STAGING = [];

export const isPosthogFeatureFlagEnabled = (featureFlag: FeatureFlag) => {
  return posthog.isFeatureEnabled(featureFlag, { send_event: false });
};
