import React, { useMemo } from "react";
import Tag from "components/common/Tag";
import { styled, Button } from "@puzzle/ui";
import { formatMoney, formatCardName, pluralize, capitalize } from "@puzzle/utils";
import { FilterStatus, useTransactionsPage } from "./TransactionsProvider";
import { DataTable } from "components/common/DataTable";
import { compact } from "lodash";
import { RecurrencePeriod, VendorFragment } from "graphql/types";
import { getAmountTagDispalyText } from "components/common/AmountMenu";

const Root = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  whiteSpace: "nowrap",
  alignItems: "center",
  gap: "$1",
});

const Stats = styled(DataTable.Stats, {
  marginLeft: "auto",
});

const VendorTag = ({ vendor }: { vendor: VendorFragment }) => {
  const { removeVendor } = useTransactionsPage();

  return vendor ? (
    <Tag onRemove={() => removeVendor(vendor.id)} variant="filledPill">
      {vendor.name}
    </Tag>
  ) : null;
};

const useVendorTags = (vendors: VendorFragment[]) => {
  return useMemo(
    () => vendors.map((vendor) => <VendorTag key={vendor.id} vendor={vendor} />),
    [vendors]
  );
};

// const useClassTags
const ActiveFilters = () => {
  const {
    accountsById,
    cardsById,
    categoriesByPermaKey,
    filter,
    setFilter,
    resetFilter,
    removeAccount,
    removeCard,
    removeCategory,
    removeClassFilters,
    removeRecurrence,
    selectedVendors,
    selectedClassFilters,
  } = useTransactionsPage();

  const categoryTags = useMemo(
    () =>
      compact(
        filter.status !== FilterStatus.NotCategorized
          ? filter.ledgerCoaKeys.map((id: string) => {
              const category = categoriesByPermaKey?.[id];
              return (
                category && (
                  <Tag key={id} onRemove={() => removeCategory(id)} variant="filledPill">
                    {category.name}
                  </Tag>
                )
              );
            })
          : []
      ),
    [categoriesByPermaKey, filter.ledgerCoaKeys, filter.status, removeCategory]
  );

  const vendorTags = useVendorTags(selectedVendors);
  const classFiltersTags = useMemo(() => {
    return selectedClassFilters.map((classFilter) => {
      return (
        <Tag
          key={classFilter.id}
          onRemove={() => removeClassFilters(classFilter.id)}
          variant="filledPill"
        >
          {classFilter.name}
        </Tag>
      );
    });
  }, [removeClassFilters, selectedClassFilters]);

  const accountTags = useMemo(
    () =>
      compact(
        filter.accountIds.map((id) => {
          const account = accountsById[id];
          return (
            account && (
              <Tag key={id} onRemove={() => removeAccount(id)} variant="filledPill">
                {account.name} - {account.mask}
              </Tag>
            )
          );
        })
      ),
    [accountsById, filter.accountIds, removeAccount]
  );

  const cardTags = useMemo(
    () =>
      compact(
        filter.cardIds.map((id) => {
          const card = cardsById[id];
          return (
            card && (
              <Tag key={id} onRemove={() => removeCard(id)} variant="filledPill">
                {formatCardName(card)}
              </Tag>
            )
          );
        })
      ),
    [cardsById, filter.cardIds, removeCard]
  );

  const notFinalizedTag = useMemo(
    () =>
      filter.status === FilterStatus.NotFinalized && (
        <Tag onRemove={() => setFilter({ status: null })} variant="filledPill">
          Not Finalized
        </Tag>
      ),
    [filter.status, setFilter]
  );

  const finalizedTag = useMemo(
    () =>
      filter.status === FilterStatus.Finalized && (
        <Tag onRemove={() => setFilter({ status: null })} variant="filledPill">
          Finalized
        </Tag>
      ),
    [filter.status, setFilter]
  );

  // NOTE: isLinked being true doesn't work as expected.
  // It only works for false/null
  const isLinkedTag = useMemo(
    () =>
      filter.isLinked === false && (
        <Tag onRemove={() => setFilter({ isLinked: null })} variant="filledPill">
          Unlinked
        </Tag>
      ),
    [filter.isLinked, setFilter]
  );

  const postedTag = useMemo(
    () =>
      filter.posted && (
        <Tag onRemove={() => setFilter({ posted: null })} variant="filledPill">
          Date pending
        </Tag>
      ),
    [filter.posted, setFilter]
  );

  const recurrenceTags = useMemo(
    () =>
      compact(
        filter.recurrences?.map((recurrence, i) => (
          <Tag
            onRemove={() => removeRecurrence(recurrence)}
            variant="filledPill"
            key={recurrence ?? i}
          >
            {recurrence === undefined
              ? "Unknown"
              : recurrence === RecurrencePeriod.None
              ? "Non-recurring"
              : capitalize(recurrence.toLowerCase())}
          </Tag>
        ))
      ),
    [filter.recurrences, removeRecurrence]
  );

  const amountTag = useMemo(() => {
    const displayText = getAmountTagDispalyText(filter);

    return (
      displayText && (
        <Tag variant="filledPill" onRemove={() => setFilter({ minAmount: null, maxAmount: null })}>
          {displayText}
        </Tag>
      )
    );
  }, [filter, setFilter]);

  const documentationTag = useMemo(
    () =>
      typeof filter.hasDocumentation === "boolean" && (
        <Tag onRemove={() => setFilter({ hasDocumentation: null })} variant="filledPill">
          {filter.hasDocumentation ? "Has documentation" : "No documentation"}
        </Tag>
      ),
    [filter.hasDocumentation, setFilter]
  );

  const accrualDateTag = useMemo(
    () =>
      filter.showAccrualDate && (
        <Tag onRemove={() => setFilter({ showAccrualDate: null })} variant="filledPill">
          Accrual date
        </Tag>
      ),
    [filter.showAccrualDate, setFilter]
  );

  const availableDateTag = useMemo(
    () =>
      filter.showAvailableDate && (
        <Tag onRemove={() => setFilter({ showAvailableDate: null })} variant="filledPill">
          Available date (Stripe only)
        </Tag>
      ),
    [filter.showAvailableDate, setFilter]
  );

  const inputMethodTag = useMemo(
    () =>
      typeof filter.isManual === "boolean" && (
        <Tag onRemove={() => setFilter({ isManual: null })} variant="filledPill">
          {filter.isManual ? "Manual transactions" : "Automatic transactions"}
        </Tag>
      ),
    [filter.isManual, setFilter]
  );

  const isBillPaymentTag = useMemo(
    () =>
      typeof filter.isBillPayment === "boolean" && (
        <Tag onRemove={() => setFilter({ isBillPayment: null })} variant="filledPill">
          {filter.isBillPayment ? "Bill payment" : "Not a bill payment"}
        </Tag>
      ),
    [filter.isBillPayment, setFilter]
  );

  const showReset = useMemo(() => {
    return (
      compact([
        ...categoryTags,
        ...vendorTags,
        ...accountTags,
        ...cardTags,
        ...recurrenceTags,
        ...classFiltersTags,
        notFinalizedTag,
        isLinkedTag,
        postedTag,
        finalizedTag,
        amountTag,
        documentationTag,
        accrualDateTag,
        availableDateTag,
        inputMethodTag,
        isBillPaymentTag,
      ]).length > 0
    );
  }, [
    categoryTags,
    vendorTags,
    accountTags,
    cardTags,
    notFinalizedTag,
    classFiltersTags,
    isLinkedTag,
    postedTag,
    finalizedTag,
    amountTag,
    documentationTag,
    accrualDateTag,
    availableDateTag,
    inputMethodTag,
    isBillPaymentTag,
    recurrenceTags,
  ]);

  return (
    <Root>
      {categoryTags}
      {vendorTags}
      {classFiltersTags}
      {accountTags}
      {cardTags}
      {notFinalizedTag}
      {isLinkedTag}
      {postedTag}
      {finalizedTag}
      {amountTag}
      {documentationTag}
      {accrualDateTag}
      {availableDateTag}
      {inputMethodTag}
      {isBillPaymentTag}
      {recurrenceTags}
      {showReset && (
        <Button size="mini" variant="minimal" onClick={resetFilter}>
          Clear filters
        </Button>
      )}
    </Root>
  );
};

const Totals = () => {
  const { totals, loading } = useTransactionsPage();

  return useMemo(
    () =>
      totals.count > 0 ? (
        <Stats>
          <span>{pluralize(totals.count, "matching transaction")}</span>
          {totals.gain ? (
            <span>In {formatMoney({ currency: "USD", amount: totals.gain })}</span>
          ) : null}
          {totals.loss ? (
            <span>Out {formatMoney({ currency: "USD", amount: totals.loss })}</span>
          ) : null}
          {totals.gain && totals.loss ? (
            <span>Total {formatMoney({ currency: "USD", amount: totals.sum })}</span>
          ) : null}
        </Stats>
      ) : loading ? (
        <Stats>
          <span>Loading...</span>
        </Stats>
      ) : null,
    [totals, loading]
  );
};

const SecondaryToolbar = () => {
  return (
    <>
      <ActiveFilters />
      <Totals />
    </>
  );
};

export default SecondaryToolbar;
