import { styled } from "./stitches";

export const CountIndicator = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  defaultVariants: {
    tone: "neutral",
    shape: "square",
  },
  variants: {
    shape: {
      square: {
        borderRadius: "4px",
        height: "16px",
        width: "16px",
        fontSize: "10px",
        lineHeight: "14px",
      },
      circle: {
        borderRadius: "50%",
        width: "1.5rem",
        height: "1.5rem",
        lineHeight: "1.2",
        padding: "$0h",
        fontSize: "$bodyS",
      },
    },
    tone: {
      neutral: {
        background: "$elephant300",
      },
      dark: {
        background: "$rhino700",
      },
    },
  },
});
