import { CalendarDate, parseDate } from "@internationalized/date";
import { styled } from "@puzzle/ui";
import { CategoryFragment, ReportingClassType } from "graphql/types";
import { TransactionPageAccountFragment, BasicTransactionFragment } from "./graphql.generated";

export type TransactionRowData = {
  date: CalendarDate;
  availableOn: CalendarDate | null;
  account: TransactionPageAccountFragment;
  category: CategoryFragment;
  detailId: string;
  amount: string;
  transaction: BasicTransactionFragment;
  descriptor: string;
  transactionId: string;
  id: string;
  classSegments: {
    id: string;
    name: string;
    description?: string | null;
    reportingClass: { id: string; name: string; type: ReportingClassType };
  }[];
  subRows?: SplitRowData[];
};

export type SplitRowData = TransactionRowData & { splitIndex: number };

export const StatusRoot = styled("div", {
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  gap: "$1",
  "& > *": { flexShrink: "0" },
});

export const transactionToRowData = (
  transactions?: BasicTransactionFragment[]
): TransactionRowData[] => {
  return (
    transactions?.map((t) => ({
      isSplit: false,
      date: parseDate(t.date),
      availableOn: t.availableOn ? parseDate(t.availableOn) : null,
      // TODO worth de-duping..?
      transaction: t,
      transactionId: t.id,
      detailId: t.id,
      account: t.account,
      amount: t.amount,
      descriptor: t.descriptor,
      category: t.detail.category,
      id: t.id,
      classSegments: t.detail.classSegments,
      subRows: t.splits.map((s, i) => ({
        id: s.id,
        splitIndex: i,
        transaction: t,
        category: t.detail.category,
        classSegments: s.classSegments,
        transactionId: t.id,
        detailId: s.id,
        account: t.account,
        date: parseDate(t.date),
        availableOn: t.availableOn ? parseDate(t.availableOn) : null,
        descriptor: s.descriptor,
        amount: s.amount,
        status: t.detail.confirmedState,
      })) as SplitRowData[],
    })) ?? []
  );
};

export enum TransactionColId {
  "date" = "date",
  "account" = "account",
  "vendor_customer" = "vendor_customer",
  "descriptor" = "descriptor",
  "category" = "category",
  "department" = "department",
  "location" = "location",
  "classes" = "classes",
  "classifications" = "classifications",
  "status" = "status",
  "amount" = "amount",
}

export type ColumnVisibility = Record<TransactionColId, boolean>;

export const defaultColumnVisibility: ColumnVisibility = {
  date: true,
  account: true,
  vendor_customer: true,
  descriptor: true,
  category: true,
  department: false,
  location: false,
  classes: false,
  classifications: true,
  status: true,
  amount: true,
};

export const TransactionColHeaderMapping = {
  date: "Date",
  account: "Source",
  vendor_customer: "Vendor/Customer",
  descriptor: "Description",
  category: "Category",
  department: "Department",
  location: "Location",
  classes: "Classes",
  classifications: "Classifications",
  status: "Status",
  amount: "Amount",
};

export const HighPriorityCoaKeys = [
  "transfer_account",
  "processor_transfers",
  "preferred_stock",
  "venture_safe_note",
  "equity",
  "miscellaneous_income",
  "miscellaneous_expenses",
  "other_revenue",
  "other_expenses",
  "income",
  "uncategorized_expenses",
  "uncategorized_reimbursements",
  "no_category",
];
