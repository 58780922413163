import React from "react";
import { Text, styled } from "@puzzle/ui";
import { Plan, PricingPlanNames, AddOn, AddOnNames } from "../types";
import { IS_PROD } from "lib/config";
import { AIChecklist, Sparkle } from "@puzzle/icons";

const Ul = styled("ul", {
  padding: "$1 $3",
  margin: "0",
});

const HelpText = () => (
  <Ul>
    <li>Bookkeeping services are provided through one of Puzzle's trusted partners.</li>
    <li>
      Pricing is determined by the partner and is based on a number of factors (e.g. amount of
      monthly expenses, etc.)
    </li>
    <li>Puzzle will provide a concierge pairing experience once you've signed up.</li>
  </Ul>
);

export enum PlanTitle {
  Formation = "Formation",
  Startup = "Startup",
  Advanced = "Advanced",
}

/**
 * Ideally this plans should come from gateway and not hardcoded here.
 */
export const PLANS: Plan[] = [
  {
    id: PricingPlanNames.FREE,
    title: PlanTitle.Formation,
    titleColor: "gray400",
    description: "Tax compliance & financial reporting basics",
    badges: ["Accounting"],
    upgradeButtonVariant: "outline",
    upgradeButtonBody: ({ isOnPaidPlan, isOnFreeTrial }) => {
      if (!isOnPaidPlan && !isOnFreeTrial) {
        return "Current plan";
      }
      if (isOnPaidPlan) {
        return "Switch to Formation";
      }
      return "Downgrade";
    },
    featureTitle: "Includes:",
    features: [
      "Concierge onboarding",
      "Auto-generated financial statements",
      "Cash & accrual financials",
      "A monthly compliance checklist",
      "Up to 15k in monthly expenses",
    ],
    bookkeepingMonthlyCost: 199,
  },
  {
    id: PricingPlanNames.PAID_PLAN_1,
    title: PlanTitle.Startup,
    titleColor: "blue400",
    price: IS_PROD ? 50 : 50,
    priceYear: IS_PROD ? 510 : 510,
    priceId: IS_PROD ? "price_1ODWoHLLbpHgYASjqCZyopH0" : "price_1O48eCCEKUfSo6K0DlJdv5c2",
    // TODO(GRO-1272) We have a bug related to some ongoing checkout flow improvements. That feature
    // is supposed to be gated, but it looks like that gating is only partially implemented, so
    // users are being forced into the yearly plan with no option to go monthly. That's causing
    // people to be be unable to check out. This is a horrible hack to just always select the
    // monthly plan until we figure out what is going on.
    priceYearId: IS_PROD ? "price_1ODWoHLLbpHgYASjhk7zCaog" : "price_1OEh6xCEKUfSo6K0oBZyvAFK",
    //priceYearId: IS_PROD ? "price_1ODWoHLLbpHgYASjhk7zCaog" : "price_1OEh6xCEKUfSo6K0oBZyvAFK",
    description: "Accounting & insights for VC-funded startups.",
    badges: ["Accounting", "Insights"],
    upgradeButtonVariant: "primary",
    upgradeButtonBody: ({ currentSubscription, isOnPaidPlan }) => {
      if (currentSubscription === PricingPlanNames.PAID_PLAN_1) {
        return "Current plan";
      }
      if (isOnPaidPlan) {
        return "Switch to Startup";
      }
      return "Upgrade";
    },
    featureTitle: (
      <>
        Everything from{" "}
        <Text color="white" weight="heavy">
          Formation
        </Text>
        , plus:
      </>
    ),
    features: [
      "Ask an accountant",
      "Net burn & runway calculator",
      "Spend & vendor insights",
      "Revenue insights (ARR & MRR)",
      "Continuous cash monitoring",
    ],
    bookkeepingMonthlyCost: 499,
    helpText: <HelpText />,
  },
  {
    id: PricingPlanNames.PAID_PLAN_2,
    title: PlanTitle.Advanced,
    titleColor: "green500",
    price: IS_PROD ? 250 : 150,
    priceYear: IS_PROD ? 2550 : 1530,
    priceId: IS_PROD ? "price_1ODWmzLLbpHgYASjrWwEnbCQ" : "price_1O48ebCEKUfSo6K0LVgpApLX",
    // TODO(GRO-1272) We have a bug related to some ongoing checkout flow improvements. That feature
    // is supposed to be gated, but it looks like that gating is only partially implemented, so
    // users are being forced into the yearly plan with no option to go monthly. That's causing
    // people to be be unable to check out. This is a horrible hack to just always select the
    // monthly plan until we figure out what is going on.
    priceYearId: IS_PROD ? "price_1ODWmzLLbpHgYASjON2qtZvi" : "price_1OEgN7CEKUfSo6K0z0j1TXgV",
    //priceYearId: IS_PROD ? "price_1ODWmzLLbpHgYASjON2qtZvi" : "price_1OEgN7CEKUfSo6K0z0j1TXgV",
    description:
      "Accounting, insights, & automation for scaling startups and accounting professionals.",
    badges: ["Accounting", "Insights", "Automation"],
    upgradeButtonBody: ({ currentSubscription, isOnPaidPlan }) => {
      if (currentSubscription === PricingPlanNames.PAID_PLAN_2 && isOnPaidPlan) {
        return "Current plan";
      }
      if (isOnPaidPlan) {
        return "Switch to Advanced";
      }
      return "Upgrade now";
    },
    upgradeButtonVariant: "primary",
    featureTitle: (
      <>
        Everything from{" "}
        <Text color="white" weight="heavy">
          Startup
        </Text>
        , plus:
      </>
    ),
    features: [
      "Accrual automation",
      "Smart monthly review checklist",
      "Collaboration tools & workflows",
      "AI categorizer",
      "AI lookup",
    ],
    specialBody: "signature offering",
    specialBodyColor: "green600",
    specialBackground: "rgba(114, 242, 166, 0.20)",
    specialTierBorder: "1px solid #178151",
    bookkeepingMonthlyCost: 499,
    helpText: <HelpText />,
  },
];

export const ADDONS: AddOn[] = [
  {
    id: AddOnNames.ADDON_AI_CATEGORIZER,
    icon: <Sparkle size={24} />,
    title: "Puzzle AI",
    price: IS_PROD ? 25 : 25,
    priceYear: IS_PROD ? 255 : 20,
    priceId: IS_PROD ? "price_1P1DyrLLbpHgYASjMNYJI4ph" : "price_1OtlhgCEKUfSo6K09XjSS7vO",
    priceYearId: IS_PROD ? "price_1P1E0GLLbpHgYASjL3tkwxt2" : "price_1P18jZCEKUfSo6K0ANTG9Cpv",
    contentHeading:
      "Leverage the power of Puzzle AI to help you categorize transactions more efficiently and accurately with the following tools:",
    contentBullets: [
      "AI Categorizer: add more context to transactions and Puzzle AI will automatically categorize your transaction",
      "AI Lookup: Puzzle AI identifies unknown vendors and recommends categories",
    ],
  },
  {
    id: AddOnNames.ADDON_MONTHLY_CHECKLIST_SMART,
    title: "Smart insights",
    icon: <AIChecklist size={44} />,
    price: IS_PROD ? 25 : 25,
    priceYear: IS_PROD ? 255 : 20,
    priceId: IS_PROD ? "price_1P2GIRLLbpHgYASjncqDZb9n" : "price_1P1yYTCEKUfSo6K0y8fQAzob",
    priceYearId: IS_PROD ? "price_1P2GMjLLbpHgYASjan3TDYF2" : "price_1P1yakCEKUfSo6K06i8nz8Rn",
    contentHeading:
      "Add an additional layer of insights and automation on top of your regular checklist to help you close your book faster with confidence:",
    contentBullets: [
      "Auto-check items that require no actions",
      "View and address reconciliation differences",
      "Review auto-detected balance issues",
    ],
  },
];
