/* eslint-disable react/display-name */
import React, { useEffect, useRef, useLayoutEffect } from "react";
import { styled, Button } from "@puzzle/ui";
import { useIntersection } from "react-use";
import { Loader } from "ve/Loader";

const Footer = styled("div", {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  marginTop: "$7",
});

export const InfiniteScrollFooter = ({
  fetchNextPage,
  loading,
}: {
  fetchNextPage: () => void;
  loading: boolean;
}) => {
  const intersectionRef = useRef(null);
  const fetchGuard = useRef(false);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: 0.8,
  });

  useEffect(() => {
    if (!fetchGuard.current && !loading && intersection && intersection.intersectionRatio) {
      fetchGuard.current = true;
      fetchNextPage();
    }
  }, [loading, intersection, fetchNextPage]);

  // Not loading doesn't mean the table is done updating, leading to double fetches.
  // useLayoutEffect isn't quite right until we put a delay.
  // Weird but works for now...
  useLayoutEffect(() => {
    if (loading) {
      return;
    }

    setTimeout(() => {
      fetchGuard.current = false;
    }, 50);
  }, [loading]);

  return (
    <Footer ref={intersectionRef}>
      {loading ? (
        <Loader variant="primary" />
      ) : (
        <Button variant="secondary" onClick={fetchNextPage}>
          Load More
        </Button>
      )}
    </Footer>
  );
};
