import { useCallback } from "react";

import { useToasts } from "@puzzle/ui";

import Analytics from "lib/analytics";
import { CalendarDateString } from "scalars";
import {
  ContractRevenueSchedulePostingMethod,
  ContractRevenueScheduleStatus,
  InclusiveDateRange,
} from "graphql/types";

import {
  ContractRevenueScheduleDocument,
  GetContractRevenueSchedulesDocument,
  GetInvoiceDocument,
  useActivateContractRevenueScheduleMutation,
  useCloseContractRevenueScheduleMutation,
  useCreateContractRevenueScheduleMutation,
  useDeleteContractRevenueScheduleMutation,
  usePauseContractRevenueScheduleMutation,
} from "./graphql.generated";

const errorToast = {
  message: `Something went wrong, and our team has been notified. We apologize for the inconvenience. Please try again later.`,
  status: "warning",
} as const;

const mutationConfig = {
  refetchQueries: [
    GetContractRevenueSchedulesDocument,
    GetInvoiceDocument,
    ContractRevenueScheduleDocument,
  ],
};

export const useVoidSchedule = () => {
  const { toast } = useToasts();
  const [voidSchedule, { loading }] = useDeleteContractRevenueScheduleMutation(mutationConfig);

  return {
    mutation: useCallback(
      ({ scheduleId }: { scheduleId?: string }) => {
        if (!scheduleId) {
          return;
        }

        return voidSchedule({
          variables: {
            input: {
              contractRevenueScheduleId: scheduleId,
            },
          },

          onCompleted: () => {
            toast({
              title: "Schedule voided",
              status: "success",
            });

            Analytics.revenueScheduleVoided({ id: scheduleId });
          },

          onError: () => {
            toast(errorToast);
          },
        });
      },
      [voidSchedule, toast]
    ),
    loading,
    isPossibleWhen: (status?: ContractRevenueScheduleStatus) =>
      status &&
      [
        ContractRevenueScheduleStatus.Paused,
        ContractRevenueScheduleStatus.Active,
        ContractRevenueScheduleStatus.Draft,
      ].includes(status),
  };
};

export function useActivateSchedule() {
  const { toast } = useToasts();
  const [activateSchedule, { loading }] =
    useActivateContractRevenueScheduleMutation(mutationConfig);

  return {
    mutation: useCallback(
      ({ scheduleId }: { scheduleId?: string }) => {
        if (!scheduleId) {
          return;
        }

        return activateSchedule({
          variables: {
            input: {
              contractRevenueScheduleId: scheduleId,
            },
          },

          onCompleted: ({ activateContractRevenueSchedule: { id, periods, accruedRevenue } }) => {
            toast({
              title: "Schedule resumed",
              status: "success",
            });

            Analytics.revenueScheduleActivated({
              id,
              numberOfPeriods: periods?.length ?? 0,
              total: accruedRevenue.recognized.amount,
            });
          },

          onError: () => {
            toast(errorToast);
          },
        });
      },
      [activateSchedule, toast]
    ),
    loading,
    isPossibleWhen: (status?: ContractRevenueScheduleStatus) =>
      status && [ContractRevenueScheduleStatus.Paused].includes(status),
  };
}

export function useCloseSchedule() {
  const { toast } = useToasts();
  const [closeSchedule, { loading }] = useCloseContractRevenueScheduleMutation(mutationConfig);

  return {
    mutation: useCallback(
      ({ scheduleId, closedDay }: { scheduleId?: string; closedDay?: CalendarDateString }) => {
        if (!scheduleId || !closedDay) {
          return;
        }

        return closeSchedule({
          variables: {
            input: {
              closedDay,
              recordClosingEntry: true,
              contractRevenueScheduleId: scheduleId,
            },
          },

          onCompleted: ({ closeContractRevenueSchedule: { id, periods, accruedRevenue } }) => {
            toast({
              title: "Schedule closed",
              status: "success",
            });

            Analytics.revenueScheduleClosed({
              id,
              numberOfPeriods: periods?.length ?? 0,
              total: accruedRevenue.recognized.amount,
            });
          },

          onError: () => {
            toast(errorToast);
          },
        });
      },
      [closeSchedule, toast]
    ),
    loading,
    isPossibleWhen: (status?: ContractRevenueScheduleStatus) =>
      status &&
      [ContractRevenueScheduleStatus.Active, ContractRevenueScheduleStatus.Paused].includes(status),
  };
}

export function usePauseSchedule() {
  const { toast } = useToasts();
  const [pauseSchedule, { loading }] = usePauseContractRevenueScheduleMutation(mutationConfig);

  return {
    mutation: useCallback(
      ({ scheduleId }: { scheduleId?: string }) => {
        if (!scheduleId) {
          return;
        }

        return pauseSchedule({
          variables: {
            input: {
              contractRevenueScheduleId: scheduleId,
            },
          },

          onCompleted: ({ pauseContractRevenueSchedule: { id, periods, accruedRevenue } }) => {
            toast({
              title:
                "The revenue recognition for this schedule has been paused successfully and will remain on hold until it's successfully resumed.",
              status: "success",
              duration: 7500,
            });

            Analytics.revenueSchedulePaused({
              id,
              numberOfPeriods: periods?.length ?? 0,
              total: accruedRevenue.recognized.amount,
            });
          },

          onError: () => {
            toast(errorToast);
          },
        });
      },
      [pauseSchedule, toast]
    ),
    loading,
    isPossibleWhen: (status?: ContractRevenueScheduleStatus) =>
      status && [ContractRevenueScheduleStatus.Active].includes(status),
  };
}

export function useCreateSchedule() {
  const { toast } = useToasts();
  const [createSchedule, { loading }] = useCreateContractRevenueScheduleMutation(mutationConfig);

  return {
    mutation: useCallback(
      ({
        dateRange,
        postingMethod,
        invoiceLineItemId,
        invoiceDiscountLineId,
        companyId,
        isActivated,
        accountingConfigurationId,
      }: {
        companyId: string;
        dateRange: InclusiveDateRange;
        postingMethod: ContractRevenueSchedulePostingMethod;
        isActivated: boolean;
        invoiceLineItemId?: string;
        invoiceDiscountLineId?: string;
        accountingConfigurationId?: string;
      }) => {
        return createSchedule({
          variables: {
            input: {
              companyId,
              dateRange,
              postingMethod,
              invoiceLineItemId,
              invoiceDiscountLineId,
              isActivated,
              accountingConfigurationId,
            },
          },

          onCompleted: () => {
            toast({
              title: "The revenue recognition for this schedule has been created successfully.",
              status: "success",
              duration: 7500,
            });

            Analytics.revenueScheduleCreated({
              invoiceLineItemId: invoiceLineItemId,
              invoiceDiscountLineId: invoiceDiscountLineId,
              startDate: dateRange.fromInclusive,
              endDate: dateRange.toInclusive,
            });
          },

          onError: () => {
            toast(errorToast);
          },
        });
      },
      [createSchedule, toast]
    ),
    loading,
  };
}
