import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Columns({
  width = 12,
  height = 8,
  color = "currentColor",
  fill = color,
  viewBox = "0 0 12 8",
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} fill="none" viewBox={viewBox} {...iconProps}>
      <path
        fill={fill}
        d="M.4 8V0h11.2v8H.4zm1.2-1.2h2.133V1.2H1.6v5.6zm3.333 0h2.134V1.2H4.933v5.6zm3.334 0H10.4V1.2H8.267v5.6z"
      ></path>
    </Icon>
  );
}
