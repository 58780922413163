import { Add, Clear, Search } from "@puzzle/icons";
import { Button, Input, Stack, colors } from "@puzzle/ui";
import React from "react";

type HeaderProps = {
  actionOnClick: () => void;
  actionName: string;
  showCreateButton?: boolean;
  createButton?: React.JSX.Element;
  searchTerm: string;
  onSearchChange: (value: string) => void;
  onSearchClear: () => void;
};

const Header = ({
  actionOnClick,
  actionName,
  showCreateButton = true,
  createButton,
  searchTerm,
  onSearchChange,
  onSearchClear,
}: HeaderProps) => {
  const renderCreateButton = () => {
    if (createButton) return createButton;

    return (
      <Button
        data-testid="create_segment_button"
        prefix={<Add />}
        size="compact"
        onClick={actionOnClick}
      >
        New {actionName}
      </Button>
    );
  };

  return (
    <Stack direction="horizontal" css={{ justifyContent: "space-between" }}>
      <Input
        type="text"
        size="compact"
        prefix={<Search />}
        suffix={
          searchTerm ? (
            <Clear
              onClick={() => {
                onSearchClear();
                onSearchChange("");
              }}
            />
          ) : null
        }
        css={{ backgroundColor: colors.mauve800, width: "180px" }}
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => {
          onSearchChange(e.target.value);
        }}
      />
      {showCreateButton && renderCreateButton()}
    </Stack>
  );
};

export default Header;
