import * as Types from '../types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
export type ProductFragment = { __typename?: 'Product', id: string, name?: string | null };

export const ProductFragmentDoc = gql`
    fragment product on Product {
  id
  name
}
    `;