// TODO Automate with something like Pathpida
// https://linear.app/puzzlefin/issue/PZ-2292/typesafe-routes-for-nextjs
export enum Route {
  sync = "/api/sync",
  token = "/api/token",
  login = "/api/login",
  authorizePartner = "/api/authorize-partner",
  // This first goes to Auth0, then our own logout handler.
  logout = "/logout",
  me = "/api/me",
  home = "/",
  emailVerification = "/email-verification",
  emailVerificationSuccess = "/email-verification-success",
  emailNotifications = "/email-notifications",
  connectPlaid = "/oauth/plaid",
  connectFinicity = "/oauth/finicity",
  connectBrex = "/oauth/brex",
  connectGusto = "/oauth/gusto",
  connectRippling = "/oauth/rippling",
  linkRippling = "/link/rippling",
  connectQuickbooks = "/oauth/quickbooks",
  connectStripe = "/oauth/stripe",
  integrations = "/integrations",
  connectionError = "/oauth/error",
  createCompany = "/company/create",
  intro = "/intro",
  invoices = "/accounting/invoices",
  newInvoice = "/accounting/invoices/new",
  error = "/error",
  accountDetails = "/account",
  burn = "/burn",
  landing = "/landing",
  checklist = "/checklist",
  companyPrivateRoute = "/company",
  reports = "/reports",
  printableReport = "/print",
  report = "/reports/:id",
  revenue = "/revenue",
  accrualRevenue = "/revenue/accrual",
  mrrRevenue = "/revenue/mrr-arr",
  mrrRevenueByCustomer = "/revenue/mrr-arr-customers",
  expenses = "/expenses",
  people = "/people",
  ltse = "/ltse",
  angelListConnect = "/connect/angellist",
  connectSuccess = "/connect/success",
  angellistCategorize = "/connect/angellist/categorize",
  angellistManage = "/connect/angellist/manage",
  genericConnect = "/connect/generic",
  acmeConnect = "/connect/acme",
  introConnect = "/connect/intro",
  genericCategorize = "/connect/generic/categorize",
  connectPartnerDataSource = "/connect/partner-data-source",
  transactions = "/transactions",
  rules = "/transactions/rules",
  settings = "/settings",
  companySettings = "/settings/company",
  automationSettings = "/settings/automation",
  notificationSettings = "/settings/notifications",
  auditLog = "/settings/audit-log",
  billing = "/settings/billing",
  historicalBooks = "/settings/historical-books",
  classesSettings = "/settings/classes",
  usersSettings = "/settings/users",
  accounting = "/accounting",
  chartOfAccounts = "/accounting/coa",
  generalLedger = "/accounting/ledger",
  manualJournals = "/accounting/journals",
  newManualJournal = "/accounting/journals/new",
  lockedPeriod = "/accounting/locked-period",
  payroll = "/transactions/payroll",
  bills = "/accounting/bills",
  newBill = "/accounting/bills/new",
  fixedAssets = "/accounting/fixed-assets",
  fixedAssetsTransactions = "/accounting/fixed-assets/transactions",
  prepaidExpenses = "/accounting/prepaid-expenses",
  payments = "/accounting/payments",
  newPayment = "/accounting/payments/new",
  prepaidExpensesTransactions = "/accounting/prepaid-expenses/transactions",
  spending = "/spending",
  vendorList = "/spending/vendor-list",
  askAccountant = "/ask-an-accountant",
  inbox = "/inbox",
  partnerOnboarding = "partner-onboarding/:partner",
  ledgerReconciliations = "/accounting/ledger-reconciliations",
  ledgerReconciliationsByAccount = "/accounting/ledger-reconciliations/account/:accountId",
  newLedgerReconciliation = "/accounting/ledger-reconciliations/account/:accountId/new",
  ledgerReconciliationViewer = "/accounting/ledger-reconciliations/:reconciliationId",
  vendors = "/vendors",
  vendors1099 = "/vendors/1099",
  apAging = "/accounting/ap-aging",
  stripe = "/transactions/stripe",
  stripeDetail = "/transactions/stripe/:bucketId",
  icons = "/icons",
  arAging = "/accounting/ar-aging",
  accountingConfigurations = "/accounting/policies",
  revenueLines = "/accounting/revenue-lines",
}
export const RouteMap = new Set(Object.values(Route));

export enum CompanyRoute {
  auth = "auth",
}

export enum ErrorPageCode {
  Whitelist = "whitelist",
  VerifyEmail = "verify_email",

  CodeInvalid = "code_invalid",
  CodeExpired = "code_expired",
  CodeAlreadyActivated = "code_already_activated",
  CodeError = "code_error",

  AuthError = "auth_error",
}

export const isDynamicRoute = (path: string) => {
  return /\[(.*?)\]/.test(path);
};

export const getClosestStaticRoute = (path: string) => {
  const segments = path.split("/");
  const redirectPathParts = [];

  for (const segment of segments) {
    if (isDynamicRoute(segment)) break;
    redirectPathParts.push(segment);
  }

  let redirectPath = redirectPathParts.join("/");
  for (let i = 0; i < redirectPathParts.length; i++) {
    if (RouteMap.has(redirectPath as Route)) {
      break;
    } else {
      const pathMinusNSegments = redirectPathParts
        .slice(0, redirectPathParts.length - 1 - i)
        .join("/");
      redirectPath = pathMinusNSegments;
    }
  }

  return redirectPath || "/";
};
