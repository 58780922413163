import React from "react";
import Icon, { IconProps } from "./Icon";

export default function Drag({
  width = 16,
  height = 16,
  viewBox = "0 0 16 16",
  color = "currentColor",
  fill = color,
  ...iconProps
}: IconProps) {
  return (
    <Icon width={width} height={height} fill="none" viewBox={viewBox} {...iconProps}>
      <path
        fill={fill}
        d="M5.996 12.8c-.33 0-.613-.118-.846-.353a1.165 1.165 0 01-.35-.85c0-.331.118-.614.353-.847.236-.233.52-.35.85-.35.331 0 .613.118.847.354.233.235.35.519.35.85 0 .33-.118.613-.354.846-.235.234-.519.35-.85.35zm4 0c-.33 0-.613-.118-.846-.353a1.165 1.165 0 01-.35-.85c0-.331.118-.614.353-.847.236-.233.52-.35.85-.35.331 0 .614.118.847.354.233.235.35.519.35.85 0 .33-.118.613-.354.846-.235.234-.519.35-.85.35zm-4-3.6c-.33 0-.613-.118-.846-.353a1.165 1.165 0 01-.35-.85c0-.331.118-.613.353-.847.236-.233.52-.35.85-.35.331 0 .613.118.847.354.233.235.35.519.35.85 0 .33-.118.613-.354.846-.235.234-.519.35-.85.35zm4 0c-.33 0-.613-.118-.846-.353a1.165 1.165 0 01-.35-.85c0-.331.118-.613.353-.847.236-.233.52-.35.85-.35.331 0 .614.118.847.354.233.235.35.519.35.85 0 .33-.118.613-.354.846-.235.234-.519.35-.85.35zm-4-3.6c-.33 0-.613-.118-.846-.353a1.165 1.165 0 01-.35-.85c0-.331.118-.613.353-.847.236-.233.52-.35.85-.35.331 0 .613.118.847.354.233.235.35.519.35.85 0 .33-.118.613-.354.846-.235.234-.519.35-.85.35zm4 0c-.33 0-.613-.118-.846-.353a1.165 1.165 0 01-.35-.85c0-.331.118-.613.353-.847.236-.233.52-.35.85-.35.331 0 .614.118.847.354.233.235.35.519.35.85 0 .33-.118.613-.354.846-.235.234-.519.35-.85.35z"
      ></path>
    </Icon>
  );
}
