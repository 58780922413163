import React, { useMemo, useRef, useState, useEffect } from "react";

import {
  MRT_TableOptions,
  MRT_RowData,
  MRT_TableInstance,
  MaterialReactTable,
} from "material-react-table";
import { ThemeProvider } from "@mui/material/styles";

import { tableSpecificThemes, dataGridTheme } from "./DataGridTheme";
import { useMaterialReactTable } from "material-react-table";
import { formatMrtCol } from "./dataGridUtils";
import { RootTableStyle } from "./DataGrid.css";
import useResizeObserver from "use-resize-observer";
import { useDebouncedCallback } from "use-debounce";
import { MutableRefObject } from "react";

interface DataGridProps<RowData extends MRT_RowData> {
  options: MRT_TableOptions<RowData>;
  height?: string;
  tableRef?: MutableRefObject<MRT_TableInstance<RowData> | null>;
  emptyState?: React.ReactElement;
}

const defaultOptions = {
  columns: [],
  data: [],
  enableTopToolbar: false,
  enableBottomToolbar: false,
  enableSorting: false,
  enableColumnActions: false,
  enableColumnFilters: false,
  enablePagination: false,
  enableColumnVirtualization: false,
  enableStickyHeader: true,
};

/**
 * Latest DataTable component built off Material React Table
 * @param options - See Material React Table options
 *
 * @param height - Height of the table, defaults to 100%
 */
const DataGrid = <RowData extends MRT_RowData>({
  options,
  height,
  tableRef,
  emptyState,
}: DataGridProps<RowData>) => {
  const col = useMemo(() => formatMrtCol(options.columns), [options.columns]);

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [tableContainerHeight, setTableContainerHeight] = useState(
    `${tableContainerRef.current?.clientHeight.toString()}px`
  );

  const debouncedSetTableContainerHeight = useDebouncedCallback((input) => {
    setTableContainerHeight(input);
  }, 500);

  useResizeObserver({
    ref: tableContainerRef,
    onResize: ({ height }) => {
      if (height) {
        debouncedSetTableContainerHeight(`${height}px`);
      }
    },
  });

  const table = useMaterialReactTable({
    ...defaultOptions,
    ...tableSpecificThemes,
    ...options,
    muiTableContainerProps: {
      ...options.muiTableContainerProps,
      sx: { maxHeight: height || tableContainerHeight },
    },
    columns: col,
    renderEmptyRowsFallback: () =>
      emptyState ?? <div style={{ padding: "10px" }}>No results found.</div>,
  });

  useEffect(() => {
    if (tableRef) {
      tableRef.current = table;
    }
  }, [table, tableRef]);

  return (
    <div ref={tableContainerRef} className={RootTableStyle}>
      <ThemeProvider theme={dataGridTheme}>
        <MaterialReactTable table={table} />
      </ThemeProvider>
    </div>
  );
};

export default DataGrid;
