import React, { useCallback, useMemo, useEffect } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePrevious } from "react-use";

import { CalendarDateString, capitalize } from "@puzzle/utils";
import { Drawer, useDialogReset, Text, IconButton, Tag, ScrollArea } from "@puzzle/ui";
import { Close } from "@puzzle/icons";

import { ContractRevenueScheduleStatus, ContractRevenueSchedulePostingMethod } from "graphql/types";
import { useActiveCompany } from "components/companies";

import {
  ContractRevenueScheduleFragment,
  useContractRevenueScheduleQuery,
} from "../graphql.generated";
import { ScheduleFormValues, scheduleSchema, FormValues } from "../InvoiceForm/InvoiceFormContext";
import { ScheduleDrawerBody } from "./ScheduleDrawerBody";
import { ScheduleDrawerActions } from "./ScheduleDrawerActions";
import { useCreateSchedule } from "../useScheduleActions";
import { toScheduleDateRangePayload } from "../shared";
import { LineField } from "../InvoiceForm/LineRow";
import Loader from "components/common/Loader";
import { Box, S, color } from "ve";

type Props = {
  lineField?: LineField;
  lineIndex?: number;
  prefetchedSchedule?: ContractRevenueScheduleFragment;
  scheduleId?: string;
  parentForm?: UseFormReturn<FormValues>;
  isLocked?: boolean;
  createNewScheduleOnSave?: boolean;
  open?: boolean;
  onOpenChange?(open: boolean): void;
};

export const RevenueRecognitionSchedule = ({
  lineField,
  lineIndex = 0,
  parentForm,
  prefetchedSchedule: _prefetchedSchedule,
  scheduleId: _scheduleId,
  createNewScheduleOnSave = false,
  open,
  onOpenChange,
}: Props) => {
  const scheduleId = usePrevious(_scheduleId) ?? _scheduleId;

  const { data: fetchedSchedule, loading } = useContractRevenueScheduleQuery({
    notifyOnNetworkStatusChange: true,
    skip: !scheduleId,
    variables: { id: scheduleId ?? "" },
  });

  const existingSchedule = scheduleId
    ? fetchedSchedule?.contractRevenueSchedule
    : _prefetchedSchedule;

  const parentSchedule = lineField && parentForm?.watch(`${lineField}.${lineIndex}.schedule`);
  const issueDate = parentForm?.watch("issueDate");
  const createSchedule = useCreateSchedule();
  const { company } = useActiveCompany<true>();
  const readOnly =
    Boolean(
      existingSchedule &&
        [
          ContractRevenueScheduleStatus.Active,
          ContractRevenueScheduleStatus.Closed,
          ContractRevenueScheduleStatus.Paused,
        ].includes(existingSchedule.status)
    ) || Boolean(scheduleId);

  const scheduleForm = useForm<ScheduleFormValues>({
    mode: "onChange",
    resolver: yupResolver(scheduleSchema),
    defaultValues: {
      serviceDuration: parentSchedule?.serviceDuration,
      startDate: parentSchedule?.startDate,
      accountingConfigurationId: parentSchedule?.accountingConfigurationId,
    },
  });

  const handleSubmit = useMemo(() => {
    return scheduleForm.handleSubmit(async (formValues) => {
      if (!formValues.startDate) {
        return;
      }

      if (!lineField) {
        throw new Error("line field required when creating schedule");
      }

      if (createNewScheduleOnSave) {
        const id = parentForm?.getValues()[lineField][lineIndex].id;
        if (!id) {
          return;
        }
        const createScheduleInput = {
          dateRange: toScheduleDateRangePayload({
            startDate: formValues.startDate,
            serviceDuration: formValues.serviceDuration,
          }),
          postingMethod: ContractRevenueSchedulePostingMethod.Automatically,
          companyId: company.id,
          isActivated: true,
          invoiceLineItemId: lineField === "lines" ? id : undefined,
          invoiceDiscountLineId: lineField === "discountLines" ? id : undefined,
          accountingConfigurationId: formValues.accountingConfigurationId,
        };
        return await createSchedule.mutation(createScheduleInput);
      }
      // Save schedule to the line on the parent form so it will be attached to mutation payload
      parentForm?.setValue(`${lineField}.${lineIndex}.schedule.startDate`, formValues.startDate);
      parentForm?.setValue(
        `${lineField}.${lineIndex}.schedule.serviceDuration`,
        formValues.serviceDuration
      );

      onOpenChange?.(false);
    });
  }, [
    company.id,
    createNewScheduleOnSave,
    createSchedule,
    parentForm,
    lineIndex,
    scheduleForm,
    onOpenChange,
    lineField,
  ]);

  const hydrateOnOpen = useCallback(() => {
    if (parentSchedule) {
      scheduleForm.setValue("startDate", parentSchedule.startDate as CalendarDateString);
    } else {
      scheduleForm.setValue("startDate", issueDate as CalendarDateString);
    }

    scheduleForm.setValue("serviceDuration", parentSchedule?.serviceDuration as CalendarDateString);
    scheduleForm.setValue("accountingConfigurationId", parentSchedule?.accountingConfigurationId);
  }, [scheduleForm, issueDate, parentSchedule]);

  useDialogReset(open, () => {
    hydrateOnOpen();
  });

  useEffect(() => {
    if (!open && scheduleForm.formState.isDirty && !existingSchedule) {
      setTimeout(() => {
        scheduleForm.reset();
      }, 200);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Drawer open={open} onOpenChange={onOpenChange} css={{ padding: 0, width: 650 }}>
      <Box css={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
        <ScrollArea css={{ display: "flex", width: "100%", height: "100%", flex: 1 }}>
          <ScrollArea.Viewport>
            <Box
              css={{
                display: "flex",
                justifyContent: "space-between",
                padding: `${S["1h"]} ${S["2"]} ${S["1h"]} ${S["3"]}`,
                alignItems: "center",
                borderBottom: `1px solid ${color.mauve600}`,
                flex: 1,
              }}
            >
              <Box css={{ display: "inline-flex", gap: S["2"] }}>
                <Text
                  css={{
                    fontSize: "$bodyM",
                    lineHeight: "$headingL",
                    letterSpacing: "$bodyXS",
                    fontWeight: "$bold",
                  }}
                >
                  Revenue recognition schedule
                </Text>
                {existingSchedule?.status && (
                  <Tag variant="filledPill">{capitalize(existingSchedule.status)}</Tag>
                )}
              </Box>
              <IconButton onClick={() => onOpenChange?.(false)} autoFocus>
                <Close color="currentColor" size={12} />
              </IconButton>
            </Box>

            {loading && scheduleId ? (
              <Loader size={48} />
            ) : (
              <>
                <ScheduleDrawerBody
                  scheduleForm={scheduleForm}
                  parentForm={parentForm}
                  existingSchedule={existingSchedule}
                  open={open}
                  readOnly={readOnly}
                  lineIndex={lineIndex}
                  lineField={lineField}
                />

                <Box
                  css={{
                    padding: `${S["2"]} ${S["3"]} ${S["11h"]} ${S["3"]}`,
                    flex: 1,
                  }}
                >
                  <ScheduleDrawerActions
                    existingSchedule={existingSchedule}
                    onOpenChange={onOpenChange}
                    readOnly={readOnly}
                    scheduleForm={scheduleForm}
                    handleSubmit={handleSubmit}
                    submitLoading={createSchedule.loading}
                  />
                </Box>
              </>
            )}
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar orientation="vertical" css={{ width: "10px !important" }}>
            <ScrollArea.Thumb variant="shadowed" />
          </ScrollArea.Scrollbar>
        </ScrollArea>
      </Box>
    </Drawer>
  );
};
