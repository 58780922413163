export const colors = {
  black: "#121718",
  blackA32: "rgba(18, 23, 24, 0.32)",

  blue100: "#2DB2E0",
  blue200: "#5AD4FF",
  blue300: "#4FB1F9",
  blue400: "#5B9EFF",
  blue500: "#63C5FF",

  gray50: "#F8F8FA",
  gray100: "#E8E8EA",
  gray200: "#D1D1D5",
  gray300: "#B3B2B8",
  gray400: "#95949B",
  gray500: "#77767E",
  gray600: "#595861",
  gray700: "#3C3B44",
  gray800: "#1E1D26",

  brown500: "#2A292E",

  green100: "#EBFFFC",
  green200: "#C9FFF1",
  green300: "#ADFFE2",
  green400: "#94FFCF",
  green500: "#81FFBB",
  green600: "#72F2A6",
  green700: "#62D89A",
  green800: "#52BD8D",

  greenBackground: "rgba(114, 242, 166, 0.20)",
  greenalpha: "#50FAAB",

  elephant100: "#9797B7",
  elephant300: "#505066",

  mauve100: "#736285",
  mauve200: "#605172",
  mauve300: "#544868",
  mauve400: "#4A405E",
  mauve500: "#3F3754",
  mauve550: "#353547",
  mauve600: "#352F4A",
  mauve650: "#302F4A",
  mauve680: "#2D2D40",
  mauve700: "#2B2740",
  mauve800: "#211F35",
  mauve850: "#1C1B2E",
  mauve900: "#171629",
  mauve950: "#21212E",

  pink500: "#FF63EF",

  purple100: "#FDF5FF",
  purple200: "#F3D8FF",
  purple300: "#E5BDFC",
  purple400: "#D3A5F7",
  purple500: "#C08FEE",
  purple600: "#AB7DE3",
  purple700: "#966DD5",
  purple800: "#7A5AB9",
  purple900: "#60479C",

  red100: "#FFEDEB",
  red200: "#FFA8AE",
  red300: "#FF8395",
  red400: "#F74B75",
  red500: "#E7366C",
  red600: "#CB2863",
  red700: "#AC1D58",
  red800: "#8C134C",
  red900: "#780038",
  red1000: "#A61807",

  white: "#FFFFFF",
  white50: "rgba(255, 255, 255, 0.08)",
  white60: "rgba(255, 255, 255, 0.10)",
  white100: "rgba(255, 255, 255, 0.08)",
  white300: "rgba(255, 255, 255, 0.24)",

  yellow100: "#FFFFD5",
  yellow200: "#FFFF92",
  yellow300: "#FFF076",
  yellow400: "#F7DB60",
  yellow500: "#E8C34D",
  yellow600: "#CBA13D",
  yellow700: "#AC802E",
  yellow800: "#8B6121",

  // yes this was in figma
  rhino200: "#4B4B66",
  rhino750: "#343445",
  rhino700: "#353548",
  rhino600: "#44445C",

  // aliases
  //primary: "$greenalpha",
  //positive: "$green600",
  //negative: "$red600",

  // specific one-offs
  tableRowBorder: "#302f4a",
  blurBackground: "rgba(33, 31, 53, 0.5)",
};
