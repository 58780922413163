import React, { useState } from "react";
import useRippling from "./useRippling";
import { IntegrationsListItemBaseProps } from "../List";
import { IntegrationConnectionStatus, IntegrationType } from "graphql/types";
import Logo from "./Logo";
import { IntegrationDisplayComponent } from "../shared/types";
import Analytics, { FeatureFlag, isPosthogFeatureFlagEnabled } from "lib/analytics";
import { AlertDialog } from "@puzzle/ui";
import { useRouter } from "next/router";

interface RipplingListItemProps {
  DisplayComponent: IntegrationDisplayComponent<
    React.PropsWithChildren<IntegrationsListItemBaseProps>
  >;
  companyId: string;
}
export const TITLE = "Rippling";
export const SUBTITLE = "Link employment costs for your financial statements and taxes.";

const RipplingListItem = ({ DisplayComponent, companyId }: RipplingListItemProps) => {
  const { onClickConnect, connection, loading } = useRippling({ companyId });
  const router = useRouter();
  const isOnboarding = router.pathname.includes("intro");
  const isRipplingIntegrationConnectionEnabled = isPosthogFeatureFlagEnabled(
    FeatureFlag.RipplingIntegrationConnection
  );
  const isExistingConnection =
    connection?.status !== undefined &&
    [IntegrationConnectionStatus.Ok, IntegrationConnectionStatus.Error].includes(
      connection?.status
    );
  const [openModal, setOpenModal] = useState(false);

  const showModalAndLogMetric = () => {
    setOpenModal(true);
    Analytics.onboardingRipplingSelected({ providerName: "Rippling" });
  };

  // We want to show Rippling in the Integrations list if,
  // 1. The company already has an active Rippling connection (even if the feature flag is disabled)
  // 2. The feature flag is enabled
  if (isExistingConnection || isRipplingIntegrationConnectionEnabled) {
    return (
      <>
        <DisplayComponent
          integrationType={IntegrationType.Rippling}
          connectIntegration={() => (isOnboarding ? showModalAndLogMetric() : onClickConnect())}
          title={TITLE}
          logo={<Logo />}
          connection={connection}
          loading={loading}
        />
        <AlertDialog size="small" open={openModal} onOpenChange={() => setOpenModal(false)}>
          <AlertDialog.Title basic showClose>
            Rippling
          </AlertDialog.Title>
          <AlertDialog.Body>
            Connect via the Integrations page after you've created your Puzzle account
          </AlertDialog.Body>
        </AlertDialog>
      </>
    );
  } else {
    return null;
  }
};

export default RipplingListItem;
