import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Menu,
  Stack,
  Text,
  colors,
  styled,
  DataTable,
  createColumnHelper,
  useDataTable,
  IconButton,
} from "@puzzle/ui";
import Header from "../shared/Header";
import EmptyState from "../shared/EmptyState";
import Loader from "components/common/Loader";
import CreateClassificationModal from "components/common/Classifications/CreateClassificationModal";
import useClassifications from "components/common/hooks/useClassifications";
import { AvailableReportingClassFragment } from "components/common/hooks/graphql.generated";
import { useActiveCompany, useCompanyDateFormatter } from "components/companies";
import { CalendarDateTimeString } from "scalars";
import { parseAbsolute } from "@puzzle/utils";
import { Add, CaretDown, ChevronDown, ChevronRight, Ellipsis } from "@puzzle/icons";
import { ReportingClassType } from "graphql/types";
import { noop } from "lodash";
import DeleteClassModal from "./DeleteClassModal";
import { ExpandedState } from "@tanstack/react-table";
import { Box, S } from "ve";

type ReportingClassRow = {
  id: string;
  name: string;
  description?: string | null;
  createdAt?: CalendarDateTimeString;
  createdBy?: { name?: string | null } | null;
  parent?: AvailableReportingClassFragment | null;
} & {
  subRows?: ReportingClassRow[];
};

const ParentIcon = styled("span", {
  display: "inline-block",
  transform: "translate(-5px, 4px)",
  color: "$gray500",
});

export enum ShowModalTypes {
  CreateGroup = "createGroup",
  CreateClass = "createClass",
}

const containsSearchString = (str: string, searchTerm: string) =>
  str.toLowerCase().includes(searchTerm.toLowerCase());

const Classes = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState<ShowModalTypes | null>(null);
  const [editingReportingClass, setEditingReportingClass] =
    useState<AvailableReportingClassFragment | null>(null);
  const [deletingReportingClassId, setDeletingReportingClassId] = useState<string | undefined>();
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const { classificationsLoading, sortedClassifications } = useClassifications({});
  const { timeZone } = useActiveCompany<true>();

  const dateFormatter = useCompanyDateFormatter({
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  const columnHelper = createColumnHelper<ReportingClassRow>();

  const ClassesCreateButton = () => {
    return (
      <Menu
        data-testid="add_to_classifications_trigger"
        trigger={
          <Button
            data-testid="add_to_classifications_trigger"
            prefix={<Add />}
            suffix={<CaretDown />}
            size="compact"
          >
            New
          </Button>
        }
        css={{ marginTop: "$0h" }}
      >
        <Menu.Item
          data-testid="create_new_group_button"
          onClick={() => setShowModal(ShowModalTypes.CreateGroup)}
        >
          New group
        </Menu.Item>
        <Menu.Item
          data-testid="create_new_class_button"
          onClick={() => setShowModal(ShowModalTypes.CreateClass)}
        >
          New class
        </Menu.Item>
      </Menu>
    );
  };

  const classifications = editingReportingClass
    ? editingReportingClass.availableValues
    : sortedClassifications;

  const tableData = useMemo(() => {
    return sortedClassifications.map((reportingClass) => {
      const { id, name, description, availableValues, createdAt, createdBy } = reportingClass;
      return {
        id,
        name,
        description,
        createdAt,
        createdBy,
        subRows: [
          ...availableValues.map((classSegment) => {
            return {
              id: classSegment.id,
              name: classSegment.name,
              description: classSegment.description,
              createdAt: classSegment.createdAt,
              createdBy: classSegment.createdBy,
            } as ReportingClassRow;
          }),
          {
            id: `${reportingClass.id}-CreateClassification`,
            name: "CreateClassification",
            createdBy: {},
            parent: reportingClass,
          } as ReportingClassRow,
        ],
      };
    });
  }, [sortedClassifications]);

  const columns = useMemo(() => {
    return [
      columnHelper.accessor("name", {
        header: "Name",
        id: "name",
        cell: ({ row, getValue }) => {
          const value = getValue();
          if (row.original.parent) {
            return (
              <Box css={{ marginLeft: S["3"] }}>
                <Button
                  size="small"
                  variant="secondary"
                  data-testid={`create_new_class_button_${row.original.parent.name}`}
                  prefix={<Add />}
                  onClick={() => {
                    if (!row.original.parent) return null;
                    setEditingReportingClass(row.original.parent);
                    setShowModal(ShowModalTypes.CreateClass);
                  }}
                >
                  New class
                </Button>
              </Box>
            );
          }

          return (
            <>
              {row.depth === 0 ? (
                <div>
                  <span style={{ marginRight: "$1h" }}>
                    {row.getIsExpanded() ? (
                      <ChevronDown color={colors.gray500} />
                    ) : (
                      <ChevronRight color={colors.gray500} />
                    )}
                  </span>
                  <Text color="$gray200">{value}</Text>
                </div>
              ) : (
                <Box css={{ marginLeft: S["3"] }}>
                  <ParentIcon>└</ParentIcon>
                  <Text color="$gray300">{value}</Text>
                </Box>
              )}
            </>
          );
        },
        size: 90,
      }),
      columnHelper.accessor("description", {
        header: "Description",
        id: "description",
      }),
      columnHelper.accessor("createdAt", {
        header: "Date created",
        id: "createdAt",
        cell: ({ row, getValue }) => {
          const value = getValue();

          if (!value) return null;
          const isTopLevel = row.depth === 0;

          return (
            <Text color={!isTopLevel ? "$gray300" : "$gray200"}>
              {dateFormatter.format(parseAbsolute(value, timeZone))}
            </Text>
          );
        },
        size: 60,
      }),
      columnHelper.accessor("createdBy", {
        header: "Created by",
        id: "createdBy",
        cell: ({ row, getValue }) => {
          const value = getValue();

          if (!value || !value.name) return null;
          const isTopLevel = row.depth === 0;

          return <Text color={!isTopLevel ? "$gray300" : "$gray200"}>{value.name}</Text>;
        },
        size: 60,
      }),
      columnHelper.display({
        id: "actions",
        size: 18,
        meta: { align: "right" },
        cell: ({ row }) => {
          const hasNoClassSegments = row.subRows.length > 1;
          if (row.depth > 0 || hasNoClassSegments) return null;
          return (
            <Menu
              onClick={(e) => e.stopPropagation()}
              trigger={
                <IconButton css={{ transform: "rotate(90deg)" }}>
                  <Ellipsis />
                </IconButton>
              }
              side="left"
            >
              <Menu.Group>
                <Button
                  onClick={async () => {
                    setDeletingReportingClassId(row.original.id);
                  }}
                  variant="minimal"
                  size="compact"
                  color="negative"
                >
                  Delete
                </Button>
              </Menu.Group>
            </Menu>
          );
        },
      }),
    ];
  }, [columnHelper, dateFormatter, timeZone]);

  const data = useMemo(() => {
    if (searchTerm.length > 1) {
      return tableData.reduce((filtered: ReportingClassRow[], item) => {
        const newItem = { ...item };

        // Filter subRows if present
        if (newItem.subRows) {
          newItem.subRows = newItem.subRows.filter((subItem) =>
            containsSearchString(subItem.name, searchTerm)
          );
        }

        if (newItem.subRows && newItem.subRows.length > 0) {
          newItem.subRows.push(
            item.subRows.find((subItem) => subItem.name === "CreateClassification")!
          );
        }

        // Check if the main item or any of its subRows match the search string
        if (
          containsSearchString(newItem.name, searchTerm) ||
          (newItem.subRows && newItem.subRows.length > 0)
        ) {
          return [...filtered, newItem];
        }

        return filtered;
      }, []);
    }

    return tableData;
  }, [tableData, searchTerm]);

  // when data / searchTerm changes, expand all rows that have subRows b
  useEffect(() => {
    if (searchTerm.length <= 1) return;

    const expandedRowIds = data.reduce((rows: Record<string, boolean>, row) => {
      if (row.subRows?.length && row.subRows.length > 0) {
        return { ...rows, [row.id]: true };
      }

      return rows;
    }, {});

    setExpanded(expandedRowIds);
  }, [data, searchTerm]);

  const table = useDataTable({
    data,
    columns,
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getRowId: (row) => row.id,
    state: {
      expanded,
    },
  });

  const deletingReportingClassName = !deletingReportingClassId
    ? undefined
    : sortedClassifications.find((rc) => rc.id === deletingReportingClassId)?.name;

  if (classificationsLoading) return <Loader />;

  return (
    <Stack>
      <Header
        actionName="class"
        actionOnClick={noop}
        showCreateButton={sortedClassifications.length > 0}
        createButton={<ClassesCreateButton />}
        searchTerm={searchTerm}
        onSearchChange={setSearchTerm}
        onSearchClear={() => setExpanded({})}
      />
      <div>
        {sortedClassifications.length > 0 ? (
          <DataTable
            table={table}
            density="small"
            onRowClick={(row) => row.toggleExpanded()}
            getRowProps={(row) => {
              const subRowStyles =
                row.depth > 0
                  ? {
                      cursor: "default",
                      backgroundColor: colors.mauve850,
                    }
                  : undefined;

              return {
                style: subRowStyles,
              };
            }}
          />
        ) : (
          <EmptyState
            title="No classes yet"
            message="Creating Classes (e.g. Region, Project) enables more detailed reporting. Within each Class, any value can be applied to financial events (e.g. transactions, manual journal entries, payrolls)."
            actionOnClick={() => setShowModal(ShowModalTypes.CreateGroup)}
            actionName="Group"
          />
        )}
      </div>
      <CreateClassificationModal
        open={showModal !== null || !!editingReportingClass}
        onOpenChange={(open) => {
          setEditingReportingClass(null);
          setShowModal(null);
        }}
        reportingClassType={ReportingClassType.UserCreated}
        reportingClass={editingReportingClass}
        classifications={classifications}
        allowReportingClassSelection={showModal === ShowModalTypes.CreateClass}
        allReportingClasses={sortedClassifications}
      />
      <DeleteClassModal
        open={!!deletingReportingClassId}
        reportingClassId={deletingReportingClassId}
        reportingClassName={deletingReportingClassName}
        onOpenChange={(open) => {
          if (!open) setDeletingReportingClassId(undefined);
        }}
        onDelete={() => setDeletingReportingClassId(undefined)}
      />
    </Stack>
  );
};

export default Classes;
