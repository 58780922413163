/* eslint-disable react/display-name */
import React, { useState, useMemo } from "react";
import { CategoryModal } from "components/transactions";
import { CategoryFragment } from "graphql/types";
import Tag from "components/common/Tag";
import { Text, Tooltip, TooltipProps } from "@puzzle/ui";
import { BasicTransactionFragment } from "../dashboard/Transactions/graphql.generated";
import { CategorySteps } from "./CategoryModal";
import { toCalendarMonthString } from "@puzzle/utils";
import { WarningDialog } from "components/dashboard/Accounting/LockedPeriods/WarningDialog";
import { LockDayWarning } from "components/dashboard/Accounting/LockedPeriods/LockedPeriod";
import { useActiveCompany } from "components/companies";
import { parseDate } from "@internationalized/date";
import { Bill } from "@puzzle/icons";
import { Box } from "ve";

interface SelectCategoryInputProps {
  categories: CategoryFragment[];
  value: CategoryFragment;
  onChange: (c: CategoryFragment) => void;
  canEdit: boolean;
  wrap?: boolean;
  transaction?: BasicTransactionFragment | null;
  capitalizable?: boolean;
  isBillPayment?: boolean;
  isInvoicePayment?: boolean;
  maxWidth?: number;
  tooltipProps?: TooltipProps;
}

const SelectCategoryInput = React.forwardRef<HTMLButtonElement, SelectCategoryInputProps>(
  (
    {
      categories,
      onChange,
      value,
      canEdit,
      wrap,
      transaction,
      isBillPayment = false,
      isInvoicePayment = false,
      maxWidth,
      tooltipProps,
    },
    ref
  ) => {
    const { lockedPeriodDate, isWithinLockedPeriod } = useActiveCompany<true>();
    const [showWarning, setShowWarning] = useState(false);
    const showLockWarning = useMemo(
      () =>
        transaction &&
        isWithinLockedPeriod(parseDate(transaction.date)) &&
        !transaction.detail.postedAt,
      [isWithinLockedPeriod, transaction]
    );

    if (!canEdit) {
      return (
        <span style={{ display: "flex" }}>
          {(isInvoicePayment || isBillPayment) && (
            <Box css={{ paddingTop: 1 }}>
              <Bill style={{ height: 12, width: 20 }} />
            </Box>
          )}
          <Text color="gray100" size="bodyS" css={{ whiteSpace: "nowrap" }}>
            {value?.name}
          </Text>
        </span>
      );
    }

    if (showLockWarning && lockedPeriodDate) {
      return (
        <>
          <Text
            color="gray100"
            size="bodyS"
            onClick={(e) => {
              e.stopPropagation();
              if (!transaction?.detail.postedAt) {
                setShowWarning(true);
              }
            }}
          >
            {value?.name}
          </Text>
          <WarningDialog
            period={toCalendarMonthString(lockedPeriodDate)}
            warnings={[LockDayWarning.TransactionWarning]}
            open={showWarning}
            onOpenChange={setShowWarning}
            transactionDate={transaction?.date ? parseDate(transaction.date) : null}
            transactionId={transaction?.id}
          />
        </>
      );
    }

    const Noop = ({ children }: React.PropsWithChildren<unknown>) => <>{children}</>;
    const Wrapper = tooltipProps
      ? ({ children }: React.PropsWithChildren<unknown>) => (
          <Tooltip content={value.name} arrow={false} {...tooltipProps}>
            {children}
          </Tooltip>
        )
      : Noop;

    return (
      <div>
        <CategoryModal
          trigger={
            <div
              onClick={(e) => {
                // Don't open the drawer
                e.stopPropagation();
              }}
            >
              <Wrapper>
                <Tag role="button" wrap={wrap} variant="pill" maxWidth={maxWidth}>
                  {isInvoicePayment ||
                    (isBillPayment && <Bill style={{ height: 14, width: 14 }} />)}
                  <Text>{value.name}</Text>
                </Tag>
              </Wrapper>
            </div>
          }
          categories={categories}
          initialValue={value}
          transaction={transaction}
          onChange={onChange}
          initialStep={CategorySteps.Category}
        />
      </div>
    );
  }
);

export default SelectCategoryInput;
