import { useCallback } from "react";
import { ApolloCache, FetchResult } from "@apollo/client";

import { useToasts } from "@puzzle/ui";
import { pluralize } from "@puzzle/utils";

import { useActiveCompany } from "components/companies";
import { DetailConfirmedState } from "graphql/types";
import {
  useBulkFinalizeTransactionsMutation,
  useBulkFinalizeTransactionsByFiltersMutation,
  BulkFinalizeTransactionsMutation,
  BulkFinalizeTransactionsByFiltersMutation,
  GetTransactionsQuery,
  GetTransactionsDocument,
  GetTransactionsQueryVariables,
} from "../graphql.generated";

export default function useBulkFinalizeTransactions(queryVariables: GetTransactionsQueryVariables) {
  const { company } = useActiveCompany<true>();
  const companyId = company.id;
  const { toast } = useToasts();
  const [_bulkFinalizeIds, bulkFinalizeIdResult] = useBulkFinalizeTransactionsMutation();
  const [_bulkFinalizeFilter, bulkFinalizeFilterResult] =
    useBulkFinalizeTransactionsByFiltersMutation();
  const isBulkUpdating = bulkFinalizeIdResult.loading && bulkFinalizeFilterResult.loading;
  const toastBulkFinalizeResponse = useCallback(
    (
      result: FetchResult<
        BulkFinalizeTransactionsMutation | BulkFinalizeTransactionsByFiltersMutation
      >,
      isUnfinalized?: boolean
    ) => {
      if (result.data) {
        const { failedIds, updatedIds } =
          "bulkUpdateFinalizedState" in result.data
            ? result.data.bulkUpdateFinalizedState
            : result.data.bulkUpdateFinalizedStateByFilters;
        if (updatedIds.length > 0) {
          toast({
            status: "success",
            title: `${pluralize(updatedIds.length, "transaction", "transactions")} ${
              isUnfinalized ? "unfinalized" : "finalized"
            }`,
          });
        }
        if (failedIds.length > 0) {
          toast({
            status: "warning",
            title: `${pluralize(
              failedIds.length,
              "transaction requires",
              "transactions require"
            )} user action`,
            message:
              "These may include transactions that are potentially capitalizable, unlinked credit card payments, and transactions in locked periods. Please review these transactions and then finalize.",
          });
        }
      } else {
        throw new Error();
      }
    },
    [toast]
  );
  const finalizeIdsInCache = useCallback(
    (cache: ApolloCache<any>, updatedIds: string[], isUnfinalized?: boolean) => {
      const currentQuery = cache.readQuery<GetTransactionsQuery>({
        variables: queryVariables,
        query: GetTransactionsDocument,
      });

      if (!currentQuery?.company || updatedIds.length === 0) {
        return;
      }

      cache.writeQuery<GetTransactionsQuery>({
        variables: queryVariables,
        query: GetTransactionsDocument,
        data: {
          ...currentQuery,
          company: {
            ...currentQuery.company,
            transactions: {
              ...currentQuery.company.transactions,
              nodes: currentQuery.company.transactions.nodes.map((transaction) => ({
                ...transaction,
                detail: {
                  ...transaction.detail,
                  confirmedState: updatedIds.includes(transaction.id)
                    ? isUnfinalized
                      ? DetailConfirmedState.UserAssigned
                      : DetailConfirmedState.Finalized
                    : transaction.detail.confirmedState,
                },
              })),
            },
          },
        },
      });
    },
    [queryVariables]
  );
  const bulkFinalizeIds = useCallback(
    async (transactionIds: string[], isUnfinalized?: boolean) => {
      try {
        const result = await _bulkFinalizeIds({
          variables: {
            input: {
              finalize: !isUnfinalized,
              transactionIds,
            },
          },

          update(cache, { data }) {
            const updatedIds = data?.bulkUpdateFinalizedState.updatedIds || [];
            finalizeIdsInCache(cache, updatedIds, isUnfinalized);
          },
        });

        toastBulkFinalizeResponse(result, isUnfinalized);
        return result;
      } catch (err) {
        toast({ status: "error", message: "Something went wrong." });
      }
    },
    [_bulkFinalizeIds, toastBulkFinalizeResponse, finalizeIdsInCache, toast]
  );

  const bulkFinalizeFilter = useCallback(
    async (isUnfinalized?: boolean) => {
      try {
        const result = await _bulkFinalizeFilter({
          variables: {
            input: {
              companyId,
              finalize: !isUnfinalized,
              filterBy: queryVariables.filterBy ?? {},
            },
          },

          update(cache, { data }) {
            const updatedIds = data?.bulkUpdateFinalizedStateByFilters.updatedIds || [];
            finalizeIdsInCache(cache, updatedIds, isUnfinalized);
          },
        });

        toastBulkFinalizeResponse(result, isUnfinalized);
        return result;
      } catch (err) {
        toast({ status: "error", message: "Something went wrong." });
      }
    },
    [
      _bulkFinalizeFilter,
      companyId,
      finalizeIdsInCache,
      queryVariables.filterBy,
      toast,
      toastBulkFinalizeResponse,
    ]
  );
  return {
    bulkFinalizeIds,
    bulkFinalizeFilter,
    isBulkUpdating,
  };
}
