/* eslint-disable react/display-name */
import React from "react";
import {
  useGetTransaction,
  useUpdateCategory,
  useExtraTransactionState,
  UpdateCategoryMetricsLocations,
  UpdateCategoryMetricsView,
} from "../hooks/useSingleTransaction";
import SelectCategoryInput from "components/transactions/SelectCategoryInput";
import { CategoryFragment } from "graphql/types";
interface CategoryMenuProps {
  id: string;
  categories: CategoryFragment[];
  metrics?: { location: UpdateCategoryMetricsLocations; component: UpdateCategoryMetricsView };
}

const UpdateCategoryMenu = React.forwardRef<HTMLButtonElement, CategoryMenuProps>(
  ({ id, categories, metrics: { location, component } = {} }, ref) => {
    const { data } = useGetTransaction(id);
    const transaction = data?.transaction;
    const updateCategory = useUpdateCategory(transaction);
    const { canEditSplits } = useExtraTransactionState(transaction);

    const currentCategory = transaction?.detail?.category;

    const handleChange = (val: CategoryFragment) => {
      if (canEditSplits && val) {
        updateCategory({ category: val, metrics: { location, component } });
      }
    };

    const value = currentCategory ? currentCategory : categories[0];
    return (
      <div>
        <SelectCategoryInput
          value={value}
          categories={categories}
          canEdit={canEditSplits}
          onChange={handleChange}
          transaction={transaction}
          isBillPayment={!!transaction?.detail.isBillPayment}
          isInvoicePayment={!!transaction?.detail.isInvoicePayment}
        />
      </div>
    );
  }
);

export default UpdateCategoryMenu;
