import { parseDate } from "@internationalized/date";
import { styled, Button } from "@puzzle/ui";
import { sumAmounts } from "@puzzle/utils";
import { differenceInMonths } from "date-fns";
import { CalendarDateString } from "scalars";
import { ContractRevenueSchedulePeriodFragment } from "./graphql.generated";
import { ClassAwareType } from "graphql/types";

export const errorToastConfig = {
  title: "The invoice hasn't been saved",
  message: `Something went wrong, and our team has been notified. We apologize for the inconvenience. Please try again later.`,
  status: "warning",
} as const;

export const CONTAINER_WIDTH = 1460;

export const Container = styled("div", {
  display: "grid",
  border: "1px solid $mauve400",
  borderRadius: "$2",
  maxWidth: CONTAINER_WIDTH,
  transition: "200ms",
  "@media print": {
    transform: "scale(0.95)",
    "*": { color: "$black !important" },
  },

  variants: {
    sidebarOpen: {
      true: { gridTemplateColumns: "500px 1fr" },
      false: { gridTemplateColumns: "80px 1fr" },
    },
  },
});

export const FormContainer = styled("div", {
  padding: "$4",
});

export const Header = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const FieldsAndActions = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row-reverse",
  marginTop: "$4",
  marginBottom: "$8",
  gap: "$4",
});

export const Fields = styled("div", {
  flex: 1,
});

export const Actions = styled("div", {
  display: "flex",
  gap: "$2",
  flexDirection: "column",

  [`${Button}`]: {
    width: "100%",
  },
  "@media print": {
    display: "none",
  },
});

export const Footer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "$2",
  marginTop: "$8",
  "@media print": { display: "none" },
});

export const Table = styled("table", {
  maxWidth: "100%",
  tableLayout: "fixed",
  borderCollapse: "collapse",

  "th, td": {
    textAlign: "left",
    color: "$gray500",
    textVariant: "$bodyXS",
    fontSize: "$bodyS",
    fontWeight: "$bold",

    borderColor: "#302F4A",
    borderStyle: "solid",
    borderWidth: 0,
    borderBottomWidth: 1,
  },

  th: {
    padding: "$1h $1",
  },

  td: {
    padding: "0",

    [`&[data-border="true"]`]: {
      borderLeftWidth: 1,
    },

    "&:first-of-type": {
      paddingLeft: "$1h",
    },
  },

  tfoot: {
    td: {
      textAlign: "right",
      borderBottomWidth: 0,
    },
  },
});

export const AddRow = styled("tr", {
  td: {
    padding: "$1 0",
    position: "relative",
    left: "-$1",
  },
  "@media print": { display: "none" },
});

export const BorderedFooterContent = styled("div", {
  borderColor: "#302F4A",
  borderStyle: "solid",
  borderWidth: 0,
  borderBottomWidth: 1,
  padding: "$2 $1",
  height: "100%",
});

export const InputBox = styled("div", {
  flex: 1,
});

export const InputGroup = styled("div", {
  display: "flex",
  gap: "$1",

  [`${InputBox}`]: {
    span: {
      color: "$gray50",
      fontWeight: "$bold",
    },

    label: {
      color: "$gray300",
      fontSize: "$bodyXS",
    },
  },
});

export const TableContainer = styled("div", {
  display: "flex",
  overflow: "hidden",

  table: {
    height: "100%",

    th: {
      background: "$mauve850",
      border: "none !important",
    },

    tr: {
      background: "$mauve800",
    },
  },
});

export function toScheduleDateRangePayload({
  startDate,
  serviceDuration,
}: {
  startDate: CalendarDateString;
  serviceDuration: string;
}) {
  return {
    fromInclusive: startDate,
    toInclusive: parseDate(startDate)
      .add({
        months: parseInt(serviceDuration),
      })
      .subtract({ days: 1 })
      .toString(),
  };
}

export function calculateServiceDuration({
  endDay,
  startDay,
  timeZone,
}: {
  endDay?: CalendarDateString | null;
  startDay?: CalendarDateString | null;
  timeZone: string;
}) {
  if (endDay && startDay) {
    const endDate = parseDate(endDay).toDate(timeZone);
    const startDate = parseDate(startDay).toDate(timeZone);
    const endOfMonth = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
    const startOfMonth = new Date(startDate.getFullYear(), startDate.getMonth(), 0);

    return differenceInMonths(endOfMonth, startOfMonth);
  }

  return 0;
}

export function sumPeriodTotal(periods?: ContractRevenueSchedulePeriodFragment[] | null) {
  if (!periods) return 0;
  return sumAmounts(periods?.map(({ revenue }) => revenue.amount ?? "0"));
}

// Need map to match classAwareEntity invoice id aliases
export const mapClassAwareTypeToId = {
  [ClassAwareType.InvoiceLine]: "invoiceItemId",
  [ClassAwareType.InvoiceTaxLine]: "invoiceTaxLineId",
  [ClassAwareType.InvoiceShippingLine]: "invoiceShippingLineId",
  [ClassAwareType.InvoiceDiscountLine]: "invoiceDiscountLineId",
} as Record<ClassAwareType, string>;
