import React from "react";
import { Button, Dialog, Stack, DialogProps, styled } from "@puzzle/ui";
import Link from "./Link";

const ShareList = styled("ul", {
  padding: "0 0 0 5px",
  margin: 0,

  "& li": {
    listStylePosition: "inside",
  },
});

export const DataConsentModal = ({
  onConfirm,
  onOpenChange,
  ...props
}: DialogProps & {
  onConfirm: () => void;
}) => {
  return (
    <Dialog size="xsmall" {...props}>
      <Dialog.Title css={{ textVariant: "$heading3" }}>Data sharing authorization</Dialog.Title>

      <Dialog.Body
        css={{
          textVariant: "$body",
          color: "$gray300",
          a: {
            color: "$green700",
            textDecoration: "none",
          },
        }}
      >
        <Stack gap="3">
          <div>
            You are authorizing Puzzle Financial Inc. to share certain information with OpenAI.
          </div>

          <div>
            This will allow you to:
            <ShareList>
              <li>Share individual transaction data</li>
              <li>Share the chart of accounts</li>
              <li>Share your business model (generically)</li>
            </ShareList>
          </div>

          <div>
            By clicking Allow, you allow Puzzle to use your information in accordance to OpenAI’s{" "}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://openai.com/policies/terms-of-use"
            >
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link target="_blank" rel="noopener noreferrer" href="https://openai.com/policies">
              Privacy Policies
            </Link>
            .
          </div>
        </Stack>
      </Dialog.Body>

      <Dialog.Footer align="right">
        <Dialog.Actions>
          <Dialog.Close asChild>
            <Button variant="secondary" onClick={() => onOpenChange?.(false)}>
              Cancel
            </Button>
          </Dialog.Close>

          <Button variant="primary" onClick={onConfirm}>
            Allow
          </Button>
        </Dialog.Actions>
      </Dialog.Footer>
    </Dialog>
  );
};
