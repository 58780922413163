import React from "react";

export const IntegrationIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
      {...props}
    >
      <rect width="28" height="28" fill="#5A5A7A" rx="4"></rect>
      <path
        fill="#D1D1D5"
        fillRule="evenodd"
        d="M21 26a5 5 0 100-10 5 5 0 000 10zm0-7.969c.259 0 .469.21.469.469v3.125a.469.469 0 01-.938 0V18.5c0-.259.21-.469.469-.469zm.625 5.469a.625.625 0 11-1.25 0 .625.625 0 011.25 0z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#B3B2B8"
        fillRule="evenodd"
        d="M24 9.5c0-.813-.688-1.5-1.5-1.5h-6l-2-2h-5A1.5 1.5 0 008 7.5v9c0 .844.656 1.5 1.5 1.5h6.303A5.998 5.998 0 0121 15c1.093 0 2.117.292 3 .803V9.5zM15.341 19a5.99 5.99 0 00-.258 3H5.5A1.48 1.48 0 014 20.5v-9A1.5 1.5 0 015.5 10H7v6.5C7 17.906 8.094 19 9.5 19h5.841z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
