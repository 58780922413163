import React, { useMemo, useEffect, useCallback, useState } from "react";
import * as yup from "yup";
import {
  useForm,
  UseFormReturn,
  useFormContext,
  FormProvider,
  SubmitHandler,
} from "react-hook-form";
import Big from "big.js";
import { yupResolver } from "@hookform/resolvers/yup";
import { CalendarDateString } from "scalars";

import { parseDate, today } from "@puzzle/utils";
import { useToasts } from "@puzzle/ui";

import useAppRouter from "lib/useAppRouter";
import useCategories from "components/common/hooks/useCategories";
import { CategoryFragment } from "graphql/fragments/category.generated";
import {
  MembershipRole,
  InvoiceStatus,
  CreateInvoiceInput,
  ContractRevenueSchedulePostingMethod,
} from "graphql/types";
import { useActiveCompany, useCompanyDateFormatter } from "components/companies";
import { Route } from "lib/routes";

import {
  useGetInvoiceQuery,
  SingleInvoiceFragment,
  useCreateInvoiceMutation,
  useUpdateInvoiceMutation,
} from "../graphql.generated";
import { glAmountToDisplayString } from "../../Accounting/GeneralLedger/LedgerAccountViewBody";
import { calculateServiceDuration, errorToastConfig, toScheduleDateRangePayload } from "../shared";
import { CustomerFragment, ProductFragment } from "graphql/types";
import Analytics from "lib/analytics";
import { useExpenseCategories } from "components/common/CategoriesFilter";
import { ClassSegment } from "components/common/Classifications/TagEntity";
import { sum } from "lodash";
import { useFormPersist } from "components/common/useFormPersist";

type InvoiceFormContextType = Omit<
  ReturnType<typeof useInvoiceForm>,
  keyof ReturnType<typeof useForm>
>;

export type ScheduleFormValues = {
  id?: string;
  serviceDuration: string;
  startDate?: CalendarDateString;
  accountingConfigurationId?: string;
};

type FormLine = {
  id: string | undefined;
  issueDate: CalendarDateString;
  product?: Pick<ProductFragment, "id" | "name">;
  description?: string;
  category?: CategoryFragment;
  amount?: number;
  schedule?: ScheduleFormValues;
  segments?: ClassSegment[];
};

export type FormValues = {
  dueDate: CalendarDateString;
  issueDate: CalendarDateString;
  customer: CustomerFragment;
  externalId?: string;
  description?: string;
  total: number;
  lines: FormLine[];
  shippingLines: FormLine[];
  discountLines: FormLine[];
};

export const durationOptions = Array(99)
  .fill(0)
  .map((_, i) => {
    const duration = (i + 1).toString();
    return {
      value: duration,
      label: duration,
    };
  });

const isLineAmountChange = (name?: string) => {
  return (
    !!name &&
    (name.startsWith("lines") ||
      name.startsWith("discountLines") ||
      name.startsWith("shippingLines") ||
      name.startsWith("taxLines")) &&
    name.endsWith(".amount")
  );
};

const isEntireLineChange = (name?: string) => {
  return !!name && ["lines", "discountLines", "shippingLines", "taxLines"].includes(name);
};

export const isPostedOrPaid = (invoiceStatus?: InvoiceStatus): boolean => {
  if (invoiceStatus) {
    return [InvoiceStatus.Posted, InvoiceStatus.Paid].includes(invoiceStatus);
  } else {
    return false;
  }
};

export const scheduleSchema = yup
  .object({
    serviceDuration: yup.string().required(),
    startDate: yup
      .string()
      .required()
      .test("date", (value) => Boolean(value && parseDate(value))),
    accountingConfigurationId: yup.string().nullable().default(null).notRequired(),
  })
  .nullable()
  .default(null)
  .notRequired();

const validationSchema = yup.object({
  dueDate: yup.string().required(),
  issueDate: yup.string().required(),
  customer: yup.object().required(),
  externalId: yup.string(),
  description: yup.string().required(),
  lines: yup
    .array()
    .of(
      yup.object({
        description: yup.string(),
        product: yup.object().nullable(),
        category: yup.object().required(),
        amount: yup.number().required(),
        schedule: scheduleSchema,
      })
    )
    .min(1)
    .required(),
  shippingLines: yup
    .array()
    .of(
      yup.object({
        description: yup.string(),
        category: yup.object().required(),
        amount: yup.number().required(),
      })
    )
    .required(),
  discountLines: yup
    .array()
    .of(
      yup.object({
        description: yup.string(),
        category: yup.object().required(),
        amount: yup.number().required(),
        schedule: scheduleSchema,
      })
    )
    .required(),
  total: yup
    .number()
    .required()
    .test("total", (value) => Boolean(value && value > 0)),
});

const transformLines = (lines: FormLine[]) => {
  return lines.map((line) => ({
    id: line.id,
    description: line.description,
    //if new product don't send id
    productId: line.product?.id?.startsWith("product") ? undefined : line.product?.id,
    productName: line.product?.name,
    coaKey: line.category?.coaKey ?? "",
    amount: Big(line.amount || 0).toString(),
    schedule: line.schedule
      ? {
          postingMethod: ContractRevenueSchedulePostingMethod.Automatically,
          dateRange: toScheduleDateRangePayload({
            startDate: line.schedule.startDate ?? "",
            serviceDuration: line.schedule.serviceDuration,
          }),
          accountingConfigurationId: line.schedule.accountingConfigurationId,
        }
      : undefined,
    segments: line.segments?.map((segment) => {
      return {
        class: segment.reportingClass.name,
        segment: segment.name,
        reportingClassType: segment.reportingClass.type,
      };
    }),
  }));
};

const calculateNumSchedules = (lines: ReturnType<typeof transformLines>) =>
  lines.reduce((acc, { schedule }) => (schedule ? acc + 1 : acc), 0);

/**
 * Syncs related form values.
 */
const useExistingInvoiceValueSync = ({
  form,
  existingInvoice,
}: {
  form: UseFormReturn<FormValues>;
  existingInvoice?: SingleInvoiceFragment;
}) => {
  const { timeZone } = useActiveCompany();
  const { categoriesByPermaKey } = useCategories();

  useEffect(() => {
    if (!existingInvoice) {
      return;
    }

    form.reset({
      total: parseFloat(existingInvoice.amount.amount),
      customer: existingInvoice.customer || undefined,
      issueDate: existingInvoice.issueDate || undefined,
      dueDate: existingInvoice.dueDate || undefined,
      description: existingInvoice.description || "",
      externalId: existingInvoice.shortId || "",
      lines: existingInvoice.lines.map((line) => ({
        product: line.product || undefined,
        description: line.description || "",
        category: line.coaKey ? categoriesByPermaKey?.[line.coaKey] : undefined,
        amount: parseFloat(line.amount.amount),
        id: line.invoiceItemId,
        segments: line.classSegments,
        schedule: line.schedule?.id
          ? {
              id: line.schedule?.id ?? undefined,
              serviceDuration: calculateServiceDuration({
                startDay: line.schedule?.startDay,
                endDay: line.schedule?.endDay,
                timeZone,
              }).toString(),
              startDate: line.schedule?.startDay ?? undefined,
              accountingConfigurationId:
                line.schedule.contractLine?.accountingConfigurationId ?? undefined,
            }
          : undefined,
      })),
      shippingLines: existingInvoice.shippingLines.map((line) => ({
        product: undefined,
        description: line.description || "",
        category: line.ledgerCategory
          ? categoriesByPermaKey?.[line.ledgerCategory.coaKey]
          : undefined,
        amount: parseFloat(line.subtotal.amount),
        id: line.invoiceShippingLineId,
        segments: line.classSegments,
      })),
      discountLines: existingInvoice.discountLines.map((line) => ({
        product: undefined,
        description: line.description || "",
        category: line.ledgerCategory
          ? categoriesByPermaKey?.[line.ledgerCategory.coaKey]
          : undefined,
        amount: -parseFloat(line.amount.amount),
        id: line.invoiceDiscountLineId,
        segments: line.classSegments,
        schedule: line.schedule?.id
          ? {
              id: line.schedule?.id ?? undefined,
              serviceDuration: calculateServiceDuration({
                startDay: line.schedule?.startDay,
                endDay: line.schedule?.endDay,
                timeZone,
              }).toString(),
              startDate: line.schedule?.startDay ?? undefined,
            }
          : undefined,
      })),
    });

    // Revalidate
    form.trigger();
  }, [existingInvoice, form, categoriesByPermaKey, timeZone]);
};

/**
 * Syncs related form values.
 */
const useFormValueSync = ({
  form: { setValue, watch },
  existingInvoice,
}: {
  form: UseFormReturn<FormValues>;
  existingInvoice?: SingleInvoiceFragment;
}) => {
  const dateFormatter = useCompanyDateFormatter({ dateStyle: "short" });

  useEffect(() => {
    const subscription = watch(
      ({ lines, discountLines, shippingLines, issueDate, customer, description }, { name }) => {
        const lineAmountChanged = isLineAmountChange(name);
        const entireLineChanged = isEntireLineChange(name); // Line was added or removed
        const toDescription = `${customer?.name} - ${dateFormatter.format(
          parseDate(issueDate as CalendarDateString)
        )}`;

        /**
         * Recalculates total when line amounts change.
         */
        if (lines && (lineAmountChanged || entireLineChanged)) {
          const lineTotal = sum(lines.map((line) => (line?.amount ? line.amount : "0")));
          const discountLineTotal = sum(
            discountLines?.map((line) => (line?.amount ? line.amount : "0"))
          );
          const shippingLineTotal = sum(
            shippingLines?.map((line) => (line?.amount ? line.amount : "0"))
          );

          // will need to change when/if tax lines are editable
          const taxLineTotal = sum(
            existingInvoice?.taxLines.map((line) =>
              line?.amount ? Number(line.amount.amount) : "0"
            )
          );
          setValue("total", lineTotal - discountLineTotal + shippingLineTotal + taxLineTotal, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
          });
        }

        /**
         * Keeps line item invoice dates in sync with top level invoice date.
         */
        if (name === "issueDate") {
          lines?.forEach((_, i) => {
            setValue(`lines.${i}.issueDate`, issueDate as CalendarDateString);
          });
        }

        /**
         * Update description with customer and issue date
         */
        if (customer && issueDate?.toString && (name === "issueDate" || name === "customer")) {
          setValue("description", toDescription, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
          });
        }

        /**
         * Update line description with top level description if:
         * - there's only one line
         * - description was auto populated from vendor
         */
        if (name === "description") {
          lines?.forEach((_, i) => {
            if (lines.length === 1 || (customer && description === toDescription)) {
              setValue(`lines.${i}.description`, description ?? "");
            }
          });
        }
      }
    );

    return () => subscription.unsubscribe();
  }, [setValue, watch, dateFormatter, existingInvoice]);
};

const addAnalytics = (invoice: SingleInvoiceFragment, numberOfSchedules: number) => {
  if (invoice.status === InvoiceStatus.Draft) {
    Analytics.invoicePosted({
      invoiceId: invoice.id,
      totalRows: invoice.lines.length,
      numberOfSchedules,
    });
  } else {
    Analytics.invoiceDrafted({
      invoiceId: invoice.id,
      totalRows: invoice.lines.length,
      numberOfSchedules,
    });
  }
};

const useCreateInvoiceFromFormData = ({
  clearPersistedForm,
}: {
  clearPersistedForm: () => void;
}) => {
  const { company } = useActiveCompany<true>();
  const companyId = company.id;
  const { toast } = useToasts();
  const { goToPathWithCurrentQuery } = useAppRouter();
  const dateFormatter = useCompanyDateFormatter({ dateStyle: "short" });
  const [createInvoiceMutation, { called, error, loading }] = useCreateInvoiceMutation();
  const [updateInvoiceMutation] = useUpdateInvoiceMutation();

  const goToNewInvoice = useCallback(
    (invoiceId: string) => {
      const t = setTimeout(() => {
        goToPathWithCurrentQuery(`${Route.invoices}/${invoiceId}`, ["id"]);
        return () => clearTimeout(t);
      });
    },
    [goToPathWithCurrentQuery]
  );

  const statusToAction = (invoiceStatus: InvoiceStatus): string => {
    // only two expected statuses are Posted and Draft here
    if (invoiceStatus === InvoiceStatus.Draft) {
      return "drafted";
    }
    return invoiceStatus.toLowerCase();
  };

  const addSuccessToast = useCallback(
    (invoice: SingleInvoiceFragment) => {
      const action = statusToAction(invoice.status);
      const amount = glAmountToDisplayString(invoice.amount.amount);
      let message = `You ${action} a invoice`;
      if (invoice.description) {
        message += ` for ${invoice.description}`;
      }
      message += ` for ${amount}`;
      if (invoice.dueDate) {
        message += ` that is due on ${dateFormatter.format(parseDate(invoice.dueDate))}`;
      }

      toast({
        title: `Invoice ${action}`,
        message: message,
      });
    },
    [toast, dateFormatter]
  );

  const addErrorToast = useCallback(() => {
    toast(errorToastConfig);
  }, [toast]);

  return {
    mutate: useCallback(
      async (formData: FormValues, isDraft: boolean, overrides?: Partial<CreateInvoiceInput>) => {
        const lines = transformLines(formData.lines);

        await createInvoiceMutation({
          variables: {
            input: {
              companyId,
              description: formData.description,
              dueDate: formData.dueDate,
              issueDate: formData.issueDate,
              customerId: formData.customer?.id,
              externalId: formData.externalId,
              status: isDraft ? InvoiceStatus.Draft : InvoiceStatus.Posted,
              lines: lines,

              ...overrides,
            },
          },
          onCompleted: ({ createInvoice }) => {
            addSuccessToast(createInvoice);
            goToNewInvoice(createInvoice.id);
            addAnalytics(createInvoice, calculateNumSchedules(lines));
            clearPersistedForm();
          },
          onError: () => {
            addErrorToast();
          },
        });
      },
      [
        companyId,
        createInvoiceMutation,
        addErrorToast,
        addSuccessToast,
        goToNewInvoice,
        clearPersistedForm,
      ]
    ),
    updateMutate: useCallback(
      async (formData: FormValues, invoiceId: string, isDraft: boolean) => {
        const lines = transformLines(formData.lines);
        await updateInvoiceMutation({
          variables: {
            input: {
              invoiceId,
              description: formData.description,
              dueDate: formData.dueDate,
              issueDate: formData.issueDate,
              customerId: formData.customer?.id,
              externalId: formData.externalId,
              status: isDraft ? InvoiceStatus.Draft : InvoiceStatus.Posted,
              lines: lines,
            },
          },
          onCompleted: ({ updateInvoice }) => {
            addSuccessToast(updateInvoice.invoice);
            goToNewInvoice(updateInvoice.invoice.id);
            addAnalytics(updateInvoice.invoice, calculateNumSchedules(lines));
            clearPersistedForm();
          },
          onError: () => {
            addErrorToast();
          },
        });
      },
      [updateInvoiceMutation, addErrorToast, addSuccessToast, goToNewInvoice, clearPersistedForm]
    ),
    mutateSucceeded: called && !error && !loading,
  };
};

export const useInvoiceForm = ({ id }: { id?: string }) => {
  const { categoriesByPermaKey } = useCategories();
  const { expenseCategories } = useExpenseCategories();
  const [submittingStatus, setSubmittingStatus] = useState<`${InvoiceStatus}` | null>(null);
  const { timeZone, membershipRole } = useActiveCompany<true>();
  const issueDate = today(timeZone);
  const { data, loading: existingInvoiceLoading } = useGetInvoiceQuery({
    variables: id ? { id } : undefined,
    skip: !id,
  });

  const existingInvoice = data?.invoice;

  const form = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      issueDate: issueDate.toString(),
      dueDate: issueDate.add({ months: 1 }).toString(),
      externalId: "",
      description: "",
      total: 0,
      lines: [
        {
          issueDate: issueDate.toString(),
          description: "",
        },
      ],
    },
  });

  const [readOnly, setReadOnly] = useState(
    existingInvoice?.status !== InvoiceStatus.Draft &&
      (membershipRole === MembershipRole.Viewer || !!existingInvoice)
  );

  useEffect(() => {
    setReadOnly(
      existingInvoice?.status !== InvoiceStatus.Draft &&
        (membershipRole === MembershipRole.Viewer || !!existingInvoice)
    );
  }, [existingInvoice, id, membershipRole]);

  // If invoice exists, then create a separately session storage value.
  const { clearPersistedForm } = useFormPersist<FormValues>({
    entity: "invoice",
    setValue: form.setValue,
    watch: form.watch,
    skip: Boolean(id),
  });

  useFormValueSync({ form, existingInvoice });
  useExistingInvoiceValueSync({ form, existingInvoice });

  const createInvoiceFromFormData = useCreateInvoiceFromFormData({ clearPersistedForm });

  const onSaveDraft = useMemo(() => {
    const saveDraft: SubmitHandler<FormValues> = async (formData) => {
      setSubmittingStatus(InvoiceStatus.Draft);
      if (existingInvoice) {
        await createInvoiceFromFormData.updateMutate(formData, existingInvoice.id, true);
      } else {
        await createInvoiceFromFormData.mutate(formData, true);
      }
    };

    return form.handleSubmit((data) => saveDraft(data));
  }, [form, createInvoiceFromFormData, existingInvoice]);

  const onPost = useMemo(() => {
    const post: SubmitHandler<FormValues> = async (formData) => {
      setSubmittingStatus(InvoiceStatus.Posted);
      if (existingInvoice) {
        await createInvoiceFromFormData.updateMutate(formData, existingInvoice.id, false);
      } else {
        await createInvoiceFromFormData.mutate(formData, false);
      }
    };

    return form.handleSubmit((data) => post(data));
  }, [form, createInvoiceFromFormData, existingInvoice]);

  return {
    form,
    categoriesByPermaKey,
    existingInvoice,
    onSaveDraft,
    onPost,
    submittingStatus,
    categories: expenseCategories,
    mutateSucceeded: createInvoiceFromFormData.mutateSucceeded,
    loading: existingInvoiceLoading,
    readOnly,
    setReadOnly,
    isEditor: membershipRole !== MembershipRole.Viewer,
  };
};

const InvoiceFormContext = React.createContext<InvoiceFormContextType | null>(null);

export const useInvoiceFormContext = () => {
  const formContext = useFormContext<FormValues>();
  const customContext = React.useContext(InvoiceFormContext);

  if (!customContext) {
    throw new Error("Must be used in InvoiceFormProvider");
  }

  return useMemo(() => ({ ...formContext, ...customContext }), [customContext, formContext]);
};

export const InvoiceFormProvider = ({
  form,
  readOnly,
  setReadOnly,
  isEditor,
  loading,
  categories,
  categoriesByPermaKey,
  onPost,
  onSaveDraft,
  submittingStatus,
  mutateSucceeded,
  existingInvoice,
  ...props
}: ReturnType<typeof useInvoiceForm> & {
  children: React.ReactNode;
}) => {
  const customContextValues = useMemo(
    () => ({
      form,
      readOnly,
      setReadOnly,
      isEditor,
      loading,
      categories,
      categoriesByPermaKey,
      onPost,
      onSaveDraft,
      submittingStatus,
      mutateSucceeded,
      existingInvoice,
    }),
    [
      form,
      readOnly,
      setReadOnly,
      isEditor,
      loading,
      categories,
      categoriesByPermaKey,
      onPost,
      onSaveDraft,
      submittingStatus,
      mutateSucceeded,
      existingInvoice,
    ]
  );

  return (
    <InvoiceFormContext.Provider value={customContextValues}>
      <FormProvider {...form} {...props} />
    </InvoiceFormContext.Provider>
  );
};
