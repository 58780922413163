import { WatchQueryFetchPolicy } from "@apollo/client";
import { RefetchWritePolicy } from "@apollo/client/core/watchQueryOptions";
import { chunk } from "lodash";
import { useCallback, useMemo } from "react";
import { GetTransactionsQueryVariables, useGetTransactionsQuery } from "../graphql.generated";

export default function useTransactions(
  queryVariables: GetTransactionsQueryVariables,
  options?: {
    fetchPolicy?: WatchQueryFetchPolicy;
    nextFetchPolicy?: WatchQueryFetchPolicy;
    refetchWritePolicy?: RefetchWritePolicy;
    skip?: boolean;
  }
) {
  const { loading, data, previousData, fetchMore, startPolling, stopPolling, refetch } =
    useGetTransactionsQuery({
      context: { batch: false },
      variables: queryVariables,
      fetchPolicy: "cache-and-network",
      skip: false,
      nextFetchPolicy: "cache-first",
      notifyOnNetworkStatusChange: true,
      ...options,
    });

  // Show the previous data when infinite loading
  const result =
    loading && queryVariables.page?.after
      ? previousData?.company?.transactions
      : data?.company?.transactions;

  const transactions = useMemo(() => result?.nodes ?? [], [result?.nodes]);
  const totals = useMemo(
    () => ({
      count: result?.pageInfo.total ?? 0,
      gain: result ? parseFloat(result.metadata.gain) : 0,
      loss: result ? parseFloat(result.metadata.loss) : 0,
      sum: result ? parseFloat(result.metadata.sum) : 0,
      absoluteValueTotal: result ? parseFloat(result.metadata.absoluteValueTotal) : 0,
      assignedToMeCount: result?.assignedToMeCount ?? 0,
    }),
    [result]
  );
  const pageInfo = result?.pageInfo;
  const endCursor = pageInfo?.endCursor;

  const fetchNextPage = useCallback(() => {
    if (!queryVariables) {
      return;
    }

    fetchMore({
      variables: {
        ...queryVariables,
        page: {
          ...queryVariables.page,
          after: endCursor,
        },
      },
    });
  }, [endCursor, fetchMore, queryVariables]);

  return {
    loading,
    transactions,
    transactionsByPage: queryVariables.page
      ? chunk(transactions, queryVariables.page?.count)
      : null,
    startPolling,
    stopPolling,
    totals,
    fetchNextPage,
    pageInfo,
    hasMore: Boolean(endCursor),
    refetch,
    queryVariables,
  };
}
