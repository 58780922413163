import { Rippling } from "@puzzle/icons";
import React from "react";
import { IntegrationLogo } from "../shared";

const RipplingLogo = ({ circular }: { circular?: boolean }) => {
  return (
    <IntegrationLogo key="rippling" color="#FCB315" circular={circular}>
      <Rippling />
    </IntegrationLogo>
  );
};

export default RipplingLogo;
