import { FileFragment } from "graphql/types";
import React, { useEffect, useMemo, useState } from "react";
import { usePreviousDistinct } from "react-use";
import { SetRequired } from "type-fest";
import useAppRouter from "lib/useAppRouter";
import {
  BasicTransactionFragment,
  BasicTransactionFragmentDoc,
  FullTransactionFragment,
} from "../graphql.generated";
import { useExtraTransactionState, useGetFullTransaction } from "../hooks/useSingleTransaction";
import { useFragment } from "@apollo/client";
import Analytics from "lib/analytics";
import { useToggle } from "react-use";
import { useActiveCompany } from "components/companies";
import { isLastMileWorkflow } from "components/AI/useAI";
import { OPTIMISTIC_UI_ASSIGNMENT_ID } from "../hooks/useAssignments";

/**
 * what function the comment box provides
 */
export type CommentBoxMode = "messaging" | "provide_ai_context";

type DetailPaneContextType = {
  previewFile?: FileFragment;
  setPreviewFile: (file?: FileFragment) => void;
  close?: () => void;
  transaction?: FullTransactionFragment | null;
  basicTransaction?: BasicTransactionFragment | null;
  canEdit: boolean;
  isLockedPeriod: boolean;
  lockWarning: boolean;
  loading: boolean;
  onPrevious?: () => void;
  onNext?: () => void;
  isEditingSplit: boolean;
  toggleEditingSplit: () => void;
  splitsOpenByDefault: boolean;
  commentBoxMode: CommentBoxMode;
};

const DetailPaneContext = React.createContext<DetailPaneContextType | null>(null);

export const useDetailPaneContextValue = ({
  transactionId,
  transactions,
  onClose,
  commentBoxMode: commentBoxModeOverride,
  splitsOpenByDefault,
}: {
  commentBoxMode?: CommentBoxMode;
  onClose?: () => void;
  transactionId?: string;
  transactions?: BasicTransactionFragment[];
  splitsOpenByDefault?: boolean;
}): DetailPaneContextType => {
  const { company } = useActiveCompany<true>();
  const { goToTransaction } = useAppRouter();
  const { data, loading, startPolling, stopPolling } = useGetFullTransaction(transactionId);
  const [isEditingSplit, toggleEditingSplit] = useToggle(false);

  const { data: basicTransaction } = useFragment<
    BasicTransactionFragment,
    BasicTransactionFragment
  >({
    fragment: BasicTransactionFragmentDoc,
    fragmentName: "basicTransaction",
    from: `Transaction:${transactionId}`,
  });

  const previousTransaction = usePreviousDistinct(data?.transaction);
  const transaction = loading ? data?.transaction : data?.transaction || previousTransaction;
  const assignment = transaction?.activity.currentAssignment;

  const [commentBoxMode, setCommentBoxMode] = useState<CommentBoxMode>(
    commentBoxModeOverride ?? "messaging"
  );

  const currentIndex = useMemo(
    () => transactions?.findIndex((t) => t.id === transactionId) ?? -1,
    [transactionId, transactions]
  );

  const [previewFile, setPreviewFile] = useState<FileFragment | undefined>(undefined);
  const extraState = useExtraTransactionState(transaction);

  useEffect(() => {
    if (commentBoxModeOverride) {
      return;
    }

    // :( we need a better way to identify if it's in the last mile workflow, for now this is how the BE does it
    const isInLastMileWorkflow =
      assignment &&
      isLastMileWorkflow(company, assignment.request) &&
      company.consentsToAI &&
      assignment.id !== OPTIMISTIC_UI_ASSIGNMENT_ID;

    if (isInLastMileWorkflow) {
      // if this is in the last mile workflow, we need to poll for updates
      // in case anything has come through via any of the channels
      // last mile is an asynchronous flow. Implementing apollo subscriptions
      // would be a better long term option instead of polling. Someday
      startPolling(500);
    } else {
      stopPolling();
    }

    setCommentBoxMode(isInLastMileWorkflow ? "provide_ai_context" : "messaging");
  }, [commentBoxModeOverride, assignment, company, startPolling, stopPolling]);

  const onPrevious = useMemo(() => {
    if (currentIndex > 0 && transactions) {
      return () => {
        Analytics.transactionDrawerArrowClicked({ direction: "previous" });
        goToTransaction(transactions[currentIndex - 1].id);
      };
    }

    return undefined;
  }, [currentIndex, goToTransaction, transactions]);

  const onNext = useMemo(() => {
    if (transactions && currentIndex >= 0 && currentIndex < transactions.length) {
      return () => {
        Analytics.transactionDrawerArrowClicked({ direction: "next" });
        if (currentIndex === transactions.length - 1) {
          goToTransaction(transactions[0].id);
        } else {
          goToTransaction(transactions[currentIndex + 1].id);
        }
      };
    }

    return undefined;
  }, [currentIndex, goToTransaction, transactions]);

  return useMemo(
    () => ({
      previewFile,
      commentBoxMode,
      setPreviewFile,
      transaction: transaction ?? undefined,
      basicTransaction: (basicTransaction || transaction) as BasicTransactionFragment,
      close: onClose,
      loading,
      onPrevious,
      onNext,
      isEditingSplit,
      toggleEditingSplit,
      splitsOpenByDefault: !!splitsOpenByDefault,
      ...extraState,
    }),
    [
      previewFile,
      transaction,
      basicTransaction,
      onClose,
      loading,
      onPrevious,
      onNext,
      isEditingSplit,
      commentBoxMode,
      toggleEditingSplit,
      splitsOpenByDefault,
      extraState,
    ]
  );
};

export const DetailPaneContextProvider = DetailPaneContext.Provider;

export function useDetailPaneContext<Loaded extends boolean = false>() {
  const context = React.useContext(DetailPaneContext);
  if (context === null) {
    throw new Error("DetailPaneContext not found");
  }
  return context as Loaded extends true
    ? SetRequired<DetailPaneContextType, "transaction">
    : DetailPaneContextType;
}
