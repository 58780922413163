import React, { useCallback, useEffect } from "react";
import Link from "next/link";

import { styled, Button, Text, Stack, StatusIcon } from "@puzzle/ui";

import { Route } from "lib/routes";

import { useTransactionsPage } from "./TransactionsProvider";
import { useCompanyDateFormatter } from "components/companies";
import Analytics from "lib/analytics";
import { CalendarDate, endOfMonth, isSameDay, startOfMonth } from "@puzzle/utils";

const Root = styled("div", {
  margin: "$10h 0",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",

  color: "$gray50",
});

const IconContainer = styled("div", {
  width: "min-content",
  margin: "0 auto $2h",
});

const CategorizationMessage = () => {
  const {
    filter,
    setFilter,
    impactfulTransactionsMetadata: { nextImpactfulRange, isFullyCategorized },
  } = useTransactionsPage();
  const nextStartDate = nextImpactfulRange?.[0];
  const nextEndDate = nextImpactfulRange?.[1];
  const monthFormatter = useCompanyDateFormatter({
    month: "long",
    year: "numeric",
  });
  const rangeFormatter = useCompanyDateFormatter({
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  const formatRangeOrFullMonth = useCallback(
    (range: [CalendarDate, CalendarDate]) => {
      if (
        isSameDay(range[0], startOfMonth(range[0])) ||
        isSameDay(range[1], endOfMonth(range[1]))
      ) {
        return monthFormatter.format(range[0]);
      }

      return rangeFormatter.formatRange(range[0], range[1]);
    },
    [monthFormatter, rangeFormatter]
  );

  useEffect(() => {
    Analytics.impactfulTransactionsCompleteMessageSeen({
      isFullyCategorized,
      startDate: filter.start.toString(),
      endDate: filter.end.toString(),
    });
  }, [filter.end, filter.start, isFullyCategorized]);

  if (!isFullyCategorized && !nextImpactfulRange) {
    return null;
  }

  return (
    <Root>
      {isFullyCategorized || !nextImpactfulRange ? (
        <>
          {/* TODO this is ALMOST identical to the contents of TopTransactionsModal/CompleteStep */}
          <IconContainer>
            <StatusIcon size={58} status="success" />
          </IconContainer>

          <Stack gap="1">
            <Text variant="headingS" color="gray50">
              {"You're fully categorized!"}
            </Text>
            <Text variant="bodyS" color="gray200">
              Head over to the dashboard to see updated metrics and financial statements.
            </Text>
          </Stack>
        </>
      ) : (
        <>
          <Text variant="bodyL" color="gray50" css={{ marginBottom: "$1h" }}>
            {formatRangeOrFullMonth([filter.start, filter.end])} complete!
          </Text>

          <Text variant="bodyS" color="gray200">
            However, you still have uncategorized impactful transactions from{" "}
            {formatRangeOrFullMonth(nextImpactfulRange)}.
          </Text>
        </>
      )}

      <Stack gap="1h" direction="horizontal" css={{ marginTop: "$4", alignItems: "center" }}>
        {/* TODO does dashboard need querystrings for date ranges? */}
        <Link href={Route.home} passHref>
          <Button
            variant="primary"
            size="compact"
            onClick={(e) => {
              Analytics.impactfulTransactionsGoToDashboardClicked();
            }}
          >
            Go to dashboard
          </Button>
        </Link>

        {nextImpactfulRange && (
          <>
            <Text variant="bodyS" color="gray400">
              or
            </Text>

            <Link
              href={`${Route.transactions}?start=${nextStartDate}&end=${nextEndDate}&status=uncategorized`}
              passHref
            >
              <Text
                color="gray50"
                as="a"
                // onClick isn't passed with passHref (yet)
                // We use setFilter since useQueryState adds lag.
                // we do not yet sync query changes as a result (complicated diff checking).
                // We provide href as a backup.
                onClick={(e) => {
                  Analytics.impactfulTransactionsContinueCategorizingClicked();
                  if (!(e.ctrlKey || e.metaKey)) {
                    setFilter({ start: nextStartDate, end: nextEndDate });
                  }
                }}
              >
                Continue categorizing
              </Text>
            </Link>
          </>
        )}
      </Stack>
    </Root>
  );
};

export default CategorizationMessage;
