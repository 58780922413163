import React from "react";
import Logo from "../rippling/Logo";
import { useRippling } from "../rippling";
import IntegrationDetails from "./IntegrationDetails";
import { DetailProps } from "./types";

const RipplingDetails = ({ companyId, ...props }: DetailProps) => {
  const rippling = useRippling({ companyId });

  return (
    <IntegrationDetails
      logo={<Logo />}
      title="Rippling"
      connection={rippling.connection}
      onReconnect={rippling.onClickConnect}
      {...props}
    />
  );
};

export default RipplingDetails;
