import Analytics from "lib/analytics";
import { useMap } from "react-use";
import {
  TransactionPageActivity_TransactionAssignment_Fragment,
  BasicTransactionFragment,
  TransactionPageUserFragment,
  useAssignUserToTransactionMutation,
  useCancelAssignmentMutation,
  useMarkAssignmentAsCompleteMutation,
  FullTransactionFragment,
} from "../graphql.generated";
import { omit } from "lodash";
import { ActorType, TransactionMemoType } from "graphql/types";
import useSelf from "components/users/useSelf";
import { isLastMileWorkflow } from "components/AI/useAI";
import { useActiveCompany } from "components/companies";
import { createGPTLoadingPlaceholderActivity } from "components/AI/useAskAI";

export const OPTIMISTIC_UI_ASSIGNMENT_ID = "optimistic_ui_assignment_placeholder_id";
export default function useAssignments(
  users: TransactionPageUserFragment[] = [],
  transaction?: FullTransactionFragment | null
) {
  const { self } = useSelf();
  const { company } = useActiveCompany<true>();
  const [assignUserMutation, { loading: assignUserLoading }] = useAssignUserToTransactionMutation();
  const [markAssignmentAsCompleteMutation, { loading: completeAssignmentLoading }] =
    useMarkAssignmentAsCompleteMutation();
  const [cancelAssignmentMutation, { loading: cancelAssignmentLoading }] =
    useCancelAssignmentMutation();
  const defaultRequests = ["category review", "documentation", "context"].map((r) => ({
    request: r,
    id: r,
  }));

  const [pendingAssignment, { set, setAll, reset }] = useMap<{
    request: string;
    targetUser?: TransactionPageUserFragment;
  }>({ request: defaultRequests[0].request, targetUser: undefined });

  const currentAssignment = transaction?.activity.currentAssignment;

  const assignUser = async ({
    targetUser,
    request,
    transaction,
  }: {
    transaction: FullTransactionFragment;
    targetUser: TransactionPageUserFragment;
    request: string;
  }) => {
    if (!transaction) {
      throw new Error("No transaction found");
    }
    if (!targetUser) {
      throw new Error("No user selected");
    }

    const optimisticAssignment: TransactionPageActivity_TransactionAssignment_Fragment = {
      __typename: "TransactionAssignment",
      actor: {
        type: ActorType.UserActor,
        name: self?.name ?? self?.email ?? "",
      },
      id: OPTIMISTIC_UI_ASSIGNMENT_ID,
      type: TransactionMemoType.Assignment,
      createdAt: new Date().toISOString(),
      createdByUser: { ...self },
      targetUser,
      request,
    };
    const willTriggerLastMile = isLastMileWorkflow(company, request);
    const additionalActivity = willTriggerLastMile ? [createGPTLoadingPlaceholderActivity()] : [];
    return assignUserMutation({
      variables: {
        input: {
          targetUserId: targetUser.id!,
          transactionId: transaction.id,
          text: request,
        },
      },
      optimisticResponse: {
        __typename: "Mutation",
        assignUserToTransaction: {
          task: null,
          transaction: {
            ...omit(transaction, "activity"),
            activity: {
              __typename: "TransactionActivityDetails",
              currentAssignment: optimisticAssignment,
              activity: [...transaction.activity.activity, ...additionalActivity],
            },
          },
        },
      },
      onCompleted() {
        Analytics.transactionAssigned({
          transactionId: transaction.id,
          assigneeId: targetUser.id!,
          request,
        });
      },
    });
  };

  const markAssignmentAsComplete = ({
    assignment,
    transaction,
  }: {
    transaction: BasicTransactionFragment;
    assignment: TransactionPageActivity_TransactionAssignment_Fragment;
  }) => {
    if (!transaction) {
      throw new Error("No transaction found");
    }

    return markAssignmentAsCompleteMutation({
      variables: {
        input: {
          transactionId: transaction.id,
          assignmentId: assignment.id,
        },
      },
      onCompleted() {
        Analytics.transactionAssignmentCompleted({
          id: assignment.id,
          transactionId: transaction.id,
          request: assignment.request,
        });
      },
    });
  };

  const cancelAssignment = ({
    assignment,
    transaction,
  }: {
    transaction: BasicTransactionFragment;
    assignment: TransactionPageActivity_TransactionAssignment_Fragment;
  }) => {
    if (!transaction) {
      throw new Error("No transaction found");
    }

    cancelAssignmentMutation({
      variables: {
        input: {
          transactionId: transaction.id,
          assignmentId: assignment.id,
        },
      },

      onCompleted() {
        Analytics.transactionAssignmentCanceled({
          id: assignment.id,
          transactionId: transaction.id,
          request: assignment.request,
        });
      },
    });
  };

  const saveAssignment = () => {
    const { request, targetUser } = pendingAssignment;
    if (request && targetUser && transaction) {
      assignUser({
        request,
        transaction,
        targetUser,
      });
    }
    reset();
  };

  const handleMarkAsCompleted = () => {
    if (!transaction || !currentAssignment) return;
    markAssignmentAsComplete({
      assignment: currentAssignment,
      transaction,
    });
  };

  const handleCancelAssignment = () => {
    if (!transaction || !currentAssignment) return;
    cancelAssignment({
      assignment: currentAssignment,
      transaction,
    });
  };

  const startNewAssignment = (seedText: string, seedUser: TransactionPageUserFragment) => {
    setAll({ request: seedText, targetUser: seedUser || users[0] });
  };

  const setCurrentlyAssigningTo = (user?: TransactionPageUserFragment) => {
    set("targetUser", user);
  };

  const updateAssignmentText = (text: string) => {
    set("request", text);
  };

  return {
    currentAssignment: transaction?.activity.currentAssignment,
    pendingAssignment,
    saveAssignment,
    updateAssignmentText,
    setCurrentlyAssigningTo,
    startNewAssignment,
    markAssignmentAsComplete: handleMarkAsCompleted,
    markAssignmentAsCanceled: handleCancelAssignment,
    completeAssignmentLoading: completeAssignmentLoading,
    cancelAssignmentLoading: cancelAssignmentLoading,
    assignUserLoading: assignUserLoading,
    defaultRequests,
    assignUser,
  };
}
