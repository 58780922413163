import * as Types from '../../graphql/types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
import { FileFragmentDoc } from '../../graphql/fragments/file.generated';
import { PageInfoFragmentDoc } from '../../graphql/fragments/pageInfo.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReportsPageReportFragment = { __typename?: 'Report', reportId?: any | null, title: string, status: Types.ReportStatus, timePeriod?: { __typename?: 'ReportTimePeriod', timePeriodKey: string, start: CalendarDateString, end: CalendarDateString } | null, file?: { __typename?: 'File', id: string, filename?: string | null, contentType: string, size: number, isDeleted: boolean, downloadInfo: { __typename?: 'FileDownloadInfo', signedUrl: string, urlExpiresAt: CalendarDateTimeString } } | null };

export type LedgerReportByPeriodFragment = { __typename?: 'LedgerReportDownloadByPeriod', title: string, file: { __typename?: 'File', id: string, filename?: string | null, contentType: string, size: number, isDeleted: boolean, downloadInfo: { __typename?: 'FileDownloadInfo', signedUrl: string, urlExpiresAt: CalendarDateTimeString } } };

export type ReportsPageAccountFragment = { __typename?: 'Account', id: string, name: string, mask?: string | null, subType?: Types.AccountSubType | null, financialInstitution: { __typename?: 'FinancialInstitution', id: string, name: string } };

export type FdicCoverageFragment = { __typename?: 'FdicCoverage', status?: Types.FdicCoverageStatus | null, depositoryTotal: { __typename?: 'Money', amount: string, currency: any }, estimatedInsuranceCoverage: { __typename?: 'Money', amount: string, currency: any } };

export type AccountsQueryVariables = Types.Exact<{
  filter: Types.AccountFilterInput;
}>;


export type AccountsQuery = { __typename?: 'Query', accounts: Array<{ __typename?: 'Account', id: string, name: string, mask?: string | null, subType?: Types.AccountSubType | null, financialInstitution: { __typename?: 'FinancialInstitution', id: string, name: string } }> };

export type LedgerReportDownloadByPeriodQueryVariables = Types.Exact<{
  input: Types.LedgerReportDownloadByPeriodInput;
}>;


export type LedgerReportDownloadByPeriodQuery = { __typename?: 'Query', ledgerReportDownloadByPeriod: { __typename?: 'LedgerReportDownloadByPeriod', title: string, file: { __typename?: 'File', id: string, filename?: string | null, contentType: string, size: number, isDeleted: boolean, downloadInfo: { __typename?: 'FileDownloadInfo', signedUrl: string, urlExpiresAt: CalendarDateTimeString } } } };

export type LedgerExcelReportDownloadByPeriodQueryVariables = Types.Exact<{
  input: Types.LedgerExcelReportDownloadByPeriodInput;
}>;


export type LedgerExcelReportDownloadByPeriodQuery = { __typename?: 'Query', ledgerExcelReportDownloadByPeriod: { __typename?: 'LedgerExcelReportDownloadByPeriod', title: string, file: { __typename?: 'File', id: string, filename?: string | null, contentType: string, size: number, isDeleted: boolean, downloadInfo: { __typename?: 'FileDownloadInfo', signedUrl: string, urlExpiresAt: CalendarDateTimeString } } } };

export type LedgerExcelMultiReportDownloadByPeriodQueryVariables = Types.Exact<{
  input: Types.LedgerExcelMultiReportDownloadByPeriodInput;
}>;


export type LedgerExcelMultiReportDownloadByPeriodQuery = { __typename?: 'Query', ledgerExcelMultiReportDownloadByPeriod: { __typename?: 'LedgerExcelReportDownloadByPeriod', title: string, file: { __typename?: 'File', id: string, filename?: string | null, contentType: string, size: number, isDeleted: boolean, downloadInfo: { __typename?: 'FileDownloadInfo', signedUrl: string, urlExpiresAt: CalendarDateTimeString } } } };

export type TransactionsReportQueryVariables = Types.Exact<{
  input: Types.TransactionReportInput;
}>;


export type TransactionsReportQuery = { __typename?: 'Query', transactionsReport: { __typename?: 'Report', reportId?: any | null, title: string, status: Types.ReportStatus, timePeriod?: { __typename?: 'ReportTimePeriod', timePeriodKey: string, start: CalendarDateString, end: CalendarDateString } | null, file?: { __typename?: 'File', id: string, filename?: string | null, contentType: string, size: number, isDeleted: boolean, downloadInfo: { __typename?: 'FileDownloadInfo', signedUrl: string, urlExpiresAt: CalendarDateTimeString } } | null } };

export type VendorReportQueryVariables = Types.Exact<{
  input: Types.VendorReportInput;
}>;


export type VendorReportQuery = { __typename?: 'Query', vendorReport: { __typename?: 'Report', reportId?: any | null, title: string, status: Types.ReportStatus, timePeriod?: { __typename?: 'ReportTimePeriod', timePeriodKey: string, start: CalendarDateString, end: CalendarDateString } | null, file?: { __typename?: 'File', id: string, filename?: string | null, contentType: string, size: number, isDeleted: boolean, downloadInfo: { __typename?: 'FileDownloadInfo', signedUrl: string, urlExpiresAt: CalendarDateTimeString } } | null } };

export type CoaReportQueryVariables = Types.Exact<{
  input: Types.CoaReportInput;
}>;


export type CoaReportQuery = { __typename?: 'Query', coaReport: { __typename?: 'Report', reportId?: any | null, title: string, status: Types.ReportStatus, timePeriod?: { __typename?: 'ReportTimePeriod', timePeriodKey: string, start: CalendarDateString, end: CalendarDateString } | null, file?: { __typename?: 'File', id: string, filename?: string | null, contentType: string, size: number, isDeleted: boolean, downloadInfo: { __typename?: 'FileDownloadInfo', signedUrl: string, urlExpiresAt: CalendarDateTimeString } } | null } };

export type ContractorQueryVariables = Types.Exact<{
  input: Types.ReportInput;
}>;


export type ContractorQuery = { __typename?: 'Query', contractor?: { __typename?: 'Report', reportId?: any | null, title: string, status: Types.ReportStatus, timePeriod?: { __typename?: 'ReportTimePeriod', timePeriodKey: string, start: CalendarDateString, end: CalendarDateString } | null, file?: { __typename?: 'File', id: string, filename?: string | null, contentType: string, size: number, isDeleted: boolean, downloadInfo: { __typename?: 'FileDownloadInfo', signedUrl: string, urlExpiresAt: CalendarDateTimeString } } | null } | null };

export type EmployeeQueryVariables = Types.Exact<{
  input: Types.ReportInput;
}>;


export type EmployeeQuery = { __typename?: 'Query', employee?: { __typename?: 'Report', reportId?: any | null, title: string, status: Types.ReportStatus, timePeriod?: { __typename?: 'ReportTimePeriod', timePeriodKey: string, start: CalendarDateString, end: CalendarDateString } | null, file?: { __typename?: 'File', id: string, filename?: string | null, contentType: string, size: number, isDeleted: boolean, downloadInfo: { __typename?: 'FileDownloadInfo', signedUrl: string, urlExpiresAt: CalendarDateTimeString } } | null } | null };

export type EmployeeChangeQueryVariables = Types.Exact<{
  input: Types.ReportInput;
}>;


export type EmployeeChangeQuery = { __typename?: 'Query', employeeChange?: { __typename?: 'Report', reportId?: any | null, title: string, status: Types.ReportStatus, timePeriod?: { __typename?: 'ReportTimePeriod', timePeriodKey: string, start: CalendarDateString, end: CalendarDateString } | null, file?: { __typename?: 'File', id: string, filename?: string | null, contentType: string, size: number, isDeleted: boolean, downloadInfo: { __typename?: 'FileDownloadInfo', signedUrl: string, urlExpiresAt: CalendarDateTimeString } } | null } | null };

export type QuickBooksReportQueryVariables = Types.Exact<{
  input: Types.QuickBooksReportInput;
}>;


export type QuickBooksReportQuery = { __typename?: 'Query', quickBooksReport?: { __typename?: 'Report', reportId?: any | null, title: string, status: Types.ReportStatus, timePeriod?: { __typename?: 'ReportTimePeriod', timePeriodKey: string, start: CalendarDateString, end: CalendarDateString } | null, file?: { __typename?: 'File', id: string, filename?: string | null, contentType: string, size: number, isDeleted: boolean, downloadInfo: { __typename?: 'FileDownloadInfo', signedUrl: string, urlExpiresAt: CalendarDateTimeString } } | null } | null };

export type MetricsAccountFragment = { __typename?: 'Account', id: string, manuallyAdded: boolean, name: string, subType?: Types.AccountSubType | null, type: Types.AccountType, mask?: string | null, status: Types.IntegrationConnectionStatus, updatedAt: CalendarDateTimeString, lastImportedAt?: CalendarDateTimeString | null, financialInstitution: { __typename?: 'FinancialInstitution', id: string, name: string, fdicCoverage?: { __typename?: 'FdicCoverage', status?: Types.FdicCoverageStatus | null, depositoryTotal: { __typename?: 'Money', amount: string, currency: any }, estimatedInsuranceCoverage: { __typename?: 'Money', amount: string, currency: any } } | null }, todaysBalance: { __typename?: 'AccountBalance', balance: string }, epoch?: { __typename?: 'AccountEpoch', cutoffBefore?: CalendarDateString | null, cutoffAtOrAfter?: CalendarDateString | null } | null };

export type MetricsAccountsQueryVariables = Types.Exact<{
  filter: Types.AccountFilterInput;
}>;


export type MetricsAccountsQuery = { __typename?: 'Query', accounts: Array<{ __typename?: 'Account', id: string, manuallyAdded: boolean, name: string, subType?: Types.AccountSubType | null, type: Types.AccountType, mask?: string | null, status: Types.IntegrationConnectionStatus, updatedAt: CalendarDateTimeString, lastImportedAt?: CalendarDateTimeString | null, financialInstitution: { __typename?: 'FinancialInstitution', id: string, name: string, fdicCoverage?: { __typename?: 'FdicCoverage', status?: Types.FdicCoverageStatus | null, depositoryTotal: { __typename?: 'Money', amount: string, currency: any }, estimatedInsuranceCoverage: { __typename?: 'Money', amount: string, currency: any } } | null }, todaysBalance: { __typename?: 'AccountBalance', balance: string }, epoch?: { __typename?: 'AccountEpoch', cutoffBefore?: CalendarDateString | null, cutoffAtOrAfter?: CalendarDateString | null } | null }> };

export type GenerateYearEndTaxPackageQueryVariables = Types.Exact<{
  input: Types.GenerateYearEndTaxPackageInput;
}>;


export type GenerateYearEndTaxPackageQuery = { __typename?: 'Query', generateYearEndTaxPackage: { __typename?: 'ReportPackage', id: any, filename: string, status: Types.ReportPackageStatus } };

export type GetReportPackageByIdQueryVariables = Types.Exact<{
  reportPackageId?: Types.InputMaybe<Types.Scalars['ReportPackageID']>;
}>;


export type GetReportPackageByIdQuery = { __typename?: 'Query', getReportPackageById: { __typename?: 'ReportPackage', status: Types.ReportPackageStatus, file?: { __typename?: 'File', id: string, filename?: string | null, contentType: string, size: number, isDeleted: boolean, downloadInfo: { __typename?: 'FileDownloadInfo', signedUrl: string, urlExpiresAt: CalendarDateTimeString } } | null } };

export type GetReportByIdQueryVariables = Types.Exact<{
  reportId?: Types.InputMaybe<Types.Scalars['ReportID']>;
}>;


export type GetReportByIdQuery = { __typename?: 'Query', getReportById: { __typename?: 'Report', status: Types.ReportStatus, file?: { __typename?: 'File', id: string, filename?: string | null, contentType: string, size: number, isDeleted: boolean, downloadInfo: { __typename?: 'FileDownloadInfo', signedUrl: string, urlExpiresAt: CalendarDateTimeString } } | null } };

export type StripeValidationRunFragment = { __typename?: 'StripeValidationRun', id: any, month: CalendarMonthString, status: Types.StripeValidationRunStatus, filename?: string | null, view?: Types.LedgerView | null, type: Types.StripeValidationRunType, createdAt: CalendarDateTimeString, file?: { __typename?: 'File', id: string, filename?: string | null, contentType: string, size: number, isDeleted: boolean, downloadInfo: { __typename?: 'FileDownloadInfo', signedUrl: string, urlExpiresAt: CalendarDateTimeString } } | null, account?: { __typename?: 'Account', id: string } | null };

export type StripeValidationRunsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['ID'];
  page?: Types.InputMaybe<Types.PageInput>;
  filterBy?: Types.InputMaybe<Types.StripeValidationRunFilterBy>;
}>;


export type StripeValidationRunsQuery = { __typename?: 'Query', company?: { __typename?: 'Company', id: string, stripeValidationRuns?: { __typename?: 'StripeValidationRunPaginator', nodes: Array<{ __typename?: 'StripeValidationRun', id: any, month: CalendarMonthString, status: Types.StripeValidationRunStatus, filename?: string | null, view?: Types.LedgerView | null, type: Types.StripeValidationRunType, createdAt: CalendarDateTimeString, file?: { __typename?: 'File', id: string, filename?: string | null, contentType: string, size: number, isDeleted: boolean, downloadInfo: { __typename?: 'FileDownloadInfo', signedUrl: string, urlExpiresAt: CalendarDateTimeString } } | null, account?: { __typename?: 'Account', id: string } | null }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null, total?: number | null } } | null } | null };

export type RunStripeValidationMutationVariables = Types.Exact<{
  input: Types.RunStripeValidationInput;
}>;


export type RunStripeValidationMutation = { __typename?: 'Mutation', runStripeValidation: { __typename?: 'StripeValidationRun', id: any, month: CalendarMonthString, status: Types.StripeValidationRunStatus, filename?: string | null, view?: Types.LedgerView | null, type: Types.StripeValidationRunType, createdAt: CalendarDateTimeString, file?: { __typename?: 'File', id: string, filename?: string | null, contentType: string, size: number, isDeleted: boolean, downloadInfo: { __typename?: 'FileDownloadInfo', signedUrl: string, urlExpiresAt: CalendarDateTimeString } } | null, account?: { __typename?: 'Account', id: string } | null } };

export const ReportsPageReportFragmentDoc = gql`
    fragment reportsPageReport on Report {
  reportId
  title
  timePeriod {
    timePeriodKey
    start
    end
  }
  file {
    ...file
  }
  status
}
    ${FileFragmentDoc}`;
export const LedgerReportByPeriodFragmentDoc = gql`
    fragment ledgerReportByPeriod on LedgerReportDownloadByPeriod {
  title
  file {
    ...file
  }
}
    ${FileFragmentDoc}`;
export const ReportsPageAccountFragmentDoc = gql`
    fragment reportsPageAccount on Account {
  id
  name
  mask
  subType
  financialInstitution {
    id
    name
  }
}
    `;
export const FdicCoverageFragmentDoc = gql`
    fragment fdicCoverage on FdicCoverage {
  depositoryTotal {
    amount
    currency
  }
  estimatedInsuranceCoverage {
    amount
    currency
  }
  status
}
    `;
export const MetricsAccountFragmentDoc = gql`
    fragment metricsAccount on Account {
  id
  manuallyAdded
  name
  subType
  type
  mask
  status
  updatedAt
  lastImportedAt
  financialInstitution {
    id
    name
    fdicCoverage {
      ...fdicCoverage
    }
  }
  todaysBalance {
    balance
  }
  epoch {
    cutoffBefore
    cutoffAtOrAfter
  }
}
    ${FdicCoverageFragmentDoc}`;
export const StripeValidationRunFragmentDoc = gql`
    fragment stripeValidationRun on StripeValidationRun {
  id
  month
  status
  filename
  view
  type
  createdAt
  file {
    ...file
  }
  account {
    id
  }
}
    ${FileFragmentDoc}`;
export const AccountsDocument = gql`
    query accounts($filter: AccountFilterInput!) {
  accounts(filter: $filter) {
    ...reportsPageAccount
  }
}
    ${ReportsPageAccountFragmentDoc}`;

/**
 * __useAccountsQuery__
 *
 * To run a query within a React component, call `useAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAccountsQuery(baseOptions: Apollo.QueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
      }
export function useAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
        }
export type AccountsQueryHookResult = ReturnType<typeof useAccountsQuery>;
export type AccountsLazyQueryHookResult = ReturnType<typeof useAccountsLazyQuery>;
export type AccountsQueryResult = Apollo.QueryResult<AccountsQuery, AccountsQueryVariables>;
export const LedgerReportDownloadByPeriodDocument = gql`
    query ledgerReportDownloadByPeriod($input: LedgerReportDownloadByPeriodInput!) {
  ledgerReportDownloadByPeriod(input: $input) {
    ...ledgerReportByPeriod
  }
}
    ${LedgerReportByPeriodFragmentDoc}`;

/**
 * __useLedgerReportDownloadByPeriodQuery__
 *
 * To run a query within a React component, call `useLedgerReportDownloadByPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useLedgerReportDownloadByPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLedgerReportDownloadByPeriodQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLedgerReportDownloadByPeriodQuery(baseOptions: Apollo.QueryHookOptions<LedgerReportDownloadByPeriodQuery, LedgerReportDownloadByPeriodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LedgerReportDownloadByPeriodQuery, LedgerReportDownloadByPeriodQueryVariables>(LedgerReportDownloadByPeriodDocument, options);
      }
export function useLedgerReportDownloadByPeriodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LedgerReportDownloadByPeriodQuery, LedgerReportDownloadByPeriodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LedgerReportDownloadByPeriodQuery, LedgerReportDownloadByPeriodQueryVariables>(LedgerReportDownloadByPeriodDocument, options);
        }
export type LedgerReportDownloadByPeriodQueryHookResult = ReturnType<typeof useLedgerReportDownloadByPeriodQuery>;
export type LedgerReportDownloadByPeriodLazyQueryHookResult = ReturnType<typeof useLedgerReportDownloadByPeriodLazyQuery>;
export type LedgerReportDownloadByPeriodQueryResult = Apollo.QueryResult<LedgerReportDownloadByPeriodQuery, LedgerReportDownloadByPeriodQueryVariables>;
export const LedgerExcelReportDownloadByPeriodDocument = gql`
    query ledgerExcelReportDownloadByPeriod($input: LedgerExcelReportDownloadByPeriodInput!) {
  ledgerExcelReportDownloadByPeriod(input: $input) {
    title
    file {
      ...file
    }
  }
}
    ${FileFragmentDoc}`;

/**
 * __useLedgerExcelReportDownloadByPeriodQuery__
 *
 * To run a query within a React component, call `useLedgerExcelReportDownloadByPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useLedgerExcelReportDownloadByPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLedgerExcelReportDownloadByPeriodQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLedgerExcelReportDownloadByPeriodQuery(baseOptions: Apollo.QueryHookOptions<LedgerExcelReportDownloadByPeriodQuery, LedgerExcelReportDownloadByPeriodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LedgerExcelReportDownloadByPeriodQuery, LedgerExcelReportDownloadByPeriodQueryVariables>(LedgerExcelReportDownloadByPeriodDocument, options);
      }
export function useLedgerExcelReportDownloadByPeriodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LedgerExcelReportDownloadByPeriodQuery, LedgerExcelReportDownloadByPeriodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LedgerExcelReportDownloadByPeriodQuery, LedgerExcelReportDownloadByPeriodQueryVariables>(LedgerExcelReportDownloadByPeriodDocument, options);
        }
export type LedgerExcelReportDownloadByPeriodQueryHookResult = ReturnType<typeof useLedgerExcelReportDownloadByPeriodQuery>;
export type LedgerExcelReportDownloadByPeriodLazyQueryHookResult = ReturnType<typeof useLedgerExcelReportDownloadByPeriodLazyQuery>;
export type LedgerExcelReportDownloadByPeriodQueryResult = Apollo.QueryResult<LedgerExcelReportDownloadByPeriodQuery, LedgerExcelReportDownloadByPeriodQueryVariables>;
export const LedgerExcelMultiReportDownloadByPeriodDocument = gql`
    query ledgerExcelMultiReportDownloadByPeriod($input: LedgerExcelMultiReportDownloadByPeriodInput!) {
  ledgerExcelMultiReportDownloadByPeriod(input: $input) {
    title
    file {
      ...file
    }
  }
}
    ${FileFragmentDoc}`;

/**
 * __useLedgerExcelMultiReportDownloadByPeriodQuery__
 *
 * To run a query within a React component, call `useLedgerExcelMultiReportDownloadByPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useLedgerExcelMultiReportDownloadByPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLedgerExcelMultiReportDownloadByPeriodQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLedgerExcelMultiReportDownloadByPeriodQuery(baseOptions: Apollo.QueryHookOptions<LedgerExcelMultiReportDownloadByPeriodQuery, LedgerExcelMultiReportDownloadByPeriodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LedgerExcelMultiReportDownloadByPeriodQuery, LedgerExcelMultiReportDownloadByPeriodQueryVariables>(LedgerExcelMultiReportDownloadByPeriodDocument, options);
      }
export function useLedgerExcelMultiReportDownloadByPeriodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LedgerExcelMultiReportDownloadByPeriodQuery, LedgerExcelMultiReportDownloadByPeriodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LedgerExcelMultiReportDownloadByPeriodQuery, LedgerExcelMultiReportDownloadByPeriodQueryVariables>(LedgerExcelMultiReportDownloadByPeriodDocument, options);
        }
export type LedgerExcelMultiReportDownloadByPeriodQueryHookResult = ReturnType<typeof useLedgerExcelMultiReportDownloadByPeriodQuery>;
export type LedgerExcelMultiReportDownloadByPeriodLazyQueryHookResult = ReturnType<typeof useLedgerExcelMultiReportDownloadByPeriodLazyQuery>;
export type LedgerExcelMultiReportDownloadByPeriodQueryResult = Apollo.QueryResult<LedgerExcelMultiReportDownloadByPeriodQuery, LedgerExcelMultiReportDownloadByPeriodQueryVariables>;
export const TransactionsReportDocument = gql`
    query transactionsReport($input: TransactionReportInput!) {
  transactionsReport(input: $input) {
    ...reportsPageReport
  }
}
    ${ReportsPageReportFragmentDoc}`;

/**
 * __useTransactionsReportQuery__
 *
 * To run a query within a React component, call `useTransactionsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionsReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransactionsReportQuery(baseOptions: Apollo.QueryHookOptions<TransactionsReportQuery, TransactionsReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionsReportQuery, TransactionsReportQueryVariables>(TransactionsReportDocument, options);
      }
export function useTransactionsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionsReportQuery, TransactionsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionsReportQuery, TransactionsReportQueryVariables>(TransactionsReportDocument, options);
        }
export type TransactionsReportQueryHookResult = ReturnType<typeof useTransactionsReportQuery>;
export type TransactionsReportLazyQueryHookResult = ReturnType<typeof useTransactionsReportLazyQuery>;
export type TransactionsReportQueryResult = Apollo.QueryResult<TransactionsReportQuery, TransactionsReportQueryVariables>;
export const VendorReportDocument = gql`
    query vendorReport($input: VendorReportInput!) {
  vendorReport(input: $input) {
    ...reportsPageReport
  }
}
    ${ReportsPageReportFragmentDoc}`;

/**
 * __useVendorReportQuery__
 *
 * To run a query within a React component, call `useVendorReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVendorReportQuery(baseOptions: Apollo.QueryHookOptions<VendorReportQuery, VendorReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VendorReportQuery, VendorReportQueryVariables>(VendorReportDocument, options);
      }
export function useVendorReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VendorReportQuery, VendorReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VendorReportQuery, VendorReportQueryVariables>(VendorReportDocument, options);
        }
export type VendorReportQueryHookResult = ReturnType<typeof useVendorReportQuery>;
export type VendorReportLazyQueryHookResult = ReturnType<typeof useVendorReportLazyQuery>;
export type VendorReportQueryResult = Apollo.QueryResult<VendorReportQuery, VendorReportQueryVariables>;
export const CoaReportDocument = gql`
    query coaReport($input: CoaReportInput!) {
  coaReport(input: $input) {
    ...reportsPageReport
  }
}
    ${ReportsPageReportFragmentDoc}`;

/**
 * __useCoaReportQuery__
 *
 * To run a query within a React component, call `useCoaReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoaReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoaReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCoaReportQuery(baseOptions: Apollo.QueryHookOptions<CoaReportQuery, CoaReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoaReportQuery, CoaReportQueryVariables>(CoaReportDocument, options);
      }
export function useCoaReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoaReportQuery, CoaReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoaReportQuery, CoaReportQueryVariables>(CoaReportDocument, options);
        }
export type CoaReportQueryHookResult = ReturnType<typeof useCoaReportQuery>;
export type CoaReportLazyQueryHookResult = ReturnType<typeof useCoaReportLazyQuery>;
export type CoaReportQueryResult = Apollo.QueryResult<CoaReportQuery, CoaReportQueryVariables>;
export const ContractorDocument = gql`
    query contractor($input: ReportInput!) {
  contractor(input: $input) {
    ...reportsPageReport
  }
}
    ${ReportsPageReportFragmentDoc}`;

/**
 * __useContractorQuery__
 *
 * To run a query within a React component, call `useContractorQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractorQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContractorQuery(baseOptions: Apollo.QueryHookOptions<ContractorQuery, ContractorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractorQuery, ContractorQueryVariables>(ContractorDocument, options);
      }
export function useContractorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractorQuery, ContractorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractorQuery, ContractorQueryVariables>(ContractorDocument, options);
        }
export type ContractorQueryHookResult = ReturnType<typeof useContractorQuery>;
export type ContractorLazyQueryHookResult = ReturnType<typeof useContractorLazyQuery>;
export type ContractorQueryResult = Apollo.QueryResult<ContractorQuery, ContractorQueryVariables>;
export const EmployeeDocument = gql`
    query employee($input: ReportInput!) {
  employee(input: $input) {
    ...reportsPageReport
  }
}
    ${ReportsPageReportFragmentDoc}`;

/**
 * __useEmployeeQuery__
 *
 * To run a query within a React component, call `useEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmployeeQuery(baseOptions: Apollo.QueryHookOptions<EmployeeQuery, EmployeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeQuery, EmployeeQueryVariables>(EmployeeDocument, options);
      }
export function useEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeQuery, EmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeQuery, EmployeeQueryVariables>(EmployeeDocument, options);
        }
export type EmployeeQueryHookResult = ReturnType<typeof useEmployeeQuery>;
export type EmployeeLazyQueryHookResult = ReturnType<typeof useEmployeeLazyQuery>;
export type EmployeeQueryResult = Apollo.QueryResult<EmployeeQuery, EmployeeQueryVariables>;
export const EmployeeChangeDocument = gql`
    query employeeChange($input: ReportInput!) {
  employeeChange(input: $input) {
    ...reportsPageReport
  }
}
    ${ReportsPageReportFragmentDoc}`;

/**
 * __useEmployeeChangeQuery__
 *
 * To run a query within a React component, call `useEmployeeChangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeChangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeChangeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmployeeChangeQuery(baseOptions: Apollo.QueryHookOptions<EmployeeChangeQuery, EmployeeChangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeChangeQuery, EmployeeChangeQueryVariables>(EmployeeChangeDocument, options);
      }
export function useEmployeeChangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeChangeQuery, EmployeeChangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeChangeQuery, EmployeeChangeQueryVariables>(EmployeeChangeDocument, options);
        }
export type EmployeeChangeQueryHookResult = ReturnType<typeof useEmployeeChangeQuery>;
export type EmployeeChangeLazyQueryHookResult = ReturnType<typeof useEmployeeChangeLazyQuery>;
export type EmployeeChangeQueryResult = Apollo.QueryResult<EmployeeChangeQuery, EmployeeChangeQueryVariables>;
export const QuickBooksReportDocument = gql`
    query quickBooksReport($input: QuickBooksReportInput!) {
  quickBooksReport(input: $input) {
    ...reportsPageReport
  }
}
    ${ReportsPageReportFragmentDoc}`;

/**
 * __useQuickBooksReportQuery__
 *
 * To run a query within a React component, call `useQuickBooksReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickBooksReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickBooksReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuickBooksReportQuery(baseOptions: Apollo.QueryHookOptions<QuickBooksReportQuery, QuickBooksReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuickBooksReportQuery, QuickBooksReportQueryVariables>(QuickBooksReportDocument, options);
      }
export function useQuickBooksReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuickBooksReportQuery, QuickBooksReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuickBooksReportQuery, QuickBooksReportQueryVariables>(QuickBooksReportDocument, options);
        }
export type QuickBooksReportQueryHookResult = ReturnType<typeof useQuickBooksReportQuery>;
export type QuickBooksReportLazyQueryHookResult = ReturnType<typeof useQuickBooksReportLazyQuery>;
export type QuickBooksReportQueryResult = Apollo.QueryResult<QuickBooksReportQuery, QuickBooksReportQueryVariables>;
export const MetricsAccountsDocument = gql`
    query metricsAccounts($filter: AccountFilterInput!) {
  accounts(filter: $filter) {
    ...metricsAccount
  }
}
    ${MetricsAccountFragmentDoc}`;

/**
 * __useMetricsAccountsQuery__
 *
 * To run a query within a React component, call `useMetricsAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetricsAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetricsAccountsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMetricsAccountsQuery(baseOptions: Apollo.QueryHookOptions<MetricsAccountsQuery, MetricsAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MetricsAccountsQuery, MetricsAccountsQueryVariables>(MetricsAccountsDocument, options);
      }
export function useMetricsAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MetricsAccountsQuery, MetricsAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MetricsAccountsQuery, MetricsAccountsQueryVariables>(MetricsAccountsDocument, options);
        }
export type MetricsAccountsQueryHookResult = ReturnType<typeof useMetricsAccountsQuery>;
export type MetricsAccountsLazyQueryHookResult = ReturnType<typeof useMetricsAccountsLazyQuery>;
export type MetricsAccountsQueryResult = Apollo.QueryResult<MetricsAccountsQuery, MetricsAccountsQueryVariables>;
export const GenerateYearEndTaxPackageDocument = gql`
    query generateYearEndTaxPackage($input: GenerateYearEndTaxPackageInput!) {
  generateYearEndTaxPackage(input: $input) {
    id
    filename
    status
  }
}
    `;

/**
 * __useGenerateYearEndTaxPackageQuery__
 *
 * To run a query within a React component, call `useGenerateYearEndTaxPackageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateYearEndTaxPackageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateYearEndTaxPackageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateYearEndTaxPackageQuery(baseOptions: Apollo.QueryHookOptions<GenerateYearEndTaxPackageQuery, GenerateYearEndTaxPackageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateYearEndTaxPackageQuery, GenerateYearEndTaxPackageQueryVariables>(GenerateYearEndTaxPackageDocument, options);
      }
export function useGenerateYearEndTaxPackageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateYearEndTaxPackageQuery, GenerateYearEndTaxPackageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateYearEndTaxPackageQuery, GenerateYearEndTaxPackageQueryVariables>(GenerateYearEndTaxPackageDocument, options);
        }
export type GenerateYearEndTaxPackageQueryHookResult = ReturnType<typeof useGenerateYearEndTaxPackageQuery>;
export type GenerateYearEndTaxPackageLazyQueryHookResult = ReturnType<typeof useGenerateYearEndTaxPackageLazyQuery>;
export type GenerateYearEndTaxPackageQueryResult = Apollo.QueryResult<GenerateYearEndTaxPackageQuery, GenerateYearEndTaxPackageQueryVariables>;
export const GetReportPackageByIdDocument = gql`
    query getReportPackageById($reportPackageId: ReportPackageID) {
  getReportPackageById(reportPackageId: $reportPackageId) {
    file {
      ...file
    }
    status
  }
}
    ${FileFragmentDoc}`;

/**
 * __useGetReportPackageByIdQuery__
 *
 * To run a query within a React component, call `useGetReportPackageByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportPackageByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportPackageByIdQuery({
 *   variables: {
 *      reportPackageId: // value for 'reportPackageId'
 *   },
 * });
 */
export function useGetReportPackageByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetReportPackageByIdQuery, GetReportPackageByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportPackageByIdQuery, GetReportPackageByIdQueryVariables>(GetReportPackageByIdDocument, options);
      }
export function useGetReportPackageByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportPackageByIdQuery, GetReportPackageByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportPackageByIdQuery, GetReportPackageByIdQueryVariables>(GetReportPackageByIdDocument, options);
        }
export type GetReportPackageByIdQueryHookResult = ReturnType<typeof useGetReportPackageByIdQuery>;
export type GetReportPackageByIdLazyQueryHookResult = ReturnType<typeof useGetReportPackageByIdLazyQuery>;
export type GetReportPackageByIdQueryResult = Apollo.QueryResult<GetReportPackageByIdQuery, GetReportPackageByIdQueryVariables>;
export const GetReportByIdDocument = gql`
    query getReportById($reportId: ReportID) {
  getReportById(reportId: $reportId) {
    file {
      ...file
    }
    status
  }
}
    ${FileFragmentDoc}`;

/**
 * __useGetReportByIdQuery__
 *
 * To run a query within a React component, call `useGetReportByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportByIdQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useGetReportByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetReportByIdQuery, GetReportByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportByIdQuery, GetReportByIdQueryVariables>(GetReportByIdDocument, options);
      }
export function useGetReportByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportByIdQuery, GetReportByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportByIdQuery, GetReportByIdQueryVariables>(GetReportByIdDocument, options);
        }
export type GetReportByIdQueryHookResult = ReturnType<typeof useGetReportByIdQuery>;
export type GetReportByIdLazyQueryHookResult = ReturnType<typeof useGetReportByIdLazyQuery>;
export type GetReportByIdQueryResult = Apollo.QueryResult<GetReportByIdQuery, GetReportByIdQueryVariables>;
export const StripeValidationRunsDocument = gql`
    query stripeValidationRuns($companyId: ID!, $page: PageInput, $filterBy: StripeValidationRunFilterBy) {
  company(id: $companyId) {
    id
    stripeValidationRuns(page: $page, filterBy: $filterBy) {
      nodes {
        ...stripeValidationRun
      }
      pageInfo {
        ...pageInfo
      }
    }
  }
}
    ${StripeValidationRunFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useStripeValidationRunsQuery__
 *
 * To run a query within a React component, call `useStripeValidationRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeValidationRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeValidationRunsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      page: // value for 'page'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useStripeValidationRunsQuery(baseOptions: Apollo.QueryHookOptions<StripeValidationRunsQuery, StripeValidationRunsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StripeValidationRunsQuery, StripeValidationRunsQueryVariables>(StripeValidationRunsDocument, options);
      }
export function useStripeValidationRunsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StripeValidationRunsQuery, StripeValidationRunsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StripeValidationRunsQuery, StripeValidationRunsQueryVariables>(StripeValidationRunsDocument, options);
        }
export type StripeValidationRunsQueryHookResult = ReturnType<typeof useStripeValidationRunsQuery>;
export type StripeValidationRunsLazyQueryHookResult = ReturnType<typeof useStripeValidationRunsLazyQuery>;
export type StripeValidationRunsQueryResult = Apollo.QueryResult<StripeValidationRunsQuery, StripeValidationRunsQueryVariables>;
export const RunStripeValidationDocument = gql`
    mutation runStripeValidation($input: RunStripeValidationInput!) {
  runStripeValidation(input: $input) {
    ...stripeValidationRun
  }
}
    ${StripeValidationRunFragmentDoc}`;
export type RunStripeValidationMutationFn = Apollo.MutationFunction<RunStripeValidationMutation, RunStripeValidationMutationVariables>;

/**
 * __useRunStripeValidationMutation__
 *
 * To run a mutation, you first call `useRunStripeValidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunStripeValidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runStripeValidationMutation, { data, loading, error }] = useRunStripeValidationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRunStripeValidationMutation(baseOptions?: Apollo.MutationHookOptions<RunStripeValidationMutation, RunStripeValidationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunStripeValidationMutation, RunStripeValidationMutationVariables>(RunStripeValidationDocument, options);
      }
export type RunStripeValidationMutationHookResult = ReturnType<typeof useRunStripeValidationMutation>;
export type RunStripeValidationMutationResult = Apollo.MutationResult<RunStripeValidationMutation>;
export type RunStripeValidationMutationOptions = Apollo.BaseMutationOptions<RunStripeValidationMutation, RunStripeValidationMutationVariables>;