import { useCallback, useEffect, useMemo } from "react";
import { uniqueId } from "lodash";
import { MutationHookOptions } from "@apollo/client";
import { useDebounce } from "use-debounce";
import { getFetchPolicyForKey, ONE_HOUR } from "apollo/getFetchPolicyForKey";

import {
  CreateCustomerInput,
  CustomerFragment,
  CustomerQueryInput,
  CustomerSortOrder,
  UpdateCustomerInput,
} from "graphql/types";
import { useActiveCompany } from "components/companies";

import {
  CreateCustomerMutation,
  CreateCustomerMutationVariables,
  CustomerQuery,
  CustomerQueryVariables,
  CustomersDocument,
  CustomersQuery,
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables,
  useCreateCustomerMutation,
  useCustomerLazyQuery,
  useCustomersQuery,
  useUpdateCustomerMutation,
} from "./graphql.generated";

type CreateCustomerInputWithoutCompany = Omit<CreateCustomerInput, "companyId" | "id">;
type UpdateCustomerInputWithoutCompany = Omit<UpdateCustomerInput, "companyId">;
type GetSingleCustomerInput = Omit<CustomerQueryInput, "companyId">;

export const makeOptimisticCustomer = ({
  name,
  ...rest
}: CreateCustomerInputWithoutCompany): CustomerFragment => ({
  __typename: "Customer",
  id: uniqueId("customer"),
  name,
  ...rest,
});

export const makeOptimisticUpdateCustomer = ({
  name,
  ...rest
}: UpdateCustomerInputWithoutCompany): CustomerFragment => ({
  __typename: "Customer",
  name,
  ...rest,
});

export function useCreateCustomer(
  baseOptions?: MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>
) {
  const { company } = useActiveCompany<true>();

  const [_createCustomer, result] = useCreateCustomerMutation({
    optimisticResponse: ({ input }) => ({
      createCustomer: {
        customer: makeOptimisticCustomer(input),
      },
    }),

    ...baseOptions,
  });

  const createCustomer = useCallback(
    ({ name, ...rest }: CreateCustomerInputWithoutCompany) =>
      _createCustomer({
        variables: {
          input: {
            companyId: company.id,
            name,
            ...rest,
          },
        },

        update(cache, { data }) {
          const customer = data?.createCustomer.customer;
          if (!customer) {
            return;
          }
          const variables = { companyId: company.id };

          const customerData = cache.readQuery<CustomersQuery>({
            query: CustomersDocument,
            variables,
          });

          cache.writeQuery<CustomersQuery>({
            variables,
            query: CustomersDocument,
            data: {
              customers: { items: [...(customerData?.customers.items || []), customer] },
            },
          });
        },
      }),
    [_createCustomer, company.id]
  );

  return [createCustomer, result] as const;
}

export function useCustomer(
  baseOptions?: MutationHookOptions<CustomerQuery, CustomerQueryVariables>
) {
  const { company } = useActiveCompany<true>();

  const [_getSingleCustomer, result] = useCustomerLazyQuery(baseOptions);

  const getSingleCustomer = useCallback(
    ({ id }: GetSingleCustomerInput) => {
      return _getSingleCustomer({
        variables: {
          input: {
            id,
            companyId: company.id,
          },
        },
      });
    },
    [company.id, _getSingleCustomer]
  );

  return [getSingleCustomer, result] as const;
}

export function useUpdateCustomer(
  baseOptions?: MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>
) {
  const { company } = useActiveCompany<true>();

  const [_updateCustomer, result] = useUpdateCustomerMutation({
    optimisticResponse: ({ input }) => ({
      updateCustomer: {
        customer: makeOptimisticUpdateCustomer(input),
      },
    }),

    ...baseOptions,
  });

  const updateCustomer = useCallback(
    ({ name, ...rest }: UpdateCustomerInputWithoutCompany) =>
      _updateCustomer({
        variables: {
          input: {
            companyId: company.id,
            name,
            ...rest,
          },
        },

        update(cache, { data }) {
          const customer = data?.updateCustomer.customer;
          if (!customer) {
            return;
          }
          const variables = { companyId: company.id };

          const customerData = cache.readQuery<CustomersQuery>({
            query: CustomersDocument,
            variables,
          });

          cache.writeQuery<CustomersQuery>({
            variables,
            query: CustomersDocument,
            data: {
              customers: { items: [...(customerData?.customers.items || []), customer] },
            },
          });
        },
      }),
    [_updateCustomer, company.id]
  );

  return [updateCustomer, result] as const;
}

const CachedTerms = new Set<string>();
export const useCustomerSearch = (filterInput: string) => {
  const [debouncedFilter] = useDebounce(filterInput, 500);
  const { company } = useActiveCompany<true>();

  const filter = useMemo(
    () => (CachedTerms.has(filterInput) ? filterInput : debouncedFilter),
    [debouncedFilter, filterInput]
  );

  const result = useCustomersQuery({
    fetchPolicy: filter ? "cache-and-network" : getFetchPolicyForKey("topCustomers", ONE_HOUR),

    variables: {
      companyId: company.id,
      filterBy: {
        name: filter,
      },
      sortBy: filter ? CustomerSortOrder.NameAsc : CustomerSortOrder.InsertOrderDesc,
    },
  });

  useEffect(() => {
    if (!result.loading) {
      CachedTerms.add(filter);
    }
  }, [filter, result.loading]);

  const loading = Boolean(
    (result.loading && !result.data) ||
      (filterInput && filterInput !== result.variables?.filterBy?.name)
  );

  return useMemo(
    () => ({
      ...result,
      loading,
    }),
    [loading, result]
  );
};
