import React, { useRef, useEffect } from "react";
import { useIntersection } from "react-use";
import { styled, Button, IconButton, ScrollArea, Tooltip, hotkeys } from "@puzzle/ui";
import { Close, ReportIssue, Arrow } from "@puzzle/icons";

import CommentBox from "./CommentBox";
import { useDetailPaneContext } from "./DetailPaneContext";
import Loader from "components/common/Loader";
import { useIntercom } from "react-use-intercom";
import Analytics from "lib/analytics";

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
});

const Header = styled("header", {
  display: "flex",
  justifyContent: "space-between",
  padding: "$1h $2 $1h $3",
  alignItems: "center",
  fontSize: "15px",
  lineHeight: "22px",
  letterSpacing: "0.2px",
  fontWeight: "$bold",
  color: "$gray500",
  borderBottom: "1px solid $mauve550",
});

// TODO Will eventually contain tabs
const Subheader = styled(Header, {
  color: "$gray100",
});

const Body = styled(ScrollArea.Viewport, {
  padding: "$3",
});

const Footer = styled("div", {
  padding: "$3",
  borderTop: "1px solid transparent",

  variants: {
    shadow: {
      true: {
        borderColor: "$rhino600",
        boxShadow: "0px -24px 24px -16px $colors$blackA32",
      },
      false: {},
    },
  },
});

const HeaderTitle = styled("div", {
  display: "flex",
  alignItems: "center",
  flex: "1",
  gap: "$1",
});

const HeaderActions = styled("div", {});

const ScrollAnchor = styled("div", {
  marginTop: -10,
  height: 10,
  zIndex: -1,
});

const DetailLayout = ({
  children,
  onNext,
  onPrevious,
}: {
  children: React.ReactNode;
  onNext?: () => void;
  onPrevious?: () => void;
}) => {
  const { transaction, basicTransaction, loading, close } = useDetailPaneContext<true>();
  const intercom = useIntercom();

  useEffect(() => {
    // Hide Intercom launcher when a detail pane is mounted
    intercom.update({ hideDefaultLauncher: true });
    return () => {
      intercom.update({ hideDefaultLauncher: false });
    };
  }, [intercom]);

  useEffect(() => {
    const unsubscribe = hotkeys(window, {
      J: () => {
        onNext && onNext();
      },
      ArrowDown: () => {
        onNext && onNext();
      },
      K: () => {
        onPrevious && onPrevious();
      },
      ArrowUp: () => {
        onPrevious && onPrevious();
      },
    });
    return () => {
      unsubscribe();
    };
  }, [onNext, onPrevious]);

  const scrollAnchorRef = useRef<HTMLDivElement | null>(null);
  const intersection = useIntersection(scrollAnchorRef, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });
  const scrolledToBottom = intersection?.isIntersecting ?? false;

  return (
    <Container>
      <Header>
        <HeaderTitle>
          <div>{(transaction || basicTransaction)?.descriptor || "Transaction"}</div>

          <Tooltip
            content="Report issue"
            side="bottom"
            sideOffset={8}
            align="start"
            alignOffset={32}
            arrow={false}
          >
            <Button
              onClick={() => {
                Analytics.feedbackButtonClicked();
                intercom.showNewMessage();
              }}
              // TODO It's possible IconButton should be closer to this (more square dimensions)? Not sure yet
              css={{
                color: "$gray600",
                padding: "$0",
                height: 24,
                width: 24,
                lineHeight: "0",
              }}
              variant="minimal"
              size="mini"
            >
              <ReportIssue size={14} />
            </Button>
          </Tooltip>
        </HeaderTitle>

        <HeaderActions>
          {onPrevious && (
            <IconButton onClick={onPrevious} disabled={!onPrevious}>
              <Arrow fill="currentColor" rotate={-90} />
            </IconButton>
          )}
          {onNext && (
            <IconButton onClick={onNext} disabled={!onNext}>
              <Arrow fill="currentColor" rotate={90} />
            </IconButton>
          )}
          <IconButton onClick={close} autoFocus>
            <Close color="currentColor" size={12} />
          </IconButton>
        </HeaderActions>
      </Header>

      <Subheader>Overview</Subheader>

      <ScrollArea css={{ flex: 1 }}>
        <Body>
          {loading ? <Loader size={48} /> : children}

          <ScrollAnchor ref={scrollAnchorRef} />
        </Body>

        <ScrollArea.Scrollbar orientation="vertical" css={{ width: "10px !important" }}>
          <ScrollArea.Thumb variant="shadowed" />
        </ScrollArea.Scrollbar>
      </ScrollArea>

      {transaction && (
        <Footer shadow={!scrolledToBottom}>
          <CommentBox
            transaction={transaction}
            onSave={() => {
              scrollAnchorRef.current?.scrollIntoView({ behavior: "smooth" });
            }}
          />
        </Footer>
      )}
    </Container>
  );
};

export default DetailLayout;
